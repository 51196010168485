app.component("preOpPlanAdjuvant", {
	templateUrl: "app/src/js/components/preOpPlan/preOpPlanAdjuvant/preOpPlanAdjuvant.html",
	controller: "preOpPlanAdjuvantController",
	bindings: {
		data: "=",
		eyeTitle: "@",
	},
});

app.controller("preOpPlanAdjuvantController", [
	"$scope",
	"PreOp",
	function ($scope, PreOp) {
		this.$onInit = function () {
			this.PreOp = PreOp;
		};
	},
]);
