app.component("preOpPlanExtras", {
	templateUrl: "app/src/js/components/preOpPlan/preOpPlanExtras/preOpPlanExtras.html",
	controller: "preOpPlanExtrasController",
	bindings: {
		data: "=",
	},
});

app.controller("preOpPlanExtrasController", [
	"$scope",
	"PreOp",
	"$timeout",
	"REACTIONS",
	"ANAESTHETIC",
	"REASON",
	"CurrentPatient",
	"ShareData",
	function ($scope, PreOp, $timeout, REACTIONS, ANAESTHETIC, REASON,CurrentPatient,ShareData) {
		$scope.reactions = REACTIONS;
		$scope.anaesthetic = ANAESTHETIC;
		$scope.reason = REASON;
		$scope.CP = CurrentPatient;
		$scope.SD = ShareData;

		$scope.surgical_plan_allergies = {
			value: $scope.SD.allergies.allergyList.length > 0 ? 'YES' : 'NKDA',
			allergies: $scope.SD.allergies.allergyList,
		};


		$scope.$watch("SD.allergies", function (newValue, oldValue) {
			if (newValue && newValue.patientId === $scope.CP.patient.id) {
				$scope.surgical_plan_allergies.allergies = newValue.allergyList;
			} 
		}, true);

		this.$onInit = function () {
			this.PreOp = PreOp;
		};

		$scope.setSelected = function (array) {
			$scope.$ctrl.data.surgical_plan_allergies.allergies = array;
		}

		$scope.removeAllergy = function (obj) {
			// PreOp.data.surgical_plan_section.surgical_plan_allergies.allergies.splice(index, 1);
			const index = $scope.$ctrl.data.surgical_plan_allergies.allergies.findIndex(t=>t.patient_allergy_id === obj.patient_allergy_id);
			$scope.$ctrl.data.surgical_plan_allergies.allergies.splice(index, 1);
		};
		$scope.reset = function (){
			$scope.$ctrl.data.surgical_plan_anaesthetic.reason = null;
		}

		$scope.addAllergy = function (med, reaction) {
			if (med === "" || med === undefined) {
				return;
			}

			if (reaction === null || reaction === undefined) {
				return;
			}

			if (!$scope.$ctrl.data.surgical_plan_allergies.allergies) {
				// if the allergies array is undefined on the surgical_plan_allergies object, create it.
				$scope.$ctrl.data.surgical_plan_allergies.allergies = [];
			}
			$scope.$ctrl.data.surgical_plan_allergies.allergies.push({
				med_name: med,  
				reaction: reaction.value,
			});
			// reset fields to empty
			this.$ctrl.allergyForm.reaction = null;
			this.$ctrl.allergyForm.medication = undefined;
		};
	},
]);
