app.component("userAdminConsole", {
    templateUrl: "app/src/js/components/admin/userConsole.html",
    controller: "userAdminController",
    bindings: {
        // type: '@',
    },
});

app.controller("userAdminController", [
    "$scope",
    "$modal",
    "AdminService",
    "NgTableParams",
    "USER_TYPE",
    "USER_TITLE",
    "USER_ROLE",
    "$window",
    function ($scope, $modal, AdminService, NgTableParams, USER_TYPE, USER_TITLE, USER_ROLE, $window) {
        $scope.formDisplay = false;
        $scope.AS = AdminService;
        $scope.AS.isProcessing = true;
        $scope.userTypeOptions = USER_TYPE;
        $scope.userTitleOptions = USER_TITLE;
        $scope.roleOptionRequired = true;
        $scope.practiceOptionRequired = true;
        $scope.autocompleteOptions = {
            componentRestrictions: { country: "nz" },
            types: ["geocode"],
        };
        $scope.display = {};
		$scope.display.address = '';
        $scope.roleModel = [];
        $scope.practiceModel  = [];
        $scope.roleOptions = USER_ROLE;
        $scope.practiceOptions = [];

        $scope.toggle = {};
		$scope.toggle.switch = false;

        $scope.switch = function () {
            $scope.toggle.switch = !$scope.toggle.switch;
        }

        $scope.roleOptionSettings = {
            selectionLimit: 1, 
            smartButtonMaxItems: 1,
            smartButtonTextConverter: function (itemText) {
                return itemText;
            },
            buttonClasses: "btn btn-dropdown-multiselect",
            showCheckAll: false,
            showUncheckAll: false,
        };

        $scope.roleOptionEvents = {
            onSelectionChanged: function () {
                $scope.$ctrl.data.roles = $scope.roleModel.length > 0 ? $scope.roleModel.map(t=>t.id) : undefined
                $scope.roleOptionRequired = $scope.$ctrl.data.roles === undefined
            },
        };

        const onPracticeUpdate = function () {
            $scope._practiceModel = $scope.practiceModel.length > 0 ? JSON.stringify($scope.practiceModel) : undefined
            $scope.practiceOptionRequired = $scope._practiceModel === undefined
        };

        $scope.practiceOptionEvents = {
            onSelectionChanged: onPracticeUpdate,
        }


        $scope.practiceOptionSettings = {
            displayProp: 'name',
            smartButtonMaxItems: 3,
            smartButtonTextConverter: function (itemText) {
                return itemText;
            },
            buttonClasses: "btn btn-dropdown-multiselect",
            showCheckAll: false,
            showUncheckAll: false,
            enableSearch: true,
            scrollableHeight: '20em',
	        scrollable: true
        };

        $scope.fetchUser = function () {
            $scope.AS.getUsers().then(function (resp) {
                $scope.tableParams = new NgTableParams({
                    count: 10
                }, {
                    counts: [],
                    dataset: resp.data
                });
            }, function (error) {

            }).then(function () {
                $scope.AS.isProcessing = false;
            })
        };

        $scope.fetchPractices = function () {
            $scope.AS.getAvailablePractices().then(function (resp) {
                $scope.practiceOptions = resp.data;
            }, function (error) {

            }).then(function () {
                $scope.AS.isProcessing = false;
            })
        };

        $scope.fetchSelectedUser = function (userId) {
            $scope.AS.getUserDetail(userId).then(function (resp) {
                $scope.$ctrl.data = resp.data;
                $scope.roleModel = $scope.$ctrl.data.roles.map(t => ({ id: t }));
                $scope.display.address = $scope.$ctrl.data.addresses[0].line1;
                $scope.toggle.switch = resp.data.status === 'ACTIVE';
                // $scope.display.address = $scope.CP.patient.addresses[0].line1;
            }, function (error) {

            }).then(function () {
                $scope.AS.isProcessing = false;
            })
        };

        $scope.fetchSelectedUserPractices = function (userId) {
            $scope.AS.getUserPractices(userId).then(function (resp) {
                //$scope.$ctrl.data = resp.data;
                $scope.practiceModel = resp.data;
                onPracticeUpdate();
            }, function (error) {

            }).then(function () {
                $scope.AS.isProcessing = false;
            })
        };

        $scope.fetchUser();

      

        $scope.createUser = function () {
            $scope.$ctrl.data = {};
            $scope.$ctrl.data.registration = null;
            $scope.$ctrl.data.practices = [];
            $scope.$ctrl.data.roles = [];
            $scope.$ctrl.data.features = {
				advancedPatientSearchFeature: true,
			};
            $scope.display = {};
		    $scope.display.address = '';
            $scope.roleModel = [];
            $scope.practiceModel  = [];
            onPracticeUpdate();
            $scope.fetchPractices();
            $scope.formDisplay = true;
        };

        $scope.selectUser = function (user, index) {
            $scope.createUser();
            $scope.$ctrl.data.id = user.id;
            $scope.fetchSelectedUser(user.id);
            $scope.fetchSelectedUserPractices(user.id);
		}

        $scope.registerOrUpdateUser = function () {
            $scope.$ctrl.data.practices = $scope.practiceModel.map(t => ({ id: t.id }));
            if($scope.$ctrl.data.addresses[0]){
                delete $scope.$ctrl.data.addresses[0].id;
            }
            const isEditUser = $scope.$ctrl.data.id !== undefined
            let handler = $scope.AS.createUser;
            if(isEditUser){
                $scope.$ctrl.data.status = $scope.toggle.switch ? "ACTIVE": "INACTIVE";
                handler = $scope.AS.updateUserDetail;
            }
            handler($scope.$ctrl.data).then(function (resp) {
                $scope.dialogMessage = isEditUser ? "User Updated" : "User Created";
                $scope.successModal = $modal({
					scope: $scope,
					templateUrl: "app/src/views/templates/modal.generic-success.tpl.html",
					show: true,
					title: "Success",
                    onHide: function () {
                        $scope.formDisplay = false;
                        $scope.fetchUser();
                    },
				});
            }, function (error) {
                if (error.data && error.data.status === 409) {
                    $scope.dialogMessage = "User already exists";
                    $scope.successModal = $modal({
                        scope: $scope,
                        templateUrl: "app/src/views/templates/modal.generic-success.tpl.html",
                        show: true,
                        title: "Failure",
                    });
                } else {
                    $scope.serverErrorModal = $modal({
                        scope: $scope,
                        templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
                        show: true,
                        title: "CatTrax has encountered an error",
                    });
                }
            }).then(function () {
                $scope.AS.isProcessing = false;
            })
        };

        $scope.cancel = function () {
            $scope.createUser();
            $scope.formDisplay = false;
        };

        $scope.setUserName = function() {
            $scope.$ctrl.data.userName = $scope.$ctrl.data.email;
        }

        $scope.setAddress = function () {
            if(!$scope.display || !$scope.display.address){
				return;
			}
            $scope.$ctrl.data.addresses = [{
                type: "POSTAL",
                line1: $scope.display.address.formatted_address,
                line2: "",
                city: "",
                postCode: "",
                country: "",
            }];
            $scope.display.address.address_components.map(function (obj) {
                if (obj.types.indexOf("postal_code") !== -1) {
                    $scope.$ctrl.data.addresses[0].postCode = obj.long_name;
                }
            });

            $scope.display.address.address_components.map(function (obj) {
                if (obj.types.indexOf("country") !== -1) {
                    $scope.$ctrl.data.addresses[0].country = obj.long_name;
                }
            });

            $scope.display.address.address_components.map(function (obj) {
                if (obj.types.indexOf("locality") !== -1) {
                    $scope.$ctrl.data.addresses[0].city = obj.long_name;
                }
            });

            $scope.display.address.address_components.map(function (obj) {
                if (obj.types.indexOf("sublocality") !== -1) {
                    $scope.$ctrl.data.addresses[0].line2 = obj.long_name;
                }
            });

        }

        $scope.goBack = function () {
            $window.history.back();
        }

        $scope.hasReferrerRole = function () {                                                          
            return ['OPTOMETRIST', 'GP', 'REGISTERED_NURSE', 'CLINICAL_NURSE_SPECIALIST', 'REGISTRAR', 'OPHTHALMOLOGIST', 'ADMIN'].some(t => $scope.$ctrl.data.roles && $scope.$ctrl.data.roles.includes(t));
        }

        function downloadFile(data, filename, mime, bom) {
			// https://github.com/kennethjiang/js-file-download/blob/master/file-download.js
			var blobData = typeof bom !== "undefined" ? [bom, data] : [data];
			var blob = new Blob(blobData, { type: "application/pdf" });
			if (typeof window.navigator.msSaveBlob !== "undefined") {
				// IE workaround for "HTML7007: One or more blob URLs were
				// revoked by closing the blob for which they were created.
				// These URLs will no longer resolve as the data backing
				// the URL has been freed."
				window.navigator.msSaveBlob(blob, filename);
			} else {
				var blobURL = window.URL.createObjectURL(blob);
				var tempLink = document.createElement("a");
				tempLink.style.display = "none";
				tempLink.href = blobURL;
				tempLink.setAttribute("download", filename);

				// Safari thinks _blank anchor are pop ups. We only want to set _blank
				// target if the browser does not support the HTML5 download attribute.
				// This allows you to download files in desktop safari if pop up blocking
				// is enabled.
				if (typeof tempLink.download === "undefined") {
					tempLink.setAttribute("target", "_blank");
				}

				document.body.appendChild(tempLink);
				tempLink.click();
				document.body.removeChild(tempLink);
				window.URL.revokeObjectURL(blobURL);
				$scope.displaySpinner = false;
			}
		}

        $scope.downloadAnalytics = function () {
			$scope.AS.isProcessing = true;
			$scope.AS.downloadAnalytics()
				.then(
					function (resp) {
                        downloadFile(resp.data, 'user_analytics.csv');
                    },
					function () {
						$scope.serverErrorModal = $modal({
							scope: $scope,
							templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
							show: true,
							title: "CatTrax has encountered an error",
						});
					}
				)
				.then(function () {
					$scope.AS.isProcessing = false;
				});
		};

    },
]);
