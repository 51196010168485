app.component("preOpInsurance", {
	templateUrl: "app/src/js/components/preOp/preOpInsurance/preOpInsurance.html",
	controller: "preOpInsuranceController",
	bindings: {
		hide: "&",
		eyeTitle: "@",
		hideSecondaryInsuranceComponent: "&",
	},
});

app.controller("preOpInsuranceController", [
	"$scope",
	"PreOp",
	"NHI",
	"CurrentPatient",
	"PatientAPI",
	"$timeout",
	"MEDICAL_INSURANCE",
	function ($scope, PreOp, NHI, CurrentPatient, PatientAPI, $timeout, MEDICAL_INSURANCE) {
		this.$onInit = function () {
			$scope.preop = PreOp;
			$scope.PA = PatientAPI;
			this.PA = PatientAPI;
			$scope.CP = CurrentPatient;
			$scope.insuranceOptions = MEDICAL_INSURANCE;
			$scope.insuranceProvider = $scope.CP.patient.insuranceProvider;
		};

		$timeout(function () {
			// focus on input when component opens
			document.querySelector("#insurance").focus();
		}, 200);

		$scope.saveInsurance = function () {
			$scope.CP.patient.insuranceProvider = $scope.insuranceProvider;
			if(!$scope.CP.patient.id) {
				$scope.$ctrl.hide();
				return;
			} 
			$scope.PA.isProcessing = true;
			$scope.PA.update($scope.CP.patient)
				.then(function (resp) {
					$scope.CP.setPatient(resp.data);
				})
				.then(function () {
					$scope.PA.isProcessing = false;
					$scope.$ctrl.hide();
					$scope.$ctrl.hideSecondaryInsuranceComponent();
				})
				.catch(function (err) {
					$scope.PA.isProcessing = false;
					console.log(err);
				});
		};
	},
]);
