app.controller("completeController", [
	"$scope",
	"$timeout",
	"UserService",
	"ReferralsAPI",
	"CurrentPatient",
	"CurrentReferral",
	"$state",
	"$rootScope",
	"$modal",
	'MEDICAL_INSURANCE',
	'$window',
	"Session",
	function($scope, $timeout, UserService, ReferralsAPI, CurrentPatient, CurrentReferral, $state, $rootScope, $modal, MEDICAL_INSURANCE, $window, Session) {
		$scope.CP = CurrentPatient;
		$scope.RA = ReferralsAPI;
		$scope.CR = CurrentReferral;
		$scope.US = UserService;
		$scope.Session = Session;
		$scope.doctorsArray = [];
		$scope.doctorsDhbArray = [];
		$scope.displayOptomsArray = [];
		$scope.override = {};
		$scope.eligible = {};
		$scope.eligible.private = true;
		$scope.showDocWarning = false;

		$scope.dhbName = $scope.CR.referral.outcome.dhb.name;
		$scope.dhbCode = $scope.CR.referral.outcome.dhb.code.toLowerCase();
		$scope.dhbIsAvailable = $scope.CR.referral.outcome.dhb.isAvailable;
		$scope.isCpacRegistered = $scope.CR.referral.outcome.dhb.isCpacRegistered;
		$scope.date = new Date();

		$scope.sanitizerDHBName = function (name) {
			return name.replace('District Health Board','region');
		};

		$scope.filterData = {
			showAll: false
		};
		$scope.selectedDoc = null;

		$scope.getInsuranceName = function (provider) {
			const item = MEDICAL_INSURANCE.find(t => t.value === provider);
			return item ? item.name : 'Add insurance';
		}

		$scope.showSouthernCrossLine = function (provider) {
			return provider === 'SOUTHERN_CROSS' || provider === 'AIA';
		}

		$scope.showOtherInsuranceLine = function (provider) {
			return provider === 'NIB' || provider === 'AA' || provider === 'PARTNERS_LIFE'
				|| provider === 'ACCURO' || provider === 'OTHER';
		}

		$scope.appendInsuranceName = function (provider, isSourthenCross) {
			if(isSourthenCross) {
				if(provider === 'SOUTHERN_CROSS') return 'Southern Cross';
				if(provider === 'AIA') return 'AIA';
				return 'Southern Cross or AIA'
			} else {
				if(provider === 'NIB') return 'NIB';
				if(provider === 'AA') return 'AA';
				if(provider === 'PARTNERS_LIFE') return 'Partners life';
				if(provider === 'ACCURO') return 'Accuro';
				if(provider === 'OTHER') return 'Other';
				return 'NIB, AA, Partners life, Accuro and other'
			}
		}


		$scope.hiddenEmptyString = function (str) {
			return str ? 'NHI: '+str : '';
		}

		$scope.isEligibleSouthernCrossOrAIA = function (provider){
            return $scope.CR.referral.outcome.isEligibleSouthernCross && (provider === 'SOUTHERN_CROSS' || provider === 'AIA');
		}

		$scope.isEligibleOtherInsurance = function (provider) {
			return (provider === 'NIB' || 
			provider === 'AA' || 
			provider === 'PARTNERS_LIFE' || 
			provider === 'ACCURO' || 
			provider === 'OTHER') && $scope.CR.referral.outcome.isEligiblePrivateOtherInsurance;
		}

		$scope.selectDoctor = function(doc) {
			$scope.selectedDoc = doc;
		};

		function resetFormData() {
			$scope.formData = {
				comments: $scope.CR.referral.outcome.additionalComments,
				isOverride: false,
				referralSystem: ""
			};
			$scope.selectedDoc = null;
			$scope.showDocWarning = false;
			$scope.filterData.showAll = false;
			$scope.displayOptomsArray = $scope.doctorsDhbArray;
		}

		// get list of doctors here
		$scope.US.getDoctors().then(
			function(resp) {
				$scope.doctorsArray = resp.data;

				$scope.doctorsArray.forEach(function(doc) {
					if (doc.dhbName.toLowerCase() === $scope.dhbCode.toLowerCase()) {
						$scope.doctorsDhbArray.push(doc);
					}
				});

				$scope.displayOptomsArray = $scope.doctorsDhbArray;
			},
			function(err) {
				console.log(err);
			}
		);

		$scope.displayDoctors = function(bool) {
			$scope.filterData.showAll = bool;
			if ($scope.filterData.showAll === true) {
				$scope.displayOptomsArray = $scope.doctorsArray;
			} else {
				$scope.displayOptomsArray = $scope.doctorsDhbArray;
			}
		};

		$scope.showCompleteReferralModal = function(referralSystem) {
			resetFormData();
			$scope.formData.referralSystem = referralSystem;
			$scope.completeReferralModal = $modal({
				scope: $scope,
				templateUrl: "app/src/views/templates/modal.complete-referral.tpl.html",
				show: true,
				title: "COMPLETE REFERRAL"
			});
			// randomise array here
			$scope.doctorsDhbArray = shuffle($scope.doctorsDhbArray);
		};

		$scope.showCompleteOverrideModal = function(referralSystem) {
			resetFormData();
			$scope.formData.isOverride = true;
			$scope.formData.referralSystem = referralSystem;
			$scope.completeReferralModal = $modal({
				scope: $scope,
				templateUrl: "app/src/views/templates/modal.override.tpl.html",
				show: true,
				title: "OVERRIDE REFERRAL"
			});
		};

		$scope.sendReferral = function(formData) {
			if ($scope.selectedDoc === null && $scope.formData.referralSystem !== "PUBLIC_SYSTEM") {
				// show warning
				$scope.showDocWarning = true;
				return;
			}
			if ($scope.formData.referralSystem !== "PUBLIC_SYSTEM") {
				formData.referredTo = {
					userId: $scope.selectedDoc.id,
					practiceId: $scope.selectedDoc.practiceId,
				};
			}
			$scope.RA.isProcessing = true;

			if (formData.isOverride === true) {
				delete formData.referredTo;
			}

			if ($scope.dhbIsAvailable === "NO" && $scope.formData.referralSystem === "PUBLIC_SYSTEM") {
				// Manual referral API
				const body = {...formData,referredByPracticeId: Session.practice.id};
				$scope.RA.completeManual(body).then(
					function(resp) {
						// download pdf
						downloadFile(
							resp.data,
							"CatTrax_" +
								$scope.CR.referral.id +
								"_" +
								$scope.CP.patient.firstName +
								$scope.CP.patient.lastName +
								"_" +
								getDateString() +
								".pdf"
						);
						$scope.RA.isProcessing = false;
						$scope.completeReferralModal.hide();
						$scope.successOverrideModal = $modal({
							scope: $scope,
							templateUrl: "app/src/views/templates/modal.successful-override.tpl.html",
							show: true,
							title: "SUCCESS",
							backdrop: "static",
							keyboard: false
						});
					},
					function(err) {
						console.log(err);
						$scope.RA.isProcessing = false;
						showErrorModal();
					}
				);
			} else {
				// Standard referral API
				const body = {...formData,referredByPracticeId: Session.practice.id};
				$scope.RA.complete(body).then(
					function(resp) {
						downloadFile(
							resp.data,
							"CatTrax_" +
								$scope.CR.referral.id +
								"_" +
								$scope.CP.patient.firstName +
								$scope.CP.patient.lastName +
								"_" +
								getDateString() +
								".pdf"
						);
						$scope.RA.isProcessing = false;
						$scope.completeReferralModal.hide();
						$scope.successOverrideModal = $modal({
							scope: $scope,
							templateUrl: "app/src/views/templates/modal.successful-override.tpl.html",
							show: true,
							title: "SUCCESS",
							backdrop: "static",
							keyboard: false
						});
					},
					function(err) {
						console.log(err);
						$scope.RA.isProcessing = false;
						showErrorModal();
					}
				);
			}
		};

		function showErrorModal () {
			$scope.serverErrorModal = $modal({
				scope: $scope,
				templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
				show: true,
				title: "CatTrax has encountered an error",
			});
		}

		$scope.startAgain = function() {
			$scope.successOverrideModal.hide();
			$scope.CP.clearPatientResult();
			$state.go("refer.search");
		};

		$scope.backToDashboard = function() {
			$scope.successOverrideModal.hide();
			//$window.history.back();
			$state.go("refer.search");
		}

		$scope.sendOverrideDetails = function() {
			$scope.overrideModal.hide();
			$scope.successOverrideModal = $modal({
				scope: $scope,
				templateUrl: "app/src/views/templates/modal.successful-override.tpl.html",
				show: true,
				title: "SUCCESS"
			});
		};

		$scope.gotoIOL = function() {
			if ($scope.CP.eligibilitySummaryFirstLoad) {
				$state.go("refer.impact");
			} else {
				console.log("show complete public referral modal here");
			}
		};

		$scope.searchPatient = function() {
			$state.go("refer.search");
		}

		$scope.isReferBtnDisabled = function(){
			return ['TECHNICIAN'].some(t => Session.user.roles.includes(t));
		}

		function downloadFile(data, filename, mime, bom) {
			// https://github.com/kennethjiang/js-file-download/blob/master/file-download.js
			var blobData = typeof bom !== "undefined" ? [bom, data] : [data];
			var blob = new Blob(blobData, { type: "application/pdf" });
			if (typeof window.navigator.msSaveBlob !== "undefined") {
				// IE workaround for "HTML7007: One or more blob URLs were
				// revoked by closing the blob for which they were created.
				// These URLs will no longer resolve as the data backing
				// the URL has been freed."
				window.navigator.msSaveBlob(blob, filename);
			} else {
				var blobURL = window.URL.createObjectURL(blob);
				var tempLink = document.createElement("a");
				tempLink.style.display = "none";
				tempLink.href = blobURL;
				tempLink.setAttribute("download", filename);

				// Safari thinks _blank anchor are pop ups. We only want to set _blank
				// target if the browser does not support the HTML5 download attribute.
				// This allows you to download files in desktop safari if pop up blocking
				// is enabled.
				if (typeof tempLink.download === "undefined") {
					tempLink.setAttribute("target", "_blank");
				}

				document.body.appendChild(tempLink);
				tempLink.click();
				document.body.removeChild(tempLink);
				window.URL.revokeObjectURL(blobURL);
			}
		}

		function getDateString() {
			var date = new Date();
			var dateString = new Date(date.getTime() - date.getTimezoneOffset() * 60000)
				.toISOString()
				.split("T")[0]
				.split("-")
				.join("");
			return dateString;
		}

		function shuffle(array) {
			var currentIndex = array.length,
				temporaryValue,
				randomIndex;
			// While there remain elements to shuffle...
			while (0 !== currentIndex) {
				// Pick a remaining element...
				randomIndex = Math.floor(Math.random() * currentIndex);
				currentIndex -= 1;
				// And swap it with the current element.
				temporaryValue = array[currentIndex];
				array[currentIndex] = array[randomIndex];
				array[randomIndex] = temporaryValue;
			}
			return array;
		}
	}
]);
