app.service('CurrentReferral', [ function() {

	var service = {

		referral: {},

		setReferral: function(referral) {
			service.referral = referral;
		},

		clearReferral: function() {
			service.referral = {};
		},


	};

	return service;
}])