app.component("visionStatus", {
	templateUrl: "app/src/js/components/visionRefraction/visionStatus.html",
	controller: "examinationStatusController",
	bindings: {
		data: "=",
		eyeTitle: "@",
		hasDifference: "=",
		isAcuityVisible: "=",
		isLinkHidden: "=",
		preset: "=",
		isVisionModule: "=",
	},
});