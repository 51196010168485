app.component("preOpPlanIntraocular", {
	templateUrl: "app/src/js/components/preOpPlan/preOpPlanIntraocular/preOpPlanIntraocular.html",
	controller: "preOpPlanIntraocularController",
	bindings: {
		data: "<",
		eyeTitle: "@",
	},
});

app.controller("preOpPlanIntraocularController", [
	"$scope",
	"PreOp",
	"LENS_TYPE",
	"LENSMODEL",
	"LENSMODEL_MONOFOCAL",
	"LENSMODEL_MONOFOCAL_TORIC",
	"LENSMODEL_EDOF",
	"LENSMODEL_EDOF_TORIC",
	"LENSMODEL_MULTIFOCAL",
	"LENSMODEL_MULTIFOCAL_TORIC",
	"LENSMODEL_ADDON",
	"LENSMODEL_ADDON_TORIC",
	function ($scope, PreOp, LENS_TYPE, LENSMODEL, LENSMODEL_MONOFOCAL,
		LENSMODEL_MONOFOCAL_TORIC, LENSMODEL_EDOF, LENSMODEL_EDOF_TORIC, LENSMODEL_MULTIFOCAL,
		LENSMODEL_MULTIFOCAL_TORIC, LENSMODEL_ADDON, LENSMODEL_ADDON_TORIC) {
		$scope.LENSMODEL = LENSMODEL.map((t) => t.name);
		$scope.tempLensModel = {};
		this.$onInit = function () {
			this.PreOp = PreOp;
			$scope.lens_type = LENS_TYPE;
			if ($scope.$ctrl.data) {
				$scope.generateLensModelOptional();
				$scope.tempLensModel.selected = $scope.$ctrl.data.lens_model;
			}
		};

		function uniqBy(array) {
			return array.reduce((unique, item) => unique.includes(item) ? unique : [...unique, item], [])
		}

		this.$onChanges = function (changesObj) {
			if (changesObj.data.currentValue) {
				const lens_model = changesObj.data.currentValue.lens_model;
						if (lens_model) {
							$scope.LENSMODEL = uniqBy($scope.LENSMODEL)
							const selected = LENSMODEL.find((t) => t.value === lens_model);
							if (selected) {
								$scope.tempLensModel = {selected: selected.name};
							} else {
								const newOption = { name: lens_model, value: lens_model };
								$scope.LENSMODEL.push(lens_model);
								$scope.LENSMODEL = uniqBy($scope.LENSMODEL)
								$scope.tempLensModel = {selected: lens_model};
							}
						}
			}else{
				if(changesObj.eyeTitle.currentValue === "OD") {
					PreOp.data.surgical_plan_section.surgical_plan_intraocular.od = {};
				}else {
					PreOp.data.surgical_plan_section.surgical_plan_intraocular.os = {};
				}
				
			}
		};

		$scope.generateLensModelOptional = function (){
			if($scope.$ctrl.data.lens_type === "NON_TORIC"){
				$scope.LENSMODEL = LENSMODEL_MONOFOCAL.map((t) => t.name);
			}else if($scope.$ctrl.data.lens_type === "TORIC"){
				$scope.LENSMODEL = LENSMODEL_MONOFOCAL_TORIC.map((t) => t.name);
			}else if($scope.$ctrl.data.lens_type === "EDOF"){
				$scope.LENSMODEL = LENSMODEL_EDOF.map((t) => t.name);
			}else if($scope.$ctrl.data.lens_type === "TORIC_EDOF"){
				$scope.LENSMODEL = LENSMODEL_EDOF_TORIC.map((t) => t.name);
			}else if($scope.$ctrl.data.lens_type === "MULTIFOCAL"){
				$scope.LENSMODEL = LENSMODEL_MULTIFOCAL.map((t) => t.name);
			}else if($scope.$ctrl.data.lens_type === "TORIC_MULTIFOCAL"){
				$scope.LENSMODEL = LENSMODEL_MULTIFOCAL_TORIC.map((t) => t.name);
			}else if($scope.$ctrl.data.lens_type === "NON_TORIC_ADDON"){
				$scope.LENSMODEL = LENSMODEL_ADDON.map((t) => t.name);
			}else if($scope.$ctrl.data.lens_type === "TORIC_ADDON"){
				$scope.LENSMODEL = LENSMODEL_ADDON_TORIC.map((t) => t.name);
			}else {
				$scope.LENSMODEL = LENSMODEL.map((t) => t.name);
			}
			$scope.tempLensModel.selected = null;
		}

		$scope.getNewLensModel = function (search) {
			var newSupes = $scope.LENSMODEL.slice();
			if (search && newSupes.indexOf(search) === -1) {
				newSupes.unshift(search);
			}
			return newSupes;
		};

		$scope.onModelChange = function () {
			if (!$scope.LENSMODEL.find((t) => t === $scope.tempLensModel.selected)) {
				$scope.LENSMODEL.push($scope.tempLensModel.selected);
				$scope.LENSMODEL = uniqBy($scope.LENSMODEL)
			}
			const obj = LENSMODEL.find(t=>t.name === $scope.tempLensModel.selected);
			if(obj){
				$scope.$ctrl.data.lens_model = obj.value;
			}else{
				$scope.$ctrl.data.lens_model = $scope.tempLensModel.selected;
			}
		};
	},
]);
