app.service("PatientAuditAPI", [
	"$http",
	"ENV",
	"Session",
	function ($http, ENV, Session) {
        function delay(milliseconds){
            return new Promise(resolve => {
                setTimeout(resolve, milliseconds);
            });
        }
		var service = {
			isProcessing: false,
			auditObject: null,
			startAudit: async function (patient_id) {
                if(service.auditObject && service.auditObject.patient_id === patient_id){
                    return;
                }else if(service.auditObject && service.auditObject.patient_id !== patient_id){
                    service.endAudit(service.auditObject.patient_id);
                    await delay(2000);
                }
				$http({
					method: "POST",
					url: ENV.API + `/api/patient-audit/patient/${patient_id}?timezone=Pacific/Auckland`,
					headers: {
						Authorization: "Bearer " + Session.token,
                        "X-PRACTICE-ID": `${Session.practice.id}`,
					},
				}).then(
					function successCallback(response) {
						service.auditObject = { patient_audit_id: response.data.patient_audit_id, patient_id: response.data.patient_id };
					},
					function errorCallback(response) {
						console.error(response);
                        service.auditObject = null;
					}
				);
			},

			endAudit: function (patient_id) {
				if (!service.auditObject) {
					return;
				} else if (service.auditObject && service.auditObject.patient_id !== patient_id) {
					//should end current session
                    console.error('cannot end audit')
                    return;
				}
                const _patient_id = service.auditObject.patient_id;
                const _patient_audit_id = service.auditObject.patient_audit_id;
                service.auditObject = null;
				$http({
					method: "PUT",
					url: ENV.API + `/api/patient-audit/patient/${_patient_id}/audit/${_patient_audit_id}`,
					headers: {
						Authorization: "Bearer " + Session.token,
                        "X-PRACTICE-ID": `${Session.practice.id}`,
					},
				}).then(
					function successCallback(response) {
						service.auditObject = null;
					},
					function errorCallback(response) {
						console.error(response);
					}
				);
			},
		};

		return service;
	},
]);
