app.component("preOpPlanBiometry", {
	templateUrl: "app/src/js/components/preOpPlan/preOpPlanBiometry/preOpPlanBiometry.html",
	controller: "preOpPlanBiometryController",
	bindings: {
		data: "=",
		eyeTitle: "@",
		eyeTitleLowercase: "@",
	},
});

app.controller("preOpPlanBiometryController", [
	"$scope",
	"PreOp",
	"LENS_TYPE",
	function ($scope, PreOp, LENS_TYPE) {
		this.$onInit = function () {
			$scope.lens_type = LENS_TYPE;
			this.PreOp = PreOp;
		};
		$scope.$ctrl.regularity = '';
		$scope.PreOp = PreOp;

		$scope.$watch("$ctrl.data", function (newValue, oldValue) {
			if (newValue && !oldValue) {
				$scope.calculation();
			}
		}, true);

		$scope.$watch("PreOp.data.surgical_plan_section.biometry", function (newValue, oldValue) {
			if (newValue && oldValue) {
				if ($scope.$ctrl.eyeTitleLowercase === 'od' && newValue.os && oldValue.os &&
					JSON.stringify(newValue.os).trim() !== JSON.stringify(oldValue.os).trim()) {
					$scope.calculation();
				} else if ($scope.$ctrl.eyeTitleLowercase === 'os' && newValue.od && oldValue.od &&
					JSON.stringify(newValue.od).trim() !== JSON.stringify(oldValue.od).trim()) {
					$scope.calculation();
				}
			}
		}, true);

		$scope.$watch("PreOp.data.vision_section.lens_status", function (newValue, oldValue) {
			if (newValue && oldValue) {
				if (JSON.stringify(newValue).trim() !== JSON.stringify(oldValue).trim()) {
					$scope.calculation();
				}
			}
		}, true);

		function getState(state){
			if(state === 'NORMAL_RANGE') return "Normal";
			if(state === 'VERY LONG') return "Very long";
			if(state === 'NORMAL') return "Normal";
			return state[0] + state.substring(1).toLowerCase();
		}

		function getACDState(state){
			if(!state) return '';
			if(state === 'NORMAL') return "Normal";
			if(state === 'VERY LONG') return "Very long";
			return state[0] + state.substring(1).toLowerCase();
		}

		$scope.calculation = function () {
			if(!$scope.$ctrl.data){
				return;
			}
			if(($scope.$ctrl.data.acd === null || $scope.$ctrl.data.acd === undefined) && !$scope.$ctrl.data.isPreload){
				$scope.$ctrl.data = {};
				$scope.$ctrl.data.k1 = null;
				$scope.$ctrl.data.al = null;
				$scope.$ctrl.al_asymmetry = '';
				$scope.$ctrl.data.acd_assessment = null;
				$scope.$ctrl.acd_asymmetry = '';
			}
			if($scope.$ctrl.data.k1_axis === 0){
				$scope.$ctrl.data.k1_axis = 180;
			}
			if ($scope.$ctrl.data.k1 && $scope.$ctrl.data.k2) {
				$scope.$ctrl.data.corneal_astigmatism_magnitude = Math.abs($scope.$ctrl.data.k1 - $scope.$ctrl.data.k2).toFixed(2)
			}else{
				$scope.$ctrl.data.corneal_astigmatism_magnitude = 0;
			}

			if($scope.$ctrl.data.corneal_astigmatism_magnitude <= 0.5){
				$scope.$ctrl.data.corneal_astigmatism_regularity = null;
			}

			if ($scope.$ctrl.data.k1_axis && $scope.$ctrl.data.k1 && $scope.$ctrl.data.k2) {
				$scope.$ctrl.data.corneal_astigmatism_axis = $scope.$ctrl.data.k1 > $scope.$ctrl.data.k2 ? $scope.$ctrl.data.k1_axis :
					($scope.$ctrl.data.k1_axis > 90 ? $scope.$ctrl.data.k1_axis - 90 : $scope.$ctrl.data.k1_axis + 90)
			}else{
				$scope.$ctrl.data.corneal_astigmatism_axis = null;
			}
			if($scope.$ctrl.data.al) {
				if($scope.$ctrl.data.al <= 20) {
					$scope.$ctrl.data.al_assessment = 'NANOPHTHALMOS';
				}else if($scope.$ctrl.data.al > 20 && $scope.$ctrl.data.al <= 21){
					$scope.$ctrl.data.al_assessment = 'MICROPHTHALMOS';
				}else if($scope.$ctrl.data.al > 21 && $scope.$ctrl.data.al < 24) {
					$scope.$ctrl.data.al_assessment = 'NORMAL_RANGE';
				}else if($scope.$ctrl.data.al >= 24 && $scope.$ctrl.data.al < 26) {
					$scope.$ctrl.data.al_assessment = 'LONG';
				}else {
					$scope.$ctrl.data.al_assessment = 'VERY LONG';
				}
			}
			if($scope.$ctrl.data.corneal_astigmatism_regularity == undefined){
				$scope.$ctrl.regularity = '';
			}else if($scope.$ctrl.data.corneal_astigmatism_regularity){
				$scope.$ctrl.regularity = '(Irregular)';
			}else{
				$scope.$ctrl.regularity = '(Regular)';
			}

			let other_al;
			if($scope.PreOp.data.surgical_plan_section && $scope.$ctrl.eyeTitleLowercase === 'od' && $scope.PreOp.data.surgical_plan_section.biometry && $scope.PreOp.data.surgical_plan_section.biometry.os){
				other_al = $scope.PreOp.data.surgical_plan_section.biometry.os.al
			}else if($scope.PreOp.data.surgical_plan_section && $scope.$ctrl.eyeTitleLowercase === 'os' && $scope.PreOp.data.surgical_plan_section.biometry && $scope.PreOp.data.surgical_plan_section.biometry.od) {
				other_al = $scope.PreOp.data.surgical_plan_section.biometry.od.al
			} 
			if (other_al && $scope.$ctrl.data.al) {
				$scope.$ctrl.data.al_asymmetry = Math.abs(other_al - $scope.$ctrl.data.al).toFixed(2)
				if ($scope.$ctrl.data.al_asymmetry > 0.5) {
					$scope.$ctrl.al_asymmetry = `, asymmetry: ${$scope.$ctrl.data.al_asymmetry}mm`
				} else {
					$scope.$ctrl.al_asymmetry = ''
				}
			}else{
				$scope.$ctrl.al_asymmetry = ''
			}
			

			if ($scope.PreOp.data.vision_section.lens_status[$scope.$ctrl.eyeTitleLowercase].status === 'PHAKIC') {
				if ($scope.$ctrl.data.acd < 2) {
					$scope.$ctrl.data.acd_assessment = 'SHALLOW';
				} else if ($scope.$ctrl.data.acd >= 2 && $scope.$ctrl.data.acd < 3.5) {
					$scope.$ctrl.data.acd_assessment = 'NORMAL';
				} else {
					$scope.$ctrl.data.acd_assessment = 'DEEP';
				}
				$scope.acd_assessment_symmetry = `(${getACDState($scope.$ctrl.data.acd_assessment)})`
			} else {
				$scope.acd_assessment_symmetry = ''
			}

			if ($scope.PreOp.data.vision_section.lens_status.od.status === 'PHAKIC' &&
				$scope.PreOp.data.vision_section.lens_status.os.status === 'PHAKIC' &&
				$scope.PreOp.data.surgical_plan_section.biometry) {
				let other_acd;
				if ($scope.$ctrl.eyeTitleLowercase === 'od' && $scope.PreOp.data.surgical_plan_section.biometry.os) {
					other_acd = $scope.PreOp.data.surgical_plan_section.biometry.os.acd
				} else if($scope.$ctrl.eyeTitleLowercase === 'os' && $scope.PreOp.data.surgical_plan_section.biometry.od) {
					other_acd = $scope.PreOp.data.surgical_plan_section.biometry.od.acd
				}
				if(other_acd){
					$scope.$ctrl.data.acd_asymmetry = Math.abs(other_acd - $scope.$ctrl.data.acd).toFixed(2)
					$scope.$ctrl.acd_asymmetry = `, asymmetry: ${$scope.$ctrl.data.acd_asymmetry}mm`;
					$scope.acd_assessment_symmetry = $scope.$ctrl.data.acd_assessment ? `(${getACDState($scope.$ctrl.data.acd_assessment)}${$scope.$ctrl.acd_asymmetry})` : "";
				}
			} else {
				$scope.$ctrl.acd_asymmetry = '';
				$scope.acd_assessment_symmetry = $scope.$ctrl.data.acd_assessment ? $scope.acd_assessment_symmetry : '';
			}
			$scope.al_assessment_symmetry = $scope.$ctrl.data.al_assessment ? `(${getState($scope.$ctrl.data.al_assessment)}${$scope.$ctrl.al_asymmetry})` : "";
			
		}

	},
]);
