app.controller('calendarController',['$scope', '$mdDialog', '$modal', '$filter',
'AppointmentAPI', 
 'Session', 'uiCalendarConfig', 'patientId','CurrentPatient','PatientAPI','PracticeService', 'CALENDAR_SEEN','patientName', 'CALENDAR_CNA_DNA','selectedAppointment','$state', 'CALENDAR_AVAILABILITY_991',
function($scope, $mdDialog, $modal, $filter, AppointmentAPI, Session,uiCalendarConfig,patientId,CurrentPatient,PatientAPI,PracticeService,CALENDAR_SEEN,patientName, CALENDAR_CNA_DNA,selectedAppointment,$state,CALENDAR_AVAILABILITY_991){

    // $scope.selectedPatientId = patientId;
    // $scope.patientOptions = [];
	$scope.allAppointments = [];
	$scope.appointment_categories = [];
	$scope.ophthalmologists = [];
	$scope.CP = CurrentPatient;
	$scope.PA = PatientAPI;
	$scope.currentSurgeon = null;
	$scope.CALENDAR_SEEN = CALENDAR_SEEN;
	$scope.CALENDAR_CNA_DNA = CALENDAR_CNA_DNA;
	$scope.Session = Session;
	$scope.calendarAvailabilityEnabled = CALENDAR_AVAILABILITY_991;

	$scope.categoriesGroup = [];

	const draftIcon = '<svg style="width:1.75em;height:1.75em;margin-left:-1.8rem; margin-top: -0.2rem" viewBox="0 0 24.00 24.00" fill="none" xmlns="http://www.w3.org/2000/svg" stroke=""><g id="SVGRepo_bgCarrier" stroke-width="0"><rect x="0" y="0" width="24.00" height="24.00" rx="12" fill="#ffffff" strokewidth="0"/></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/><g id="SVGRepo_iconCarrier"> <circle opacity="0.5" cx="12" cy="12" r="10" stroke="#ff0000" stroke-width="1.5"/> <path d="M12 6V18" stroke="#ff0000" stroke-width="1.5" stroke-linecap="round"/> <path d="M15 9.5C15 8.11929 13.6569 7 12 7C10.3431 7 9 8.11929 9 9.5C9 10.8807 10.3431 12 12 12C13.6569 12 15 13.1193 15 14.5C15 15.8807 13.6569 17 12 17C10.3431 17 9 15.8807 9 14.5" stroke="#ff0000" stroke-width="1.5" stroke-linecap="round"/> </g></svg>';
	const pendingIcon = '<svg style="width:1.75em;height:1.75em;margin-left:-1.8rem; margin-top: -0.2rem" viewBox="0 0 24.00 24.00" fill="none" xmlns="http://www.w3.org/2000/svg" stroke=""><g id="SVGRepo_bgCarrier" stroke-width="0"><rect x="0" y="0" width="24.00" height="24.00" rx="12" fill="#ffffff" strokewidth="0"/></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/><g id="SVGRepo_iconCarrier"> <circle opacity="0.5" cx="12" cy="12" r="10" stroke="#ffa500" stroke-width="1.5"/> <path d="M12 6V18" stroke="#ffa500" stroke-width="1.5" stroke-linecap="round"/> <path d="M15 9.5C15 8.11929 13.6569 7 12 7C10.3431 7 9 8.11929 9 9.5C9 10.8807 10.3431 12 12 12C13.6569 12 15 13.1193 15 14.5C15 15.8807 13.6569 17 12 17C10.3431 17 9 15.8807 9 14.5" stroke="#ffa500" stroke-width="1.5" stroke-linecap="round"/> </g></svg>';
	const paidIcon = '<svg style="width:1.75em;height:1.75em;margin-left:-1.8rem; margin-top: -0.2rem" viewBox="0 0 24.00 24.00" fill="none" xmlns="http://www.w3.org/2000/svg" stroke=""><g id="SVGRepo_bgCarrier" stroke-width="0"><rect x="0" y="0" width="24.00" height="24.00" rx="12" fill="#ffffff" strokewidth="0"/></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/><g id="SVGRepo_iconCarrier"> <circle opacity="0.5" cx="12" cy="12" r="10" stroke="#008000" stroke-width="1.5"/> <path d="M12 6V18" stroke="#008000" stroke-width="1.5" stroke-linecap="round"/> <path d="M15 9.5C15 8.11929 13.6569 7 12 7C10.3431 7 9 8.11929 9 9.5C9 10.8807 10.3431 12 12 12C13.6569 12 15 13.1193 15 14.5C15 15.8807 13.6569 17 12 17C10.3431 17 9 15.8807 9 14.5" stroke="#008000" stroke-width="1.5" stroke-linecap="round"/> </g></svg>';

	
	this.$onInit = function () {
		$scope.patientId = patientId;
		$scope.patientName = patientName;
		$scope.API = AppointmentAPI;
		$scope.eventSources = [];
		$scope.PracticeAPI = PracticeService;
		$scope.selectedAppointment = selectedAppointment;
		$scope.practiceSummaryList = [];
		$scope.currentPractice = {id: null,name:''};
		fetchDoctors();
		fetchSetting();
		fetchCalendarAvailability(Session.practice.id);
	};

	this.$onDestroy = function () {
		removePopup();
	}

	function fetchDoctors () {
		$scope.API.getAppointmentUsers().then(
			function (resp) {
				$scope.ophthalmologists = [];
				resp.data.forEach(function (doc) {
					$scope.ophthalmologists.push({
						name: doc.firstName + " " + doc.lastName + `${Number(Session.userId) === doc.id ? ' (Me)' : ''}`,
						value: doc.id,
						practiceSummaryList: doc.practiceSummaryList,
					});
				});
				$scope.ophthalmologists.sort(function (a, b) {
					if (a.name < b.name) {
						return -1;
					}
					if (a.name > b.name) {
						return 1;
					}
					return 0;
				});
				const target = $scope.ophthalmologists.find((t) => t.value == Session.userId);
				if(target){
					$scope.currentSurgeon = target.value;
					$scope.practiceSummaryList = target.practiceSummaryList;
					$scope.currentPractice.id = Session.practice.id;
					$scope.currentPractice.name = Session.practice.name;
				}else{
					$scope.currentSurgeon = 'all';
				}
				
			},
			function (err) {
				console.log(err);
			}
		).then(function () {
			fetchUserSettings();
		});
	}

	$scope.dateTimeHandler = function (strDate) {
		/** 
		const offset = moment(strDate).utcOffset() / 60;
		if (strDate instanceof Date) {
			return strDate.toISOString().replace("Z", `+${offset}:00`);
		}

		return strDate.replace("Z", `+${offset}:00`); */
		return new Date(strDate);
	}

	$scope.formatDateTime = function (strDate) {
		if(strDate instanceof Date){
			return new Date(moment(strDate).utc().toISOString().replace('.000Z', ''));
		}
		const local = moment.utc(strDate).toDate();
		return new Date(moment(local).local());
		// return new Date(moment(strDate).utc().toISOString().replace('.000Z', ''));
	}

	// const examEvent = {backgroundColor: '#378006',
	// borderColor: '#378006'}

	// const otherEvent = {backgroundColor: '#0135d2',
	// borderColor: '#0135d2'}

	function removeDuplicatedObj (arr) {
		return arr.filter((value, index, self) => index === self.findIndex((t) => (t.patient_id === value.patient_id)))
	}

	function routeEvent (category) {
		const obj = $scope.appointment_categories.find(t=>t.category_id === category);
		return obj ?  {backgroundColor: `#${obj.colour}` , borderColor: `#${obj.colour}` } : {}
	}

	function getIcon(invoiceList) {
		if (!invoiceList || invoiceList.length === 0) {
			return null;
		}
		if (invoiceList.some((t) => t.status === "DRAFT")) {
			return draftIcon;
		} else if (invoiceList.some((t) => t.status === "SUBMITTED")) {
			return pendingIcon;
		} else if (invoiceList.every((t) => t.status === "PAID")) {
			return paidIcon;
		}
		return null;
	}

	function renderAppointmentEvents(data) {
		$scope.events = data.map(t=> ({
			title: t.patient_name,
			id: t.patient_appointment_id,
			icon : getIcon(t.invoice_list),
			appointment: {...t},
			start: moment($scope.dateTimeHandler(t.start_date_utc)),
			end: moment($scope.dateTimeHandler(t.start_date_utc)).add(t.duration_minutes, 'm'),
			...routeEvent(t.category_id)
		}))
		$scope.events=[...$scope.events,...$scope.blockedEvents]; // TODO: 991 Calendar Time Availability
		$scope.eventSources = [$scope.events];

		if(uiCalendarConfig.calendars.myCalendar){
			uiCalendarConfig.calendars.myCalendar.fullCalendar('removeEvents');
			uiCalendarConfig.calendars.myCalendar.fullCalendar('addEventSource', $scope.events); 
			uiCalendarConfig.calendars.myCalendar.fullCalendar('rerenderEvents');
		}
	}

	function fetchUserSettings() {
		const target = $scope.categoriesGroup.find((t) => t.practice === $scope.currentPractice.id);
		if (target) {
			$scope.appointment_categories = angular.copy(target.appointment_categories);
			fetchPatientAppointments();
			return;
		}
		$scope.PracticeAPI.isProcessing = true;
		$scope.PracticeAPI.queryCalendarSetting($scope.currentPractice.id)
			.then(
				function (resp) {
					$scope.appointment_categories = angular.copy(resp.data.appointment_categories);
					$scope.categoriesGroup.push({
						practice: $scope.currentPractice.id,
						appointment_categories: $scope.appointment_categories,
					});
				},
				function (error) {
					console.error(error);
				}
			)
			.then(function () {
				$scope.PracticeAPI.isProcessing = false;
				fetchPatientAppointments();
			});
	}

	$scope.calendar_range_start = null;
	$scope.calendar_range_end = null;
 
	function fetchPatientAppointments() {
		$scope.API.isProcessing = true;
		if($scope.eventSources == null){
			$scope.loaded = false;
		}

		if($scope.calendar_range_start && $scope.calendar_range_end && $scope.currentSurgeon){

		}else{
			return;
		}

		// $scope.loaded = false;
		// const today = new Date();
		// const startDate = new Date(today.getFullYear(),today.getMonth()-1, today.getDate());
		let surgeon = $scope.currentSurgeon === 'all' ? '' : $scope.currentSurgeon;

		if($scope.selectedAppointment){
			const start_date_utc = $filter("date")($scope.selectedAppointment.start_date_utc, "yyyy-MM-dd");
			$scope.calendar_range_start = moment(start_date_utc).add(-1,"d").utc().toISOString();
			$scope.calendar_range_end = moment(start_date_utc).add(1,"d").utc().toISOString();
			surgeon = $scope.selectedAppointment.clinician_id;
		}
		
		$scope.API.queryPatientAppointment(surgeon,$scope.calendar_range_start,$scope.calendar_range_end,$scope.currentPractice.id)
			.then(
				function (resp) {
					// $scope.patientOptions = removeDuplicatedObj(resp.data.map(t=>({patient_id:t.patient_id, patient_name: t.patient_name})));
					$scope.allAppointments = angular.copy(resp.data);
					renderAppointmentEvents($scope.allAppointments);
					if($scope.selectedAppointment){
						$scope.alertOnEventClick({id:$scope.selectedAppointment.patient_appointment_id});
						$scope.selectedAppointment = null;
					}
					// $scope.onSurgeonChange();
				},
				function (error) {}
			)
			.then(function () {
				$scope.API.isProcessing = false;
				$scope.loaded = true;
			});
	}

    	/* alert on eventClick */
		$scope.alertOnEventClick = function (data, jsEvent, view) {
			const event = $scope.events.find(t=>t.id === data.id);
			$scope.appointment = event.appointment;
			$scope.appointment_clone = angular.copy(event.appointment);
			const start_date = new Date($scope.appointment.start_date_utc);
			$scope.appointment._startTime = new Date(1970, 0, 1, start_date.getHours(), start_date.getMinutes(), 0);
			fetchAppointmentAudit($scope.appointment.patient_appointment_id);
			$scope.practiceName = queryPracticeName();
			$scope.detailsModal = $modal({
                scope: $scope,
                templateUrl: "app/src/views/templates/modal.appointment-detail.tpl.html",
                show: true,
                backdrop: "static",
                title: "Schedule Appointment",
            });
	        return false;
			// if (data.url) {
			// 	window.open(data.url, "_blank");
			// 	return false;
			// }
		};
		/* alert on Drop */
		$scope.alertOnDrop = function (event, delta, revertFunc, jsEvent, ui, view) {
			const start = moment(utc(event.start));
			if(start.isBefore(moment().subtract(7,'d'))) {
				return revertFunc();
			}
			const appointment = { ...event.appointment, start_date_utc: new Date(event.start) };
			$scope.API.updateAppointment(appointment.patient_id, appointment.patient_appointment_id, toUTCDate(appointment), $scope.currentPractice.id).then(
				function (resp) {
					fetchPatientAppointments();
				},
				function (error) {
					revertFunc();
				}
			)
			//.then(removePopup);
		};
		/* alert on Resize */
		$scope.alertOnResize = function (event, delta, revertFunc, jsEvent, ui, view) {
			let duration = moment.duration(delta);
			let minute = duration.asMinutes();
           
			const appointment = { ...event.appointment, duration_minutes: minute + event.appointment.duration_minutes };
			$scope.API.updateAppointment(appointment.patient_id, appointment.patient_appointment_id, appointment,$scope.currentPractice.id).then(
				function (resp) {
					fetchPatientAppointments();
				},
				function (error) {
					revertFunc();
				}
			);
			
		};

		function selectPatient(patient, appointment) {
			$scope.CP.setPatient(patient, appointment);
			if (!$scope.CP.patientIsMine) {
				//need to transfer
				$scope.isFromCalendar = true;
				$scope.patientDetailsModal = $modal({
					scope: $scope,
					templateUrl: "app/src/views/templates/modal.patient-details.tpl.html",
					show: true,
					title: "PATIENT DETAILS",
				});
			}else{
				$scope.hide();
				$state.go("refer.search");
			}
		}

		$scope.showTransferModal = function () {
			$scope.patientDetailsModal.hide();
			$scope.previousPracticeName = $scope.CP.patient.practice.name;
			$scope.transferModal = $modal({
				scope: $scope,
				templateUrl: "app/src/views/templates/modal.transfer.tpl.html",
				show: true,
				title: "TRANSFER PATIENT",
			});
		};

		$scope.transferPatient = function () {
			$scope.PA.isProcessing = true;
			$scope.PA.transfer($scope.CP.patient.id, Session.userId, Session.practice.id)
				.then(function (resp) {
					$scope.CP.setPatient(resp.data);
				})
				.then(function () {
					$scope.PA.isProcessing = false;
					$scope.transferModal.hide();
					$scope.hide();
				});
		};

		$scope.forwardPatientDashboard = function(appointment) {
			$scope.PA.getPatient(appointment.patient_id).then(function (resp){
				selectPatient(resp.data, appointment);
			}, function (error) {
				$scope.serverErrorModal = $modal({
					scope: $scope,
					templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
					show: true,
					title: "CatTrax has encountered an error",
				});
			});
		}

		function removePopup () {
			var popups = document.querySelectorAll(".popover");
			if (popups) {
				   for (var i = 0; i < popups.length; i++) {
					   var popup = popups[i];
					   var popupElement = angular.element(popup);
					   popupElement.scope().$parent.isOpen = false;
					   popupElement.remove();
				   }
			   }
		}

		function formatTimestamp (timestamp){
			return timestamp;
			// $filter("date")($scope.dateTimeHandler(timestamp), "yyyy-MM-dd HH:mm") 
		}

		$scope.getProgressTimestamp = function (key) {
			if($scope.appointment.patient_seen && $scope.appointment.patient_seen[key]){
				return '(' +$filter("date")(new Date($scope.appointment.patient_seen[`${key}_timestamp`]), "dd/MMM HH:mm") + ')';
			}
			return '';
		}

		function getProgressString(seen) {
			if (!seen) {
				return "";
			} 

			let timestamp = null;
			let seen_latest = null;

			if(seen.doctor_started){
				timestamp = new Date(seen.doctor_started_timestamp);
				seen_latest = 'Doctor started';
			}

			if(seen.doctor_finished){
				if(!timestamp || moment(seen.doctor_finished_timestamp).isAfter(moment(timestamp)) ){
					timestamp = new Date(seen.doctor_finished_timestamp);
					seen_latest = 'Doctor finished';
				}
			}

			if(seen.technician_started) {
				if(!timestamp || moment(seen.technician_started_timestamp).isAfter(moment(timestamp)) ){
					timestamp = new Date(seen.technician_started_timestamp);
					seen_latest = 'Technician started';
				}
			}

			if(seen.technician_finished) {
				if(!timestamp || moment(seen.technician_finished_timestamp).isAfter(moment(timestamp)) ){
					timestamp = new Date(seen.technician_finished_timestamp);
					seen_latest = 'Technician finished';
				}
			}

			if(seen.nurse_started) {
				if(!timestamp || moment(seen.nurse_started_timestamp).isAfter(moment(timestamp)) ){
					seen_latest = 'Nurse started';
					timestamp = new Date(seen.nurse_started_timestamp);
				}
			}

			if(seen.nurse_finished) {
				if(!timestamp || moment(seen.nurse_finished_timestamp).isAfter(moment(timestamp)) ){
					seen_latest = 'Nurse finished';
					timestamp = new Date(seen.nurse_finished_timestamp);
				}
			}


			if(seen.optometrist_started) {
				if(!timestamp || moment(seen.optometrist_started_timestamp).isAfter(moment(timestamp)) ){
					seen_latest = 'Optometrist started';
					timestamp = new Date(seen.optometrist_started_timestamp);
				}
			}

			if(seen.optometrist_finished) {
				if(!timestamp || moment(seen.optometrist_finished_timestamp).isAfter(moment(timestamp)) ){
					seen_latest = 'Optometrist finished';
					timestamp = new Date(seen.optometrist_finished_timestamp);
				}
			}

			if(seen.orthoptist_started) {
				if(!timestamp || moment(seen.orthoptist_started_timestamp).isAfter(moment(timestamp)) ){
					seen_latest = 'Orthoptist started';
					timestamp = new Date(seen.orthoptist_started_timestamp);
				}
			}

			if(seen.orthoptist_finished) {
				if(!timestamp || moment(seen.orthoptist_finished_timestamp).isAfter(moment(timestamp)) ){
					seen_latest = 'Orthoptist finished';
					timestamp = new Date(seen.orthoptist_finished_timestamp);
				}
			}

			if(seen.arrived) {
				if(!timestamp || moment(seen.arrived_timestamp).isAfter(moment(timestamp)) ){
					seen_latest = 'Arrived';
					timestamp = new Date(seen.arrived_timestamp);
				}
			}

			if(seen.departed) {
				if(!timestamp || moment(seen.departed_timestamp).isAfter(moment(timestamp)) ){
					seen_latest = 'Departed';
					timestamp = new Date(seen.departed_timestamp);
				}
			}

			if(seen.could_not_attend) {
				if(!timestamp || moment(seen.could_not_attend_timestamp).isAfter(moment(timestamp)) ){
					seen_latest = 'CNA';
					timestamp = new Date(seen.could_not_attend_timestamp);
				}
			}

			if(seen.did_not_attend) {
				if(!timestamp || moment(seen.did_not_attend_timestamp).isAfter(moment(timestamp)) ){
					seen_latest = 'DNA';
					timestamp = new Date(seen.did_not_attend_timestamp);
				}
			}

			if(seen.rebooked) {
				if(!timestamp || moment(seen.rebooked_timestamp).isAfter(moment(timestamp)) ){
					seen_latest = 'Rebooked';
					timestamp = new Date(seen.rebooked_timestamp);
				}
			}

			if(seen_latest && timestamp){
				return `(${seen_latest}: ${$filter("date")(new Date(timestamp), "HH:mm")})`
			}
			return "";
		}

		$scope.eventMouseout = function(event, jsEvent, view) {
			removePopup();
		}

		function renderInvoiceDetail(invoiceList, note) {
			let result = note ? note : '';
			if(invoiceList){
				invoiceList.forEach(t=>{result+= `<p>$${t.total} - ${t.status}</p>`})
			}
			return `<div>${result}</div>`;
		  }
		

		/* Render Tooltip */
		$scope.eventRender = function (eventObj, $el, view) {
			// $el.attr({
			// 	"uib-tooltip": eventObj.appointment.note,
			// 	'tooltip-append-to-body': true
			// });
			// $compile($el)($scope);
			if(!eventObj.appointment){
				return;
			}
			if(eventObj && ['agendaWeek','month','listWeek'].includes(view.name)){
				let element = $el.find(".fc-list-item-title");
				// eventObj.title = 'hello world';
				if(element.length === 0) {
					element = $el.find(".fc-title");
				}
				if(view.name === 'listWeek'){
					element[0].innerHTML = `${eventObj.title} ${getProgressString(eventObj.appointment.patient_seen)}`;
				}
				
				element.attr('style',"font-family:'Gordita Regular';text-decoration: underline;display:flex;cursor: pointer;padding-left:24px; height: 2.5rem").click(function(event) {
					event.preventDefault();
					event.stopPropagation();
					//removePopup();
					$scope.forwardPatientDashboard(eventObj.appointment);
				});
				const timeElement = $el.find(".fc-list-item-time");
				if(timeElement){
					timeElement.attr("style","cursor: pointer");
				}
			}

			if (eventObj.icon && view.name === "listWeek") {
				// $el.find(".fc-content").prepend(eventObj.icon);
				$el.find(".fc-list-item-title").prepend(eventObj.icon);
			} else if (eventObj.icon && view.name === "agendaDay") {
				$el.find(".fc-title").attr("style", "margin-left:2rem;height:2.5rem;display:flex").prepend(eventObj.icon);
			} else if (eventObj.icon) {
				$el.find(".fc-content").attr("style", "padding-top:0.2rem;padding-bottom:0.2rem");
				$el.find(".fc-title").prepend(eventObj.icon);
			}

			removePopup();
			// if(eventObj.appointment.note){
				$el.popover({
					title: eventObj.title,
					content: $(renderInvoiceDetail(eventObj.appointment.invoice_list, eventObj.appointment.note)),
					trigger: 'hover',
					placement: 'top',
					container: 'body',
					html: true
				  });
			// }
		
		};

		$scope.dayClick = function (info) {
			// alert('clicked ' + info.dateStr);
		};

		$scope.onCategoryChange = function (){
			if(!$scope.appointment.category_id){
				return;
			}
			const obj = $scope.appointment_categories.find(t=>t.category_id === $scope.appointment.category_id);
			if(obj) {
				$scope.appointment.duration_minutes = obj.duration;
			}
		}

		$scope.selectAllow = function (selectInfo) {
			const event = $scope.blockedEvents[0];
			if(moment(event.end).isBefore(selectInfo.start)){
				return true;
			}else if(moment(event.start).isAfter(selectInfo.end)){
				return true;
			}
			return false;
		}

		$scope.select = function (_range_start, _range_end) {
			$scope.appointmentAuditList = [];
			const range_start = moment(utc(_range_start));
			const range_end = moment(utc(_range_end));
		    
			if (range_start.isBefore(moment().subtract(7,'d')) || !$scope.patientId || !$scope.currentSurgeon || $scope.currentSurgeon === "all") {
				uiCalendarConfig.calendars.myCalendar.fullCalendar("unselect");
				alert('To book an appointment, you first need to select a patient using patient search')
				return false;
			}
			const start = range_start;
			const end = range_end;
			var duration = moment.duration(end.diff(start));
			var minute = duration.asMinutes();
			const clinician = $scope.ophthalmologists.find((t) => t.value == $scope.currentSurgeon);

			const _start_date_time = new Date(range_start);

			var localTime  = moment.utc(range_start).toDate();
			localTime = moment(localTime).format('HH:mm:ss');
			const array = localTime.split(':');
		
			$scope.appointment = {
				patient_name: $scope.patientName,
				start_date_utc: _start_date_time,
				_startTime: new Date(1970, 0, 1, array[0], array[1], 0),
				patient_id: $scope.patientId,
				clinician_id: clinician.value,
				clinician_name: clinician.name,
				// clinician_id: Session.userId,
				duration_minutes: minute,
				timezone: "Pacific/Auckland",
			}
			$scope.practiceName = queryPracticeName();
			$scope.detailsModal = $modal({
                scope: $scope,
                templateUrl: "app/src/views/templates/modal.appointment-detail.tpl.html",
                show: true,
                backdrop: "static",
                title: "Schedule Appointment",
            });
		};

		$scope.closeDetailsModal = function(){
			if($scope.appointment_clone && $scope.appointment.patient_appointment_id){
				const event = $scope.events.find((t) => t.appointment.patient_appointment_id === $scope.appointment.patient_appointment_id);
				event.appointment = $scope.appointment_clone
			}
			$scope.detailsModal.hide();
		}

		function queryPracticeName() {
			const target = $scope.practiceSummaryList.find(t=>t.id === $scope.currentPractice.id);
			if(target){
				return target.name;
			}
			return $scope.Session.practice.name;
		}

		function eventAfterAllRender (view) {
			if(view.name !== "listWeek"){
				return;
			}
			view.el.find('.fc-widget-header').each(function() {
				const selectedDate = $(this).parent().attr('data-date');
				$(this).append('<span class="fc-list-heading-alt"><a href><img src="app/build/img/download.svg"></a> &nbsp;</span>').click(function (event){
					event.preventDefault();
					event.stopPropagation();
					downloadPdf(selectedDate);
				});
			  })
		}

		function viewRender(view, element) {
			$scope.calendar_range_start = utc(view.start);;
			$scope.calendar_range_end = utc(view.end);
			fetchPatientAppointments();
		}

		// var date = new Date();
		// var d = date.getDate();
		// var m = date.getMonth();
		// var y = date.getFullYear();

		/* event source that contains custom events on the scope */
		// $scope.events = [
		// 	{ title: "All Day Event", start: new Date(y, m, 1) },
		// 	{ title: "Long Event", start: new Date(y, m, d - 5), end: new Date(y, m, d - 2) },
		// 	{ id: 999, title: "Repeating Event", start: new Date(y, m, d - 3, 16, 0), allDay: false },
		// 	{ id: 999, title: "Repeating Event", start: new Date(y, m, d + 4, 16, 0), allDay: false },
		// 	{ title: "Birthday Party", start: new Date(y, m, d + 1, 19, 0), end: new Date(y, m, d + 1, 22, 30), allDay: false },
		// 	{ title: "Click for Google", start: new Date(y, m, 28), end: new Date(y, m, 29), url: "http://google.com/" },
		// ];
		// $scope.eventSources = [$scope.events];
		$scope.uiConfig = {
			calendar: {
				editable: true,
				nowIndicator: true,
				// eventStartEditable: true,
				selectable: true,
				selectHelper: true,
				header: {
					// left: "month basicWeek basicDay agendaWeek agendaDay",
					left: "month agendaWeek agendaDay listWeek",
					center: "title",
					right: "today prev,next",
				},
				defaultView: 'agendaWeek',
				columnHeaderFormat: 'ddd DD/MMM/YY',
				eventClick: $scope.alertOnEventClick,
				eventDrop: $scope.alertOnDrop,
				eventResize: $scope.alertOnResize,
				eventRender: $scope.eventRender,
				eventMouseout: $scope.eventMouseout,
				eventAfterAllRender : eventAfterAllRender,
				dayClick: $scope.dayClick,
				selectConstraint: "businessHours",
				eventConstraint: "businessHours",
				select: $scope.select,
				viewRender: viewRender,
				businessHours: {
					dow: [0,1, 2, 3, 4, 5,6], // Monday - Thursday
					start: "7:00", // a start time (10am in this example)
					end: "22:00", // an end time (6pm in this example)
				},
				slotDuration: "00:10:00",
				minTime: "07:00:00",
				maxTime: "22:15:00",
				allDaySlot: false,
				// eventLimit: true,
				eventLimitText: "More",
				eventLimit: 3,
				height:'parent',
				// contentHeight:400,
				views: {
					agendaWeek: {
						type: "agenda",
						duration: { days: 7 },
						buttonText: "week",
					},
				},
				// selectAllow: $scope.selectAllow TODO: 991 Calendar Time Availability
			},
		};
		$scope.hide = function () {
			$mdDialog.hide();
		};

		$scope.cancel = function () {
			$mdDialog.cancel();
		};

		$scope.answer = function (answer) {
			$mdDialog.hide(answer);
		};

		$scope.deleteAppointment = function (obj) {
			$scope.API.isProcessing = true;

			$scope.API.deleteAppointment(obj.patient_id, 
				obj.patient_appointment_id, $scope.currentPractice.id)
				.then(
					function (resp) {
						fetchPatientAppointments();
					},
					function (error) {}
				)
				.then(function () {
					$scope.API.isProcessing = false;
					if ($scope.detailsModal) {
						$scope.detailsModal.hide();
					}
				});
		};

		$scope.onPatientSeenChange = function (item) {
			if($scope.appointment.patient_seen[item]){
				$scope.appointment.patient_seen[`${item}_timestamp`] = new Date().toISOString();
			}else{
				$scope.appointment.patient_seen[`${item}_timestamp`] = null;
			}

			if(!$scope.appointment.patient_seen.could_not_attend && !$scope.appointment.patient_seen.did_not_attend){
				$scope.appointment.patient_seen.rebooked_timestamp = null;
				$scope.appointment.patient_seen.rebooked = false;
			}
		}

		function toUTCDate (appointment) {
			const local = new Date(appointment.start_date_utc).toISOString();
			const local_utc = local.replace('.000Z','');
			return {...appointment,start_date_utc: moment(local_utc).toISOString()};
		}

		function utc (date) {
			const local = new Date(date).toISOString();
			const local_utc = local.replace('.000Z','');
			return  moment(local_utc).toISOString();
		}

		$scope.saveAppointment = function (valid) {
			if (valid) {
				$scope.API.isProcessing = true;
				if($scope.appointment._startTime){
					const original = new Date($scope.appointment.start_date_utc);
					const time = utc($scope.appointment._startTime).split('T')[1];
					original.setHours(time.split(':')[0]);
					original.setMinutes(time.split(':')[1]);
					$scope.appointment.start_date_utc = original;
				}
				if ($scope.appointment.patient_appointment_id) {
					$scope.API.updateAppointment(
						$scope.appointment.patient_id,
						$scope.appointment.patient_appointment_id,
						$scope.appointment,$scope.currentPractice.id
					)
						.then(
							function (resp) {
								fetchPatientAppointments();
							},
							function (error) {}
						)
						.then(function () {
							$scope.API.isProcessing = false;
							if ($scope.detailsModal) {
								$scope.detailsModal.hide();
							}
						});
					return;
				}

				const appointment = $scope.appointment._startTime ? $scope.appointment : toUTCDate($scope.appointment);

				$scope.API.bookAppointment(appointment,$scope.currentPractice.id)
					.then(
						function (resp) {
							fetchPatientAppointments();
						},
						function (error) {}
					)
					.then(function () {
						$scope.API.isProcessing = false;
						if ($scope.detailsModal) {
							$scope.detailsModal.hide();
						}
					});
			} else {
				console.log("invalid form");
			}
		};

		$scope.appointmentAuditList = [];

		$scope.utc2local = function(utc) {
			return $filter("date")(moment.utc(utc).toDate(), "dd/MM/yyyy HH:mm");
		}

		function fetchAppointmentAudit(appointmentId) {
			$scope.appointmentAuditList = [];
			$scope.API.getReminderAudit(appointmentId, $scope.currentPractice.id).then(function(response){
				$scope.appointmentAuditList = response.data;
				$scope.appointmentAuditList.sort((a, b) => a.reminder_sent_time_UTC - b.reminder_sent_time_UTC);
			})
		}

		$scope.sendManualReminder = function (patient_appointment_id,patient_id){
			$scope.API.isProcessing = true;
			$scope.API.sendReminder(patient_appointment_id, patient_id)
				.then(
					function () {
						fetchAppointmentAudit(patient_appointment_id);
					},
					function () {
						$scope.serverErrorModal = $modal({
							scope: $scope,
							templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
							show: true,
							title: "CatTrax has encountered an error",
						});
					}
				)
				.then(function () {
					$scope.API.isProcessing = false;
				});
		}

		$scope.onSurgeonChange = function() {
			/** 
			if(!$scope.currentSurgeon ||$scope.currentSurgeon === 'all'){
				return renderAppointmentEvents($scope.allAppointments);
			}
			if($scope.currentSurgeon){
				return renderAppointmentEvents($scope.allAppointments.filter(t=>t.clinician_id === $scope.currentSurgeon));
			}*/
			$scope.practiceSummaryList = [];
	
			// if(`${$scope.currentSurgeon}` === Session.userId){
				const target = $scope.ophthalmologists.find((t) => t.value == $scope.currentSurgeon);
				if (target) {
					$scope.practiceSummaryList = target.practiceSummaryList;
					if (!$scope.practiceSummaryList.find((t) => t.id === $scope.currentPractice.id)) {
						$scope.currentPractice.id = target.practiceSummaryList[0].id;
					}
				}
				
			// }
			fetchPatientAppointments();
		  }

		  $scope.onPracticeChange = function() {
			// fetchPatientAppointments();
			fetchCalendarAvailability($scope.currentPractice.id);
			fetchUserSettings();
		  }

		  function downloadFile(data, filename, mime, bom) {
			// https://github.com/kennethjiang/js-file-download/blob/master/file-download.js
			var blobData = typeof bom !== "undefined" ? [bom, data] : [data];
			var blob = new Blob(blobData, { type: "application/pdf" });
			if (typeof window.navigator.msSaveBlob !== "undefined") {
				// IE workaround for "HTML7007: One or more blob URLs were
				// revoked by closing the blob for which they were created.
				// These URLs will no longer resolve as the data backing
				// the URL has been freed."
				window.navigator.msSaveBlob(blob, filename);
			} else {
				var blobURL = window.URL.createObjectURL(blob);
				var tempLink = document.createElement("a");
				tempLink.style.display = "none";
				tempLink.href = blobURL;
				tempLink.setAttribute("download", filename);

				// Safari thinks _blank anchor are pop ups. We only want to set _blank
				// target if the browser does not support the HTML5 download attribute.
				// This allows you to download files in desktop safari if pop up blocking
				// is enabled.
				if (typeof tempLink.download === "undefined") {
					tempLink.setAttribute("target", "_blank");
				}

				document.body.appendChild(tempLink);
				tempLink.click();
				document.body.removeChild(tempLink);
				window.URL.revokeObjectURL(blobURL);
				$scope.displaySpinner = false;
			}
		}

		function getFileName() {
            var theTime = $filter("date")(new Date(), "yyyy-MM-dd@hmma");
            return (
                "CatTrax_appointments_" +
                theTime +
                ".pdf"
            );
        }

		function downloadPdf(selectedDate){
		    const events = $scope.events.filter((t) => {
				const local = moment.utc(t.appointment.start_date_utc).toDate();
				return moment(local).local().format('YYYY-MM-DD').startsWith(selectedDate);
			});
			const data = {
				clinician_id: Number($scope.currentSurgeon), 
				appointment_list_items: events.map(t=>({patient_id: t.appointment.patient_id, appointment_id: t.appointment.patient_appointment_id})),
			};
			$scope.API.downloadAppointmentList(data, $scope.currentPractice.id).then(function (resp) {
				downloadFile(resp.data, getFileName());
			});
		}

		$scope.getAppointmentType = function () {
			if ($scope.settingOptions) {
				const target = $scope.settingOptions.find((t) => t.appointment_category_id === $scope.appointment.category_id);
				if (target && (target.email_reminders_enabled || target.sms_reminders_enabled)) {
					return false;
				} else {
					return true;
				}
			}
			return false;
		};

		$scope.isManualReminderDisabled = function () {
			let isCategoryEnabled = false;
			let isLastReminderEnabled = false;
			//check category category_id
			if ($scope.settingOptions) {
				const target = $scope.settingOptions.find((t) => t.appointment_category_id === $scope.appointment.category_id);
				if (target && (target.email_reminders_enabled || target.sms_reminders_enabled)) {
					isCategoryEnabled = true;
				}
			}
		    
			const latest = $scope.appointmentAuditList[$scope.appointmentAuditList.length - 1];
			if (!latest && !isCategoryEnabled) {
				return true;
			}

			if(latest && latest.reminder_sent_time_UTC){
				const differ = new Date() - moment.utc(latest.reminder_sent_time_UTC).toDate();
				if (differ > 1000 * 60 * 10) {
					isLastReminderEnabled = true;
				}
			}else{
				isLastReminderEnabled = true;
			}
			
			return !isCategoryEnabled || !isLastReminderEnabled;
		};

		$scope.isManualReminderShow = function (){
			const practiceFeatures = Session.practice.practiceFeatures;
			if($scope.appointment.patient_appointment_id && practiceFeatures && (practiceFeatures.appointmentEmailReminderFeature || practiceFeatures.appointmentSMSReminderFeature)){
				return true;
			}
			return false;
		}

		$scope.settingOptions = [];
		function fetchSetting() {
			$scope.settingOptions = [];
			$scope.API.isProcessing = true;
			$scope.API.getAppointmentReminderSetting($scope.Session.user.id)
				.then(
					function (response) {
						if(response.data && response.data.category_reminder_settings){
							$scope.settingOptions = response.data.category_reminder_settings;
						}
					},
					function (error) {
						console.log(error);
					}
				)
				.then(() => ($scope.API.isProcessing = false));
		}

		$scope.blockedEvents = [];

		function transformBlockedEvents (data) {
			const events = data.filter(t=>t.recurrence_type === 'ONE_OFF');

			const join = (date,time) => {
				let _time = time;
				if(time.split(':')[0].length < 2){
					_time = '0'+_time;
				}
				return date.split('T')[0] + 'T' + _time;
			}
				
			return events.map(t=>({
				start: join(t.date_occurrence_utc,t.start_time_local),
				end: join(t.date_occurrence_utc,t.end_time_local),
				rendering: 'background',
				editable: false,
				startEditable: false,
				durationEditable: false,
				resourceEditable: false,
				overlap: false,
				color: '#ff0000'
			}));
			// return [{
			// 	start: '2025-03-18T00:00:00',
			// 	end: '2025-03-21T00:00:00',
			// 	rendering: 'background',
			// 	editable: false,
			// 	startEditable: false,
			// 	durationEditable: false,
			// 	resourceEditable: false,
			// 	overlap: false,
			// 	color: 'red'
			//   }] TODO: 991 Calendar Time Availability
		}

		function fetchCalendarAvailability(practiceId) {
			if(!$scope.calendarAvailabilityEnabled){
				return;
			}
			$scope.API.isProcessing = true;
			$scope.blockedEvents = [];
			$scope.API.getPracticeAvailability(practiceId)
				.then(
					function (resp) {
						$scope.blockedEvents = transformBlockedEvents(resp.data);
					},
					function (error) {
						console.error(error);
					}
				)
				.then(() => ($scope.API.isProcessing = false));
		}
}]);