app.component("ouStatus", {
	templateUrl: "app/src/js/components/examination/ouStatus.html",
	controller: "ouStatusController",
	bindings: {
		data: "=",
		eyeTitle: "@",
		showBest: "=",
		showUncorrected: "=",
		isAcuityVisible: "=",
		isLinkHidden: "=",
	},
});
app.controller("ouStatusController", [
	"$scope",
	"LETTER",
	"CYL",
	"DEVICE",
	"$timeout",
	"LINE",
	function ($scope, LETTER, CYL, DEVICE, $timeout, LINE) {
		$scope.visualacuityline = LINE;
		$scope.letter = LETTER;
		$scope.cyl = CYL;
		$scope.device = DEVICE;
		$scope.alwaysHidden = true;
		$scope.marginTop = '3.7em'
		$scope.paddingTop = '7.4em'

		$scope.$watch("$ctrl.isAcuityVisible", function (newValue, oldValue) {
			if (newValue) {
				$scope.marginTop = '26.4em';
				$scope.paddingTop = 0;
			} else {
				$scope.marginTop = '11.5em';
				$scope.paddingTop = 0;
			}
		}, true);

		$scope.$watch("$ctrl.isLinkHidden", function (newValue, oldValue) {
			if (newValue || (!newValue && !$scope.$ctrl.isAcuityVisible)) {
				$scope.marginTop = '11.5em';
				$scope.paddingTop = 0;
			} else {
				$scope.marginTop = '14em';
				$scope.paddingTop = 0;
			}
		}, true);

		$scope.$watch("$ctrl.showBest", function (newValue, oldValue) {
			if (newValue && $scope.$ctrl.isAcuityVisible) {
				$scope.marginTop = '26.4em';
				$scope.paddingTop = 0;
			} else if (newValue && !$scope.$ctrl.isLinkHidden) {
				$scope.marginTop = '11.5em'; //14em
				$scope.paddingTop = 0;
			} else if (newValue && $scope.$ctrl.isLinkHidden && !$scope.$ctrl.isAcuityVisible){
				$scope.marginTop = '11.5em';
				$scope.paddingTop = 0;
			} else {
				$scope.marginTop = '14em'; //11.5em
				$scope.paddingTop = 0;
			}
		}, true);

	

		$timeout(function () {
			if ($scope.$ctrl.data == undefined) {
				$scope.$ctrl.data = {};
			}
			// UI requires these fields to default to "0" if no value present.
			// $timeout used to wait and see if DB provides value (hack)
			// if ($scope.$ctrl.data.best_va_letter == null || $scope.$ctrl.data.best_va_letter == undefined) {
			// 	$scope.$ctrl.data.best_va_letter = 0;
			// }
			// if ($scope.$ctrl.data.uncorrected_va_letter == null || $scope.$ctrl.data.uncorrected_va_letter == undefined) {
			// 	$scope.$ctrl.data.uncorrected_va_letter = 0;
			// }
		}, 800);
	},
]);
