app.component("profileMenu", {
	templateUrl: "app/src/js/directives/profileMenu.html",
	controller: "profileMenuController",
	bindings: {
        changeEmail: "&",
	},
});

app.controller("profileMenuController", [
	"$scope",
    "$modal",
    "$window",
    "Session",
    "BillingAPI",
	function ($scope,$modal,$window, Session, BillingAPI) {

        this.$onInit = function () {
            $scope.xeroEnabled = Session.practice.practiceFeatures.billingFeature === true;
            $scope.BillingAPI = BillingAPI;
            $scope.XeroStateReady = false;
            $scope.isXeroLoggedIn = false;
            if($scope.xeroEnabled && Session.practice && Session.practice.id){
                getLoggedIn();
            }
        }

        $scope.loginPopup = function (){
            $scope.loginInvoiceModal = $modal({
                scope: $scope,
                templateUrl: "app/src/views/templates/modal.invoice-login.tpl.html",
                show: true,
                backdrop: "static",
                keyboard: false,
            });
        }
    
        $scope.logoutXero = function (){
            $scope.BillingAPI.isProcessing = true;
            $scope.BillingAPI.logoutXero().then(function (resp) {
                $scope.isXeroLoggedIn = false;
                Session.setXeroLoginState(false);
            }, function (error) {
                console.error(error);
            }).then(function(){
                $scope.BillingAPI.isProcessing = false;
            });
            
        }
    
        function getLoggedIn() {
            $scope.BillingAPI.isProcessing = true;
            $scope.BillingAPI.getLoggedIn().then(function (resp) {
                $scope.XeroStateReady = true;
                if(resp.data.logged_in_to){
                    $scope.isXeroLoggedIn = true;
                    Session.setXeroLoginState(true);
                }else{
                    // getXeroOrg();
                }
            }, function (error) {
                console.error(error);
            }).then(function(){
                $scope.BillingAPI.isProcessing = false;
            });
        }

        // function getXeroOrg() {
        //     $scope.BillingAPI.isProcessing = true;
        //     $scope.BillingAPI.getXeroOrg().then(function (resp) {
        //         console.log(`${JSON.stringify(resp.data)}`)
        //     }, function (error) {
        //         console.error(error);
        //     }).then(function(){
        //         $scope.BillingAPI.isProcessing = false;
        //     });
        // }

        $scope.loginXero = function () {
            if($scope.loginInvoiceModal){
                $scope.loginInvoiceModal.hide();
            }
            $scope.BillingAPI.isProcessing = true;
            $scope.BillingAPI.getXeroLoginUrl().then(function(resp){
                const xeroUrl = resp.data;
                $window.open(xeroUrl, "_blank", "location=no,toolbar=yes,scrollbars=yes,resizable=yes,top=200,left=500,width=500,height=600");
            },function(error){
                console.error(error);
            }).then(function(){
                $scope.BillingAPI.isProcessing = false;
            })
        }
		
	},
]);
