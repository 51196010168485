app.service("ContactAPI", [
	"$http",
	"ENV",
	"Session",
	function ($http, ENV, Session) {
		var service = {
			isProcessing: false,

			tracePatient: function (userId, practiceId, patientId) {
				return $http({
					method: "POST",
					url: ENV.API + "/api/tracing",
					headers: {
						Authorization: "Bearer " + Session.token,
						"Content-Type": "application/json",
					},
					data: {
						userId: userId,
						practiceId: practiceId,
						patientId: patientId,
					},
				});
			},

			traceUser: function (userId, practiceId) {
				return $http({
					method: "POST",
					url: ENV.API + "/api/tracing",
					headers: {
						Authorization: "Bearer " + Session.token,
						"Content-Type": "application/json",
					},
					data: {
						userId: userId,
						practiceId: practiceId,
					},
				});
			},
		};

		return service;
	},
]);
