app.component("procedureSettings", {
	templateUrl: "app/src/js/components/surgeryAgreement/procedureSettings.html",
	controller: "procedureSettingsController",
	bindings: {
		data: "<",
		eyeTitle: "@",
		surgerySettings: "<",
	},
});

app.controller("procedureSettingsController", [
	"$scope",
	"SURGERY_AGREEMENT_TYPE",
	"ANAESTHETIC_TYPE",
	"SurgeryAgreementAPI",
	function ($scope, SURGERY_AGREEMENT_TYPE, ANAESTHETIC_TYPE, SurgeryAgreementAPI) {
		this.$onInit = function () {
			$scope.form = { procedure_settings_od: {}, procedure_settings_os: {} };
			$scope.SURGERY_AGREEMENT_TYPE = SURGERY_AGREEMENT_TYPE;
			$scope.ANAESTHETIC_TYPE = ANAESTHETIC_TYPE;
			$scope.SAAPI = SurgeryAgreementAPI;
		};

		$scope.primaryProcedureCallback = function (form){
			if(!form.diagnosis_object){
				$scope.$ctrl.data.primary_procedure = null;
				return;
			}
			$scope.$ctrl.data.primary_procedure = {...form.diagnosis_object, concept_id: form.diagnosis_object.conceptId};

			if($scope.$ctrl.surgerySettings && 
				$scope.$ctrl.surgerySettings.length > 0) {
				const target = $scope.$ctrl.surgerySettings.find(t=>t.unique_procedure_key === form.diagnosis_object.conceptId);
				if(target){
					$scope.$ctrl.data.risks = target.risks.join('\n\n');
					$scope.$ctrl.data.anaesthetic = target.anaesthetic;
					$scope.$ctrl.data.laymans_procedure_description = target.laymans_procedure_description;
					$scope.$ctrl.data.after_surgery_advice = target.after_surgery_advice.join('\n\n');
					// $scope.$ctrl.setAfterSurgeryAdvice({value: target.after_surgery_advice.join('\n\n')})
					// $scope.$ctrl.data.risks = target.risks.join('\n\n');
				}
			}



			// const obj = $scope.$ctrl.otherTypeSetting.find(t => t.primary_procedure.concept_id === form.diagnosis_object.conceptId);
			// if(obj){
			// 	$scope.$ctrl.data.other_procedure.secondary_procedure_list = angular.copy(obj.secondary_procedure_list);
			// 	$scope.$ctrl.data.other_procedure.description = obj.description;
			// 	$scope.$ctrl.data.anaesthetic = obj.anaesthetic;
			// 	$scope.$ctrl.data.instructions_for_patient = obj.instructions_for_patient;
			// 	$scope.$ctrl.data.intraoperative_medications = obj.intraoperative_medications;

			// 	if($scope.$ctrl.setDefaultMedications){
			// 	   $scope.$ctrl.setDefaultMedications({value: obj.postop_medications, eyes: $scope.$ctrl.eyeTitle});
			// 	}
			// } else {
			// 	$scope.$ctrl.data.other_procedure.secondary_procedure_list = [];
			// 	$scope.$ctrl.data.other_procedure.description = null;
			// 	$scope.$ctrl.data.anaesthetic = null;
			// 	$scope.$ctrl.data.instructions_for_patient = null;
			// 	$scope.$ctrl.data.intraoperative_medications = null;
			// }
		}

		function generateUniqueProcedureKey (primary_procedure,secondary_procedure_list) {
			let key = primary_procedure.concept_id;
			if(secondary_procedure_list && secondary_procedure_list.length > 0){
				secondary_procedure_list.forEach(t=>{
					key = key+"-"+t.concept_id
				})
			}
			return key;
		}

		$scope.secondaryProcedureCallback = function (form){
			if(!$scope.$ctrl.data.secondary_procedure_list){
				$scope.$ctrl.data.secondary_procedure_list = [];
			}
			
			if (form) {
				const array = form.map(t => ({ ...t, concept_id: t.conceptId }));
				$scope.$ctrl.data.secondary_procedure_list = array;
				const key = generateUniqueProcedureKey($scope.$ctrl.data.primary_procedure,array);
                
				if($scope.$ctrl.surgerySettings && 
					$scope.$ctrl.surgerySettings.length > 0) {
					const target = $scope.$ctrl.surgerySettings.find(t=>t.unique_procedure_key === key);
					if(target){
						$scope.$ctrl.data.risks = target.risks.join('\n\n');
						$scope.$ctrl.data.anaesthetic = target.anaesthetic;
					    $scope.$ctrl.data.laymans_procedure_description = target.laymans_procedure_description;
						$scope.$ctrl.data.after_surgery_advice = target.after_surgery_advice.join('\n\n');
						// $scope.$ctrl.setAfterSurgeryAdvice({value: target.after_surgery_advice.join('\n\n')})
						// $scope.$ctrl.data.risks = target.risks.join('\n\n');
					}
				}

			}
			
		}
	},
]);
