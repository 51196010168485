app.controller("loginController", [
	"$scope",
	"$window",
	"$timeout",
	"$modal",
	"$state",
	"AuthService",
	"UserService",
	"Session",
	"AUTHENTICATION_STATE",
	function ($scope, $window, $timeout, $modal, $state, AuthService, UserService, Session, AUTHENTICATION_STATE) {
		$scope.AS = AuthService;
		$scope.US = UserService;
		$scope.controllerName = "loginController";
		$scope.page = $state.current.name;
		$scope.creds = {};
		$scope.requestSent = false;
		$scope.requestSuccessful = false;
		$scope.requestUnsuccessful = false;
		$scope.mfaParameters = {};
		$scope.mfaConfigFirstStep = false;
		$scope.mfaConfigSecondStep = false;
		$scope.mfaConfigFinalStep = false; 
		$scope.secretCode = '';
		$scope.form = {};
		$scope.showPassword = false;
		$scope.showPassword2 = false;
		$scope.showPassword3 = false;
		$scope.showPassword4 = false;

		function callback(creds, authEvent) {
			if (authEvent.authenticationState === AUTHENTICATION_STATE.waitingMFACode) {
				$scope.requestSent = false;
				$scope.AS.isProcessing = false;
				$scope.mfaParameters = { ...authEvent, creds };
				$scope.mfaVerifyModal = $modal({
					scope: $scope,
					templateUrl: "app/src/views/templates/modal.mfa-verify.tpl.html",
					show: true,
					title: "Please enter the 6-digit code from your authenticator app",
					backdrop: "static",
					keyboard: false,
					onHide: function () {
						// document.querySelector("#password").select();
					},
				});
			} else if (authEvent.authenticationState === AUTHENTICATION_STATE.configMFA) {
				$scope.AS.isProcessing = false;
				$scope.mfaParameters = { ...authEvent, creds };
				$scope.mfaConfigFirstStep = true;
				$scope.mfaConfigSecondStep = false;
				$scope.mfaConfigFinalStep = false;
				$scope.mfaConfigModal = $modal({
					scope: $scope,
					templateUrl: "app/src/views/templates/modal.mfa-config.tpl.html",
					show: true,
					title: "Security upgrade for new features",
					backdrop: "static",
					keyboard: false,
					onHide: function () {
						// document.querySelector("#password").select();
					},
				});
			} else if (authEvent.authenticationState === AUTHENTICATION_STATE.normal) {
				$state.go("welcome");
			} else if (authEvent.authenticationState === AUTHENTICATION_STATE.updateTempPassword) {
				$scope.newUserName = authEvent.userName;
				$scope.changePasswordModal = $modal({
					scope: $scope,
					templateUrl: "app/src/views/templates/modal.change-password.tpl.html",
					show: true,
					title: "Welcome to CatTrax",
					keyboard: false,
					backdrop: "static",
				});
				$timeout(function () {
					document.querySelector("#pw1").focus();
				}, 200);
			}
		}

		$scope.backToFirstStep = function () {
			$scope.mfaConfigFirstStep = true;
			$scope.mfaConfigSecondStep = false;
		}

		$scope.mfaCodeChange = function (code){
			if(!code){
				$scope.form.mfaVerifyForm && $scope.form.mfaVerifyForm.code.$setValidity("wrongMFA", true);
				$scope.form.mfaConfigForm && $scope.form.mfaConfigForm.code.$setValidity("wrongMFA", true);
			}
		}

		$scope.requestMFAVerify = function (code) {
			$scope.AS.isProcessing = true;
			$scope.AS.verifyMfaCode($scope.mfaParameters.creds,
				code).then(function (resp) {
					Session.create(resp.data.access_token, resp.data.expires_in, resp.headers('x-user-id'), resp.data.idp_access_token, resp.data.refresh_token);
					$scope.cancelMFAConfig();
				},
					function (err) {
						if(err && err.status === 401){
							$scope.form.mfaVerifyForm.code.$setValidity("wrongMFA", false);
						}
					}
				).then(function(){
					$scope.AS.isProcessing = false;
				})
		}

		$scope.cancelMFAConfig = function () {
			if ($scope.mfaConfigModal || $scope.mfaVerifyModal) {
				$scope.US.getUser().then(function (resp) {
					Session.setCurrentUser(resp.data);
					if($scope.mfaConfigModal){
						$scope.mfaConfigModal.hide();
					}
					if($scope.mfaVerifyModal){
						$scope.mfaVerifyModal.hide();
					}
					$state.go("welcome");
				}, function(error){
					console.log(error);
					$scope.serverErrorModal = $modal({
						scope: $scope,
						templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
						show: true,
						title: "CatTrax has encountered an error",
					});
				})
			}
		}

		$scope.showMFASecond = function () {
			$scope.AS.isProcessing = true;
			$scope.AS.initialMfa($scope.mfaParameters.idp_access_token).then(function (resp) {
					$scope.mfaConfigSecondStep = true;
					$scope.mfaConfigFirstStep = false;
					$scope.secretCode =`otpauth://totp/CatTrax?secret=${resp.data.secret_code}`;
				}, function (error) { 
					console.log(error);
					$scope.serverErrorModal = $modal({
						scope: $scope,
						templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
						show: true,
						title: "CatTrax has encountered an error",
					});
				}).then(function () {
					$scope.AS.isProcessing = false;
				})
		}

		$scope.completeMfaConfig = function (code) {
			$scope.AS.completeMfa($scope.mfaParameters.idp_access_token,
				code).then(function (resp) {
					if (resp.status === 204) {
						$scope.mfaConfigFinalStep = true;
						$scope.mfaConfigFirstStep = false;
						$scope.mfaConfigSecondStep = false;
						$scope.$apply();
					}
				}, function (error) {
					if(error){
						$scope.form.mfaConfigForm.code.$setValidity("wrongMFA", false);
					}
				})
		}

		$scope.goDashboard = function () {
			$scope.cancelMFAConfig();
		}

		$scope.onSubmit = function (creds) {
			document.querySelector("#login-submit").blur();
			$scope.AS.login(creds, callback).then(
				function () {
					// $state.go("welcome");
				},
				function (err) {
					$scope.AS.isProcessing = false;
					$scope.badLoginModal = $modal({
						scope: $scope,
						templateUrl: "app/src/views/templates/modal.bad-login.tpl.html",
						show: true,
						title: "Oops!",
						onHide: function () {
							document.querySelector("#password").select();
						},
					});
					// set focus to ok button here
					$timeout(function () {
						$window.document.querySelector("#ok-button").focus();
					}, 200);
				}
			);
		};

		$scope.contact = function () {
			$scope.loginContactModal = $modal({
				scope: $scope,
				templateUrl: "app/src/views/templates/modal.login-contact.tpl.html",
				show: true,
				title: "Register to use CatTrax",
			});
		};

		$scope.lostPassword = function () {
			$scope.requestSent = false;
			$scope.requestSuccessful = false;
			$scope.requestUnsuccessful = false;

			$scope.lostPasswordModal = $modal({
				scope: $scope,
				templateUrl: "app/src/views/templates/modal.lost-password.tpl.html",
				show: true,
				title: "Password Reset",
			});
		};

		function resetPasswordError() {
			$scope.requestUnsuccessful = true;
			$scope.requestSuccessful = false;
			$scope.requestSent = true;
		}

		$scope.requestPwReset = function (email) {
			$scope.AS.getPasswordResetToken(email).then(
				function (response) {
					if(response.status === 204){
						$scope.setPassword = {
							password: "",
							passwordVerify: "",
							resetCode: "",
							email: email
						};
						$scope.requestSent = true;
						$scope.requestSuccessful = true;
						
					}else{
						resetPasswordError();
					}
				},
				function () {
					resetPasswordError();
				}
			);
		};

		$scope.passwordChangeNewUser = function (password, oldPassword) {
			$scope.US.isProcessing = true;
			$scope.US.setPassword(password,oldPassword,$scope.newUserName)
				.then(
					function (resp) {
						Session.destroy();
						$scope.changePasswordModal.hide();
						window.location.reload();
						// $scope.US.isProcessing = false;
					},
					function (err) {
						if (err) {
							$scope.samePasswordError = true;
							$timeout(function () {
								document.querySelector("#pw1").select();
							}, 200);
						}
						// $scope.US.isProcessing = false;
						// throw new Error("Same Password");
					}
				)
				.then(function (obj) {
					// window.location.reload();
					$scope.US.isProcessing = false;
				});
		};

		$scope.passwordChange = function (resetCode,password) {
			$scope.AS.resetPassword(resetCode, password, $scope.setPassword.email).then(
				function () {
					// show success message
					$scope.lostPasswordModal.hide();
					$scope.successPwChangeModal = $modal({
						scope: $scope,
						templateUrl: "app/src/views/templates/modal.success-pw-change.tpl.html",
						show: true,
						title: "Password Reset",
						keyboard: false,
						backdrop: "static",
						onHide: function () {
							$state.go("login", {}, { inherit: false });
						},
					});
				},
				function () {
					// show failure message
					$scope.serverErrorModal = $modal({
						scope: $scope,
						templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
						show: true,
						title: "CatTrax has encountered an error",
					});
				}
			);
		};

		$scope.onKeyDown = function(event){
			if(event.keyCode === 13){
				document.querySelector("#login-submit").focus();
			}
		}

		$scope.toggleShowPassword = function(){
			$scope.showPassword = !$scope.showPassword;
		}

		$scope.toggleShowPassword2 = function(){
			$scope.showPassword2 = !$scope.showPassword2;
		}

		$scope.toggleShowPassword3 = function(){
			$scope.showPassword3 = !$scope.showPassword3;
		}

		$scope.toggleShowPassword4 = function(){
			$scope.showPassword4 = !$scope.showPassword4;
		}

	},
]);