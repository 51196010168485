app.component("preOpHistoryUveitis", {
	templateUrl: "app/src/js/components/preOpHistory/preOpHistoryUveitis/preOpHistoryUveitis.html",
	controller: "preOpHistoryUveitisController",
	bindings: {
		data: "=",
		eyeTitle: "@",
		eyeTitleLowercase: "@",
	},
});

app.controller("preOpHistoryUveitisController", [
	"$scope",
	"PreOp",
	function ($scope, PreOp) {
		this.$onInit = function () {
			this.PreOp = PreOp;
		};
		$scope.$watch("$ctrl.data.prev_uveitis", function (newValue, oldValue) {
			if (newValue === 'NO') {
				$scope.$ctrl.data.posterior_synechiae = null;
			}
		}, true);
	},
]);
