app.component("maculopathyPopUp", {
	templateUrl: "app/src/js/components/preOpHistory/preOpDiabetes/maculopathyPopUp/maculopathyPopUp.html",
	controller: "maculopathyPopUpController",
	bindings: {
		data: "=",
		eyeTitle: "@",
	},
});

app.controller("maculopathyPopUpController", [
	"$scope",
	"PreOp",
	function ($scope, PreOp) {
		$scope.preop = PreOp;
	},
]);
