app.component("dymoDiagnosis", {
	templateUrl: "app/src/js/components/dymoDiagnosis/dymoDiagnosis.html",
	controller: "dymoDiagnosisController",
});

app.controller("dymoDiagnosisController", [
	"$scope",
	"$mdDialog",
	"CurrentPatient",
	"Session",
	"$filter",
	"UserService",
  '$window',
	function ($scope, $mdDialog, CurrentPatient, Session, $filter, UserService,$window) {
		$scope.$mdDialog = $mdDialog;
		$scope.CP = CurrentPatient;
		$scope.Session = Session;

		$scope.US = UserService;

		$scope.isEnvReady = false;

		$scope.label = null;

		$scope.printerOptions = [];
		$scope.printersSelect = null;

		$scope.printCopies = 2;

		$scope.ophthalmologists = [];
		$scope.selectedUser = null;

		// called when the document completly loaded
		function onload() {}

		$scope.loadPrintersAsync = function () {
			$scope.printerOptions = [];
			// const printersSelect = document.getElementById("printersSelect");
			dymo.label.framework
				.getPrintersAsync()
				.then(function (printers) {
					if (printers.length == 0) {
						alert("No DYMO printers are installed. Install DYMO printers.");
						return;
					}
					printers.forEach(function (printer) {
						let printerName = printer["name"];
						// let option = document.createElement("option");
						$scope.printerOptions.push({ value: printerName, name: printerName });
						// option.value = printerName;
						// option.appendChild(document.createTextNode(printerName));
						// printersSelect.appendChild(option);
					});
					// $scope.populatePrinterDetail();
					$scope.printersSelect = $scope.printerOptions[0].value;
					$scope.$apply();
				})
				.thenCatch(function (e) {
					alert("Load Printers failed: " + e);
					return;
				});
		};

		$scope.printLabel = function () {
			try {
				if (!$scope.label) {
					alert("Load label before printing");
					return;
				}
				// const printersSelect = document.getElementById("printersSelect");
				$scope.label.print(
					$scope.printersSelect,
					`<LabelWriterPrintParams><Copies>${$scope.printCopies}</Copies></LabelWriterPrintParams>`
				);
				$mdDialog.hide();
			} catch (e) {
				alert(e.message || e);
			}
		};

		this.$onInit = function () {
			const isMac = $window.navigator.userAgent.indexOf("Mac OS X") !== -1 ? true : false;

      $scope.downloadLink = isMac ? 'https://download.dymo.com/dymo/Software/Mac/DCDMac1.4.4.18.pkg': 'https://download.dymo.com/dymo/Software/Win/DCDSetup1.4.5.1.exe';

			if (dymo.label.framework.init) {
				dymo.label.framework.init(onload);
			} else {
				onload();
			}
			$scope.checkEnv();
		};

		function updatePreview() {
			if (!$scope.label) return;

      try {
        var pngData = $scope.label.render();
        var labelImage = document.getElementById("labelImage");
        if (labelImage) {
          labelImage.src = "data:image/png;base64," + pngData;
        }
      }catch(e){
        $scope.isEnvReady = false;
        $scope.label = null;
      }

		
		}

		$scope.loadPatient = function () {
			const user = $scope.selectedUser
				? $scope.selectedUser
				: '';
			const testAddressLabelXml = `<?xml version="1.0" encoding="utf-8"?>
<DesktopLabel Version="1">
  <DYMOLabel Version="4">
    <Description>DYMO Label</Description>
    <Orientation>Landscape</Orientation>
    <LabelName>Addresss0722370</LabelName>
    <InitialLength>0</InitialLength>
    <BorderStyle>SolidLine</BorderStyle>
    <DYMORect>
      <DYMOPoint>
        <X>0.23</X>
        <Y>0.060000002</Y>
      </DYMOPoint>
      <Size>
        <Width>3.21</Width>
        <Height>0.9966666</Height>
      </Size>
    </DYMORect>
    <BorderColor>
      <SolidColorBrush>
        <Color A="1" R="0" G="0" B="0"></Color>
      </SolidColorBrush>
    </BorderColor>
    <BorderThickness>1</BorderThickness>
    <Show_Border>False</Show_Border>
    <HasFixedLength>False</HasFixedLength>
    <FixedLengthValue>0</FixedLengthValue>
    <DynamicLayoutManager>
      <RotationBehavior>ClearObjects</RotationBehavior>
      <LabelObjects>
        <TextObject>
          <Name>Name</Name>
          <Brushes>
            <BackgroundBrush>
              <SolidColorBrush>
                <Color A="0" R="1" G="1" B="1"></Color>
              </SolidColorBrush>
            </BackgroundBrush>
            <BorderBrush>
              <SolidColorBrush>
                <Color A="1" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </BorderBrush>
            <StrokeBrush>
              <SolidColorBrush>
                <Color A="1" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </StrokeBrush>
            <FillBrush>
              <SolidColorBrush>
                <Color A="0" R="1" G="1" B="1"></Color>
              </SolidColorBrush>
            </FillBrush>
          </Brushes>
          <Rotation>Rotation0</Rotation>
          <OutlineThickness>1</OutlineThickness>
          <IsOutlined>False</IsOutlined>
          <BorderStyle>SolidLine</BorderStyle>
          <Margin>
            <DYMOThickness Left="0" Top="0" Right="0" Bottom="0" />
          </Margin>
          <HorizontalAlignment>Left</HorizontalAlignment>
          <VerticalAlignment>Middle</VerticalAlignment>
          <FitMode>AlwaysFit</FitMode>
          <IsVertical>False</IsVertical>
          <FormattedText>
            <FitMode>AlwaysFit</FitMode>
            <HorizontalAlignment>Left</HorizontalAlignment>
            <VerticalAlignment>Middle</VerticalAlignment>
            <IsVertical>False</IsVertical>
            <LineTextSpan>
              <TextSpan>
                <Text>${$scope.CP.patient.firstName + " " + $scope.CP.patient.lastName}, </Text>
                <FontInfo>
                  <FontName>Arial</FontName>
                  <FontSize>11</FontSize>
                  <IsBold>True</IsBold>
                  <IsItalic>False</IsItalic>
                  <IsUnderline>False</IsUnderline>
                  <FontBrush>
                    <SolidColorBrush>
                      <Color A="1" R="0" G="0" B="0"></Color>
                    </SolidColorBrush>
                  </FontBrush>
                </FontInfo>
              </TextSpan>
            </LineTextSpan>
          </FormattedText>
          <ObjectLayout>
            <DYMOPoint>
              <X>0.22999997</X>
              <Y>0.08135716</Y>
            </DYMOPoint>
            <Size>
              <Width>1.57601</Width>
              <Height>0.34190157</Height>
            </Size>
          </ObjectLayout>
        </TextObject>
        <TextObject>
          <Name>Text_1</Name>
          <Brushes>
            <BackgroundBrush>
              <SolidColorBrush>
                <Color A="0" R="1" G="1" B="1"></Color>
              </SolidColorBrush>
            </BackgroundBrush>
            <BorderBrush>
              <SolidColorBrush>
                <Color A="1" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </BorderBrush>
            <StrokeBrush>
              <SolidColorBrush>
                <Color A="1" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </StrokeBrush>
            <FillBrush>
              <SolidColorBrush>
                <Color A="0" R="1" G="1" B="1"></Color>
              </SolidColorBrush>
            </FillBrush>
          </Brushes>
          <Rotation>Rotation0</Rotation>
          <OutlineThickness>1</OutlineThickness>
          <IsOutlined>False</IsOutlined>
          <BorderStyle>SolidLine</BorderStyle>
          <Margin>
            <DYMOThickness Left="0" Top="0" Right="0" Bottom="0" />
          </Margin>
          <HorizontalAlignment>Left</HorizontalAlignment>
          <VerticalAlignment>Middle</VerticalAlignment>
          <FitMode>AlwaysFit</FitMode>
          <IsVertical>False</IsVertical>
          <FormattedText>
            <FitMode>AlwaysFit</FitMode>
            <HorizontalAlignment>Left</HorizontalAlignment>
            <VerticalAlignment>Middle</VerticalAlignment>
            <IsVertical>False</IsVertical>
            <LineTextSpan>
              <TextSpan>
                <Text>NHI:${$scope.CP.patient.nhi}, Ph:${$scope.CP.patient.phoneNumber}\n${user}, ${$scope.Session.practice.name}</Text>
                <FontInfo>
                  <FontName>Arial</FontName>
                  <FontSize>25.6</FontSize>
                  <IsBold>False</IsBold>
                  <IsItalic>False</IsItalic>
                  <IsUnderline>False</IsUnderline>
                  <FontBrush>
                    <SolidColorBrush>
                      <Color A="1" R="0" G="0" B="0"></Color>
                    </SolidColorBrush>
                  </FontBrush>
                </FontInfo>
              </TextSpan>
            </LineTextSpan>
          </FormattedText>
          <ObjectLayout>
            <DYMOPoint>
              <X>0.24000001</X>
              <Y>0.5731335</Y>
            </DYMOPoint>
            <Size>
              <Width>3.0490665</Width>
              <Height>0.41215533</Height>
            </Size>
          </ObjectLayout>
        </TextObject>
        <TextObject>
          <Name>Name2</Name>
          <Brushes>
            <BackgroundBrush>
              <SolidColorBrush>
                <Color A="0" R="1" G="1" B="1"></Color>
              </SolidColorBrush>
            </BackgroundBrush>
            <BorderBrush>
              <SolidColorBrush>
                <Color A="1" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </BorderBrush>
            <StrokeBrush>
              <SolidColorBrush>
                <Color A="1" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </StrokeBrush>
            <FillBrush>
              <SolidColorBrush>
                <Color A="0" R="1" G="1" B="1"></Color>
              </SolidColorBrush>
            </FillBrush>
          </Brushes>
          <Rotation>Rotation0</Rotation>
          <OutlineThickness>1</OutlineThickness>
          <IsOutlined>False</IsOutlined>
          <BorderStyle>SolidLine</BorderStyle>
          <Margin>
            <DYMOThickness Left="0" Top="0" Right="0" Bottom="0" />
          </Margin>
          <HorizontalAlignment>Left</HorizontalAlignment>
          <VerticalAlignment>Middle</VerticalAlignment>
          <FitMode>AlwaysFit</FitMode>
          <IsVertical>False</IsVertical>
          <FormattedText>
            <FitMode>AlwaysFit</FitMode>
            <HorizontalAlignment>Left</HorizontalAlignment>
            <VerticalAlignment>Middle</VerticalAlignment>
            <IsVertical>False</IsVertical>
            <LineTextSpan>
              <TextSpan> 
                <Text>DOB:${$filter("date")($scope.CP.patient.dateOfBirth, "dd/MM/yyyy")}</Text>
                <FontInfo>
                  <FontName>Arial</FontName>
                  <FontSize>21.6</FontSize>
                  <IsBold>False</IsBold>
                  <IsItalic>False</IsItalic>
                  <IsUnderline>False</IsUnderline>
                  <FontBrush>
                    <SolidColorBrush>
                      <Color A="1" R="0" G="0" B="0"></Color>
                    </SolidColorBrush>
                  </FontBrush>
                </FontInfo>
              </TextSpan>
            </LineTextSpan>
          </FormattedText>
          <ObjectLayout>
            <DYMOPoint>
              <X>1.8060099</X>
              <Y>0.07</Y>
            </DYMOPoint>
            <Size>
              <Width>1.6050001</Width>
              <Height>0.34894708</Height>
            </Size>
          </ObjectLayout>
        </TextObject>
      </LabelObjects>
    </DynamicLayoutManager>
  </DYMOLabel>
  <LabelApplication>Blank</LabelApplication>
  <DataTable>
    <Columns></Columns>
    <Rows></Rows>
  </DataTable>
</DesktopLabel>`;
			$scope.label = dymo.label.framework.openLabelXml(testAddressLabelXml);

			updatePreview();
		};

		$scope.checkEnv = function () {
			try {
				const result = dymo.label.framework.checkEnvironment();
				if (result.isBrowserSupported && result.isFrameworkInstalled && result.isWebServicePresent) {
					$scope.isEnvReady = true;
					setTimeout(() => {
						start();
					}, 1000);
				}
			} catch (e) {
				$scope.isEnvReady = false;
			}
		};

		function start() {
			try {
				fetchUsers();
				$scope.loadPatient();
				$scope.loadPrintersAsync();
			} catch (e) {
				$scope.isEnvReady = false;
				$scope.label = null;
				$scope.$apply();
			}
		}

		$scope.onUserChange = function () {
			$scope.loadPatient();
		};

		function fetchUsers() {
			$scope.US.getUsersForLabel()
				.then(
					function (resp) {
						$scope.ophthalmologists = [];
						resp.data.forEach(function (doc) {
							$scope.ophthalmologists.push({
								name: doc.firstName + " " + doc.lastName,
								value: doc.title + " " + doc.firstName + " " + doc.lastName,
                id: doc.id,
							});
						});
						$scope.ophthalmologists.sort(function (a, b) {
							if (a.name < b.name) {
								return -1;
							}
							if (a.name > b.name) {
								return 1;
							}
							return 0;
						});

            const target = $scope.ophthalmologists.find((t) => t.id === $scope.Session.user.id);
			      $scope.selectedUser = target ? target.value : $scope.ophthalmologists[0].value;

					},
					function (err) {
						console.log(err);
					}
				)
				.then(() => $scope.loadPatient());
		}
	},
]);
