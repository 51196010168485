app.service("AdminService", [
	"$http",
	"ENV",
	"Session",
	function ($http, ENV, Session) {
		var service = {
			isProcessing: false,

			getUsers: function () {
				service.isProcessing = true;
				return $http({
					method: "GET",
					url: ENV.API + "/api/users",
					headers: {
						Authorization: "Bearer " + Session.token,
					},
				});
			},
			createUser: function (user) {
				service.isProcessing = true;
				return $http({
					method: "POST",
					url: ENV.API + "/api/users",
					headers: {
						Authorization: "Bearer " + Session.token,
					},
					data: user,
				});

			},
			getAvailablePractices: function () {
				service.isProcessing = true;
				return $http({
					method: "GET",
					url: ENV.API + "/api/practices/summary",
					headers: {
						Authorization: "Bearer " + Session.token,
					},
				});
			},
			getUserDetail: function (userId) {
				service.isProcessing = true;
				return $http({
					method: "GET",
					url: ENV.API + "/api/users/" + userId,
					headers: {
						Authorization: "Bearer " + Session.token,
					},
				}); 
			},
			getUserPractices: function (userId) {
				return $http({
					method: "GET",
					url: ENV.API + "/api/users/" + userId + "/practices",
					headers: {
						Authorization: "Bearer " + Session.token,
					},
				});
			},
			updateUserDetail: function (user) {
				service.isProcessing = true;
				return $http({
					method: "PATCH",
					url: ENV.API + "/api/users/"+user.id,
					headers: {
						Authorization: "Bearer " + Session.token,
					},
					data: user,
				});
			},
			getPracticeDetail: function (practiceId) {
				service.isProcessing = true;
				return $http({
					method: "GET",
					url: ENV.API + "/api/practices/" + practiceId,
					headers: {
						Authorization: "Bearer " + Session.token,
					},
				}); 
			},
			updatePracticeDetail: function (practice) {
				service.isProcessing = true;
				return $http({
					method: "PATCH",
					url: ENV.API + "/api/practices/"+practice.id,
					headers: {
						Authorization: "Bearer " + Session.token,
					},
					data: practice,
				});
			},
			createPractice: function (practice) {
				service.isProcessing = true;
				return $http({
					method: "POST",
					url: ENV.API + "/api/practices",
					headers: {
						Authorization: "Bearer " + Session.token,
					},
					data: practice,
				});
			},
			downloadAnalytics: function() {
				return $http({
					method: "GET",
					url: ENV.API + "/api/users/analytics",
					headers: {
						Authorization: "Bearer " + Session.token,
					},
					responseType: "arraybuffer",
				});
			}
		};

		return service;
	},
]);
