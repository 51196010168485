app.controller("nhiRecordController", [
	"$scope",
	"optionalPatients",
	"CurrentPatient",
	"NgTableParams",
	"$modal",
	"$filter",
	"REFERENCE_TYPE",
	"$mdDialog",
	"callback",
	"$mdSidenav",
	"$timeout",
	"skipAction",
	function ($scope, optionalPatients, CurrentPatient, NgTableParams, $modal, $filter, REFERENCE_TYPE, $mdDialog, callback, $mdSidenav, $timeout,skipAction) {

		function getNameValues(names) {
			let preferred = names.find((t) => t.preferred);
			if(!preferred){
				preferred = names[0];
			}
			if (preferred) {
				const familyNameCol = preferred.familyName ? preferred.familyName: '';
				let middleNameCol = '';
				let firstNameCol = '';
				if(preferred.givenNameList && preferred.givenNameList.length > 1){
					middleNameCol = preferred.givenNameList[1];
					firstNameCol = preferred.givenNameList[0];
				}else if (preferred.givenNameList && preferred.givenNameList.length > 0){
					firstNameCol = preferred.givenNameList[0];
				}
				
				return {middleNameCol,familyNameCol,firstNameCol};
			}
			return {middleNameCol: '',familyNameCol:'',firstNameCol:''};
		}

		this.$onInit = function () {
			$scope.optionalPatients = optionalPatients;
			$scope.callback = callback;
			$scope.skipAction =skipAction;
			$scope.tableOptionalPatientsParams = new NgTableParams(
				{
					count: 5,
				},
				{
					counts: [],
					dataset: $scope.optionalPatients.map((t) => ({
						...t,
						...getNameValues(t.names),
						// middleNameCol: t.names.find((t) => t.preferred).givenNameList[1],
						// familyNameCol: t.names.find((t) => t.preferred).familyName,
						// firstNameCol: t.names.find((t) => t.preferred).givenNameList[0],
					})),
				}
			);
		};

		$scope.popoverPreview = {
			title: "",
			content: "Preview",
		};

		$scope.previewNHIRecord = function (record) {
			$scope.record = record;
			$scope.toggleRight();
		};

		$scope.renderNHIColumn = function (record) {
			if(!record){
				return '';
			}
			const nhi = record.nhi.find((t) => t.type === "OFFICIAL");
			if(nhi){
				record.nhiCol = nhi.nhi;
				return nhi.nhi;
			}else{
				record.nhiCol = record.nhi[0].nhi;
				return record.nhi[0].nhi;
			}
			
			
		};

		$scope.renderScore = function (record) {
			if(record.score === 0){
				return '';
			}
			return ` (${record.score}% match)`;
		};

		$scope.formatDate = function (_date) {
			if (_date) {
				return _date.replaceAll("-", "/");
				// const date = new Date(_date);
				// return date.getDate()  + "-" + (date.getMonth()+1) + "-" + date.getFullYear();
			}
			return "";
		};

		$scope.skip = function() {
			$mdDialog.hide();
			if($scope.skipAction){
				$scope.skipAction();
			}
		}

		$scope.renderNameRow = function (index) {
			const row = $scope.record.names[index];
			return row.givenNameList.join(" ") + " " + row.familyName;
		};

		$scope.renderAddressRow = function (index) {
			const row = $scope.record.addresses[index];
			let address = row.addressLine.join(" ");

			if (row.addressCity) {
				address = address + ", " + row.addressCity;
			}

			if (row.addressCountry) {
				address = address + ", " + row.addressCountry;
			}

			if (row.addressPostalCode) {
				address = address + ", " + row.addressPostalCode;
			}
			return address;
		};

		$scope.renderNHIRow = function (index) {
			const row = $scope.record.nhi[index];
			return row.nhi;
		};

		$scope.composeHeader = function (record) {
			if(!$scope.record){
				return '';
			}
			return $scope.renderNHIColumn(record)+':'+ $scope.renderNameRow(0);
		}

		$scope.createNewPatientUI = function () {
			$scope.callback($scope.record);
			$mdDialog.hide();
			// if ($scope.patientsOptionalModal) {
			// 	$scope.patientsOptionalModal.hide();
			// }
			// $scope.CP.createNewPatient();
		};

		$scope.formatPractitioner = function (obj) {
			const displayName = obj.displayName ? obj.displayName : '';
			const orgDisplayName = obj.orgDisplayName ? obj.orgDisplayName : '';
			const locationDisplayName = obj.locationDisplayName ? obj.locationDisplayName : '';
			return `${displayName}, ${orgDisplayName}, ${locationDisplayName}`;
		};

		$scope.toggleRight = buildToggler("right");
		$scope.isOpenRight = function () {
			return $mdSidenav("right").isOpen();
		};

		$scope.$watch(
			"isOpenRight()",
			function (newValue, oldValue) {
				if(!newValue){
                  $scope.record = null;
				}
			},
			true
		);

		/**
		 * Build handler to open/close a SideNav; when animation finishes
		 * report completion in console
		 */
		function buildDelayedToggler(navID) {
			return debounce(function () {
				// Component lookup should always be available since we are not using `ng-if`
				$mdSidenav(navID)
					.toggle()
					.then(function () {
						// $log.debug("toggle " + navID + " is done");
					});
			}, 200);
		}

		function buildToggler(navID) {
			return function () {
				// Component lookup should always be available since we are not using `ng-if`
				$mdSidenav(navID)
					.toggle()
					.then(function () {
						// $log.debug("toggle " + navID + " is done");
					});
			};
		}

		/**
		 * Supplies a function that will continue to operate until the
		 * time is up.
		 */
		function debounce(func, wait, context) {
			var timer;

			return function debounced() {
				var context = $scope,
					args = Array.prototype.slice.call(arguments);
				$timeout.cancel(timer);
				timer = $timeout(function () {
					timer = undefined;
					func.apply(context, args);
				}, wait || 10);
			};
		}
	},
]).controller("RightCtrl", function ($scope, $timeout, $mdSidenav, $log) {
		$scope.close = function () {
			// Component lookup should always be available since we are not using `ng-if`
			$mdSidenav("right")
				.close()
				.then(function () {
					$log.debug("close RIGHT is done");
				});
		};
	});
