app.controller("preOpController", [
	"$scope",
	"PreOp",
	// "CurrentReferral",
	// "ReferralsAPI",
	"SurgeryAPI",
	"NHI",
	// "filterFilter",
	"$timeout",
	"Session",
	// "$rootScope",
	// "PatientAPI",
	// "$modal",
	"CurrentPatient",
	"$state",
	"$filter",
	"$q",
	"$modal",
	"$window",
	"FEATURES",
	"POPULATE_DATA_SUCCESS",
	"toast",
	"ENV",
	function (
		$scope,
		PreOp,
		// CurrentReferral,
		// ReferralsAPI,
		SurgeryAPI,
		NHI,
		// filterFilter,
		$timeout,
		Session,
		// $rootScope,
		// PatientAPI,
		// $modal,
		CurrentPatient,
		$state,
		$filter,
		$q,
		$modal,
		$window,
		FEATURES,
		POPULATE_DATA_SUCCESS,
		toast,
		ENV
	) {
		$scope.CP = CurrentPatient;
		$scope.isHistoryCollapsed = true;
		$scope.isPlanCollapsed = true;
		$scope.displaySecondaryNHI = false;
		$scope.displaySecondaryInsurance = false;
		$scope.displaySpinner = false;
		$scope.visionAlerts = [];
		$scope.historyAlerts = [];
		$scope.planAlerts = [];
		$scope.alertMessage = "Data saved, complete all sections to finalise the surgical plan.";
		$scope.nhi = {};
		$scope.history_disable = "";
		$scope.additional_note_visiable = true;
		$scope.life_quality_added = "NO";
		$scope.isAcuityVisible = false;
		$scope.show_quality_life = false;
		$scope.isLinkHidden = true;
		$scope.showPrice = false;
		$scope.additionalNotes = "";
		$scope.preset = false;
		$scope.isTechnician = Session.user.roles[0] === "TECHNICIAN";
		$scope.isBioEnable = true;

		$scope.closeInsuranceModal = function (){
			$scope.displaySecondaryInsurance = false;
			if($scope.CP.patient.insuranceProvider){
				$scope.submitWorkUp();
			}
		}

		$scope.$on('$locationChangeStart', function (event, next, current) {
			if(next.endsWith('/refer/search')){
				if($scope.processingWorkup){
					$scope.processingWorkup.hide();
				}
				if($scope.successfulWorkup){
					$scope.successfulWorkup.hide();
				}
			}
		});

		$scope.isPremiumLensesEnable = FEATURES.PREMIUMLENS && Session.user.features && Session.user.features.premiumLensesFeature && Session.practice.type === "PRIVATE";
		

		$scope.showPriceFun = function(){
			$scope.showPrice = true;
		}

		function callback() {
			toast.create(POPULATE_DATA_SUCCESS);
		}

		SurgeryAPI.getWorkUp($scope.CP.patient.id, callback).then(function () {
			$scope.PreOp = PreOp.data;
			$scope.preset = true;
			
			if ($scope.PreOp && $scope.PreOp.vision_section && $scope.PreOp.vision_section.quality_of_life) {
				$scope.life_quality_added = "YES"
			}
			if($scope.PreOp && $scope.PreOp.surgical_plan_section && $scope.PreOp.surgical_plan_section.surgical_plan_comments){
				$scope.additionalNotes = $scope.PreOp.surgical_plan_section.surgical_plan_comments;
			}
		});

		$scope.clearLifeQuality = function () {
			PreOp.data.vision_section.quality_of_life = null;
		}

		$scope.saveVision = async function (isWorkup,showErrorModal) {
			return new Promise((resolve,reject) => {
				if (!$scope.vision.$valid && isWorkup) {
					$scope.isHistoryCollapsed = true;
					$scope.isPlanCollapsed = true;
					$scope.displaySpinner = false;
					throw new Error("error in vision section");
				}
				SurgeryAPI.saveVision($scope.CP.patient.id, PreOp.data.workup_id, PreOp.data.vision_section)
					.then(function () {
						$scope.visionAlerts.push({ msg: $scope.alertMessage });
						if ($scope.vision.$submitted === true && isWorkup) {
							// Check to see the form has been submitted through the DOM (means no validation errors)
							$scope.isHistoryCollapsed = false;
						}
						if($scope.PreOp.surgical_plan_section && $scope.PreOp.surgical_plan_section.surgical_plan_comments !== $scope.additionalNotes){
							$scope.additionalNotes = $scope.PreOp.surgical_plan_section.surgical_plan_comments;
							$scope.savePlan(); // TO SAVE PreOp.surgical_plan_section.surgical_plan_comments
						}
						resolve();
					})
					.catch(function (err) {
						console.log(err);
						reject(err);
						if(showErrorModal){
							$scope.serverErrorModal = $modal({
								scope: $scope,
								templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
								show: true,
								title: "CatTrax has encountered an error",
							});
						}
					});
			})
			
		};

		$scope.saveHistory = async function (isWorkup, showErrorModal) {
			return new Promise((resolve,reject) => {
				if (!$scope.history.$valid && isWorkup) {
					$scope.isPlanCollapsed = true;
					$scope.displaySpinner = false;
					throw new Error("error in history section");
				}
				SurgeryAPI.saveHistory($scope.CP.patient.id, PreOp.data.workup_id, PreOp.data.ophthalmic_section)
					.then(function () {
						$scope.historyAlerts.push({ msg: $scope.alertMessage });
						if ($scope.history.$submitted === true) {
							// Check to see the form has been submitted through the DOM (means no validation errors)
							$scope.isPlanCollapsed = false;
						}
						resolve();
					})
					.catch(function (err) {
						console.log(err);
						reject(err);
						if(showErrorModal){
							$scope.serverErrorModal = $modal({
								scope: $scope,
								templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
								show: true,
								title: "CatTrax has encountered an error",
							});
						}
					});
			})
		};

		$scope.savePlan = async function (showErrorModal) {
			return new Promise((resolve,reject) => {
				SurgeryAPI.savePlan($scope.CP.patient.id, PreOp.data.workup_id, PreOp.data.surgical_plan_section)
				.then(function () {
					$scope.planAlerts.push({ msg: $scope.alertMessage });
					resolve();
				})
				.catch(function (err) {
					console.log(err);
					reject(err);
					if(showErrorModal){
						$scope.serverErrorModal = $modal({
							scope: $scope,
							templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
							show: true,
							title: "CatTrax has encountered an error",
						});
					}
				});
			})
		   
		};

		$scope.submitWorkUp = function () {
			// Display NHI warning if value is not present
			if ($scope.CP.patient.nhi === null && $scope.displaySecondaryNHI === false && $scope.displaySecondaryInsurance === false) {
				$scope.displaySecondaryNHI = true;
				return;
			}
			if ($scope.CP.patient.insuranceProvider === null && $scope.displaySecondaryInsurance === false) {
				$scope.hideSecondaryNhiComponent();
				$scope.displaySecondaryInsurance = true;
				return;
			}

			// Display Spinner. Submit all forms to ensure no unsaved data. This action is performed synchronously to avoid 500 errors. Submit workup, download file.
			// Refetch the workup as the workup_id changes after a successful submission.
			$scope.displaySpinner = true;

			// Display processing modal
			$scope.processingWorkup = $modal({
				scope: $scope,
				templateUrl: "app/src/views/templates/modal.processing-surgery-workup.tpl.html",
				show: true,
				title: "PROCESSING...",
				keyboard: false,
				backdrop: "static",
			});

			$scope
				.saveVision(true, false)
				.then(function () {
					return $scope.saveHistory(true, false);
				})
				.then(function () {
					return $scope.savePlan(false);
				})
				.then(function () {
					return SurgeryAPI.submitWorkUp($scope.CP.patient.id, PreOp.data.workup_id);
				})
				.then(function (resp) {
					downloadFile(resp.data, getFileName());
				})
				//not necessary to be called
				// .then(function () {
				// 	return SurgeryAPI.getWorkUp($scope.CP.patient.id).then(function () {
				// 		$scope.PreOp = PreOp.data;
				// 	});
				// })
				.then(function () {
					$scope.processingWorkup.hide();
					$scope.displaySpinner = false;
					$scope.successfulWorkup = $modal({
						scope: $scope,
						templateUrl: "app/src/views/templates/modal.successful-surgery-workup.tpl.html",
						show: true,
						backdrop: "static",
						keyboard: false,
						title: "SUCCESS",
					});
				})
				.catch(function (err) {
					// Catch all errors here. Hide processing modal. Hide spinner. Log any errors
					$scope.processingWorkup.hide();
					$scope.displaySpinner = false;
					console.log(err);
					$scope.serverErrorModal = $modal({
						scope: $scope,
						templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
						show: true,
						title: "CatTrax has encountered an error",
					});
				});
		};

		$scope.closeAlert = function (index, arr) {
			arr.splice(index, 1);
		};

		$scope.returnToSearch = function () {
			$scope.successfulWorkup.hide();
			$scope.CP.clearPatientResult();
			$state.go("refer.search");
		};

		$scope.returnToDashboard = function () {
			$scope.successfulWorkup.hide();
			$window.history.back();
		}

		$scope.hideSecondaryNhiComponent = function () {
			$scope.displaySecondaryNHI = false;
		};

		$scope.hideSecondaryInsuranceComponent = function () {
			$scope.displaySecondaryInsurance = false;
		};

		function downloadFile(data, filename, mime, bom) {
			// https://github.com/kennethjiang/js-file-download/blob/master/file-download.js
			var blobData = typeof bom !== "undefined" ? [bom, data] : [data];
			var blob = new Blob(blobData, { type: "application/pdf" });
			if (typeof window.navigator.msSaveBlob !== "undefined") {
				// IE workaround for "HTML7007: One or more blob URLs were
				// revoked by closing the blob for which they were created.
				// These URLs will no longer resolve as the data backing
				// the URL has been freed."
				window.navigator.msSaveBlob(blob, filename);
			} else {
				var blobURL = window.URL.createObjectURL(blob);
				var tempLink = document.createElement("a");
				tempLink.style.display = "none";
				tempLink.href = blobURL;
				tempLink.setAttribute("download", filename);

				// Safari thinks _blank anchor are pop ups. We only want to set _blank
				// target if the browser does not support the HTML5 download attribute.
				// This allows you to download files in desktop safari if pop up blocking
				// is enabled.
				if (typeof tempLink.download === "undefined") {
					tempLink.setAttribute("target", "_blank");
				}

				document.body.appendChild(tempLink);
				tempLink.click();
				document.body.removeChild(tempLink);
				window.URL.revokeObjectURL(blobURL);
			}
		}

		function getFileName() {
			var theTime = $filter("date")(new Date(), "yyyy-MM-dd@hmma");
			return (
				"CatTrax_surgical_plan_" +
				theTime +
				"_" +
				$scope.CP.patient.firstName +
				"_" +
				$scope.CP.patient.lastName +
				"_" +
				$scope.CP.patient.dateOfBirth +
				".pdf"
			);
		}

		function reset(newValue) {
			if (newValue.fuchs_guttata !== "NO" ||
				newValue.corneal_scarring !== "NO" ||
				newValue.amblyopia !== "NO") {
				$scope.history_disable = "";
			}
		}

		$scope.$watch(
			"PreOp.vision_section.vision.od.uncorrected_va_line",
			function (newValue, oldValue) {
				if(!$scope.PreOp){
					return
				}
				if (newValue) {
					$scope.isLinkHidden = false;
				}else if(!$scope.PreOp.vision_section.vision.os.uncorrected_va_line){
					$scope.isLinkHidden = true;
					$scope.isAcuityVisible = false;
				}
			},
			true
		);
		$scope.$watch(
			"PreOp.vision_section.vision.os.uncorrected_va_line",
			function (newValue, oldValue) {
				if(!$scope.PreOp){
					return
				}
				if (newValue) {
					$scope.isLinkHidden = false;
				}else if(!$scope.PreOp.vision_section.vision.od.uncorrected_va_line){
					$scope.isLinkHidden = true;
					$scope.isAcuityVisible = false;
				}
			},
			true
		);

		$scope.$watch("life_quality_added", function (newValue, oldValue) {
			if (newValue === "NO" && $scope.PreOp && $scope.PreOp.vision_section) {
				$scope.PreOp.vision_section.quality_of_life = null;
			}
		}, true);

		$scope.$watch("PreOp.vision_section.lens_status.od.status", function (newValue, oldValue) {
			if (newValue) {
				$scope.show_quality_life = true;
			}else {
				$scope.show_quality_life = false;
			}
		}, true);

		$scope.$watch("PreOp.vision_section.lens_status.os.status", function (newValue, oldValue) {
			if (newValue) {
				$scope.show_quality_life = true;
			}else {
				$scope.show_quality_life = false;
			}
		}, true);

		$scope.$watch("PreOp.ophthalmic_section.ophthalmic_risk_factors.od", function (newValue, oldValue) {
			newValue && reset(newValue);
		}, true);

		$scope.$watch("PreOp.ophthalmic_section.ophthalmic_risk_factors.os", function (newValue, oldValue) {
			newValue && reset(newValue);
		}, true);

		$scope.$watch("PreOp.ophthalmic_section.ophthalmic_risk_factors.pseudoexfoliation", function (newValue, oldValue) {
			if (newValue && newValue !== "NO") {
				$scope.history_disable = "";
			}
		}, true);

		$scope.$watch("PreOp.ophthalmic_section.diabetes.diabetes_status", function (newValue, oldValue) {
			if (newValue && newValue !== "NO") {
				$scope.history_disable = "";
			}
		}, true);

		$scope.$watch("PreOp.ophthalmic_section.glaucoma", function (newValue, oldValue) {
			if (newValue && (newValue.od.glaucoma !== "NO" || newValue.os.glaucoma !== "NO")) {
				$scope.history_disable = "";
			}
		}, true);

		$scope.$watch("PreOp.ophthalmic_section.amd", function (newValue, oldValue) {
			if (newValue && (newValue.od.amd !== "NO" || newValue.os.amd !== "NO")) {
				$scope.history_disable = "";
			} else if (newValue && (newValue.od.epiretinal_membrane !== "NO" || newValue.os.epiretinal_membrane !== "NO")) {
				$scope.history_disable = "";
			}
		}, true);

		$scope.$watch("PreOp.ophthalmic_section.uveitis", function (newValue, oldValue) {
			if (newValue && (newValue.od.prev_uveitis !== "NO" || newValue.os.prev_uveitis !== "NO")) {
				$scope.history_disable = "";
			}
		}, true);

		$scope.$watch("PreOp.ophthalmic_section.prev_ocular", function (newValue, oldValue) {
			if (newValue && (newValue.od.prev_ocular_trauma !== "NO" || newValue.os.prev_ocular_trauma !== "NO")) {
				$scope.history_disable = "";
			}
		}, true);
		$scope.disableHistory = function () {
			if (!$scope.PreOp.ophthalmic_section) {
				$scope.PreOp.ophthalmic_section = {
					diabetes: {},
					glaucoma: {
						od: {},
						os: {}
					},
					amd: {
						od: {},
						os: {}
					},
					uveitis: {
						od: {},
						os: {}
					},
					prev_ocular: {
						od: {},
						os: {}
					},
				};
			}

			if (!$scope.PreOp.ophthalmic_section.ophthalmic_risk_factors) {
				$scope.PreOp.ophthalmic_section.ophthalmic_risk_factors = {
					od: {},
					os: {}
				};
			}

			$scope.PreOp.ophthalmic_section.ophthalmic_risk_factors.od.fuchs_guttata = "NO";
			$scope.PreOp.ophthalmic_section.ophthalmic_risk_factors.od.corneal_scarring = "NO";
			$scope.PreOp.ophthalmic_section.ophthalmic_risk_factors.od.amblyopia = "NO";
			$scope.PreOp.ophthalmic_section.ophthalmic_risk_factors.os.fuchs_guttata = "NO";
			$scope.PreOp.ophthalmic_section.ophthalmic_risk_factors.os.corneal_scarring = "NO";
			$scope.PreOp.ophthalmic_section.ophthalmic_risk_factors.os.amblyopia = "NO";

			$scope.PreOp.ophthalmic_section.ophthalmic_risk_factors.pseudoexfoliation = "NO";
			$scope.PreOp.ophthalmic_section.diabetes.diabetes_status = "NO";

			$scope.PreOp.ophthalmic_section.glaucoma.od.glaucoma = "NO";
			$scope.PreOp.ophthalmic_section.glaucoma.os.glaucoma = "NO";

			$scope.PreOp.ophthalmic_section.amd.od.amd = "NO";
			$scope.PreOp.ophthalmic_section.amd.os.amd = "NO";

			$scope.PreOp.ophthalmic_section.amd.od.epiretinal_membrane = "NO";
			$scope.PreOp.ophthalmic_section.amd.os.epiretinal_membrane = "NO";

			$scope.PreOp.ophthalmic_section.uveitis.od.prev_uveitis = "NO";
			$scope.PreOp.ophthalmic_section.uveitis.os.prev_uveitis = "NO";

			$scope.PreOp.ophthalmic_section.prev_ocular.od.prev_ocular_trauma = "NO";
			$scope.PreOp.ophthalmic_section.prev_ocular.os.prev_ocular_trauma = "NO";
		};

		$scope.biomertryList = [];

		$scope.closeBiometryViewer = function () {
			if($scope.biometryListModal){
				$scope.biometryListModal.hide();
			}
		}

		$scope.previewBiometry = function (index) {
			const file = $scope.biomertryList[index];
			$scope.pdfUrl = ENV.API + `/api/files/patient/${$scope.CP.patient.id}/file/${file.patient_file_id}`;
			$scope.scroll = 0;
			$scope.onLoad = function () {
				setTimeout(()=>{
					angular.element('#pdf_zoom_in').trigger("click");
					angular.element('#pdf_zoom_out').trigger("click");
				},500);
			}
			$scope.previewObj = file;
		}

		$scope.checkboxModel = {};
		$scope.isBiometrySelected = false;

		$scope.watchCheckboxModel = function(index){
			const state = !$scope.checkboxModel[`${index}`];
			$scope.biomertryList.forEach((element,i) => {
				$scope.checkboxModel[`${i}`] = false;
			});
			$scope.checkboxModel[`${index}`] = state;
			$scope.isBiometrySelected = state;
			if(state){
				$scope.previewBiometry(index);
			}else{
				$scope.previewObj = null;
			}
		}

		function commonPreloadBiometry() {
			const obj = $scope.biomertryList.find((t,index)=> 
				$scope.checkboxModel[`${index}`]
			);
			if(obj){
				//preload
				if(!$scope.PreOp.surgical_plan_section){
					$scope.PreOp.surgical_plan_section = {
						biometry: {
							os: {},
							od: {},
						}
					}
				}else if (!$scope.PreOp.surgical_plan_section.biometry) {
					$scope.PreOp.surgical_plan_section.biometry = {
						os: {},
						od: {},
					};
				}
			
				if (obj.iol_od) {
					$scope.PreOp.surgical_plan_section.biometry.od = { ...obj.iol_od, isPreload: true };
				}

				if (obj.iol_os) {
					$scope.PreOp.surgical_plan_section.biometry.os = { ...obj.iol_os, isPreload: true };
				}
				$scope.closeBiometryViewer();
			}

		}

		$scope.onGeneralClick = function () {
			if($scope.generalModal){
				$scope.generalModal.hide();
			}
			commonPreloadBiometry();
		}

		$scope.preloadBiometry = function () {
			const obj = $scope.biomertryList.find((t,index)=> 
				$scope.checkboxModel[`${index}`]
			);
			if(!obj.patient_details_match.all_patient_details_matches){
				$scope.generalModal = $modal({
					scope: $scope,
					templateUrl: "app/src/views/templates/modal.general.tpl.html",
					show: true,
					title: "Warning",
					content: 'Warning you are preloading biometry where the patient details on the biometry sheet do not appear to match the current patient, are you sure you wish to proceed',
					backdrop: true,
					keyboard: false,
				});
               return;
			}
			commonPreloadBiometry();
		}

		function getMatchText (item) {
			const {
				patient_dob_matches,
				patient_first_name_matches,
				patient_last_name_matches} = item.patient_details_match;
			let text = '';
			if(patient_dob_matches){
				text = 'DOB,';
			}

			if(patient_first_name_matches){
				text = text + 'First Name,'
			}

			if(patient_last_name_matches){
				text = text + 'Last Name,'
			}

			if(text === ''){
				return {isMatched: false, matchedText: 'WARNING: Biometry details do not appear to match'};
			}

			return {isMatched: true, matchedText: 'Match: ' + text.substring(0,text.length-1) };
			
		}

		$scope.convertBiometryType = function(str) {
			if(str === 'MASTER_IOL'){
				return 'IOL Master 700';
			}
			return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
		}

		$scope.loadBiomertry = function (){
			$scope.displaySpinner = true;
			SurgeryAPI.fetchBiometryFiles($scope.CP.patient.id).then(function (resp) {
				$scope.biomertryList = resp.data.sort(function compareFn(a, b) { return new Date(b.date_created).getTime() - new Date(a.date_created).getTime() });
				$scope.biomertryList = $scope.biomertryList.map(t=>({...t, ...getMatchText(t)}));
			}, function(error){
				console.error(`${JSON.stringify(error)}`);
			}).then(function() {
				$scope.displaySpinner = false;
				$scope.previewObj = null;
				
				$scope.biomertryList.forEach((element,index) => {
					$scope.checkboxModel[`${index}`] = false;
				});
				//Popup modal
				$scope.httpHeaders = { Authorization: `Bearer ${Session.token}`, "X-PRACTICE-ID": `${Session.practice.id}` };
				$scope.biometryListModal = $modal({
					scope: $scope,
					templateUrl: "app/src/views/templates/modal.biometry-list.tpl.html",
					show: true,
					title: "Biometry PREVIEW",
					backdrop: true,
					keyboard: false,
				});

			})
		}
	},
]);
