app.component("insuranceSelection", {
	// templateUrl: "app/src/js/components/insuranceSelection/insuranceSelection.html",
	controller: "insuranceSelectionController",
	bindings: {
		closeInsuranceModal: "&",
        hideSecondaryInsuranceComponent: "&",
	},
});

app.controller("insuranceSelectionController", [
	"$scope",
	"$modal",
	"PRACTICE_TYPE",
	"AdminService",
    "NgTableParams",
	"DHB_LIST",
    "$window",
	function ($scope, $modal, PRACTICE_TYPE,AdminService, NgTableParams, DHB_LIST, $window) {
        $scope.insuranceModal = $modal({
            scope: $scope,
            templateUrl: "app/src/js/components/insuranceSelection/insuranceSelection.html",
            show: true,
            backdrop: "static",
			keyboard: false,
            title: "Choose Insurance",
            onHide: function () {
                $scope.$ctrl.closeInsuranceModal();
                // $scope.formDisplay = false;
                // $scope.fetchPractices();
            },
        });
	},
]);