app.controller("fileDetailController", [
	"$scope",
	"newFileItem",
	"fileType",
	"fileOptions",
	"roles",
	"$filter",
	"Session",
	"UserService",
	"NHISearchPatientsAPI",
	function ($scope, newFileItem, fileType, fileOptions, roles,$filter,Session,UserService,NHISearchPatientsAPI) {
		$scope.today = new Date();
		$scope.newFileItem = newFileItem;
		$scope.US = UserService;

		$scope.HPI = NHISearchPatientsAPI;
		$scope.noResultsOrg = false;
		$scope.noResultsPractitioner = false;


		$scope.fileItem_fileType = fileType;
		$scope.fileOptions = fileOptions;
		$scope.referralReason = [{value:'SURGERY',name: 'Surgery'},{value:'REVIEW',name: 'Review'}];
		$scope.referralUrgency = [{value:'URGENT',name: 'Urgent'},{value:'HIGH',name: 'High'},{value:'ROUTINE',name: 'Routine'}];

		$scope.referralDataTrackingEnabled = Session.practice.practiceFeatures.referralDataTrackingFeature && Session.practice.practiceFeatures.fhirNhiFeature;

		$scope.practiceOptions = Session.practiceOptions;
		$scope.users = [];

		function filterSecurityOptions(roles) {
			const result = [
				{ value: "GLOBAL", name: "Add to the patient record" },
				{ value: "PRACTICE", name: "Visible only to the uploader's practice" },
			];
			if (roles.some((t) => t === "REGISTRAR" || t === "OPHTHALMOLOGIST")) {
				result.push({ value: "OWNER", name: "Visible only to the uploader" });
			}
			return result;
		}

		$scope.securityOptions = filterSecurityOptions(roles);

		const WITHIN_MY_PRACTICE = [
			"REFERRAL",
			"CLINIC_LETTER",
			"AGREEMENT_TO_TREATMENT",
			"CATQUEST",
			"SCANNED_HISTORIC_NOTES",
			"SCANNED_OPERATION_NOTE",
		];
		$scope.fileTypesSettings = {
			displayProp: "name",
			selectionLimit: 1,
			smartButtonMaxItems: 1,
			smartButtonTextConverter: function (itemText) {
				return itemText;
			},
			buttonClasses: "btn btn-dropdown-multiselect",
			showCheckAll: false,
			showUncheckAll: false,
			enableSearch: true,
			scrollableHeight: "20em",
			scrollable: true,
		};

		function filterSecurityOptions(roles) {
			const result = [
				{ value: "GLOBAL", name: "Add to the patient record" },
				{ value: "PRACTICE", name: "Visible only to the uploader's practice" },
			];
			if (roles.some((t) => t === "REGISTRAR" || t === "OPHTHALMOLOGIST")) {
				result.push({ value: "OWNER", name: "Visible only to the uploader" });
			}
			return result;
		}

		$scope.fileTypeEvents = {
			onItemSelect: function (item) {
				$scope.newFileItem.fileType = item.id;
			},
		};

		$scope.onPracticeChange = function () {
            $scope.users = [];
            fetchUsers($scope.newFileItem.referredToPracticeId);
        }

		function fetchUsers(practiceId) {
            $scope.US.isProcessing = true;
            $scope.US.getDoctorsByPracticeId(practiceId).then(function(resp){
                $scope.users = [];
                resp.data.forEach(function (doc) {
                    $scope.users.push({ name: doc.firstName + " " + doc.lastName, value: doc.id });
                });
            },function(error){
                
            }).then(function(){
                $scope.US.isProcessing = false;
            })
        }

		$scope.fetchOrg = function(value) {
			$scope.org_loading = true;
			$scope.newFileItem.org = null;
			$scope.newFileItem.location = null;
            return new Promise((resolve, reject) => {
                searchOrg(value, resolve, reject)
            })
		}

		function searchOrg(terms, resolve, reject) {
            $scope.resultOrg = [];
            $scope.noResultsOrg = false;
            $scope.HPI.queryOrganisations(terms)
                .then(
                    function (resp) {
                        if (resp.data) {
                            $scope.resultOrg=resp.data;
                            resolve($scope.resultOrg)
                        }
                    },
                    function (err) {
                        console.log(`${JSON.stringify(err)}`)
                        $scope.noResultsOrg = true;
                        $scope.resultOrg = [];
                        reject(err)
                    }
                ).then(function () {
                    $scope.org_loading = false;
                })
        }

		$scope.orgOnSelect = function(item, model, label, event) {
            $scope.newFileItem.org = item.location_id;
			$scope.newFileItem.location = angular.copy(item);
        }

		$scope.fetchPractitioner = function(value) {
			$scope.practitioner_loading = true;
			$scope.newFileItem.practitioner = null;
			$scope.newFileItem.practitionerObj = null;
            return new Promise((resolve, reject) => {
                searchPractitioner(value, resolve, reject)
            })
		}

		function searchPractitioner(terms, resolve, reject) {
            $scope.resultPractitioner = [];
            $scope.noResultsPractitioner = false;
            $scope.HPI.queryPractitioners(terms)
                .then(
                    function (resp) {
                        if (resp.data) {
                            $scope.resultPractitioner=resp.data;
                            resolve($scope.resultPractitioner)
                        }
                    },
                    function (err) {
                        console.log(`${JSON.stringify(err)}`)
                        $scope.noResultsPractitioner = true;
                        $scope.resultPractitioner = [];
                        reject(err)
                    }
                ).then(function () {
                    $scope.practitioner_loading = false;
                })
        }

		$scope.practitionerOnSelect = function(item, model, label, event) {
            $scope.newFileItem.practitioner = item.id;
			$scope.newFileItem.practitionerObj = angular.copy(item);
        }

		$scope.formatQualification = function(item){
			let formattedStr = '';

			if(item.start_APC_date_UTC){
                formattedStr = `Start:${$filter("date")(new Date(item.start_APC_date_UTC), "yyyy-MM-dd")}, `;
			}

			if(item.end_APC_date_UTC){
				formattedStr = formattedStr + `End:${$filter("date")(new Date(item.end_APC_date_UTC), "yyyy-MM-dd")}, `;
			}

			if(item.scope_of_practice){
				formattedStr = formattedStr + `Scope:${item.scope_of_practice.join(',')}, `;
			}

			if(item.registration_status){
				formattedStr = formattedStr + `Status:${item.registration_status}`;
			}
			return formattedStr;
		}

		$scope.$watch(
			"fileItem_fileType",
			function (newValue, oldValue) {
				if (newValue) {
					if (WITHIN_MY_PRACTICE.includes(newValue.id)) {
						$scope.newFileItem.security_level = "PRACTICE";
					} else {
						$scope.newFileItem.security_level = "GLOBAL";
					}

					if(newValue.id === 'REFERRAL'){
						$scope.newFileItem.dateOfReferral = $filter("date")(new Date(), "dd/MM/yyyy");
						$scope.newFileItem.referral_reason = 'SURGERY';
						$scope.newFileItem.referral_urgency = 'ROUTINE';
						$scope.newFileItem.referredToPracticeId = Session.practice.id;
						$scope.onPracticeChange();
					} else {
						delete $scope.newFileItem.dateOfReferral;
						delete $scope.newFileItem.referral_reason;
						delete $scope.newFileItem.referral_urgency;
						delete $scope.newFileItem.referredToPracticeId;
						delete $scope.newFileItem.referredToUserId;
					}
				}
			},
			true
		);
	},
]);
