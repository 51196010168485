app.component("surgeonHeader", {
	templateUrl: "app/src/js/components/operationNote/surgeonHeader/surgeonHeader.html",
	controller: "surgeonHeaderController",
	bindings: {
		data: "=",
		doctors: "=",
		assistant: "@"
	},
});

app.controller("surgeonHeaderController", [
	"$scope",
	function ($scope) {
		// $scope.assistantOptions = angular.copy($ctrl.doctors);
		$scope.$watch('$ctrl.data.surgeon_user_id', function(newValue) {
			if(newValue){
				$scope.refreshAssistant();
			}
		  });

		$scope.refreshAssistant = function () {
			if(!$scope.$ctrl.assistant){
				return;
			}
			if (!($scope.$ctrl.data.surgeon_user_id == null)) {
				const list = angular.copy($scope.$ctrl.doctors);
				$scope.assistantOptions = list.filter((t) => t.value !== $scope.$ctrl.data.surgeon_user_id);
				if (!$scope.$ctrl.data.postop_care) {
					$scope.$ctrl.data.postop_care = {};
				}
				$scope.$ctrl.data.postop_care.clinician = list.find((t) => t.value === $scope.$ctrl.data.surgeon_user_id).name;
			}
		};
	},
]);
