app.service("PreOp", [
	"$http",
	"ENV",
	"Session",
	function ($http, ENV, Session) {
		// var service = {
		// 	isProcessing: false,
		// 	conatctMessage: '',
		// 	feedbackMessage: '',
		// 	contact: function(message) {
		// 		return $http({
		// 			method: 'POST',
		// 			url: ENV.API + '/api/emails/contact',
		// 			headers: {
		// 				'Authorization': 'Bearer ' + Session.token,
		// 			},
		// 			data: {
		// 				"sender": {
		// 					"id": Session.userId
		// 				},
		// 				"message": message
		// 			}
		// 		})
		// 	},
		// 	feedback: function(message, subject) {
		// 		return $http({
		// 			method: 'POST',
		// 			url: ENV.API + '/api/emails/feedback',
		// 			headers: {
		// 				'Authorization': 'Bearer ' + Session.token,
		// 			},
		// 			data: {
		// 				"sender": {
		// 					"id": Session.userId
		// 				},
		// 				"message": message,
		// 				"subject": subject
		// 			}
		// 		})
		// 	},
		// };
		var service = {
			isProcessing: false,
			setData: function (data) {
				service.data = data;
			},
			data: {},
		};

		return service;
	},
]);
