app.service("Session", [
	"localStorageService",
	function (localStorageService) {
		var service = {
			user: {},
			practice: null,
			practiceOptions: [],
			timestamp: null,
			xeroLogin: null,
			token: null,
			userId: null,
			idp_access_token: null,
			refresh_token: null,
			// backgroundTask: null,

			create: function (token, expires_in, userId, idp_access_token, refresh_token) {
				service.token = token;
				service.userId = userId;
				service.timestamp = Date.now() + expires_in * 1000;
				service.idp_access_token = idp_access_token;
				service.refresh_token = refresh_token;
				// var obj = {
				// 	token: token,
				// 	userId: userId,
				// 	timestamp: service.timestamp,
				// 	idp_access_token: service.idp_access_token,
				// 	refresh_token
				// };
				// localStorageService.set("userObj", obj);
				/** 
				service.backgroundTask = setInterval(() => {
					
					if (Date.now() + 5 * 60 * 1000 > service.timestamp) {
						$rootScope.sessionExpireModal && $rootScope.sessionExpireModal.hide();
						$rootScope.relogin = function () {
							$rootScope.sessionExpireModal && $rootScope.sessionExpireModal.hide();
							service.destroy();
							$state.go('login');
						}
						const minute = (service.timestamp - Date.now())/1000/60
						$rootScope.sessionExpireModal = $modal({
							scope: $rootScope,
							templateUrl: "app/src/views/templates/modal.session-expire.tpl.html",
							show: true,
							keyboard: false,
							backdrop: "static",
							title: `Your session is about to expire in ${Math.floor(minute)} minute`,
						});
					}
				}, 60 * 1000);
				*/
			},

			destroy: function () {
			    // $http({
				// 	method: 'POST',
				// 	url: ENV.API + '/api/security/logout',
				// 	headers: {
				// 		'Authorization': 'Bearer ' + service.token,
				// 	}
				// })
				service.userId = null;
				service.practice = null;
				service.practiceOptions = [];
				service.token = null;
				service.idp_access_token = null;
				service.refresh_token = null;
				service.xeroLogin = null;
				localStorageService.clearAll();
				/** 
				if(service.backgroundTask){
					clearInterval(service.backgroundTask);
				}
				*/
			},

			setPractice: function (practice) {
				service.practice = practice;
			},

			setCurrentUser: function (user) {
				if (!user.features) {
					user.features = {};
				}
				if (user.features && !user.features.favoritePatientsFeature) {
					user.features.favoritePatientsFeature = [];
				}
				service.user = user;
			},

			setPracticeOptions: function (practiceList) {
				practiceList.sort((a, b) => {
					const nameA = a.name;
					const nameB = b.name;
					if (nameA < nameB) {
					  return -1;
					}
					if (nameA > nameB) {
					  return 1;
					}
					return 0;
				  });
				service.practiceOptions = practiceList;
			},

			setXeroLoginState: function (state) {
				service.xeroLogin = state;
			}
		};

		return service;
	},
]);