app.component("automationTask", {
	templateUrl: "app/src/js/components/automationTask/automationTask.html",
	controller: "automationTaskController",
	bindings: {
		type: "@",
		onChange: "&",
	},
});
app.controller("automationTaskController", [
	"$scope",
	"TasksAPI",
	"Session",
	"CurrentPatient",
	function ($scope, TasksAPI, Session, CurrentPatient) {
		this.$onInit = function () {
			$scope.TasksAPI = TasksAPI;
			$scope.CP = CurrentPatient;
			$scope.hasSetting = false;
			$scope.setting = {};
			$scope.label = null;
			$scope.hidden = false;
			$scope.local = { isAutomationTaskEnabled: false };
			if ($scope.$ctrl.type === "referral_automation_settings") {
				$scope.hidden = true;
			}
			fetchSetting();
			fetchLabels();
		};

		// $scope.$on("trigger:automation-task", function (e) {
		// 	if ($scope.hasSetting && $scope.isAutomationTaskEnabled) {
		// 		createTask();
		// 	}
		// });

		$scope.triggerChange = function () {
			if ($scope.$ctrl.onChange) {
				$scope.$ctrl.onChange({
					event: { isAutomationTaskEnabled: $scope.local.isAutomationTaskEnabled ? "true" : "false", type: $scope.$ctrl.type },
				});
			}
		};

		function fetchSetting() {
			$scope.TasksAPI.getTaskSetting().then(function (response) {
				if (
					response.data.task_settings &&
					response.data.task_settings[$scope.$ctrl.type] &&
					response.data.task_settings[$scope.$ctrl.type].create_task_checked
				) {
					$scope.hasSetting = true;
					$scope.local.isAutomationTaskEnabled = true;
					$scope.setting = response.data.task_settings[$scope.$ctrl.type];
					$scope.dueDate = moment().add($scope.setting.days_until_due_date, "d").toDate();
				}
			});
		}

		function fetchLabels() {
			$scope.TasksAPI.queryTaskLabels().then(
				function (resp) {
					$scope.label = resp.data.find(
						(t) => !t.is_name_editable && t.practice_id === Session.practice.id && t.name === "Inbox"
					);
				},
				function (error) {
					//do nothing
				}
			);
		}

		// function createTask() {
		// 	if (!$scope.label) {
		// 		return;
		// 	}
		// 	//if user already make it auto create in settings
		// 	if($scope.setting.create_task_checked){
		// 		return;
		// 	}

		// 	//local to utc
		// 	const date_due_UTC = moment().add($scope.setting.days_until_due_date, "d").utc().toISOString();
		// 	const data = {
		// 		is_completed: false,
		// 		date_due_UTC: date_due_UTC,
		// 		patient_id: $scope.CP.patient.id,
		// 		practice_id: Session.practice.id,
		// 		user_id: Session.user.id,
		// 		assigned_to_user_id: $scope.setting.assign_to_user_id,
		// 		timezone: "Pacific/Auckland",
		// 		description: $scope.setting.description,
		// 		assigned_to_practice_id: Session.practice.id,
		// 		task_label_id: $scope.label.task_label_id,
		// 	};
		// 	$scope.TasksAPI.createTask(data);
		// }
	},
]);
