app.component("patientDiagnosisList", {
	templateUrl: "app/src/js/components/patientDiagnosis/patientDiagnosisList.html",
	controller: "patientDiagnosisListController",
    bindings: {
		data: "=",
		diagnosisType: "@"
	},
});

app.controller("patientDiagnosisListController", [
	"$scope",
	"CurrentPatient",
	"DiagnosisAPI",
	"$timeout",
	function ($scope, CurrentPatient, DiagnosisAPI, $timeout) {
		// this.$onInit = function () {
		//    console.log(`=====${JSON.stringify($scope.$ctrl.data)}`);
		// };
		$scope.$watch("$ctrl.data", function (newValue, oldValue) {
			if (newValue) {
				$scope.filteredData = [];
				newValue.forEach(element => {
					if (element.od && element.os) {
						if (element.od.status === "ACTIVE" || element.os.status === "ACTIVE") {
							$scope.filteredData.push(element);
						}

					} else if (element.od && !element.os) {
						if (element.od.status === "ACTIVE") {
							$scope.filteredData.push(element);
						}
					} else if (!element.od && element.os) {
						if (element.os.status === "ACTIVE") {
							$scope.filteredData.push(element);
						}
					} else if(element.status === 'ACTIVE'){
						$scope.filteredData.push(element);
					}
				});
			} 
		}, true);
		$scope.popoverOpened1=false;
	},
]);
