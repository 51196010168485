app.component("patientDashboard", {
	templateUrl: "app/src/js/components/patientDashboard/patientDashboard.html",
	controller: "patientDashboardController",
});

app.controller("patientDashboardController", [
	"$scope",
	"$location",
	"CurrentPatient",
	"MEDICAL_INSURANCE",
	"Session",
	"$timeout",
	"PatientAPI",
	"NHI",
	"$modal",
	"ReferralsAPI",
	"CurrentReferral",
	"$state",
	"OperationNote",
	"OperationAPI",
	"$filter",
	"ContactAPI",
	"$window",
	"ENV",
	"NgTableParams",
	"NHISearchPatientsAPI",
	"PlaceAPI",
	"NHI_SERVICE_ERROR",
	"CONFIG",
	"InjectionNoteAPI",
	"UploadFileAPI",
	"ProgressNoteAPI",
	"DiagnosisAPI",
	"ShareData",
	"NHI_VERSION_V2",
	"$mdDialog",
	"ETHNICITY_CODING_272",
	function ($scope,
		 $location,
		 CurrentPatient, 
		 MEDICAL_INSURANCE, 
		 Session, 
		 $timeout, 
		 PatientAPI, 
		 NHI, 
		 $modal, 
		 ReferralsAPI, 
		 CurrentReferral, 
		 $state, 
		 OperationNote, 
		 OperationAPI, 
		 $filter, 
		 ContactAPI, 
		 $window, 
		 ENV, 
		 NgTableParams, 
		 NHISearchPatientsAPI, 
		 PlaceAPI,
		 NHI_SERVICE_ERROR,
		 CONFIG,
		 InjectionNoteAPI,
		 UploadFileAPI,
		 ProgressNoteAPI,
		 DiagnosisAPI,
		 ShareData,
		 NHI_VERSION_V2,
		 $mdDialog,
		 ETHNICITY_CODING_272
		 ) {
		this.$onInit = function () {
			this.CP = CurrentPatient;
			this.CP.eligibilitySummaryFirstLoad = true;
			$scope.NHI_VERSION_V2 = NHI_VERSION_V2;
			$scope.CALENDAR_ENABLE = false;
			if(Session.practice.practiceFeatures && Session.practice.practiceFeatures.calendarFeature === true){
				$scope.CALENDAR_ENABLE = true;
			}
			$scope.fhirNhiFeature = Session.practice.practiceFeatures.fhirNhiFeature;
			if(this.CP.patient && this.CP.patient.id){
				let appointment = null;
				if(this.CP.patient.appointment){
					appointment = angular.copy(this.CP.patient.appointment);
				}
				PatientAPI.isProcessing = true;
				PatientAPI.getPatient(this.CP.patient.id).then(function (resp){
					CurrentPatient.setPatient(resp.data, appointment);
					fetchPractitioners();
				}, function (error) {
					if(!$scope.serverErrorModal){
						$scope.serverErrorModal = $modal({
							scope: $scope,
							templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
							show: true,
							title: "CatTrax has encountered an error",
						});
					}
					
				}).then(function (){
					PatientAPI.isProcessing = false;
				});

				//add to recent
				PatientAPI.addToRecentPatients(this.CP.patient.id);
			}		
		};

        $scope.ethnicityCodingEnabled = ETHNICITY_CODING_272;
		$scope.status = { open: false };
		$scope.OperationNoteList_chart = null;
		$scope.diagnosisData_ocular = [];
		$scope.diagnosisData_systemic = [];
		$scope.fileAPI = UploadFileAPI;
		$scope.PNAPI = ProgressNoteAPI;
		$scope.progressNoteData = [];
		$scope.uploadedDocuments = [];
		$scope.showTimeline = true;
		$scope.ShareData = ShareData;

		$scope.DiagnosisAPI = DiagnosisAPI;
		$scope.popoverOpened1=false;
		$scope.tooltipHide = false;


		$scope.today = new Date();
		$scope.popoverOpened1 = false;
		$scope.$on('$locationChangeStart', function (event, next, current) {
			if ($scope.showCommentsOnly) {
				event.preventDefault();
				$scope.showCommentsOnly = false;
				return;
			} else {
				$scope.CP.clearPatient();
				event.preventDefault();
				return;
			}
		});
		$scope.display = {};
		$scope.display.address = '';
		$scope.display.apartmentNumber = '';
		$scope.showingDate = {};
		$scope.Session = Session;
		$scope.CP = CurrentPatient;
		$scope.PA = PatientAPI;
		$scope.RA = ReferralsAPI;
		$scope.CR = CurrentReferral;
		$scope.NHIAPI = NHISearchPatientsAPI;
		$scope.ContactAPI = ContactAPI;
		$scope.insuranceProvider = MEDICAL_INSURANCE;
		$scope.PlaceAPI = PlaceAPI;
		$scope.userIsPracticeAdmin = $scope.Session.user.roles.length === 1 && $scope.Session.user.roles[0] === "PRACTICE_ADMIN";
		$scope.showWorkupButton = false;
		$scope.showOperationNoteButton = false;
		$scope.showCommentsOnly = false;
		$scope.showPatientEdit = true;
		$scope.showingWarning = false;
		$scope.hideSaveButton = false;
		$scope.showEditButton = true;
		$scope.documentFilter = [];
		$scope.dateArray = [];
		$scope.pathwayFilter = [{ id: '', title: 'All' }, { id: 'Cataract', title: 'Cataract' }];
		$scope.optionalPatients = [];
		$scope.isNHIFeatureEnabled = false;
		$scope.$ctrl.sendEmr = false;

		
		if (
			Session.practice.practiceFeatures &&
			(Session.practice.practiceFeatures.nhiFeature === true || Session.practice.practiceFeatures.fhirNhiFeature === true)
		) {
			$scope.isNHIFeatureEnabled = true;
		}

		if (!$scope.CP.patient || !$scope.CP.patient.id) {
			$scope.showEditButton = false;
			//380: 'Not asked' should be the default value
			if($scope.CP.patient){
				$scope.CP.patient = {...$scope.CP.patient, smokerAnswer: 'NOT_ASKED'};
			}else{
				$scope.CP.patient.smokerAnswer = 'NOT_ASKED';
			}
		}

		function fetchPractitioners (){
			PatientAPI.getPractitioners($scope.CP.patient.id).then(function (resp){
				$scope.CP.patient.practitioners = angular.copy(resp.data);
			}, function (error) {});
		}

		$scope.$watch(
			"CP.patient.dateOfBirth",
			function (newValue) {
				if (newValue) {
					var dob = new Date($scope.CP.patient.dateOfBirth);
					$scope.showingDate = {
						DD: dob.getDate(),
						MM: dob.getMonth() + 1,
						YYYY: dob.getFullYear(),
					};
				}
			},
			true
		);

		$scope.$watch(
			"CP.patient.id",
			function (newValue) {
				if (newValue) {
					$scope.dashboardReload();
				}
			},
			true
		);

		$scope.popoverDormantNHI = function(obj) {
			return {
				title: 'Dormant NHI',
				content:
				obj.dormantIdentifiers.join(),
			};
		} 

	
		$scope.openEditForm = function () {
			// if(!$scope.showCommentsOnly){
			//   $scope.showPatientEdit = true;
			//   $scope.showEditButton = false;
			// }
			if (!$scope.showCommentsOnly) {
				$timeout(function () {
					$scope.showPatientEdit = true;
				}, 200);
				$scope.showEditButton = false;
			}
		}

		$scope.getInsuranceName = function (provider) {
			const item = MEDICAL_INSURANCE.find(t => t.value === provider);
			return item ? item.name : 'Add insurance';
		}
		// if ($scope.Session.user.roles.length === 1 && $scope.Session.user.roles[0] === "PRACTICE_ADMIN") {
		// 	$scope.userIsPracticeAdmin = true;
		// }
	

		if (Session.user.roles[0] === "OPTOMETRIST" && ['PRIVATE','DHB_PUBLIC'].includes(Session.practice.type)) {
			$scope.showWorkupButton = true;
		} else if(['TECHNICIAN','GP','REGISTERED_NURSE','CLINICAL_NURSE_SPECIALIST','REGISTRAR','OPHTHALMOLOGIST','ADMIN'].some(t => t === Session.user.roles[0])){
			$scope.showWorkupButton = true;
		} else {
			$scope.showWorkupButton = false;
		}



		$scope.autocompleteOptions = {
			componentRestrictions: { country: "nz" },
			types: ["geocode"],
		};

		function resetFormExtras() {
			$scope.showingDate = {
				YYYY: undefined,
				MM: undefined,
				DD: undefined,
			};
			$scope.display.address = "";
			$scope.display.apartmentNumber = "";
			$scope.selection = [];

			$scope.ethnicities = [
				{
					name: "New Zealand European",
					value: "NZ_EUROPEAN",
					selected: false,
				},
				{
					name: "Maori",
					value: "MAORI",
					selected: false,
				},
				{
					name: "Samoan",
					value: "SAMOAN",
					selected: false,
				},
				{
					name: "Cook Island Maori",
					value: "COOK_ISLAND_MAORI",
					selected: false,
				},
				{
					name: "Tongan",
					value: "TONGAN",
					selected: false,
				},
				{
					name: "Niuean",
					value: "NIUEAN",
					selected: false,
				},
				{
					name: "Chinese",
					value: "CHINESE",
					selected: false,
				},
				{
					name: "Indian",
					value: "INDIAN",
					selected: false,
				},
				{
					name: "Other",
					value: "OTHER",
					selected: false,
				},
			];
		}
		resetFormExtras();
		var dob = new Date($scope.CP.patient.dateOfBirth);
		$scope.showingDate = {
			DD: dob.getDate(),
			MM: dob.getMonth() + 1,
			YYYY: dob.getFullYear(),
		};
		if ($scope.CP.patient.id) {
			$scope.showPatientEdit = false;
			$scope.hideSaveButton = false;
			if ($scope.CP.patient.addresses.length === 0) {
				var obj = {
					line1: "",
					postCode: 1111,
					type: "PHYSICAL",
				};
				$scope.CP.patient.addresses.push(obj);
			} else {
				$scope.display.address = $scope.CP.patient.addresses[0].line1;
				$scope.display.apartmentNumber = $scope.CP.patient.addresses[0].apartmentNumber;
				$scope.startAddress = $scope.CP.patient.addresses[0].line1;
			}

			angular.forEach($scope.CP.patient.ethnicities, function (ethn) {
				angular.forEach($scope.ethnicities, function (item, idx) {
					if (ethn === $scope.ethnicities[idx].value) {
						$scope.ethnicities[idx].selected = true;
					}
				});
			});
		} else if ($scope.CP.patient.addresses && $scope.CP.patient.addresses[0]) {
			$scope.display.address = $scope.CP.patient.addresses[0].line1;
			$scope.startAddress = $scope.CP.patient.addresses[0].line1;
			// $scope.display.address = $scope.CP.patient.addresses[0].line1;
			$scope.display.apartmentNumber = $scope.CP.patient.addresses[0].apartmentNumber ? $scope.CP.patient.addresses[0].apartmentNumber : '';
			// $scope.startAddress = $scope.CP.patient.addresses[0].line1;
		}

		if(['REGISTRAR','OPHTHALMOLOGIST','ADMIN'].some(t => t === Session.user.roles[0])){
			$scope.showOperationNoteButton = true;
		}else{
			$scope.showOperationNoteButton = false;
		}

		//TODO fetchPatientDocument();
		fetchPatientDiagnosis();
		fetchProgressNote();
		requestOperationNote(); // for chart
		$scope.$watch(
			"ethnicities|filter:{selected:true}",
			function (newValue) {
				$scope.selection = newValue.map(function (ethn) {
					return ethn.value;
				});
			},
			true
		);
		$scope.$watch(
			"selection",
			function (newValue, oldValue) {
				if (newValue && newValue.length > 0) {
					if ($scope.selection.indexOf("OTHER") === -1) {
						$scope.CP.patient.otherEthnicities = "";
					}
				}
			},
			true
		);

		$scope.$watch(
			"CP.patient.addresses",
			function (newValue, oldValue) {
				if (newValue && newValue[0] && newValue[0].json) {
					$scope.display.address = JSON.parse(newValue[0].json);
					$scope.startAddress = newValue[0].line1;
					$scope.display.apartmentNumber = newValue[0].apartmentNumber
						? newValue[0].apartmentNumber
						: "";
				}
			},
			true
		);






		$scope.cancel = function () {
			$scope.CP.clearPatient();
		}

		$scope.collapse = function () {
			$scope.showPatientEdit = false;
			$scope.showEditButton = true;
			if(!$scope.CP.patient.id){
				//cancel new patient create
				$window.history.back();
			}
		}

		function makeDateString() {
			return $scope.showingDate.YYYY + "-" + $scope.showingDate.MM + "-" + $scope.showingDate.DD;
		}

		$scope.removeEmptyStringValues = function () {
			// Once text is entered and deleted into the phoneNumber, mobileNumber and email fields the value is set to an empty string.
			// Backend requires these values to pass the regex or be null
			if ($scope.CP.patient.phoneNumber === "") {
				$scope.CP.patient.phoneNumber = null;
			}
			if ($scope.CP.patient.mobileNumber === "") {
				$scope.CP.patient.mobileNumber = null;
			}
			if ($scope.CP.patient.email === "") {
				$scope.CP.patient.email = null;
			}
		};

		$scope.validateNHI = function (value, theForm) {
			if (value === "") {
				$scope.badNHI = false;
				theForm.NHI.$setValidity("badNHI", true);
				delete $scope.CP.patient.nhi;
				return;
			}

			if (NHI.validate(value) === true) {
				$scope.badNHI = false;
				theForm.NHI.$setValidity("badNHI", true);
				searchNHIPatients(value);
			} else {
				$scope.badNHI = true;
				theForm.NHI.$setValidity("badNHI", false);
			}
		};

		function queryPatients(lastName, dob) {
			return $scope.NHI_VERSION_V2 ? $scope.NHIAPI.queryPatientsV2(lastName, dob) : $scope.NHIAPI.queryPatients(lastName, dob);
		}

		function modalForNHI() {
			if($scope.NHI_VERSION_V2){
				$mdDialog.show({
					controller: "nhiRecordController",
					templateUrl: "app/src/views/templates/modal.select-patient-v2.tpl.html",
					parent: angular.element(document.body),
					clickOutsideToClose: false,
					scope: $scope.$new(true),
					preserveScope: false,
					onComplete: (scope, element) => {
						element.prepend('<span id="modal-close-btn" class="glyphicon glyphicon-remove dismiss-close-icon pointer"></span>');
						$("#modal-close-btn").click(function (event) {
							$mdDialog.hide();
						});
					},
					resolve: {
						optionalPatients: () => $scope.optionalPatients,
						callback: () => (nhiObj) => $scope.presetPatientV2(nhiObj),
						skipAction: () => null
					},
				});
			}else{
				$scope.patientsOptionalModal = $modal({
					scope: $scope,
					templateUrl: "app/src/views/templates/modal.select-patient.tpl.html",
					show: true,
					title: "SUGGESTED PATIENTS FROM THE NHI SERVICE",
					backdrop: "static",
					keyboard: false,
					onHide: function () {
						//TODO
						// $scope.resetSearch();
					},
				});
			}
		}

		$scope.searchPatient = function () {
			$scope.PA.isProcessing = true;
			$scope.optionalPatients = [];
			$scope.clickedPatient = null;
			const dob = $scope.showingDate.YYYY + "/" + $scope.showingDate.MM + "/" + $scope.showingDate.DD
			queryPatients($scope.CP.patient.lastName, dob)
				.then(function (resp) { 
					if(resp.data){
						// $scope.optionalPatients.push(resp.data);
						// $scope.optionalPatients = $scope.optionalPatients.flat();
						$scope.optionalPatients = resp.data.map(t => ({ ...t, 
							firstName: t.names[0].firstName,
							middleName: t.names[0].middleName,
							lastName: t.names[0].lastName,
							codedEthnicities: angular.copy(t.ethnicities),
							expanded: false,
						 }))
						$scope.tableOptionalPatientsParams = new NgTableParams({
							count: 5
						}, {
							counts: [],
							dataset: $scope.optionalPatients
						});
					}
					modalForNHI();
				},
					function (err) { 
						console.log(`${JSON.stringify(err)}`)
						if($scope.serverErrorModal){
							$scope.serverErrorModal.hide();
						}
						$scope.alertMessage = NHI_SERVICE_ERROR.body;
						if(!$scope.serverErrorModal){
							$scope.serverErrorModal = $modal({
								scope: $scope,
								templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
								show: true,
								title: NHI_SERVICE_ERROR.title,
							});
						}
					})
				.then(function () {
					$scope.PA.isProcessing = false;
				})
		}

		$scope.trimLongText = function(text){
			if(text && text.length > 30){
				return text.substring(0, 27) + "...";
			}
			return text;
		}

		function searchAllNHIPatients(nhi) {
			return $scope.NHI_VERSION_V2 ? $scope.NHIAPI.searchAllNHIPatientsV2(nhi) : $scope.NHIAPI.searchAllNHIPatients(nhi);
		}

		function renderNHIResultV1(resp) {
			if (resp.data) {
				$scope.optionalPatients.push(resp.data);
				$scope.optionalPatients = $scope.optionalPatients.map(t => ({ ...t, 
					firstName: t.names[0].firstName,
					middleName: t.names[0].middleName,
					lastName: t.names[0].lastName,
					expanded: false,
				 }))

				$scope.tableOptionalPatientsParams = new NgTableParams({
					count: 5,
				}, {
					counts: [],
					dataset: $scope.optionalPatients
				});
			}
		}

		function getName(nameArr) {
			const target = nameArr.find(t=>t.preferred);
			return {firstName:target.givenNameList.join(' '),middleName:'',lastName:target.familyName};
		}


		function renderNHIResultV2(resp) {
			if (resp.data) {
				$scope.optionalPatients = [];
				if(Array.isArray(resp.data)){
					const transformed = resp.data.map(t=>({...t,codedEthnicities:angular.copy(t.ethnicities)}))
					$scope.optionalPatients.push(transformed);
				}else{
					$scope.optionalPatients.push({...resp.data,codedEthnicities: resp.data.ethnicities});
				}
				$scope.optionalPatients = $scope.optionalPatients.map(t => ({ ...t, 
					expanded: false,
					...getName(t.names),
				 }))

				$scope.tableOptionalPatientsParams = new NgTableParams({
					count: 5,
				}, {
					counts: [],
					dataset: $scope.optionalPatients
				});
			}
		}

		function searchNHIPatients(nhi) {
			if(!$scope.isNHIFeatureEnabled){
				return;
			}
			$scope.PA.isProcessing = true;
			$scope.optionalPatients = [];
			$scope.clickedPatient = null;
			searchAllNHIPatients(nhi)
				.then(
					function (resp) {
						$scope.NHI_VERSION_V2 ? renderNHIResultV2(resp) : renderNHIResultV1(resp);

						modalForNHI();

					},
					function (err) {
						console.log(`${JSON.stringify(err)}`)
						if($scope.serverErrorModal){
							$scope.serverErrorModal.hide();
						}
						$scope.alertMessage = NHI_SERVICE_ERROR.body;
						if (!$scope.serverErrorModal) {
							$scope.serverErrorModal = $modal({
								scope: $scope,
								templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
								show: true,
								title: NHI_SERVICE_ERROR.title,
							});
						}
					}
				).then(function () {
					$scope.PA.isProcessing = false;
				})
		}

		$scope.selectOptionalPatient = function (patient) {
			$scope.optionalPatients.forEach(function (val) {
				val.expanded = false;
			})
			patient.expanded = true;
		}

		$scope.createNewPatientUI = function () {
			if ($scope.patientsOptionalModal) {
				$scope.patientsOptionalModal.hide();
			}
			// $scope.CP.createNewPatient();
		}

		function formatAddress(address) {
			let formattedAddress = '';
			if (address.streetAddress) {
				formattedAddress += address.streetAddress + ',';
			}
			if (address.suburb) {
				formattedAddress += address.suburb + ',';
			}
			if (address.city) {
				formattedAddress += address.city;
			}
			return formattedAddress
		}

		function formatAddressPostCode(address) {
			let formattedAddress = '';
			if (address.streetAddress) {
				formattedAddress += address.streetAddress + ',';
			}
			if (address.suburb) {
				formattedAddress += address.suburb + ',';
			}
			if (address.city) {
				formattedAddress += address.city + ',';
			}
			if (address.postCode) {
				formattedAddress += address.postCode;
			}
			return formattedAddress
		}

		function extractApartmentNumber(addressObj) {
			const streetAddress = addressObj.streetAddress
			if (streetAddress.includes('/')) {
				addressObj.streetAddress = streetAddress.split('/')[1];
				addressObj.apartmentNumber = streetAddress.split('/')[0];
			}
		}

		$scope.presetPatient = function (patient) {
			$scope.showingDate.DD = parseInt(patient.dateOfBirth.substr(8, 2));
			$scope.showingDate.MM = parseInt(patient.dateOfBirth.substr(5, 2));
			$scope.showingDate.YYYY = parseInt(patient.dateOfBirth.substr(0, 4));
			$scope.CP.patient.lastName = patient.names[0].lastName;
			$scope.CP.patient.dateOfBirth = makeDateString();
			$scope.CP.patient.firstName = patient.names[0].firstName;
			$scope.CP.patient.nhi = patient.nhi;
			$scope.CP.patient.gender = $scope.NHIAPI.transformGenderProp(patient.gender);
			// $scope.CP.patient.isResident = patient.isResident;
			if (patient.contacts && patient.contacts.length > 0) {
				if (!patient.contacts[0].confidentialInformation && patient.contacts[0].phoneNumber) {
					$scope.CP.patient.phoneNumber = patient.contacts[0].phoneNumber;
				}
			}
			if (CONFIG.NHI_ADDRESS && patient.addresses && patient.addresses.length > 0) {
				$scope.CP.patient.addresses = [{}];
				const address = patient.addresses[0];
				extractApartmentNumber(address);
				const add = formatAddressPostCode(address);
				$scope.PlaceAPI.getPlaceDetail(document.getElementById('hidden_address'), add, function (place) {
					$scope.CP.patient.addresses[0] = {type: "PHYSICAL"};
					$scope.CP.patient.addresses[0].apartmentNumber = address.apartmentNumber ? address.apartmentNumber : '';
					$scope.display.address = angular.copy(place);
					$scope.display.apartmentNumber = $scope.CP.patient.addresses[0].apartmentNumber ? $scope.CP.patient.addresses[0].apartmentNumber : '';
					setTimeout(()=>{
						$scope.$apply();
					},0);
					
				});
			}
			if ($scope.patientsOptionalModal) {
				$scope.patientsOptionalModal.hide();
			}
			$scope.CP.createNewPatientWithPreset();
		}

		function composeDOB(dateOfBirth) {
			if($scope.fhirNhiFeature){
				$scope.showingDate.DD = parseInt(dateOfBirth[0]);
				$scope.showingDate.MM = parseInt(dateOfBirth[1]);
				$scope.showingDate.YYYY = parseInt(dateOfBirth[2]);
			}else{
				$scope.showingDate.DD = parseInt(dateOfBirth[2]);
				$scope.showingDate.MM = parseInt(dateOfBirth[1]);
				$scope.showingDate.YYYY = parseInt(dateOfBirth[0]);
			}
		}

		function getNZCitizenState(nzCitizen) {
           if(nzCitizen === 'YES'){
			  return true;
		   }else if(nzCitizen === 'NO'){
			  return false;
		   }
		   return null;
		}

		$scope.presetPatientV2 = function (patient) {
			const dateOfBirth = patient.dob.split('-');
			composeDOB(dateOfBirth);
			$scope.CP.patient.lastName = patient.familyNameCol;
			$scope.CP.patient.dateOfBirth = $scope.showingDate.YYYY + "/" + $scope.showingDate.MM + "/" + $scope.showingDate.DD;
			$scope.CP.patient.firstName = patient.firstNameCol;
			$scope.CP.patient.nhi = patient.nhiCol;
			$scope.CP.patient.gender = $scope.NHIAPI.transformGenderProp(patient.gender);
			$scope.CP.patient.isResident = getNZCitizenState(patient.nzCitizen);
			$scope.CP.patient.codedEthnicities = patient.codedEthnicities;
			// if (patient.contacts && patient.contacts.length > 0) {
			// 	if (!patient.contacts[0].confidentialInformation && patient.contacts[0].phoneNumber) {
			// 		$scope.CP.patient.phoneNumber = patient.contacts[0].phoneNumber;
			// 	}
			// }
			if (CONFIG.NHI_ADDRESS && patient.addresses && patient.addresses.length > 0) {
				$scope.CP.patient.addresses = [{}];
				let address = patient.addresses.find(t=>t.addressType === 'PHYSICAL');
				if(!address){
					address = patient.addresses[0];
				}

				if(address && !address.addressCountry && !address.overseas){
					address.addressCountry = 'New Zealand';
				}

				// extractApartmentNumber(address);
				// const add = formatAddressPostCode(address);
				const add = address.addressLine.join(',') + ',' + address.addressCity + ',' + address.addressCountry + ',' + address.addressPostalCode;
			
				$scope.PlaceAPI.getPlaceDetail(document.getElementById('hidden_address'), add, function (place) {
					$scope.CP.patient.addresses[0] = {type: "PHYSICAL"};
					$scope.CP.patient.addresses[0].apartmentNumber = address.apartmentNumber ? address.apartmentNumber : '';
					$scope.display.address = angular.copy(place);
					$scope.display.apartmentNumber = $scope.CP.patient.addresses[0].apartmentNumber ? $scope.CP.patient.addresses[0].apartmentNumber : '';
					setTimeout(()=>{
						$scope.$apply();
					},0);
					
				});
			}
			if ($scope.patientsOptionalModal) {
				$scope.patientsOptionalModal.hide();
			}
			$scope.CP.createNewPatientWithPreset();
		}

		function saveChanges() {
			if ($scope.startAddress === $scope.display.address) {
				// then address is unchanged and skip
			} else {
				if ($scope.display.address instanceof Object) {
					$scope.CP.patient.addresses[0].line1 = $scope.display.address.formatted_address;
					$scope.CP.patient.addresses[0].json = JSON.stringify($scope.display.address);

					$scope.display.address.address_components.map(function (obj) {
						if (obj.types.indexOf("postal_code") !== -1) {
							$scope.CP.patient.addresses[0].postCode = obj.long_name;
						}
					});

					$scope.display.address.address_components.map(function (obj) {
						if (obj.types.indexOf("country") !== -1) {
							$scope.CP.patient.addresses[0].country = obj.long_name;
						}
					});

					$scope.display.address.address_components.map(function (obj) {
						if (obj.types.indexOf("locality") !== -1) {
							$scope.CP.patient.addresses[0].city = obj.long_name;
						}
					});

					$scope.display.address.address_components.map(function (obj) {
						if (obj.types.indexOf("sublocality") !== -1) {
							$scope.CP.patient.addresses[0].line2 = obj.long_name;
						}
					});
				} else {
					document.querySelector("#ct-address").select();
					$scope.addressError = true;
					return false;
				}
			}
			$scope.CP.patient.addresses[0].apartmentNumber = $scope.display.apartmentNumber;
			$scope.PA.isProcessing = true;

			if (angular.isDate(new Date(makeDateString()))) {
				$scope.CP.patient.dateOfBirth = makeDateString();
			}

			$scope.CP.patient.ethnicities = $scope.selection;

			$scope.removeEmptyStringValues();

			$scope.PA.update($scope.CP.patient, $scope.$ctrl.sendEmr)
				.then(
					function (resp) {
						$scope.CP.setPatient(resp.data);
						//$scope.searchResults[$scope.CPIdx] = resp.data;
					},
					function (err) {
						console.log(err);
						if (err.data && err.data.status === 409) {
							// close modal
							// $scope.createModal.hide();
							// show error modal
							$scope.duplicateModal = $modal({
								scope: $scope,
								templateUrl: "app/src/views/templates/modal.duplicate-patient.tpl.html",
								show: true,
								title: "DUPLICATE PATIENT",
								backdrop: "static",
								keyboard: false,
								onHide: function () {
									$scope.CP.setPatient($scope.CP.patientBackup);
									// $scope.resetSearch();
								},
							});
						} else {
							if (!$scope.serverErrorModal) {
								$scope.serverErrorModal = $modal({
									scope: $scope,
									templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
									show: true,
									title: "CatTrax has encountered an error",
								});
							}
						}
					}
				)
				.then(function (obj) {
					$scope.PA.isProcessing = false;
					$scope.showPatientEdit = false;
					$scope.showEditButton = true;
				});
		};

		$scope.registerOrUpdatePatient = function () {
			if ($scope.CP.patient.id /**&& !$scope.CP.isPatientNew**/) {
				saveChanges();
				return
			}
			if (!$scope.hideSaveButton && !$scope.showingWarning) {
				$scope.hideSaveButton = true;
				$timeout(function () {
					$scope.showingWarning = true;
				}, 10);
				return
			}
			$scope.PA.isProcessing = true;
			$scope.CP.patient.practice = { id: Session.practice.id };
			$scope.CP.patient.dateOfBirth = makeDateString();
			$scope.CP.patient.ethnicities = $scope.selection;
			if(!$scope.CP.patient.addresses){
				$scope.CP.patient.addresses = [{}];
			}

			if ($scope.display.address.formatted_address) {
				$scope.CP.patient.addresses[0].line1 = $scope.display.address.formatted_address;
			}
			$scope.CP.patient.addresses[0].type = "PHYSICAL";
			$scope.CP.patient.addresses[0].line3 = null;
			$scope.CP.patient.addresses[0].line4 = null;
			if ($scope.display.apartmentNumber) {
				$scope.CP.patient.addresses[0].apartmentNumber = $scope.display.apartmentNumber;
			}
			$scope.CP.patient.addresses[0].json = JSON.stringify($scope.display.address);

			if ($scope.display.address instanceof Object) {
				$scope.display.address.address_components.map(function (obj) {
					if (obj.types.indexOf("postal_code") !== -1) {
						$scope.CP.patient.addresses[0].postCode = obj.long_name;
					}
				});

				$scope.display.address.address_components.map(function (obj) {
					if (obj.types.indexOf("country") !== -1) {
						$scope.CP.patient.addresses[0].country = obj.long_name;
					}
				});

				$scope.display.address.address_components.map(function (obj) {
					if (obj.types.indexOf("locality") !== -1) {
						$scope.CP.patient.addresses[0].city = obj.long_name;
					}
				});

				$scope.display.address.address_components.map(function (obj) {
					if (obj.types.indexOf("sublocality") !== -1) {
						$scope.CP.patient.addresses[0].line2 = obj.long_name;
					}
				});
			}

			$scope.removeEmptyStringValues();

			$scope.PA.create($scope.CP.patient, $scope.$ctrl.sendEmr)
				.then(
					function (resp) {
						$scope.CP.setPatient(resp.data);
						$scope.successfulEditModal = $modal({
							scope: $scope,
							templateUrl: "app/src/views/templates/modal.successful-edit.tpl.html",
							show: true,
							title: "SUCCESS",
							onHide: function () {
								$scope.showPatientEdit = false;
								$scope.showingWarning = false;
								$scope.hideSaveButton = false;
								// $scope.CP.clearPatient();
								// $timeout(function(){
								// 	angular.element(document.querySelector("#submit")).trigger("click");
								// },200);
								// angular.element(document.querySelector("#submit")).trigger("click");
							},
						});

					},
					function (err) {
						console.log(err);
						$scope.PA.isProcessing = false;
						if (err.data && err.data.status === 409) {
							// close modal
							// $scope.createModal.hide();
							// show error modal
							$scope.duplicateModal = $modal({
								scope: $scope,
								templateUrl: "app/src/views/templates/modal.duplicate-patient.tpl.html",
								show: true,
								title: "DUPLICATE PATIENT",
								backdrop: "static",
								keyboard: false,
								onHide: function () {
									// $scope.resetSearch();
								},
							});
						} else {
							if (!$scope.serverErrorModal) {
								$scope.serverErrorModal = $modal({
									scope: $scope,
									templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
									show: true,
									title: "CatTrax has encountered an error",
								});
							}
						}
					}
				)
				.then(function (obj) {
					$scope.PA.isProcessing = false;
					$scope.showEditButton = true;
					$scope.showPatientEdit = false;
				});
		};

		$scope.startReferral = function () {
			$scope.PA.isProcessing = true;
			$scope.RA.startReferral().then(
				function (resp) {
					$scope.CR.setReferral(resp.data);
					// $scope.patientDetailsModal.hide();
					$scope.PA.isProcessing = false;
					$state.go("examination");
				},
				function (err) {
					console.log(err);
					$scope.PA.isProcessing = false;
				}
			);
		};

		$scope.tracePatient = function () {
			// show spinner
			ContactAPI.isProcessing = true;
			$scope.ContactAPI.tracePatient(Session.userId, Session.practice.id, $scope.CP.patient.id).then(
				function (resp) {
					// hide spinner
					ContactAPI.isProcessing = false;
					// $scope.patientDetailsModal.hide();
					// show success modal
					$scope.traceSuccessModal = $modal({
						scope: $scope,
						templateUrl: "app/src/views/templates/modal.successful-contact-trace.tpl.html",
						show: true,
						title: "SUCCESS",
						backdrop: "static",
						keyboard: false,
						onHide: function () {
							// $scope.startAgain();
							// This function name - startAgain(), can not be changed as it is called from the modal
							// template in two controllers(this one and examinationController).
						},
					});
				},
				function (err) {
					ContactAPI.isProcessing = false;
					console.log(err);
				}
			);
		};
		$scope.startWorkUp = function () {
			// $scope.patientDetailsModal.hide();
			$state.go("pre-op");
		};

		$scope.startOperationNote = function () {
			// $scope.patientDetailsModal.hide();
			$state.go("operation-note");
		};

		$scope.startInjectionNote = function () {
			$state.go("injection-note");
		}

		$scope.startAgain = function () {
			$scope.traceSuccessModal && $scope.traceSuccessModal.hide();
			$window.history.back();
		};

		$scope.backToDashboard = function () {
			$scope.traceSuccessModal && $scope.traceSuccessModal.hide();
		}


		function fetchProgressNote() {
			if (!$scope.CP.patient || !$scope.CP.patient.id || $scope.userIsPracticeAdmin) {
				return;
			}
			
			$scope.PNAPI.isProcessing = true;
			$scope.PNAPI.getProgressNotes($scope.CP.patient.id).then(function (resp) { 
				const newData = resp.data.map(t => {
					return {
						...t, 
						dateOfConsult: $filter("date")(new Date(t.date_of_consult), "dd-MMMM-yyyy"),
						dateOfEntry: $filter("date")(new Date(t.date_of_entry), "dd-MMMM-yyyy"),
					}
				}).sort(function compareFn(a, b) { return new Date(a.dateOfConsult).getTime() - new Date(b.dateOfConsult).getTime() });
				$scope.progressNoteData = newData;
			}, function (error) {
				console.error(error); 
				if (!$scope.serverErrorModal) {
					$scope.serverErrorModal = $modal({
						scope: $scope,
						templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
						show: true,
						title: "CatTrax has encountered an error",
					});
				}
			}).then(function () {
				$scope.PNAPI.isProcessing = false;
			})
		}

		function fetchPatientDocument () {
			if(fetchDocumentHandler){
				fetchDocumentHandler();
			}
		}

		$scope.documentEvent = function (document) {
			if (document) {
				$scope.showCommentsOnly = true;
				$scope.hiddenFooter = false;
				$scope.operative_eye = document.operative_eye;
				$scope.disableSelect = document.disableSelect;
				$scope.OperationNote = document.operationNote;
			}
		}

		function requestOperationNote() {
			if (!$scope.CP.patient || !$scope.CP.patient.id || $scope.userIsPracticeAdmin) {
				return;
			}
			OperationAPI.getOperationNotes($scope.CP.patient.id).then(function () {
				if (OperationNote.noteList.length === 0) {
					$scope.OperationNote = OperationNote.data;
					// $scope.OperationNoteList = [];
					$scope.OperationNoteList_chart = [];
				} else {
					// $scope.OperationNoteList = OperationNote.noteList;
					$scope.OperationNoteList_chart = OperationNote.noteList;
				}
			});
		}
		

		$scope.addInjectionComment = function () {
			const body = {
				injection_note_od_comment: $scope.OperationNote.op_note_od.comment,
				injection_note_os_comment: $scope.OperationNote.op_note_os.comment,
			};
			
			InjectionNoteAPI.addNewComment($scope.OperationNote.patient_id, $scope.OperationNote.injection_note_id, body)
			.then(function (resp) {
				$scope.showCommentsOnly = false;
			})
			.catch(function (error) {
				console.error(`append comment ${JSON.stringify(error)}`);
				if (!$scope.serverErrorModal) {
					$scope.serverErrorModal = $modal({
						scope: $scope,
						templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
						show: true,
						title: "CatTrax has encountered an error",
					});
				}
			});
		}

		$scope.addComment = function () {
			const body = {
				op_note_od_comment: $scope.OperationNote.op_note_od.comment,
				op_note_os_comment: $scope.OperationNote.op_note_os.comment,
			};
			OperationAPI.addNewComment($scope.OperationNote.patient_id, $scope.OperationNote.operation_note_id, body)
				.then(function (resp) {
					// requestOperationNote();
					$scope.showCommentsOnly = false;
				})
				.catch(function (error) {
					console.error(`append comment ${JSON.stringify(error)}`);
					if (!$scope.serverErrorModal) {
						$scope.serverErrorModal = $modal({
							scope: $scope,
							templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
							show: true,
							title: "CatTrax has encountered an error",
						});
					}
				});
		};

		$scope.hideComment = function() {
			$scope.OperationNote = {};
			$scope.showCommentsOnly = false;
		}
		

		$scope.isSectionTwoVisible = function () {
			return $scope.showingDate.DD &&
				$scope.showingDate.MM &&
				$scope.showingDate.YYYY &&
				$scope.CP.patient.firstName &&
				$scope.CP.patient.lastName &&
				$scope.CP.patient.phoneNumber;
		}

		$scope.isSectionThreeVisible = function () {
			return $scope.isSectionTwoVisible() && $scope.display.address;
		}

		$scope.formatDate = function (_date) {
			if(_date){
				const date = new Date(_date);
				return date.getDate()  + "-" + (date.getMonth()+1) + "-" + date.getFullYear();
			}
			return "";
		}

		$scope.startClinicalNote = function () {
			$state.go("clinical-note");
		}

		$scope.diagnosisCallback = function (form){
			$scope.diagnosis = {...$scope.diagnosis,...form};
		}

		$scope.saveDiagnosis = function () {
			$scope.DiagnosisAPI.isProcessing = true;
			let diagnosisArray = [];
			if($scope.diagnosis.diagnosis_type === 'ocular'){
				if($scope.diagnosis.operative_eye === 'OU'){
					diagnosisArray = [{
						date_diagnosis_onset: $filter("date")(new Date($scope.diagnosis.date_onset), "yyyy/MM/dd"),
						laterality: 'OD',
						search_term: $scope.diagnosis.diagnosis,
						note: $scope.diagnosis.comment,
						type: 'OCULAR',
						snomed_concept: {
							concept_id: $scope.diagnosis.diagnosis_object.conceptId
						}
					}, {
						date_diagnosis_onset: $filter("date")(new Date($scope.diagnosis.date_onset), "yyyy/MM/dd"),
						laterality: 'OS',
						search_term: $scope.diagnosis.diagnosis,
						note: $scope.diagnosis.comment,
						type: 'OCULAR',
						snomed_concept: {
							concept_id: $scope.diagnosis.diagnosis_object.conceptId
						}
					}];
				}else{
					diagnosisArray = [{
						date_diagnosis_onset: $filter("date")(new Date($scope.diagnosis.date_onset), "yyyy/MM/dd"),
						laterality: $scope.diagnosis.operative_eye,
						search_term: $scope.diagnosis.diagnosis,
						note: $scope.diagnosis.comment,
						type: 'OCULAR',
						snomed_concept: {
							concept_id: $scope.diagnosis.diagnosis_object.conceptId
						}
					}];
				}
			}else{
				diagnosisArray = [{
					date_diagnosis_onset: $filter("date")(new Date($scope.diagnosis.date_onset), "yyyy/MM/dd"),
					// laterality: $scope.diagnosis.operative_eye,
					search_term: $scope.diagnosis.diagnosis,
					note: $scope.diagnosis.comment,
					type: 'SYSTEMIC',
					snomed_concept: {
						concept_id: $scope.diagnosis.diagnosis_object.conceptId
					}
				}];
			}
	
			
			$scope.DiagnosisAPI.saveDiagnosis($scope.CP.patient.id,diagnosisArray).then(
				function (resp) {
					if (resp.data) {
					}
				},
				function (err) {
					console.log(`${JSON.stringify(err)}`)

				}
			).then(function () {
				$scope.diagnosisModal.hide();
				fetchPatientDiagnosis();
				$scope.DiagnosisAPI.isProcessing = false;
			});

		}

		$scope.showDiagnosis = function (type) {
			$scope.diagnosis = {
				comment: '',
				date_onset: $filter("date")(new Date(), "dd-MMMM-yyyy"),
			}
			$scope.diagnosis_type = type;
			$scope.diagnosisModal = $modal({
				scope: $scope,
				templateUrl: "app/src/views/templates/modal.diagnosis.tpl.html",
				show: true,
				title: "DIAGNOSES AND PROCEDURES",
				backdrop: "static",
				keyboard: false,
			});
		}

		function fetchPatientDiagnosis() {
			if (!$scope.CP.patient || !$scope.CP.patient.id || $scope.userIsPracticeAdmin) {
				return;
			}
			$scope.DiagnosisAPI.isProcessing = true;
			$scope.DiagnosisAPI.getDiagnosis($scope.CP.patient.id)
				.then(
					function (resp) {
						if (resp.data) {
							$scope.diagnosisData_systemic = resp.data.filter(m => m.type === 'SYSTEMIC').map(t => {
								if (t.snomed_concept.fsn.term.endsWith('(procedure)')) {
									return { ...t, internal_type: 1, date_diagnosis_onset: new Date(t.date_diagnosis_onset) };
								}
								return { ...t, internal_type: 0, date_diagnosis_onset: new Date(t.date_diagnosis_onset) };
							});

							const array = resp.data.filter(t=>t.type === 'OCULAR').map(t=> {
								if(t.snomed_concept.fsn.term.endsWith('(procedure)')){
									return {...t, internal_type: 1, date_diagnosis_onset: new Date(t.date_diagnosis_onset)};
								}
								return {...t, internal_type: 0, date_diagnosis_onset: new Date(t.date_diagnosis_onset)};
							});
							array.sort(function compareFn(a, b) {
								if (new Date(a.date_diagnosis_onset) > new Date(b.date_diagnosis_onset)) {
									return -1;
								}
								if (new Date(a.date_diagnosis_onset) < new Date(b.date_diagnosis_onset)) {
									return 1;
								}
								return 0;
							});
							array.sort(function compareFn(a, b) {
								if (a.internal_type > b.internal_type) {
									return 1;
								}
								if (a.internal_type < b.internal_type) {
									return -1;
								}
								return 0;
							});
							const od_array = array.filter(t=>t.laterality === 'OD');
							const os_array = array.filter(t=>t.laterality === 'OS');

							const rows = [];
							const simple_rows = [];
							const od_array_simple = array.filter(t=>t.laterality === 'OD' && t.status === 'ACTIVE');
							const os_array_simple = array.filter(t=>t.laterality === 'OS' && t.status === 'ACTIVE');

							const rows_count_simple = Math.max(od_array_simple.length, os_array_simple.length);
							for(let i = 0; i< rows_count_simple; i++ ){
								simple_rows.push({od: od_array_simple[i], os: os_array_simple[i]});
							}

							if (od_array.length === os_array.length) {
								for (let i = 0; i < od_array.length; i++) {
									rows.push({ od: od_array[i], os: os_array[i] });
								}
							}else if(od_array.length > os_array.length){
								for (let i = 0; i < od_array.length; i++) {
									if(!os_array[i]){
										rows.push({ od: od_array[i], os: null });
									}else{
										rows.push({ od: od_array[i], os: os_array[i] });
									}
									
								}
							}else if(od_array.length < os_array.length){
								for (let i = 0; i < os_array.length; i++) {
									if(!od_array[i]){
										rows.push({ od: null, os: os_array[i] });
									}else{
										rows.push({ od: od_array[i], os: os_array[i] });
									}
									
								}
							}
							$scope.diagnosisData_ocular = rows;
							$scope.diagnosisData_ocular_simple = simple_rows;
						}
					},
					function (err) {
						console.log(err);
					}
				)
				.then(function (obj) {
					$scope.DiagnosisAPI.isProcessing = false;
				});
		}

		$scope.removeDiagnosis = function (diagnosis_id) {
			$scope.DiagnosisAPI.isProcessing = true;
			$scope.DiagnosisAPI.inactivateDiagnosis($scope.CP.patient.id, diagnosis_id)
				.then(
					function (resp) {
						// console.log(`!!!! ${JSON.stringify(resp.data)}`);
						// $scope.diagnosisData = resp.data;
					},
					function (err) {
						console.log(err);
					}
				)
				.then(function (obj) {
					$scope.DiagnosisAPI.isProcessing = false;
					fetchPatientDiagnosis();
				});
			
		}

		$scope.isSNOMEDEnabled = function() {
			return !['PRACTICE_ADMIN'].includes(Session.user.roles[0]);
		}

		$scope.askProgressNoteModal = function () {
			$scope.alertMessage = 'Are you sure to quit progress note ?';
			$scope.errorModal = $modal({
                scope: $scope,
                templateUrl: "app/src/views/templates/modal.file-upload-error.tpl.html",
                show: true,
                title: "Progress note is not submitted yet",
            });
		}

		$scope.closeFileUploadErrorModal = function () {
			if('Are you sure to quit progress note ?' === $scope.alertMessage){
				$scope.progressNoteModal.hide();
			}
			$scope.alertMessage = null; 
			$scope.errorModal.hide();
		}

		$scope.showProgressNoteModal = function (obj) {
			if(obj){
				$scope.progressNoteObj = obj;
			}else{
				const time = new Date();
				time.setSeconds(0,0);
				var lname = $scope.Session.user.lastName || ''
			    var fname = $scope.Session.user.firstName || ''
			    const fullName = fname + ' ' + lname;
				const progressNoteSettings = Session.practice.practiceFeatures.progressNoteSettings111;
				$scope.progressNoteObj = {
					is_soap_format: progressNoteSettings ? progressNoteSettings.is_soap_format : false,
					security_level: progressNoteSettings ? progressNoteSettings.security_level : 'GLOBAL',
					is_virtual_consult: false,
					booked_for_surgery_section:{
						booked_for_surgery: false
					},
					date_of_consult: $filter("date")(new Date(), "dd/MM/yyyy"),
					time_of_consult:  time,
					date_of_entry: $filter("date")(new Date(), "dd/MM/yyyy"),
					time_of_entry: time,
					// follow_up_type: 'ONE_DAY',
					clinician: fullName,
					practiceId: $scope.Session.practice.id,
                    patientId: $scope.CP.patient.id,
					subjective_note: '',
					objective_note: '',
					assessment_note: '',
					plan_note: '',
				};
			}
			
			$scope.progressNoteModal = $modal({
				scope: $scope,
				templateUrl: "app/src/views/templates/modal.progress-note.tpl.html",
				show: true,
				title: "Progress Note",
				backdrop: "static",
				keyboard: false,
			});
		}

		$scope.previewFile = function(file){
			if(previewDocumentHandler){
				previewDocumentHandler(file.document_id, file.document_type, file.file_name);
			}
		}

		$scope.refreshProgressNotes = function () {
			fetchProgressNote();
			fetchPatientDocument();
		}

		$scope.refreshprescription = function () {
			fetchPatientDocument();
		}

		$scope.onProgressNoteSuccess = function () {
			if ($scope.progressNoteModal) {
				$scope.progressNoteModal.hide();
			}
			$scope.refreshProgressNotes();
		}

		$scope.onVisionUpdate = function () {
			fetchPatientDocument();
			$scope.showTimeline = false;
			setTimeout(()=>{
				$scope.showTimeline = true;
			},500);
		}

		let fetchPatientVision = null;
		let fetchPatientsMedication = null;
		let fetchDocumentHandler = null;
		let previewDocumentHandler = null;

		$scope.registerVisionHandler = function(handler){
			fetchPatientVision = handler;
		}

		$scope.registerMedicationHandler = function(handler){
			fetchPatientsMedication = handler;
		}

		$scope.registerDocumentsHandler = function(fetch,preview){
			fetchDocumentHandler = fetch;
			previewDocumentHandler = preview;
		}

		$scope.filesFetched = function (uploadedDocuments) {
			if(uploadedDocuments){
				$scope.uploadedDocuments = uploadedDocuments;
			}
		}

	

		$scope.dashboardReload = function () {
			fetchPatientDocument();
			fetchPatientDiagnosis();
			fetchProgressNote();
			$scope.ShareData.reloadAllergies($scope.CP.patient.id);
			if(fetchPatientVision){
				fetchPatientVision();
			}
			if(fetchPatientsMedication){
				fetchPatientsMedication();
			}
		}
	},
]);
