app.service("AllergyAPI", [
	"$http",
	"ENV",
	"Session",
	function ($http, ENV, Session) {
		var service = {
			isProcessing: false,

			searchAllergy: function (term,eclType) {
				return $http({
					method: "GET",
					url: ENV.API + `/api/patient-allergy?term=${term}&eclType=${eclType}`,
					headers: {
						Authorization: "Bearer " + Session.token,
						"Content-Type": "application/json",
						"X-PRACTICE-ID": `${Session.practice.id}`,
					},
				});
			},
            addAllergy: function (patientId, allergyObj) {
                return $http({
					method: "POST",
					url: ENV.API + `/api/patient-allergy/${patientId}`,
					headers: {
						Authorization: "Bearer " + Session.token,
						"Content-Type": "application/json",
						"X-PRACTICE-ID": `${Session.practice.id}`,
					},
                    data: allergyObj
				}); 
            },
            inactivatePatientAllergy: function (patientId, allergyId) {
                return $http({
					method: "DELETE",
					url: ENV.API + `/api/patient-allergy/${patientId}/${allergyId}`,
					headers: {
						Authorization: "Bearer " + Session.token,
						"Content-Type": "application/json",
						"X-PRACTICE-ID": `${Session.practice.id}`,
					}
				}); 
            },
            getAllergy: function(patientId){
                return $http({
					method: "GET",
					url: ENV.API + `/api/patient-allergy/${patientId}`,
					headers: {
						Authorization: "Bearer " + Session.token,
						"Content-Type": "application/json",
						"X-PRACTICE-ID": `${Session.practice.id}`,
					},
				});

            },
			getNKDA: function (patientId) {
				return $http({
					method: "GET",
					url: ENV.API + `/api/patient-allergy/${patientId}/settings`,
					headers: {
						Authorization: "Bearer " + Session.token,
						"Content-Type": "application/json",
						"X-PRACTICE-ID": `${Session.practice.id}`,
					},
				});
			},
			saveNKDA: function (patientId, value) {
				return $http({
					method: "PUT",
					url: ENV.API + `/api/patient-allergy/${patientId}/settings?nkda=${value}`,
					headers: {
						Authorization: "Bearer " + Session.token,
						"Content-Type": "text/plain;charset=UTF-8",
						"X-PRACTICE-ID": `${Session.practice.id}`,
					},
					transformResponse: [
						function (data) { 
							return data; 
						}
					]
				});
			},
		};

		return service;
	},
]);
