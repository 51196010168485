app.component("extraProcedureSection", {
	templateUrl: "app/src/js/components/operationNote/extraProcedureSection/extraProcedureSection.html",
	controller: "extraProcedureSectionController",
	bindings: {
		data: "=",
		eyeTitle: "@",
		submitted: "="
	},
});

app.controller("extraProcedureSectionController", [
	"$scope",
	"$timeout",
	function ($scope,$timeout) {
		$scope.warn = false;

		this.$onInit = function () {
			if($scope.$ctrl.data.adjuvant_procedures && $scope.$ctrl.data.adjuvant_procedures.adjuvant_procedures === 'NO') {
				$scope.disableProcedures();
				$scope.procedures_disable = 'NO';
			}
		};


		$scope.removeOther = function (index) {
			$scope.$ctrl.data.adjuvant_procedures.other.splice(index, 1);
		};

		$scope.disableProcedures = function () {
			if (!$scope.$ctrl.data) {
				$scope.$ctrl.data = { adjuvant_procedures: {} };
			}

			if (!$scope.$ctrl.data.adjuvant_procedures) {
				$scope.$ctrl.data.adjuvant_procedures = {};
			}

			$scope.$ctrl.data.adjuvant_procedures.vision_blue = "NO";
			$scope.$ctrl.data.adjuvant_procedures.intracameral_phenylephrine = "NO";
			$scope.$ctrl.data.adjuvant_procedures.triamcinolone = "NO";
			$scope.$ctrl.data.adjuvant_procedures.iris_hooks = "NO";
			$scope.$ctrl.data.adjuvant_procedures.malyugan_ring = "NO";
			$scope.$ctrl.data.adjuvant_procedures.planned_anterior_vitrectomy = "NO";
			$scope.$ctrl.data.adjuvant_procedures.unplanned_anterior_vitrectomy = "NO";
			$scope.$ctrl.data.adjuvant_procedures.unplanned_posterior_vitrectomy = "NO";
			$scope.$ctrl.data.adjuvant_procedures.sulcus = "NO";
			$scope.$ctrl.data.adjuvant_procedures.capsular_tension_ring = "NO";
			$scope.$ctrl.data.adjuvant_procedures.intravitreal_injection = "NO";
			$scope.$ctrl.data.adjuvant_procedures.migs = "NO";
			$scope.$ctrl.data.adjuvant_procedures.corneal_suture = "NO";
		};

		$scope.enableProcedures = function () {
			$scope.procedures_disable = "";
		};

		$scope.addOther = function (text) {
			if (text == null) {
				return;
			}

			if (!$scope.$ctrl.data.adjuvant_procedures) {
				$scope.$ctrl.data.adjuvant_procedures = { other: [] };
			}

			if (!$scope.$ctrl.data.adjuvant_procedures.other) {
				$scope.$ctrl.data.adjuvant_procedures.other = [];
			}
			$scope.$ctrl.data.adjuvant_procedures.other.push(text);
			// reset fields to empty
			this.$ctrl.form.other = null;
			$scope.warn = false;
		};

		$scope.showWarn = function () {
			$scope.warn = true;
		}

		$scope.reset = function () {
			if (!$scope.$ctrl.data.adjuvant_procedures) {
				$scope.$ctrl.data.adjuvant_procedures = {};
			}
			if($scope.$ctrl.data.adjuvant_procedures.vision_blue &&
			$scope.$ctrl.data.adjuvant_procedures.intracameral_phenylephrine &&
			$scope.$ctrl.data.adjuvant_procedures.triamcinolone &&
			$scope.$ctrl.data.adjuvant_procedures.iris_hooks &&
			$scope.$ctrl.data.adjuvant_procedures.malyugan_ring &&
			$scope.$ctrl.data.adjuvant_procedures.planned_anterior_vitrectomy &&
			$scope.$ctrl.data.adjuvant_procedures.unplanned_anterior_vitrectomy &&
			$scope.$ctrl.data.adjuvant_procedures.unplanned_posterior_vitrectomy &&
			$scope.$ctrl.data.adjuvant_procedures.sulcus &&
			$scope.$ctrl.data.adjuvant_procedures.capsular_tension_ring &&
			$scope.$ctrl.data.adjuvant_procedures.intravitreal_injection &&
			$scope.$ctrl.data.adjuvant_procedures.migs &&
			$scope.$ctrl.data.adjuvant_procedures.corneal_suture){
				let form = angular.element(document.querySelector("#operation-form"));
				$timeout(
					function () {
						if ($scope.$ctrl.submitted) {
							// element[0].dispatchEvent(new Event("submit"));
							form.submit();
						}
					},
					0,
					false
				);
			}
		};
	},
]);
