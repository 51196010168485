app.component("progressNoteList", {
	templateUrl: "app/src/js/components/progressNote/progressNoteList.html",
	controller: "progressNoteListController",
	bindings: {
		data: "=",
		files: "=",
		clickFile: "<",
		refresh: "<",
	},
});

app.controller("progressNoteListController", [
	"$scope",
	"$filter",
	"CurrentPatient",
	"ProgressNoteAPI",
	"$modal",
	"Session",
	"VIRTUAL_PROGRESS_NOTE_1027",
	function ($scope, $filter, CurrentPatient, ProgressNoteAPI, $modal, Session, VIRTUAL_PROGRESS_NOTE_1027) {
		$scope.PNAPI = ProgressNoteAPI;
		$scope.CP = CurrentPatient;
		$scope.Session = Session;
		$scope.virtualProgressNoteFeature = VIRTUAL_PROGRESS_NOTE_1027;

		$scope.updateVisibility = function (record) {
			const noteId = record.progress_note_id;
			const level = record.security_level === 'GLOBAL' ? 'PRACTICE' : 'GLOBAL';
			$scope.PNAPI.isProcessing = true;
			$scope.PNAPI.updateVisibility($scope.CP.patient.id, noteId,level).then(function (resp) {
				$scope.$ctrl.refresh();
			}, function (error) { 
				$scope.serverErrorModal = $modal({
					scope: $scope,
					templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
					show: true,
					title: "CatTrax has encountered an error",
				});
			}).then(function () {
				$scope.PNAPI.isProcessing = false;
			})
		}

		$scope.deleteNote = function (noteId) {
			$scope.PNAPI.isProcessing = true;
			$scope.PNAPI.deleteProgressNote($scope.CP.patient.id, noteId).then(function (resp) {
				$scope.$ctrl.refresh();
			}, function (error) { 
				$scope.serverErrorModal = $modal({
					scope: $scope,
					templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
					show: true,
					title: "CatTrax has encountered an error",
				});
			}).then(function () {
				$scope.PNAPI.isProcessing = false;
			})
		}

		$scope.restoreNote = function (noteId) {
			$scope.PNAPI.isProcessing = true;
			$scope.PNAPI.restoreProgressNote($scope.CP.patient.id, noteId).then(function (resp) {
				$scope.$ctrl.refresh();
			}, function (error) { 
				$scope.serverErrorModal = $modal({
					scope: $scope,
					templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
					show: true,
					title: "CatTrax has encountered an error",
				});
			}).then(function () {
				$scope.PNAPI.isProcessing = false;
			})
		}

		function sameDay(d1, d2) {
			return d1.getFullYear() === d2.getFullYear() &&
			  d1.getMonth() === d2.getMonth() &&
			  d1.getDate() === d2.getDate();
		  }
		
		$scope.previewFile = function (note){
			$scope.$ctrl.clickFile(note);
		}

		$scope.getFileList = function (note) {
			if ($scope.$ctrl.files.length > 0) {
				return $scope.$ctrl.files.filter(t => sameDay(t.date_time, new Date(note.dateOfConsult)));
			}
			return [];
		}

		$scope.transformDate = function (date) {
			const array = date.split('-');
			return array[2]+'/'+array[1]+'/'+array[0];
		}

		$scope.transformFollowUpType = function (record) {
			switch(record.follow_up_type) {
				case 'ONE_DAY': return '1 day';
				case 'TWO_DAYS': return '2 days';
				case 'THREE_DAYS': return '3 days';
				case 'FOUR_DAYS': return '4 days';
				case 'FIVE_DAYS': return '5 days';
				case 'SIX_DAYS': return '6 days';
				case 'SEVEN_DAYS': return '7 days';
				case 'EIGHT_DAYS': return '8 days';
				case 'NINE_DAYS': return '9 days';
				case 'TEN_DAYS': return '10 days';
				case 'ELEVEN_DAYS': return '11 days';
				case 'TWELVE_DAYS': return '12 days';
				case 'ONE_WEEK': return '1 week';
				case 'TWO_WEEKS': return '2 weeks';
				case 'THREE_WEEKS': return '3 weeks';
				case 'FOUR_WEEKS': return '4 weeks';
				case 'FIVE_WEEKS': return '5 weeks';
				case 'SIX_WEEKS': return '6 weeks';
				case 'SEVEN_WEEKS': return '7 weeks';
				case 'EIGHT_WEEKS': return '8 weeks';
				case 'NINE_WEEKS': return '9 weeks';
				case 'TEN_WEEKS': return '10 weeks';
				case 'ELEVEN_WEEKS': return '11 weeks';
				case 'TWELVE_WEEKS': return '12 weeks';
				case 'ONE_MONTH': return '1 month';
				case 'TWO_MONTHS': return '2 months';
				case 'THREE_MONTHS': return '3 months';
				case 'FOUR_MONTHS': return '4 months';
				case 'FIVE_MONTHS': return '5 months';
				case 'SIX_MONTHS': return '6 months';
				case 'SEVEN_MONTHS': return '7 months';
				case 'EIGHT_MONTHS': return '8 months';
				case 'NINE_MONTHS': return '9 months';
				case 'TEN_MONTHS': return '10 months';
				case 'ELEVEN_MONTHS': return '11 months';
				case 'TWELVE_MONTHS': return '12 months';
				case 'TWENTY_FOUR_MONTHS': return '24 months';
				case 'ONE_YEAR': return '1 year';
				case 'OTHER': return record.follow_up_date;
				case 'SEE_AS_REQUIRED': return 'see as required';
				case 'DISCHARGE': return 'discharge';
				default: return 'unknown';
			}
		}
		$scope.transformNextVisit = function (record) {
			let result = [];
			if(record.next_visit_section){
				if(record.next_visit_section.biometry){
					result.push('Biometry')
				}
				if(record.next_visit_section.oct_macula){
					result.push('OCT macula')
				}
				if(record.next_visit_section.oct_glaucoma){
					result.push('OCT glaucoma')
				}
				if(record.next_visit_section.oct_anterior_segment){
					result.push('OCT anterior segment')
				}
				if(record.next_visit_section.visual_field){
					result.push('Visual field')
				} 

				if(record.next_visit_section.corneal_tomography){
					result.push('Corneal tomography')
				}

				if(record.next_visit_section.photos){
					result.push('Photos')
				}

				if(record.next_visit_section.yag_pc){
					result.push('YAG PC')
				}

				if(record.next_visit_section.yag_pi){
					result.push('YAG PI')
				}

				if(record.next_visit_section.alt){
					result.push('ALT')
				}
				if(record.next_visit_section.slt){
					result.push('SLT')
				}
				if(record.next_visit_section.dilate_od){
					result.push('DILATE OD')
				}
				if(record.next_visit_section.dilate_os){
					result.push('DILATE OS')
				}
				if(record.next_visit_section.dilate_ou){
					result.push('DILATE OU')
				}


				if(record.next_visit_section.other){
					result.push(record.next_visit_section.other)
				}
			}
			return result.length > 0 ? `[${result.join(', ')}]` : '';
		}

		function convertSecurityLevel(level, uploaded_by_user_name, uploaded_by_practice_name) {
			if (level === 'GLOBAL') {
				return 'Patient record';
			} else if (level === 'OWNER') {
				return uploaded_by_user_name;
			} else if (level === 'PRACTICE') {
				return uploaded_by_practice_name;
			}
			return '';
		}

		$scope.securityLevelText = function(record) {
			return convertSecurityLevel(record.security_level, '', Session.practice.name);
		}


	},
]);
