app.directive('footer', function () {
	return {
		restrict: 'E',
		templateUrl: 'app/src/js/directives/footer.html',
		replace: false,
		controller: 'footerController'
	};
});

app.controller('footerController', ['$scope', 'UserService', 'EmailAPI', 'Session', '$timeout', '$modal', '$state', '$location', 'CurrentPatient', function ($scope, UserService, EmailAPI, Session, $timeout, $modal, $state, $location, CurrentPatient) {

	$scope.EA = EmailAPI;
	$scope.SP = CurrentPatient;
	$scope.message = {};
	$scope.Session = Session;
	$scope.showPatientSearch = false;
	$scope.practices = $scope.Session.practiceOptions;
	$scope.$watch('Session', function (newValue, oldValue) {
		if ($scope.Session.practice) {
			$scope.showPatientSearch = true;
		} else {
			$scope.showPatientSearch = false;
		}
	}, true)

	$scope.showHelpModal = function () {
		$scope.helpModal = $modal({
			scope: $scope,
			templateUrl: 'app/src/views/templates/modal.help.tpl.html',
			show: true,
			title: 'HELP/FAQ',
		})

		$timeout(function () {
			angular.element('#q1-icon-wrap').triggerHandler('click');
		}, 700);

	}

	$scope.showVideo = function ($event) {
		const elements = document.getElementsByClassName("q-icon-wrap rotate");
		if (elements.length > 0) {
			if($event.currentTarget === elements[0]){
				angular.element($event.currentTarget).toggleClass('rotate');
				angular.element($event.currentTarget).parents('.q-wrap').find('.q-body-wrap').slideToggle();
				return;
			}
			angular.element(elements[0]).parents('.q-wrap').find('.q-body-wrap').slideToggle();
			angular.element(elements[0]).removeClass('rotate');
		}
		angular.element($event.currentTarget).toggleClass('rotate');
		angular.element($event.currentTarget).parents('.q-wrap').find('.q-body-wrap').slideToggle();
	}

	$scope.showGuideModal = function () {
		$scope.guideModal = $modal({
			scope: $scope,
			templateUrl: 'app/src/views/templates/modal.guide.tpl.html',
			show: true,
			title: 'CATTRAX USER GUIDE',
		});
		$timeout(function () {
			angular.element('#q1-icon-wrap').triggerHandler('click');
		}, 700);
	}

	$scope.showFeedBackModal = function () {
		$scope.message = {};
		$scope.feedBackModal = $modal({
			scope: $scope,
			templateUrl: 'app/src/views/templates/modal.feedback.tpl.html',
			show: true,
			title: 'FEEDBACK'
		})
	}
	$scope.sendFeedBackDetails = function (valid) {
		// send feedback form data here... then show success
		if (valid) {
			$scope.EA.isProcessing = true;
			$scope.EA.feedback($scope.message.feedback, $scope.message.subject).then(function (resp) {
				$scope.feedBackModal.hide();
				$scope.EA.isProcessing = false;
				$scope.successFeedBackModal = $modal({
					scope: $scope,
					templateUrl: 'app/src/views/templates/modal.success-feedback.tpl.html',
					show: true,
					title: 'FEEDBACK SUBMITTED'
				})
			})
		} else { console.log('invalid form'); }
	}

	$scope.showContactModal = function () {
		$scope.message = {};
		$scope.contactModal = $modal({
			scope: $scope,
			templateUrl: 'app/src/views/templates/modal.contact.tpl.html',
			show: true,
			title: 'CONTACT US'
		})
	}
	$scope.sendContactDetails = function () {
		$scope.EA.isProcessing = true;
		$scope.EA.contact($scope.message.contact).then(function (resp) {
			$scope.contactModal.hide();
			$scope.EA.isProcessing = false;
			$scope.successContactModal = $modal({
				scope: $scope,
				templateUrl: 'app/src/views/templates/modal.success-contact.tpl.html',
				show: true,
				title: 'MESSAGE SENT'
			})
		})
	}
	$scope.searchPatient = function () {
		$scope.SP.clearPatientResult();
		if ($state.current.url === '/search') {
			$state.reload();
		} else {
			$state.go("refer.search");
		}
	}
}])