app.component("preOpHistoryGlaucoma", {
	templateUrl: "app/src/js/components/preOpHistory/preOpHistoryGlaucoma/preOpHistoryGlaucoma.html",
	controller: "preOpHistoryGlaucomaController",
	bindings: {
		data: "=",
		eyeTitle: "@",
	},
});

app.controller("preOpHistoryGlaucomaController", [
	"$scope",
	"PreOp",
	function ($scope, PreOp) {
		this.$onInit = function () {
			this.PreOp = PreOp;
		};
		$scope.$watch("$ctrl.data.glaucoma", function (newValue, oldValue) {
			if (newValue === 'NO') {
				$scope.$ctrl.data.mean_difference = null;
				$scope.$ctrl.data.cup_to_disk_ratio = null;
				$scope.$ctrl.data.surgery = null;
				$scope.$ctrl.data.medication = {};
			}
		}, true);
	},
]);
