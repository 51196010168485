app.controller('referController', ['$scope', function($scope) {

	// Parent of all referral controllers. This controller currently containes no functionality.
	// This file should be 
	$scope.topDirections = ["left", "up"];
	$scope.bottomDirections = ["down", "right"];

	$scope.availableModes = ["md-fling", "md-scale"];
	$scope.selectedMode = "md-scale";

	$scope.availableDirections = ["up", "down", "left", "right"];
	$scope.selectedDirection = "left";


}])