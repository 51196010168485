app.component("preOpLifeQuality", {
	templateUrl: "app/src/js/components/preOpLifeQuality/preOpLifeQuality.html",
	controller: "preOpLifeQualityController",
	bindings: {
		data: "=",
	},
});

app.controller("preOpLifeQualityController", [
	"$scope",
	"LIFE_QUALITY_QUESTIONS",
	function ($scope, LIFE_QUALITY_QUESTIONS) {
		$scope.questions = LIFE_QUALITY_QUESTIONS;

		// console.log(`!!! ${JSON.stringify($scope.data)}`)
		$scope.$watch("$ctrl.data", function (newValue, oldValue) {
			if (!newValue) {
				$scope.$ctrl.data = { qol_A: null, qol_B: null };
				$scope.questions.forEach(item => { $scope.$ctrl.data[item.model] = null })
			}
		}, true);
	},
]);
