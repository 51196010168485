app.controller("examinationController", [
	"$scope",
	"$timeout",
	"CurrentReferral",
	"ReferralsAPI",
	"CurrentPatient",
	"$state",
	"$rootScope",
	"$modal",
	"LETTER",
	"LINE",
	"CYL",
	"VISUALACUITYLINE",
	"$window",
	"POPULATE_DATA_SUCCESS",
	"toast",
	"$location",
	"$stateParams",
	function (
		$scope,
		$timeout,
		CurrentReferral,
		ReferralsAPI,
		CurrentPatient,
		$state,
		$rootScope,
		$modal,
		LETTER,
		LINE,
		CYL,
		VISUALACUITYLINE,
		$window,
		POPULATE_DATA_SUCCESS,
		toast,
		$location,
		$stateParams,
	) {
		$scope.CP = CurrentPatient;
		$scope.RA = ReferralsAPI;
		$scope.CR = CurrentReferral;
		$scope.submitFormText = "SUBMIT FORM";
		$scope.isPseudo = false;
		$scope.letter = LETTER;
		$scope.line = LINE;
		$scope.cyl = CYL;
		$scope.displayContactTracingMessageInButtonSurround = false;
		$scope.showOU = false;
		$scope.showWarning = false;
		$scope.ou_best_show = false;
		$scope.hasDifference = false;
		$scope.isAcuityVisible = false;
		$scope.life_quality_added = "NO";
		$scope.isLinkHidden = true;
		$scope.show_quality_life = false;
		$scope.displaySecondaryInsurance = false;
		$scope.preset = false;

		$scope.$on('$viewContentLoaded', function(){
			if($stateParams.isFromReferral){
				$location.replace();
			}
		  });
		

		$scope.closeInsuranceModal = function (){
			$scope.displaySecondaryInsurance = false;
			if($scope.CP.patient.insuranceProvider){
				$scope.submit();
			}
		}

		$scope.$watch("life_quality_added", function (newValue, oldValue) {
			if (newValue === "NO") {
				$scope.formData.quality_of_life = null;
			}
		}, true);

		$scope.setDefaultFormData = function () {
			if ($scope.CR.referral && $scope.CR.referral.cpacScoreMeasurement) {
				$scope.formData = $scope.CR.referral.cpacScoreMeasurement;
				$scope.preset = true;
				if($scope.formData.leftEye && ($scope.formData.leftEye.bcvaLineNear ||$scope.formData.leftEye.bcvaLineMedium)){
					$scope.isAcuityVisible = true;
					$scope.isLinkHidden = false;
				}else if($scope.formData.rightEye && ($scope.formData.rightEye.bcvaLineNear ||$scope.formData.rightEye.bcvaLineMedium)){
					$scope.isAcuityVisible = true;
					$scope.isLinkHidden = false;
				}
				
				if ($scope.formData.leftEye && !$scope.formData.leftEye.intraocular_pressure_device) {
					$scope.formData.leftEye.intraocular_pressure_iop = null;
				}
				if ($scope.formData.rightEye && !$scope.formData.rightEye.intraocular_pressure_device) {
					$scope.formData.rightEye.intraocular_pressure_iop = null;
				}
				
				if($scope.formData.quality_of_life && Object.values($scope.formData.quality_of_life).some(x => x !== null)){
					$scope.life_quality_added = "YES";
				}
				$scope.formData.comments = $scope.CR.referral.outcome.additionalComments;
				toast.create(POPULATE_DATA_SUCCESS);
			} else {
				$scope.formData = {
					rightEye: {
						noImprovement: false,
						glare: false,
						myopicShift: false,
						bcvaLetter: 0,
						// auto_refraction: null,
					},
					leftEye: {
						noImprovement: false,
						glare: false,
						myopicShift: false,
						bcvaLetter: 0,
						// auto_refraction: null,
					},
					// bcvaLine_binocular: "NPL",
					bcvaLetter_binocular: 0,
					uncorrected_va_letter: 0,
				};
			}
		};

		$scope.setDefaultFormData();

		var morphNames = ["nuclearOpalescence", "nuclearColour", "cortical", "postSubcapsular", "pupilSize"];

		$scope.rightMorphError = false;
		$scope.leftMorphError = false;

		$scope.validateRightMorph = function () {
			if (
				$scope.formData.rightEye.lensStatus === "PHAKIC" &&
				($scope.formData.rightEye.cortical === undefined ||
					$scope.formData.rightEye.postSubcapsular === undefined ||
					$scope.formData.rightEye.nuclearOpalescence === undefined ||
					$scope.formData.rightEye.nuclearColour === undefined ||
					$scope.formData.rightEye.pupilSize === undefined)
			) {
				$scope.rightMorphError = true;
				return false;
			} else {
				$scope.rightMorphError = false;
			}
		};

		$scope.validateLeftMorph = function () {
			if (
				$scope.formData.leftEye.lensStatus === "PHAKIC" &&
				($scope.formData.leftEye.cortical === undefined ||
					$scope.formData.leftEye.postSubcapsular === undefined ||
					$scope.formData.leftEye.nuclearOpalescence === undefined ||
					$scope.formData.leftEye.nuclearColour === undefined ||
					$scope.formData.leftEye.pupilSize === undefined)
			) {
				$scope.leftMorphError = true;
				return false;
			} else {
				$scope.leftMorphError = false;
			}
		};

		$scope.lensError = false;
		$scope.comboErrorRightEye = false;

		$scope.clearLifeQuality = function () {
			$scope.formData.quality_of_life = null;
		}

		$scope.submit = function () {
			toast.destroy(POPULATE_DATA_SUCCESS);
			let valid = $scope.vision.$valid;
			// checkComboError();
			if ($scope.rightMorphError) {
				return false;
			}
			if ($scope.leftMorphError) {
				return false;
			}

			if (!valid) {
				return valid;
			}

			if ($scope.CP.patient.insuranceProvider === null) {
				$scope.displaySecondaryInsurance = true;
				return;
			}else{
				$scope.displaySecondaryInsurance = false;
			}

			if ($scope.showWarning === true) {
				$scope.RA.clinical($scope.formData).then(
					function (resp) {
						$scope.CR.setReferral(resp.data);

						if (resp.data.status === "DOCUMENT_VISION") {
							// Show refer another patient modal here.
							$scope.documentVisionModal = $modal({
								scope: $scope,
								templateUrl: "app/src/views/templates/modal.successful-document-vision.tpl.html",
								title: "Success",
								keyboard: false,
								backdrop: "static",
							});
						} else if (resp.data.status === "CONTACT_TRACING") {
							// Show refer another patient modal here.
							$scope.documentVisionModal = $modal({
								scope: $scope,
								templateUrl: "app/src/views/templates/modal.successful-contact-trace.tpl.html",
								title: "Success",
								keyboard: false,
								backdrop: "static",
							});
						} else {
							$state.go("refer.complete");
						}
					}
				).then(function () {
				}).catch(function(err) {
					console.log(err);
					$scope.serverErrorModal = $modal({
						scope: $scope,
						templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
						show: true,
						title: "CatTrax has encountered an error",
					});
				});
			}
			$scope.showWarning = true;
		};

		$scope.hideSecondaryInsuranceComponent = function () {
			$scope.displaySecondaryInsurance = false;
		};

		$scope.startAgain = function () {
			$scope.documentVisionModal.hide();
			$state.go("refer.search");
		};

		$scope.backToDashboard = function() {
			$scope.documentVisionModal && $scope.documentVisionModal.hide();
			$window.history.back();
		}

		$scope.applyToParent = function (params, eye) {
			$scope.morphModal.hide();
			for (var prop in params) {
				$scope.formData[eye][prop] = params[prop];
			}
			$scope.validateRightMorph();
			$scope.validateLeftMorph();
		};

		$scope.resetMorph = function (eye) {
			// Called from within the template
			angular.forEach(morphNames, function (name) {
				delete $scope.formData[eye][name];
			});

			if (eye === "rightEye") {
				$scope.validateRightMorph();
			}

			if (eye === "leftEye") {
				$scope.validateLeftMorph();
			}
		};

		$scope.setIsPseudo = function () {
			$scope.submitFormText = "DOCUMENT VISION/REFRACTION";
			$scope.isPseudo = true;
			$scope.displayContactTracingMessageInButtonSurround = false;
		};

		$scope.setIsContactTracing = function () {
			$scope.submitFormText = "REGISTER CONTACT TRACE";
			$scope.isPseudo = true;
			$scope.displayContactTracingMessageInButtonSurround = true;
		};

		$scope.setIsDefault = function () {
			$scope.submitFormText = "SUBMIT FORM";
			$scope.displayContactTracingMessageInButtonSurround = false;
		};

		$scope.$watch(
			"formData.rightEye.ucvaLine",
			function (newValue, oldValue) {
				if (newValue && $scope.formData.leftEye.ucvaLine) {
					$scope.showOU = true;
					setUncorrectedMin();
				} else if (newValue == null) {
					$scope.showOU = false;
				}
			},
			true
		);
		$scope.$watch(
			"formData.leftEye.ucvaLine",
			function (newValue, oldValue) {
				if (newValue && $scope.formData.rightEye.ucvaLine) {
					$scope.showOU = true;
					setUncorrectedMin();
				} else if (newValue == null) {
					$scope.showOU = false;
				}
			},
			true
		);

		$scope.$watch(
			"formData.rightEye.ucvaLine",
			function (newValue, oldValue) {
				if (newValue) {
					$scope.isLinkHidden = false;
				}else if(!$scope.formData.leftEye.ucvaLine){
					$scope.isLinkHidden = true;
					$scope.isAcuityVisible = false;
				}
			},
			true
		);
		$scope.$watch(
			"formData.leftEye.ucvaLine",
			function (newValue, oldValue) {
				if (newValue) {
					$scope.isLinkHidden = false;
				}else if(!$scope.formData.rightEye.ucvaLine){
					$scope.isLinkHidden = true;
					$scope.isAcuityVisible = false;
				}
			},
			true
		);

		function setUncorrectedMin() {
			const right = LINE.find((t) => t.value === $scope.formData.rightEye.ucvaLine);
			const left = LINE.find((t) => t.value === $scope.formData.leftEye.ucvaLine);
			const min = Math.min(parseFloat(right.name), parseFloat(left.name));
			if (min) {
				$scope.formData.uncorrected_va_line = LINE.find((t) => t.name === "" + min).value;
			}
		}

		$scope.$watch(
			"formData.rightEye.bcvaLetter",
			function (newValue, oldValue) {
				if (newValue !== null && $scope.formData.leftEye.bcvaLetter !== null) {
					setBcvaLetter();
				}
			},
			true
		);
		$scope.$watch(
			"formData.leftEye.bcvaLetter",
			function (newValue, oldValue) {
				if (newValue !== null && $scope.formData.rightEye.bcvaLetter !== null) {
					setBcvaLetter();
				}
			},
			true
		);

		function setBcvaLetter() {
			const right_bcvaLine = LINE.find((t) => t.value === $scope.formData.rightEye.bcvaLine);
			const left_bcvaLine = LINE.find((t) => t.value === $scope.formData.leftEye.bcvaLine);
			if(!right_bcvaLine || !left_bcvaLine){
				return;
			}
			
			if(right_bcvaLine === left_bcvaLine){
				const right_bcvaLetter = LETTER.find((t) => t.value === $scope.formData.rightEye.bcvaLetter);
				const left_bcvaLetter = LETTER.find((t) => t.value === $scope.formData.leftEye.bcvaLetter);
				const max = Math.max(parseFloat(right_bcvaLetter.value), parseFloat(left_bcvaLetter.value));
				$scope.formData.bcvaLetter_binocular = LETTER.find((t) => t.value === max).value;
			}else if(parseFloat(right_bcvaLine.name) > parseFloat(left_bcvaLine.name)) {
				$scope.formData.bcvaLetter_binocular = $scope.formData.leftEye.bcvaLetter;
			} else if (parseFloat(right_bcvaLine.name) < parseFloat(left_bcvaLine.name)){
				$scope.formData.bcvaLetter_binocular = $scope.formData.rightEye.bcvaLetter;
			}
		}


		$scope.$watch(
			"formData.rightEye.bcvaLine",
			function (newValue, oldValue) {
				if (newValue && $scope.formData.leftEye.bcvaLine) {
					$scope.ou_best_show = true;
					setBestMin();
				} else if (newValue == null) {
					$scope.ou_best_show = false;
				}
			},
			true
		);
		$scope.$watch(
			"formData.leftEye.bcvaLine",
			function (newValue, oldValue) {
				if (newValue && $scope.formData.rightEye.bcvaLine) {
					$scope.ou_best_show = true;
					setBestMin();
				} else if (newValue == null) {
					$scope.ou_best_show = false;
				}
			},
			true
		);

		function transformBcvaLine(bcvaLine) {
			const obj = {... bcvaLine};
			if(obj.name === 'CF'){
				obj.name = '1000';
				return obj;
			}else if(obj.name === 'HM'){
				obj.name = '1001';
				return obj;
			}else if(obj.name === 'POL'){
				obj.name = '1002';
				return obj;
			}else if(obj.name === 'NPL'){
				obj.name = '1003';
				return obj;
			}
			return bcvaLine;
		}

		function setBestMin() {
			const right = transformBcvaLine(LINE.find((t) => t.value === $scope.formData.rightEye.bcvaLine));
			const left = transformBcvaLine(LINE.find((t) => t.value === $scope.formData.leftEye.bcvaLine));
			const min = Math.min(parseFloat(right.name), parseFloat(left.name));

			if (min) {
				let obj = LINE.find((t) => t.name === "" + min);
				let value = undefined;
				if(obj){
					value = obj.value;
				}else{
                     if(min === 1000){
						value = "CF_30_CM";
					 }else if(min === 1001){
						value = "HM_30_CM";
					 }else if(min === 1002){
						value = "PL";
					 }else if(min === 1003){
						value = "NPL";
					 }
				}
				$scope.formData.bcvaLine_binocular = value;
			}
			if(min === parseFloat(right.name)){
				$scope.formData.bcvaLetter_binocular = $scope.formData.rightEye.bcvaLetter;
			}else {
				$scope.formData.bcvaLetter_binocular = $scope.formData.leftEye.bcvaLetter;
			}
		}


		function checkHasDifference() {
			if (
				$scope.formData.rightEye.sphere == null ||
				$scope.formData.rightEye.cyl == null ||
				$scope.formData.leftEye.sphere == null ||
				$scope.formData.leftEye.cyl == null
			) {
				return false;
			}
			const se_right = $scope.formData.rightEye.sphere + 0.5 * $scope.formData.rightEye.cyl;
			const se_left = $scope.formData.leftEye.sphere + 0.5 * $scope.formData.leftEye.cyl;
			return !(Math.abs(se_right - se_left) < 2);
		}

		$scope.$watch(
			"formData.rightEye.sphere",
			function (newValue, oldValue) {
				if (newValue !== null && $scope.formData.rightEye.cyl !== undefined) {
					$scope.hasDifference = checkHasDifference();
				} else if (newValue == null) {
					$scope.hasDifference = false;
				}
			},
			true
		);
		$scope.$watch(
			"formData.rightEye.cyl",
			function (newValue, oldValue) {
				if (newValue !== null && $scope.formData.rightEye.sphere !== undefined) {
					$scope.hasDifference = checkHasDifference();
				} else if (newValue == null) {
					$scope.hasDifference = false;
				}
			},
			true
		);

		$scope.$watch(
			"formData.leftEye.sphere",
			function (newValue, oldValue) {
				if (newValue !== null && $scope.formData.leftEye.cyl !== undefined) {
					$scope.hasDifference = checkHasDifference();
				} else if (newValue == null) {
					$scope.hasDifference = false;
				}
			},
			true
		);
		$scope.$watch(
			"formData.leftEye.cyl",
			function (newValue, oldValue) {
				if (newValue !== null && $scope.formData.leftEye.sphere !== undefined) {
					$scope.hasDifference = checkHasDifference();
				} else if (newValue == null) {
					$scope.hasDifference = false;
				}
			},
			true
		);

		$scope.$watch(
			"formData.rightEye.lensStatus",
			function (newValue, oldValue) {
				if(newValue){
					$scope.show_quality_life = true;
				}else{
					$scope.show_quality_life = false;
				}
				if (newValue !== "PHAKIC") {
					// reset dependent values here
					$scope.formData.rightEye.visualPotential = null;
					$scope.formData.rightEye.myopicShift = null;
					$scope.formData.rightEye.glare = null;
				}
				if (newValue === "") {
					$scope.setIsDefault();
					return;
				} else if (newValue === "PSEUDO_PHAKIC" && $scope.formData.leftEye.lensStatus === "PSEUDO_PHAKIC") {
					$scope.setIsPseudo();
					return;
				} else if (newValue === "PSEUDO_PHAKIC_WITH_PCO" && $scope.formData.leftEye.lensStatus === "PSEUDO_PHAKIC") {
					$scope.setIsPseudo();
					return;
				} else if (newValue === "PSEUDO_PHAKIC" && $scope.formData.leftEye.lensStatus === "PSEUDO_PHAKIC_WITH_PCO") {
					$scope.setIsPseudo();
				} else if (newValue === "PSEUDO_PHAKIC_WITH_PCO" && $scope.formData.leftEye.lensStatus === "PSEUDO_PHAKIC_WITH_PCO") {
					$scope.setIsPseudo();
				} else if (newValue === "CONTACT_TRACING") {
					// If one lensStatus is set to CONTACT_TRACING, both must be CONTACT_TRACING.
					$scope.formData.leftEye.lensStatus = "CONTACT_TRACING";
					$scope.setIsContactTracing();
					angular.forEach(morphNames, function (name) {
						delete $scope.formData["leftEye"][name];
					});
					$scope.leftMorphError = false;
				} else if (oldValue === "CONTACT_TRACING") {
					// If one selection moves from CONTACT_TRACING the other must be null
					$scope.formData.leftEye.lensStatus = "";
				} else {
					$scope.submitFormText = "SUBMIT FORM";
					$scope.isPseudo = false;
				}
			},
			true
		);

		$scope.$watch(
			"formData.leftEye.lensStatus",
			function (newValue, oldValue) {
				if(newValue){
					$scope.show_quality_life = true;
				}else{
					$scope.show_quality_life = false;
				}
				if (newValue !== "PHAKIC") {
					// reset dependent values here
					$scope.formData.leftEye.visualPotential = null;
					$scope.formData.leftEye.myopicShift = null;
					$scope.formData.leftEye.glare = null;
				}

				if (newValue === "") {
					$scope.setIsDefault();
					return;
				} else if (newValue === "PSEUDO_PHAKIC" && $scope.formData.rightEye.lensStatus === "PSEUDO_PHAKIC") {
					$scope.setIsPseudo();
					return;
				} else if (newValue === "PSEUDO_PHAKIC_WITH_PCO" && $scope.formData.rightEye.lensStatus === "PSEUDO_PHAKIC") {
					$scope.setIsPseudo();
					return;
				} else if (newValue === "PSEUDO_PHAKIC" && $scope.formData.rightEye.lensStatus === "PSEUDO_PHAKIC_WITH_PCO") {
					$scope.setIsPseudo();
					return;
				} else if (newValue === "PSEUDO_PHAKIC_WITH_PCO" && $scope.formData.rightEye.lensStatus === "PSEUDO_PHAKIC_WITH_PCO") {
					$scope.setIsPseudo();
					return;
				} 
				//TODO  DOUBLE CHECK
				else if (newValue === "CONTACT_TRACING") {
					// If one lensStatus is set to CONTACT_TRACING, both must be CONTACT_TRACING.
					$scope.formData.rightEye.lensStatus = "CONTACT_TRACING";
					$scope.setIsContactTracing();
					angular.forEach(morphNames, function (name) {
						delete $scope.formData["rightEye"][name];
					});
					$scope.rightMorphError = false;
				} 
				else if (oldValue === "CONTACT_TRACING") {
					// If one selection moves from CONTACT_TRACING the other must be null
					$scope.formData.rightEye.lensStatus = "";
				} else {
					$scope.submitFormText = "SUBMIT FORM";
					$scope.isPseudo = false;
				}
			},
			true
		);

		$scope.showMorphologyOD = function () {
			$scope.morphModal = $modal({
				scope: $scope,
				templateUrl: "app/src/views/templates/modal.morphology-od.tpl.html",
				title: "CATARACT MORPHOLOGY OD",
				controller: "morphController",
			});
		};

		$scope.showMorphologyOS = function () {
			$scope.morphModal = $modal({
				scope: $scope,
				templateUrl: "app/src/views/templates/modal.morphology-os.tpl.html",
				title: "CATARACT MORPHOLOGY OS",
				controller: "morphController",
			});
		};

		$scope.forceValidation = function () {
			angular.forEach($scope.exam.$error.required, function (field) {
				field.$setDirty();
			});
		};
	},
]);

app.controller("morphController", function ($scope) {
	$scope.ODData = {};
	$scope.OSData = {};

	// Bind parent formData to current scope if the values have been set
	if ($scope.formData.rightEye.cortical != undefined) {
		$scope.ODData.cortical = $scope.formData.rightEye.cortical;
	}
	if ($scope.formData.rightEye.nuclearOpalescence != undefined) {
		$scope.ODData.nuclearOpalescence = $scope.formData.rightEye.nuclearOpalescence;
	}
	if ($scope.formData.rightEye.nuclearColour != undefined) {
		$scope.ODData.nuclearColour = $scope.formData.rightEye.nuclearColour;
	}
	if ($scope.formData.rightEye.postSubcapsular != undefined) {
		$scope.ODData.postSubcapsular = $scope.formData.rightEye.postSubcapsular;
	}
	if ($scope.formData.rightEye.pupilSize != undefined) {
		$scope.ODData.pupilSize = $scope.formData.rightEye.pupilSize;
	}

	if ($scope.formData.leftEye.cortical != undefined) {
		$scope.OSData.cortical = $scope.formData.leftEye.cortical;
	}
	if ($scope.formData.leftEye.nuclearOpalescence != undefined) {
		$scope.OSData.nuclearOpalescence = $scope.formData.leftEye.nuclearOpalescence;
	}
	if ($scope.formData.leftEye.nuclearColour != undefined) {
		$scope.OSData.nuclearColour = $scope.formData.leftEye.nuclearColour;
	}
	if ($scope.formData.leftEye.postSubcapsular != undefined) {
		$scope.OSData.postSubcapsular = $scope.formData.leftEye.postSubcapsular;
	}
	if ($scope.formData.leftEye.pupilSize != undefined) {
		$scope.OSData.pupilSize = $scope.formData.leftEye.pupilSize;
	}
});
