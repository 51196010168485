app.controller("searchController", [
	"$scope",
	"CurrentReferral",
	"ReferralsAPI",
	"ContactAPI",
	"NHI",
	"filterFilter",
	"$timeout",
	"Session",
	"$rootScope",
	"PatientAPI",
	"$modal",
	"CurrentPatient",
	"MEDICAL_INSURANCE",
	function (
		$scope,
		CurrentReferral,
		ReferralsAPI,
		ContactAPI,
		NHI,
		filterFilter,
		$timeout,
		Session,
		$rootScope,
		PatientAPI,
		$modal,
		CurrentPatient,
		MEDICAL_INSURANCE
	) {
		$scope.CurrentPatient = CurrentPatient;
		$scope.reloadSearchDashboard = false;
		$scope.showSearchDashboard = true;
		// $scope.$on('$locationChangeStart', function(event, next, current){
		// 	console.log(`search controller 33`)
		// 	if(!$scope.showSearchDashboard){
		// 		console.log(`search controller 35`)
		// 		event.preventDefault();
		// 		$scope.CurrentPatient.clearPatient();
		// 		$scope.showSearchDashboard = true;
		// 		return;
		// 	}
		// });
		$scope.$watch(
			"CurrentPatient",
			function (newValue) {
				if (newValue && newValue.patient && newValue.patient.id) {
					if(newValue.patientIsMine){
						$scope.showSearchDashboard = false;
					}
				} else if (newValue && newValue.isPatientNew) {
					$scope.showSearchDashboard = false;
				}
				else {
					$scope.showSearchDashboard = true;
				}
				if(newValue && !newValue.patient.id && newValue.patients.length > 0){
					$scope.reloadSearchDashboard = true;
				}else{
					$scope.reloadSearchDashboard = false;
				}
			},
			true
		);
	},
]);
