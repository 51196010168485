app.service("NHISearchPatientsAPI", [
	"$http",
	"ENV",
	"Session",
	function ($http, ENV, Session) {
		var service = {
			isProcessing: false,

			searchAllNHIPatients: function (nhi) {
				return $http({
					method: "GET",
					url: ENV.API + `/api/nhi/${nhi}`,
					headers: {
						Authorization: "Bearer " + Session.token,
						"Content-Type": "application/json",
						"X-PRACTICE-ID": `${Session.practice.id}`,
					},
				});
			},
			queryPatients: function (lastName, DOB) {
				return $http({
					method: "GET",
					url: ENV.API + `/api/nhi?lastName=${lastName}&dob=${DOB}`,
					headers: {
						Authorization: "Bearer " + Session.token,
						"Content-Type": "application/json",
						"X-PRACTICE-ID": `${Session.practice.id}`,
					},
				});
			},
			searchAllNHIPatientsV2: function (nhi) {
				return $http({
					method: "GET",
					url: ENV.API + `/api/nhi/${nhi}`,
					headers: {
						Authorization: "Bearer " + Session.token,
						"Content-Type": "application/json",
						"X-PRACTICE-ID": `${Session.practice.id}`,
					},
				});
			},
			queryPatientsV2: function(lastName, dob,firstName='', middleName='') {
				let option = '';
				if(firstName){
					option = option +  `&firstName=${firstName}`
				}
				if(middleName){
					option = option +  `&middleName=${middleName}`
				}
				return $http({
					method: 'GET',
					url: ENV.API + `/api/nhi?dob=${dob}&lastName=${lastName}${option}`,
					headers: {
						'Authorization': 'Bearer ' + Session.token,
						"Content-Type": "application/json",
						"X-PRACTICE-ID": `${Session.practice.id}`,
					}
				})
			},
			queryPractitioners: function (fullName) {
				return $http({
					method: 'GET',
					url: ENV.API + `/api/hpi/practitioner?searchTerm=${fullName}`,
					headers: {
						'Authorization': 'Bearer ' + Session.token,
						"X-PRACTICE-ID": `${Session.practice.id}`,
					}
				})
			},
			queryOrganisations: function (name) {
				return $http({
					method: 'GET',
					url: ENV.API + `/api/hpi/location?searchTerm=${name}`,
					headers: {
						'Authorization': 'Bearer ' + Session.token,
						"X-PRACTICE-ID": `${Session.practice.id}`,
					}
				})
			},
			transformGenderProp: function (gender) {
				if(gender === 'MALE' || gender === 'FEMALE'){
					return gender.toUpperCase();
				}
				return 'OTHER';
			}
		};

		return service;
	},
]);
