app.component("preOpMyPatients", {
	templateUrl: "app/src/js/components/preOp/preOpMyPatients/preOpMyPatients.html",
	controller: "preOpMyPatientsController",
});

app.controller("preOpMyPatientsController", [
	"$scope",
	"$modal",
	"$timeout",
	"Session",
	"CurrentPatient",
	"PatientAPI",
	"$state",
	function ($scope, $modal, $timeout,  Session, CurrentPatient, PatientAPI,$state) {
		$scope.CP = CurrentPatient;
		$scope.userIsPracticeAdmin = false;
		$scope.showWorkupButton = false;
		$scope.showOperationNoteButton = false;
		$scope.PA = PatientAPI;
		$scope.favoritePatientList = [];
		$scope.patientBackup = null;

		if(Session.user.features && Session.user.features.favoritePatientsFeature){
			$scope.favoritePatientList = Session.user.features.favoritePatientsFeature;
		}

		$scope.cancelTransfer = function () {
			$scope.CP.setPatient($scope.patientBackup);
		}

		$scope.showTransferModal = function () {
			$scope.patientDetailsModal.hide();
			$scope.previousPracticeName = $scope.CP.patient.practice.name;
			$scope.transferModal = $modal({
				scope: $scope,
				templateUrl: "app/src/views/templates/modal.transfer.tpl.html",
				show: true,
				title: "TRANSFER PATIENT",
			});
		};

		$scope.transferPatient = function () {
			$scope.PA.isProcessing = true;
			$scope.PA.transfer($scope.CP.patient.id, Session.userId, Session.practice.id)
				.then(function (resp) {
					$scope.CP.setPatient(resp.data);
					$state.go("refer.search");
				})
				.then(function () {
					$scope.PA.isProcessing = false;
					$scope.transferModal.hide();
					$scope.successModal = $modal({
						scope: $scope,
						templateUrl: "app/src/views/templates/modal.success.tpl.html",
						show: true,
						title: "SUCCESS",
						onHide: function () {},
					});
				});
		};
	

		$scope.selectPatient = function (patient) {
			if($scope.CP.patientBackup){
				$scope.patientBackup = angular.copy($scope.CP.patientBackup);
			}
			$scope.CP.clearPatient();
			$timeout(function () {
				$scope.CP.setPatient(patient);
				if (!$scope.CP.patientIsMine) {
					//need to transfer
					// $scope.$hide();
					$scope.patientDetailsModal = $modal({
						scope: $scope,
						templateUrl: "app/src/views/templates/modal.patient-details.tpl.html",
						show: true,
						title: "PATIENT DETAILS",
					});
				}
			});

			
		};

		$scope.switchPatient = function (patientId) {
			$state.go("refer.search");
			if($scope.CP.patient.id === patientId){
				return;
			}
			$scope.PA.isProcessing = true;
			$scope.PA.getPatient(patientId).then(function (resp){
				$scope.selectPatient(resp.data);
			}, function (error) {
				$scope.serverErrorModal = $modal({
					scope: $scope,
					templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
					show: true,
					title: "CatTrax has encountered an error",
				});
			}).then(function (){
				$scope.PA.isProcessing = false;
			});
		}

		$scope.removeFavoritePatient = function (patientId) {
			$scope.PA.removeFavourite(patientId)
			.then(
				function (resp) {
					if (resp.status === 202) {
						Session.user.features.favoritePatientsFeature =
							Session.user.features.favoritePatientsFeature.filter(t => t.patientId !== patientId);
					}
				},
				function (err) {
					console.log(err);
				}
			)
			.then(function () {
				$scope.favoritePatientList = Session.user.features.favoritePatientsFeature;
			});
		}
	},
]);
