app.service("DiagnosisAPI", [
	"$http",
	"ENV",
	"Session",
	function ($http, ENV, Session) {
		var service = {
			isProcessing: false,

			searchDiagnosis: function (term, isOcular, isNonCataract) {
				return $http({
					method: "GET",
					url: ENV.API + (isNonCataract ? `/api/diagnosis?term=${term}&isNonCataract=true` : `/api/diagnosis?term=${term}&isOcular=${isOcular}`),
					headers: {
						Authorization: "Bearer " + Session.token,
						"Content-Type": "application/json",
						"X-PRACTICE-ID": `${Session.practice.id}`,
					},
				});
			},
            saveDiagnosis: function (patientId, diagnosisArray) {
                return $http({
					method: "POST",
					url: ENV.API + `/api/diagnosis/patient/${patientId}`,
					headers: {
						Authorization: "Bearer " + Session.token,
						"Content-Type": "application/json",
						"X-PRACTICE-ID": `${Session.practice.id}`,
					},
                    data: diagnosisArray
				}); 
            },
            inactivateDiagnosis: function (patientId, diagnosisId) {
                return $http({
					method: "PATCH",
					url: ENV.API + `/api/diagnosis/patient/${patientId}/diagnosis/${diagnosisId}`,
					headers: {
						Authorization: "Bearer " + Session.token,
						"Content-Type": "application/json",
						"X-PRACTICE-ID": `${Session.practice.id}`,
					},
                    data: {
                        status: "INACTIVE",
                        inactivation_reason: null
                    }
				}); 

            },
            getDiagnosis: function(patientId){
                return $http({
					method: "GET",
					url: ENV.API + `/api/diagnosis/patient/${patientId}`,
					headers: {
						Authorization: "Bearer " + Session.token,
						"Content-Type": "application/json",
						"X-PRACTICE-ID": `${Session.practice.id}`,
					},
				});

            }
		};

		return service;
	},
]);
