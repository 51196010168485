app.component("userDetails", {
	templateUrl: "app/src/js/components/userDetails/userDetails.html",
	controller: "userDetailsController",
});

app.controller("userDetailsController", [
	"$scope",
	"Session",
	"PracticeService",
    "$modal",
    "AuthService",
    "CurrentPatient",
    "$state",
    "AppointmentTimerService",
	function ($scope, Session, PracticeService,$modal,AuthService,CurrentPatient,$state,AppointmentTimerService) {
		this.$onInit = function () {
			$scope.Session = Session;
            $scope.selectedPractice = null;
            $scope.practiceDetail = null;
            $scope.AS = AuthService;
            $scope.ATS = AppointmentTimerService;
            $scope.CP = CurrentPatient;
            const user = $scope.Session.user;
            $scope.fullName = `${user.title} ${user.firstName} ${user.lastName}`;
            $scope.email = user.email;
            $scope.userRole = user.roles[0];
            $scope.userFeatures = readUserFeatures(user.features);
            $scope.practices = $scope.Session.practiceOptions;
			$scope.PracticeService = PracticeService;
			$scope.smsAuditRange = null;
            $scope.requestPwResetForm = {};
		};

        $scope.changeEmail = function () {
            $scope.popoverOpened1=false;
            $scope.requestSent = false;
            $scope.verifySuccess = false;
            $scope.changeEmailModal = $modal({
                scope: $scope,
                templateUrl: "app/src/views/templates/modal.change-email.tpl.html",
                show: true,
                backdrop: "static",
                keyboard: false,
                title: "Current email: "+$scope.Session.user.userName,
            });
        };

        $scope.closeChangeEmailModal = function () {
            if ($scope.changeEmailModal) {
                $scope.changeEmailModal.hide();
            }
            if ($scope.verifySuccess) {
                logout();
            }
        }

        $scope.verifyEmail = function (email) {
            $scope.AS.isProcessing = true;
            $scope.AS.updateEmail(email).then(function (resp) {
                $scope.requestSent = true;
            }, function (error) {
                console.log(error);
                $scope.serverErrorModal = $modal({
                    scope: $scope,
                    templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
                    show: true,
                    title: "CatTrax has encountered an error",
                });
            }).then(function () {
                $scope.AS.isProcessing = false;
            })
        }

        $scope.verifyCode = function (code) {
            $scope.AS.isProcessing = true;
            $scope.AS.verifyEmail(code).then(function (resp) {
                $scope.requestSent = false;
                $scope.verifySuccess = true;
            }, function (error) {
                console.log(error);
                $scope.serverErrorModal = $modal({
                    scope: $scope,
                    templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
                    show: true,
                    title: "CatTrax has encountered an error",
                });
            }).then(function () {
                $scope.AS.isProcessing = false;
            })
        }

        function delay(milliseconds){
            return new Promise(resolve => {
                setTimeout(resolve, milliseconds);
            });
        }

        async function logout() {
            if ($scope.changeEmailModal) {
                $scope.changeEmailModal.hide();
            }
            $scope.CP.clearPatient();
            await delay(500);
            $scope.ATS.stopTimer();
            $scope.AS.logout();
            Session.destroy();
            $state.go('login');
        }

		$scope.options = {
            chart: {
                type: 'historicalBarChart',
                height: 450,
                margin : {
                    top: 20,
                    right: 20,
                    bottom: 65,
                    left: 50
                },
                x: function(d){return d[0];},
                y: function(d){return d[1];},
                showValues: true,
                valueFormat: function(d){
                    return d;
                },
                xAxis: {
                    axisLabel: 'Date',
                    tickFormat: function(d) {
                        return d3.time.format('%x')(new Date(d))
                    },
                    rotateLabels: 30,
                    showMaxMin: false
                },
                yAxis: {
                    axisLabel: 'SMS Count',
                    axisLabelDistance: -10,
                    tickFormat: function(d){
                        return d;
                    }
                },
                tooltip: {
                    keyFormatter: function(d) {
                        return d3.time.format('%x')(new Date(d));
                    }
                },
                zoom: {
                    enabled: true,
                    scaleExtent: [1, 10],
                    useFixedDomain: false,
                    useNiceScale: false,
                    horizontalOff: false,
                    verticalOff: true,
                    unzoomEventType: 'dblclick.zoom'
                }
            }
        };

        $scope.practiceOnChange = function(){
            $scope.practiceDetail = $scope.practices.find(t=>t.id === $scope.selectedPractice);
			$scope.fetchSMSAudit();
        }

        function mappingUserFeature(key) {
			if (key === "southernCrossPilotFeature") {
				return "Southern cross pilot enabled";
			} else if (key === "premiumLensesFeature") {
				return "Premium lens pack enabled";
			} else if (key === "advancedPatientSearchFeature") {
				return "Advanced patient search enabled";
			} else if (key === "clinicalLetterFeature") {
				return "Clinical letter enabled";
			}
			return key;
		}

        function mappingPracticeFeature(key) {
			if (key === "nhiFeature") {
				return "NHI enabled";
			} else if (key === "fhirNhiFeature") {
				return "FHIR Nhi enabled";
			} else if (key === "billingFeature") {
				return "Billing enabled";
			} else if (key === "appointmentEmailReminderFeature") {
				return "Appointment email reminder enabled";
			} else if (key === "appointmentSMSReminderFeature") {
				return "Appointment SMS reminder enabled";
			} else if (key === "sendTasksWithinPracticeFeature") {
				return "Send tasks within practice enabled";
			} else if (key === "sendTasksOutsidePracticeFeature") {
				return "Send tasks outside practice enabled";
			} else if (key === "referralDataTrackingFeature") {
				return "Referral data tracking enabled";
			} else if (key === "calendarFeature") {
				return "Calendar enabled";
			} else if (key === "billingInvoiceGSTInclusiveFeature") {
				return "Billing invoice GST inclusive enabled";
			} else if (key === "progressNoteSettings") {
				return "Progress note settings enabled";
			}
			return key;
		}

        function readUserFeatures(userFeatures) {
			const result = [];
			Object.keys(userFeatures).forEach((t) => {
				if (userFeatures[t] === true) {
					result.push(mappingUserFeature(t));
				}
			});
			return result.join(", ");
		}

        $scope.readPracticeFeatures = function (practiceFeatures) {
            const result = [];
			Object.keys(practiceFeatures).forEach((t) => {
				if (practiceFeatures[t] !== null) {
					result.push(mappingPracticeFeature(t));
				}
			});
			return result.join(", ");
        }

		$scope.fetchSMSAudit = function () {
			if (!$scope.selectedPractice) {
				return;
			}
			const duration = $scope.smsAuditRange.split("-");
			const start_date_UTC = moment(duration[0]).utc().toISOString();
			const end_date_UTC = moment(duration[1]).utc().toISOString();
			$scope.PracticeService.isProcessing = true;

			$scope.PracticeService.querySMSAudit($scope.selectedPractice, start_date_UTC, end_date_UTC)
				.then(
					function (response) {
						$scope.data = [
							{
								key: "Quantity",
								bar: true,
								values: response.data.chart_data[0].chart_data_points.map((t) => {
									const fields = t.x.split("/");
									// fields[0]: day; fields[1]:month; fields[2]: YY
									return [moment(`${fields[1]}/${fields[0]}/${fields[2]}`).utc().valueOf(), t.y];
								}),
							},
						];
						if ($scope.data.length > 0 && $scope.data[0].values.length > 0) {
							$scope.options.chart = {
								...$scope.options.chart,
								xDomain: [
									$scope.data[0].values[0][0] - 10000,
									$scope.data[0].values[$scope.data[0].values.length - 1][0] + 10000,
								],
							};
						}
					},
					function (error) {}
				)
				.then(() => ($scope.PracticeService.isProcessing = false));
		};
	},
]);
