app.component("medicationSection", {
	templateUrl: "app/src/js/components/injectionNote/medicationSection/medicationSection.html",
	controller: "medicationSectionController",
	bindings: {
		data: "<",
		eyeTitle: "@",
	},
});

app.controller("medicationSectionController", [
	"$scope",
	"INJECTIONPROCEDURE",
	"INJECTIONLOCATION",
	"DISTANCELIMBUS",
	"INDICATIONTYPE",
	"INCISIONSIZE",
	"INTRAVITREAL_MEDICATION",
    "INTRAVITREAL_IMPLANT_MEDICATION",
    "SUBTENONS_INJECTION_MEDICATION",
    "ORBITAL_FLOOR_MEDICATION",
    "SUBCONJUNCTIVAL_INJECTION_MEDICATION",
    "INTRAVITREAL_TAP_INJECT_MEDICATION",
	function ($scope, 
		INJECTIONPROCEDURE, 
		INJECTIONLOCATION, 
		DISTANCELIMBUS, 
		INDICATIONTYPE, 
		INCISIONSIZE,
        INTRAVITREAL_MEDICATION,
        INTRAVITREAL_IMPLANT_MEDICATION,
        SUBTENONS_INJECTION_MEDICATION,
        ORBITAL_FLOOR_MEDICATION,
        SUBCONJUNCTIVAL_INJECTION_MEDICATION,
        INTRAVITREAL_TAP_INJECT_MEDICATION
		) {
		$scope.injectionProcedure = INJECTIONPROCEDURE;
		// $scope.anaesthetic = INJECTIONANAESTHETIC;
		// $scope.preparation = INJECTIONPREPARATION;
		// $scope.ocularOptions =OCULARTYPE;
		$scope.injection_location = INJECTIONLOCATION;
		$scope.distance_from_limbus = DISTANCELIMBUS;
		$scope.indicationType = INDICATIONTYPE;
		$scope.incisionSize = INCISIONSIZE.map((t) => t);
		$scope.warn = false;
		$scope.otherOvdArray = [];
		$scope.warn_ovd = false;
        $scope.form = {};
        $scope.medication_type = [];
        $scope.dose_type = [];
        $scope.distance_not_required = false;
        $scope.location_not_required = false;

        $scope.removeMedication = function (index) {
			$scope.$ctrl.data.injection_details.splice(index, 1);
		};

        $scope.$watchGroup(['form.procedure_type', 'form.medication', 'form.dose.selected','form.injection_location','form.distance_from_limbus'], function(newVal, oldVal) { 
            let filled = false;
            if(newVal[0] && newVal[1] && newVal[2]){
                filled = true;
            }

            if(!$scope.location_not_required && !newVal[3]){
                filled = false;
            }

            if(!$scope.distance_not_required && !newVal[4]){
                filled = false;
            }

            if (filled) {
                $scope.$ctrl.data.injection_details.push({
                    medication: newVal[1],
                    dose: newVal[2],
                    procedure_type: newVal[0],
                    injection_location: newVal[3],
                    distance_from_limbus: newVal[4],
                })
                $scope.form = {};
                $scope.distance_not_required = false;
                $scope.location_not_required = false;
                $scope.medication_type = [];
                $scope.dose_type = [];
            }
          
        });

        $scope.getMedicineName = function (id) {
            let target = null;
            target = INTRAVITREAL_MEDICATION.find(t => t.id === id);
            if(!target){
                target = INTRAVITREAL_IMPLANT_MEDICATION.find(t => t.id === id);
            }
            if(!target){
                target = SUBTENONS_INJECTION_MEDICATION.find(t => t.id === id);
            }
            if(!target){
                target = SUBCONJUNCTIVAL_INJECTION_MEDICATION.find(t => t.id === id);
            }
            if(!target){
                target = INTRAVITREAL_TAP_INJECT_MEDICATION.find(t => t.id === id);
            }
            return target ? target.name : id;
        }

        $scope.getProcedureName = function (id) {
            const target = INJECTIONPROCEDURE.find(t => t.id === id);
            return target ? `${target.name}, ` : '';
        }


        $scope.getDistanceName = function (id) {
            const target = DISTANCELIMBUS.find(t => t.id === id);
            return target ? `${target.name}mm from limbus` : '';
        }
		
        $scope.onProcedureChange = function () {
            // $scope.form.distance_from_limbus = "_4mm";
            if ($scope.form.procedure_type === 'INTRAVITREAL_INJECTION') {
                $scope.medication_type = INTRAVITREAL_MEDICATION;
            } else if ($scope.form.procedure_type === 'INTRAVITREAL_IMPLANT') {
                $scope.medication_type = INTRAVITREAL_IMPLANT_MEDICATION;
            } else if ($scope.form.procedure_type === 'SUBTENONS_INJECTION') {
                $scope.medication_type = SUBTENONS_INJECTION_MEDICATION;
            } else if ($scope.form.procedure_type === 'ORBITAL_FLOOR_INJECTION') {
                $scope.medication_type = ORBITAL_FLOOR_MEDICATION;
            } else if ($scope.form.procedure_type === 'SUBCONJUNCTIVAL_INJECTION') {
                $scope.medication_type = SUBCONJUNCTIVAL_INJECTION_MEDICATION;
            } else if ($scope.form.procedure_type === 'INTRAVITREAL_TAP_AND_INJECT') {
                $scope.medication_type = INTRAVITREAL_TAP_INJECT_MEDICATION;
            } else {
                $scope.medication_type = [];
                $scope.dose_type = [];
                $scope.form.injection_location = null;
                $scope.form.distance_from_limbus = null;
            }
            if($scope.medication_type.length > 0){
                $scope.form.medication= $scope.medication_type[0].id;
                $scope.onMedicationChange();
            }
        }

        $scope.onMedicationChange = function () {
            $scope.form.dose = null;
            $scope.distance_not_required = false;
            $scope.location_not_required = false;
            if($scope.form.procedure_type === 'INTRAVITREAL_INJECTION'){
                if ($scope.form.medication === 'BEVACIZUMAB') {
                    $scope.dose_type = [{ id: "1.25mg/0.05mL", name: "1.25mg/0.05mL" }];
                } else if ($scope.form.medication === 'AFLIBERCEPT') {
                    $scope.dose_type = [{ id: "2mg/0.05mL", name: "2mg/0.05mL" }];
                } else if ($scope.form.medication === 'RANABIZUMAB') {
                    $scope.dose_type = [{ id: "0.5mg/0.05mL", name: "0.5mg/0.05mL" }, 
                    { id: "0.3mg/0.05mL", name: "0.3mg/0.05mL" }];
                } else if ($scope.form.medication === 'TRIAMCINOLONE_ACETONIDE') {
                    $scope.dose_type = [{ id: "4mg/0.1mL", name: "4mg/0.1mL" }, 
                    { id: "2mg/0.05mL", name: "2mg/0.05mL" }];
                } else if ($scope.form.medication === 'METHOTREXATE') {
                    $scope.dose_type = [{ id: "400mcg/0.1mL", name: "400mcg/0.1mL" }];
                } 
            } else if ($scope.form.procedure_type === 'INTRAVITREAL_IMPLANT'){
                if ($scope.form.medication === 'DEXAMETHASONE_IMPLANT') {
                    $scope.dose_type = [{ id: "0.7mg", name: "0.7mg" }];
                } 
            } else if ($scope.form.procedure_type === 'SUBTENONS_INJECTION'){
                if ($scope.form.medication === 'TRIAMCINOLONE_ACETONIDE') {
                    $scope.dose_type = [{ id: "40mg/1mL", name: "40mg/1mL" },
                    {
                        id: "20mg/0.5mL", name: "20mg/0.5mL"
                    }];

                    $scope.distance_not_required = true;
                    $scope.form.distance_from_limbus = null;
                } 
            } else if ($scope.form.procedure_type === 'ORBITAL_FLOOR_INJECTION') {
                if ($scope.form.medication === 'TRIAMCINOLONE_ACETONIDE') {
                    $scope.dose_type = [{ id: "40mg/1mL", name: "40mg/1mL" },
                    {
                        id: "20mg/0.5mL", name: "20mg/0.5mL"
                    }];
                    $scope.distance_not_required = true;
                    $scope.form.distance_from_limbus = null;

                    $scope.location_not_required = true;
                    $scope.form.injection_location = null;
                } 
            } else if ($scope.form.procedure_type === 'SUBCONJUNCTIVAL_INJECTION') {
                if ($scope.form.medication === 'DEXAMETHASONE') {
                    $scope.dose_type = [{ id: "4mg", name: "4mg" },{ id: "8mg", name: "8mg" }];
                } else if ($scope.form.medication === 'BEVACIZUMAB') {
                    $scope.dose_type = [{ id: "2.5mg/0.1mL", name: "2.5mg/0.1mL" }];
                } 
            } else if ($scope.form.procedure_type === 'INTRAVITREAL_TAP_AND_INJECT') {
                if ($scope.form.medication === 'VITREOUS_TAP') {
                    $scope.dose_type = [
                        { id: "0.05mL", name: "0.05mL" },
                        { id: "0.1ml", name: "0.1ml"},
                        { id: "0.15ml", name: "0.15ml"},
                        { id: "0.2ml", name: "0.2ml"},
                        { id: "0.25ml", name: "0.25ml"},
                        { id: "0.3ml", name: "0.3ml"},
                    ];
                } else if ($scope.form.medication === 'CEFAZOLIN') {
                    $scope.dose_type = [{ id: "2.25mg/0.1mL", name: "2.25mg/0.1mL" }];
                } else if ($scope.form.medication === 'VANCOMYCIN') {
                    $scope.dose_type = [{ id: "1mg/0.1mL", name: "1mg/0.1mL" }];
                } else if ($scope.form.medication === 'AMIKACIN') {
                    $scope.dose_type = [{ id: "400mcg/0.1mL", name: "400mcg/0.1mL" }];
                } else if ($scope.form.medication === 'CEFTAZIDIME') {
                    $scope.dose_type = [{ id: "2mg/0.1mL", name: "2mg/0.1mL" }];
                } 
            } 

            // if($scope.dose_type.length > 0){
            //     $scope.form.dose = $scope.dose_type[0].id;
            // }
        }

        $scope.onOptionChange = function () {
            // if (!$scope.dose_type.find((t) => t.id === $scope.form.hello.selected.id)) {
            //     console.log(`===${JSON.stringify($scope.form.hello.selected)}`);
            //     $scope.dose_type.push({ id: $scope.form.hello.selected.id, name: $scope.form.hello.selected.id });
            // }
		};

        $scope.getDoseType = function (search) {
            var newSupes = $scope.dose_type.map(t=>t.id).slice();
			 if (search && newSupes.indexOf(search) === -1) {
			   newSupes.unshift(search);
			 }
			 return newSupes;
		};

		// $scope.removeIncision = function (index) {
		// 	$scope.$ctrl.data.incisions.splice(index, 1);
		// };

		// $scope.removeOther = function (index) {
		// 	$scope.otherOvdArray.splice(index, 1);
		// };

		
		// $scope.selection = [];
		// if($scope.$ctrl.data.ovd){
		// 	$scope.selection = $scope.$ctrl.data.ovd;
		// }

		// this.$onChanges = function (changesObj) {
		// 	if (changesObj.data.currentValue) {
		// 		if (changesObj.data.currentValue.ovd && changesObj.data.currentValue.ovd.length > 0) {
		// 			changesObj.data.currentValue.ovd.forEach((element) => {
		// 				const obj = $scope.OVD.find((t) => t.value === element);
		// 				if (obj) {
		// 					obj.selected = true;
		// 				} else {
		// 					$scope.OVD[5].selected = true;
		// 					$scope.otherOvdArray = element.split(",");
		// 					// $scope.otherOvd = element;
		// 				}
		// 			});
		// 		}
		// 	}
		// };

		// $scope.$watch(
		// 	"OVD|filter:{selected:true}",
		// 	function (newValue) {
		// 		$scope.selection = newValue.map(function (item) {
		// 			return item.value;
		// 		});
		// 		if ($scope.$ctrl.data) {
		// 			$scope.$ctrl.data.ovd = $scope.selection.map(function (item) {
		// 				if(item === "Other") {
		// 					$scope.warn_ovd = false;
		// 				}
		// 				if (item === "Other" && $scope.otherOvdArray.length > 0) return getOtherOvdArrayString($scope.otherOvdArray);
		// 				return item;
		// 			});
		// 		}
		// 	},
		// 	true
		// );

		// function getOtherOvdArrayString(array) {
		// 	let str = '';
		// 	array.forEach( (element,index) => {
		// 		str = str + element;
		// 		if(index !== array.length-1){
		// 			str = str + ','
		// 		}
		// 	})
		// 	return str;
		// }

		// $scope.addOther = function(){
		// 	if($scope.form_other && $scope.form_other.length > 2 && $scope.form_other.length < 41){
		// 		$scope.otherOvdArray.push($scope.form_other);
		// 		$scope.$ctrl.data.ovd = $scope.selection.map(function (item) {
		// 			if (item === "Other") return getOtherOvdArrayString($scope.otherOvdArray);
		// 			return item;
		// 		});
		// 		$scope.warn_ovd = false;
		// 		$scope.form_other = '';
		// 	}
		// }

		// $scope.OVD = OVD.map(t => ({...t, selected: false}));
		
		// $scope.addIncision = function () {
		// 	if (
		// 		!$scope.$ctrl.form.incision_size ||
		// 		!$scope.$ctrl.form.incision_enlargement ||
		// 		(!$scope.$ctrl.form.incision_location_degree && $scope.$ctrl.form.incision_location_degree !== 0) ||
		// 		!$scope.$ctrl.form.incision_location
		// 	) {
		// 		return;
		// 	}
		// 	let size, enlargement, location_degree, location;
		// 	try {
		// 		size = $scope.$ctrl.form.incision_size.val;
		// 		enlargement = $scope.$ctrl.form.incision_enlargement;
		// 		location_degree = $scope.$ctrl.form.incision_location_degree;
		// 		location = $scope.$ctrl.form.incision_location;
		// 	} catch (error) {
		// 		return;
		// 	}

		// 	if (!$scope.$ctrl.data.incisions) {
		// 		$scope.$ctrl.data.incisions = [];
		// 	}
		// 	$scope.$ctrl.data.incisions.push({
		// 		size: size ? Number.parseFloat(size.id).toFixed(1) : null,
		// 		enlargement,
		// 		location_degree,
		// 		location_type: location,
		// 	});
		// 	// reset fields to empty
		// 	this.$ctrl.form.incision_size.val = null;
		// 	// this.$ctrl.form.incision_enlargement = null;
		// 	this.$ctrl.form.incision_location_degree = null;
		// 	$scope.warn = false;
		// 	// this.$ctrl.form.incision_location = null;
		// };
		// $scope.onChange = function () {
		// 	if (angular.isString($scope.$ctrl.form.incision_size.val)) {
		// 		$scope.$ctrl.form.incision_size.val = {
		// 			id: $scope.$ctrl.form.incision_size.val,
		// 			name: $scope.$ctrl.form.incision_size.val,
		// 		};
		// 		if (!$scope.incisionSize.find((t) => t.name === $scope.$ctrl.form.incision_size.name)) {
		// 			$scope.incisionSize.push($scope.$ctrl.form.incision_size.val);
		// 		}
		// 	}
		// 	$scope.showWarn();
		// 	// $scope.$ctrl.data.ccc.pupil_expander = $scope.$ctrl.freeTextDemo.val.id;
		// };

		// $scope.showWarn = function () {
		// 	$scope.warn = true;
		// };

		// $scope.showWarn_ovd = function () {
		// 	$scope.warn_ovd = true;
		// };
	},
]);
