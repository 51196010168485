app.controller("injectionNoteController", [
	"$scope",
	"OperationNote",
	"InjectionNoteAPI",
	"NHI",
	"$timeout",
	"Session",
	"CurrentPatient",
	"$state",
	"$filter",
	"$q",
	"$modal",
	"UserService",
	"OPERATIONPROCEDURE",
	"SurgeryAPI",
	"PreOp",
	"$window",
	"ShareData",
	function (
		$scope,
		OperationNote,
		InjectionNoteAPI,
		NHI,
		$timeout,
		Session,
		CurrentPatient,
		$state,
		$filter,
		$q,
		$modal,
		UserService,
		OPERATIONPROCEDURE,
		SurgeryAPI,
		PreOp,
		$window,
		ShareData,
	) {
		$scope.CP = CurrentPatient;
		$scope.isHistoryCollapsed = true;
		$scope.isPlanCollapsed = true;
		$scope.displaySecondaryNHI = false;
		$scope.displaySecondaryInsurance = false;
		$scope.visionAlerts = [];
		$scope.historyAlerts = [];
		$scope.planAlerts = [];
		$scope.alertMessage = "Data saved, complete all sections to finalise the surgical plan.";
		$scope.nhi = {};
		$scope.operative_eye = "";
		$scope.showCommentsOnly = false;
		$scope.displayContactTracingMessageInButtonSurround = false;
		$scope.ophthalmologists = [];
		$scope.isSubmitted = false;
		$scope.hiddenFooter = false;
		$scope.noteDetails = "";
		$scope.disableSelect = false;
		$scope.injectionNoteHeader = "";
		$scope.displaySpinner = false;
		$scope.remoteDefaultSetting = null;
		$scope.PreOp = null;
		$scope.noteContent = "injection note";

		$scope.closeInsuranceModal = function (){
			$scope.displaySecondaryInsurance = false;
			if($scope.CP.patient.insuranceProvider){
				$scope.submit();
			}
		}

		$scope.US = UserService;
		$scope.US.getDoctorsByPracticeId(Session.practice.id).then(
			function (resp) {
				$scope.ophthalmologists = [];
				resp.data.forEach(function (doc) {
					$scope.ophthalmologists.push({ name: doc.firstName + " " + doc.lastName, value: doc.id });
				});
				$scope.ophthalmologists.sort(function (a, b) {
					if (a.name < b.name) {
						return -1;
					}
					if (a.name > b.name) {
						return 1;
					}
					return 0;
				});
				requestInjectionNote();
				// const obj = $scope.ophthalmologists.find(t=>t.value === Session.user.id);
				// if(obj){
				// 	$scope.InjectionNote.surgeon_user_id = obj.value;
				// }
				
			},
			function (err) {
				console.log(err);
			}
		);
		

		$scope.$watch(
			"operative_eye",
			function (newValue) {
				if (newValue === "OD") {
					$scope.InjectionNote.injection_note_od.operative_eye = "YES";
					$scope.InjectionNote.injection_note_os.operative_eye = "NO";
				} else if (newValue === "OS") {
					$scope.InjectionNote.injection_note_os.operative_eye = "YES";
					$scope.InjectionNote.injection_note_od.operative_eye = "NO";
				} else if (newValue === "BOTH") {
					$scope.InjectionNote.injection_note_od.operative_eye = "YES";
					$scope.InjectionNote.injection_note_os.operative_eye = "YES";
				}
			},
			true
		);

		$scope.saveHistory = function (isWorkup) {
			if (!$scope.history.$valid && isWorkup) {
				$scope.isPlanCollapsed = true;
				throw new Error("error in history section");
			}
			return null;
		};

		function checkValid() {
			let valid = $scope.vision.$valid;
			return valid;
		}

		$scope.submit = function () {
			$scope.isSubmitted = true;
			const valid = checkValid();

			if (!valid) return;
			// display NHI warning if value is not present
			if ($scope.CP.patient.nhi === null && $scope.displaySecondaryNHI === false && $scope.displaySecondaryInsurance === false) {
				$scope.displaySecondaryNHI = true;
				return;
			}

			if ($scope.CP.patient.insuranceProvider === null && $scope.displaySecondaryInsurance === false) {
				$scope.hideSecondaryNhiComponent();
				$scope.displaySecondaryInsurance = true;
				return;
			}

			if (!$scope.showWarning) {
				$scope.showWarning = true;
				return;
			}

			$scope.displaySpinner = true;
			$scope.isSuccess = false;
			if($scope.InjectionNote.injection_note_od && $scope.InjectionNote.injection_note_od.operative_eye === 'NO'){
				$scope.InjectionNote.injection_note_od = null;
			}
			if($scope.InjectionNote.injection_note_os && $scope.InjectionNote.injection_note_os.operative_eye === 'NO'){
				$scope.InjectionNote.injection_note_os = null;
			}
			$scope.processingWorkup = $modal({
				scope: $scope,
				templateUrl: "app/src/views/templates/modal.processing-operation-note-workup.tpl.html",
				show: true,
				title: "PROCESSING...",
				keyboard: false,
				backdrop: "static",
			});
			InjectionNoteAPI.submitInjectionNote($scope.InjectionNote,$scope.createNoteOption)
				.then(function (resp) {
					downloadFile(resp.data, getFileName());
					$scope.$broadcast('trigger:automation-task');
					$scope.processingWorkup.hide();
					$scope.isSuccess = true;
					$scope.successfulWorkup = $modal({
						scope: $scope,
						templateUrl: "app/src/views/templates/modal.processing-operation-note-workup.tpl.html",
						show: true,
						title: "SUCCESS",
						keyboard: false,
						backdrop: "static",
					});
				},function (error) {
					$scope.processingWorkup.hide();
					$scope.displaySpinner = false;
					$scope.serverErrorModal = $modal({
						scope: $scope,
						templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
						show: true,
						title: "CatTrax has encountered an error",
					});
				})
				.catch(function (error) {
					console.error(`submit injection note error ${JSON.stringify(error)}`);
					$scope.processingWorkup.hide();
					$scope.displaySpinner = false;
				});
		};

		$scope.downloadPDF = function (note) {
			InjectionNoteAPI.downloadPDF(note.patient_id, note.operation_note_id).then(function (resp) {
				downloadFile(resp.data, getFileName());
			});
		};

		$scope.submitWorkUp = function () {
			// display NHI warning if value is not present
			if ($scope.CP.patient.nhi === null && $scope.displaySecondaryNHI === false && $scope.displaySecondaryInsurance === false) {
				$scope.displaySecondaryNHI = true;
				return;
			}

			if ($scope.CP.patient.insuranceProvider === null && $scope.displaySecondaryInsurance === false) {
				$scope.hideSecondaryNhiComponent();
				$scope.displaySecondaryInsurance = true;
				return;
			}
		};

		$scope.closeAlert = function (index, arr) {
			arr.splice(index, 1);
		};

		$scope.returnToSearch = function () {
			$scope.successfulWorkup.hide();
			$scope.CP.clearPatientResult();
			$state.go("refer.search");
		};

		$scope.sugicalHistory = function () {
			$scope.successfulWorkup && $scope.successfulWorkup.hide();
			$scope.remoteDefaultSetting = null;
			// requestInjectionNote();
			$window.history.back();
		};

		$scope.showAppendButton = function (note) {
			$scope.showCommentsOnly = true;
			$scope.hiddenFooter = false;
			$scope.operative_eye = note.eye === "OD/OS" ? "BOTH" : note.eye;
			$scope.disableSelect = note.eye === "OD/OS" ? false : true;
			$scope.InjectionNoteList = [];
			$scope.InjectionNote= {
				operation_note_id: note.operation_note_id,
				patient_id: note.patient_id,
				op_note_od: { operative_eye: "NO" },
				op_note_os: { operative_eye: "NO" },
			};
			// $scope.successfulWorkup && $scope.successfulWorkup.hide();
		};

		function createNewForm() {
			let patient_id = $scope.CP.patient.id;
			// if ($scope.InjectionNoteList.length > 0) {
			// 	patient_id = $scope.InjectionNoteList[0].patient_id;
			// }
			const completed_by_user_id = Session.userId;
			const practice_id = Session.practice.id;
			$scope.InjectionNoteList = [];
			$scope.operative_eye = "";
			$scope.disableSelect = false;
			$scope.hiddenFooter = false;
			$scope.InjectionNote= {
				injection_note_id: null,
				patient_id: patient_id,
				// completed_by_user_id: completed_by_user_id,
				surgeon_user_id: null,
				
				practice_id: practice_id,
				// status: null,
				// date_created: null,
				// date_updated: null,
				injection_note_od: {
					injection_details: [],
					// distance_from_limbus: "_4_0mm"
				},
				injection_note_os: {
					injection_details: [],
					// distance_from_limbus: "_4_0mm"
				},
				// postop_medications: [],
				post_injection_care: {},
			};
			const currentSurgeon = $scope.ophthalmologists.find((t) => t.value === Session.userId);
			$scope.InjectionNote.surgeon_user_id = currentSurgeon ? currentSurgeon.value : null;
			$scope.injectionNoteHeader = "INJECTION NOTE";
		};
		
		function requestInjectionNote() {
			$scope.showWarning = false;
			$scope.displaySecondaryNHI = false;
			$scope.displaySecondaryInsurance = false;

			$scope.InjectionNoteList = [];
			createNewForm();
			$scope.displaySpinner = true;
			InjectionNoteAPI.getPatientSetting($scope.CP.patient.id).then(function (response) {
				$scope.displaySpinner = false;
				if(response.status === 200){
					if(response.data.injection_note_settings && response.data.injection_note_settings.injection_note_od_settings){
						$scope.InjectionNote.injection_note_od = angular.copy(response.data.injection_note_settings.injection_note_od_settings);
						$scope.InjectionNote.injection_note_od.operative_eye = "YES";
					}
					if(response.data.injection_note_settings && response.data.injection_note_settings.injection_note_os_settings){
						$scope.InjectionNote.injection_note_os = angular.copy(response.data.injection_note_settings.injection_note_os_settings);
						$scope.InjectionNote.injection_note_os.operative_eye = "YES";
					}
				}
				let flag = $scope.operative_eye;
				if ($scope.InjectionNote.injection_note_od !== null && $scope.InjectionNote.injection_note_od.operative_eye === "YES") {
					flag = "OD";
				}

				if ($scope.InjectionNote.injection_note_os !== null && $scope.InjectionNote.injection_note_os.operative_eye === "YES") {
					flag = flag + "OS";
				}
				$scope.operative_eye = flag === "ODOS" ? "BOTH" : flag;
				const obj = $scope.ophthalmologists.find(t=>t.value === Session.user.id);
				if(obj){
					$scope.InjectionNote.surgeon_user_id = obj.value;
				}
			}).catch(function (error) {
				console.error(`get patient injection note error ${JSON.stringify(error)}`);
				$scope.processingWorkup && $scope.processingWorkup.hide();
				$scope.displaySpinner = false;
			});
			
			;
		}

		function downloadFile(data, filename, mime, bom) {
			// https://github.com/kennethjiang/js-file-download/blob/master/file-download.js
			var blobData = typeof bom !== "undefined" ? [bom, data] : [data];
			var blob = new Blob(blobData, { type: "application/pdf" });
			if (typeof window.navigator.msSaveBlob !== "undefined") {
				// IE workaround for "HTML7007: One or more blob URLs were
				// revoked by closing the blob for which they were created.
				// These URLs will no longer resolve as the data backing
				// the URL has been freed."
				window.navigator.msSaveBlob(blob, filename);
			} else {
				var blobURL = window.URL.createObjectURL(blob);
				var tempLink = document.createElement("a");
				tempLink.style.display = "none";
				tempLink.href = blobURL;
				tempLink.setAttribute("download", filename);

				// Safari thinks _blank anchor are pop ups. We only want to set _blank
				// target if the browser does not support the HTML5 download attribute.
				// This allows you to download files in desktop safari if pop up blocking
				// is enabled.
				if (typeof tempLink.download === "undefined") {
					tempLink.setAttribute("target", "_blank");
				}

				document.body.appendChild(tempLink);
				tempLink.click();
				document.body.removeChild(tempLink);
				window.URL.revokeObjectURL(blobURL);
				$scope.displaySpinner = false;
			}
		}

		function getFileName() {
			var theTime = $filter("date")(new Date(), "yyyy-MM-dd@hmma");
			return (
				"CatTrax_operation_note_" +
				theTime +
				"_" +
				$scope.CP.patient.firstName +
				"_" +
				$scope.CP.patient.lastName +
				"_" +
				$scope.CP.patient.dateOfBirth +
				".pdf"
			);
		}

		$scope.hideSecondaryNhiComponent = function () {
			$scope.displaySecondaryNHI = false;
		};

		$scope.hideSecondaryInsuranceComponent = function () {
			$scope.displaySecondaryInsurance = false;
		};

		$scope.createNoteOption = {};

		$scope.onAutomationTaskChange = function(event) {
			if(event.type === 'injection_note_automation_settings'){
				$scope.createNoteOption.addAutoTaskInjection = event.isAutomationTaskEnabled;
			}
		}
	},
]);
