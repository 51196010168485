app.component("preOpPlanPrice", {
	templateUrl: "app/src/js/components/preOpPlan/preOpPlanPrice/preOpPlanPrice.html",
	controller: "preOpPlanPriceController",
	bindings: {
		biometry: "=",
		eye: "=",
	},
});

app.controller("preOpPlanPriceController", [
	"$scope",
	"PreOp",
	"CurrentPatient",
	"Session",
	"PremiumLensAPI",
	"$modal",
	function ($scope, PreOp, CurrentPatient, Session, PremiumLensAPI, $modal) {
		$scope.CP = CurrentPatient;
		$scope.right_toric_recommend = null;
		$scope.left_toric_recommend = null;
		$scope.hasClinicalConsideration = false;
		$scope.hasClinicalConsideration2 = false;
		$scope.isToricMonofocalSuggested = false;
		$scope.PL = PremiumLensAPI;
		$scope.PreOp = PreOp;

		$scope.lens_checked = {
			eyhance_checked: false,
			monofocal_toric_checked: false,
			synergy_checked: false,
			symfony_checked: false,
			rayone_emv_checked: false,
			varioMax_checked: false,
			mf30_checked: false,
			comfort_checked: false,
			vario_checked: false,
			rayone_checked: false,
			lentis_checked: false,
			alcon_toric_checked: false,
			pure_see_checked: false,
			vivity_checked: false,
			pan_optix_checked: false,
			galaxy_checked: false,
			clearview_checked: false
		}
		$scope.showSecondStep = false;
		$scope.showThirdStep = false;
		$scope.showAnotherEmail = false;
		$scope.left_toric = true;
		$scope.right = {};
		$scope.left = {};
		$scope.Session = Session;
		// $scope.responseEmail = $scope.Session.user.email;
		// $scope.patientEmail = $scope.CP.patient.email;
		$scope.anotherEmail = "";
		$scope.history = []
		$scope.requestBody = {
			lens_additional_notes: "",
			patientEmail: $scope.CP.patient.email,
			responseEmail: $scope.Session.user.email,
			anotherEmail: "",
			practice_id: $scope.Session.practice.id,
			patient_id: $scope.CP.patient.id,
			monofocal: {
				toric: {},
				non_toric: {},
			},
			symfony: {
				toric: {},
				non_toric: {},
			},
			synergy: {
				toric: {},
				non_toric: {},
			},
			eyhance: {
				toric: {},
				non_toric: {},
			},
			rayone_emv: {
				toric: {},
				non_toric: {},
			},
			rayone: {
				toric: {},
				non_toric: {},
			},
			comfort: {
				toric: {},
				non_toric: {},
			},
			mf30: {
				toric: {},
				non_toric: {},
			},
			varioMax:{
				toric: {},
				non_toric: {},
			},
			vario: {
				toric: {},
				non_toric: {},
			},
			lentis: {
				toric: {},
				non_toric: {},
			},
			alcon_toric: {
				toric: {},
				non_toric: {},
			},
			pure_see: {
				toric: {},
				non_toric: {},
			},
			vivity: {
				toric: {},
				non_toric: {},
			},
			pan_optix:{
				toric: {},
				non_toric: {},
			},
			galaxy:{
				toric: {},
				non_toric: {},
			},
			clearview: {
				toric: {},
				non_toric: {},
			},
		}
		function showErrorModal(error) {
			console.error(`error ${JSON.stringify(error)}`)
			$scope.serverErrorModal = $modal({
				scope: $scope,
				templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
				show: true,
				title: "CatTrax has encountered an error",
			});
		}

		function presetPrice(list) {
			list.forEach(t => {
				if (t.lens_name === "MONOFOCAL") {
					if (t.lens_type === "TORIC") {
						$scope.requestBody.monofocal.toric.price = t.lens_price
					} else {
						$scope.requestBody.monofocal.non_toric.price = t.lens_price
					}
				} else if (t.lens_name === "SYMFONY") {
					if (t.lens_type === "TORIC") {
						$scope.requestBody.symfony.toric.price = t.lens_price
					} else {
						$scope.requestBody.symfony.non_toric.price = t.lens_price
					}
				} else if (t.lens_name === "SYNERGY") {
					if (t.lens_type === "TORIC") {
						$scope.requestBody.synergy.toric.price = t.lens_price
					} else {
						$scope.requestBody.synergy.non_toric.price = t.lens_price
					}
				} else if (t.lens_name === "EYHANCE") {
					if (t.lens_type === "TORIC") {
						$scope.requestBody.eyhance.toric.price = t.lens_price
					} else {
						$scope.requestBody.eyhance.non_toric.price = t.lens_price
					}
				} else if (t.lens_name === "RAYONE_EMV") {
					if (t.lens_type === "TORIC") {
						$scope.requestBody.rayone_emv.toric.price = t.lens_price
					} else {
						$scope.requestBody.rayone_emv.non_toric.price = t.lens_price
					}
				} else if (t.lens_name === "COMFORT") {
					if (t.lens_type === "TORIC") {
						$scope.requestBody.comfort.toric.price = t.lens_price
					} else {
						$scope.requestBody.comfort.non_toric.price = t.lens_price
					}
				} else if (t.lens_name === "MF30") {
					if (t.lens_type === "TORIC") {
						$scope.requestBody.mf30.toric.price = t.lens_price
					} else {
						$scope.requestBody.mf30.non_toric.price = t.lens_price
					}
				} else if (t.lens_name === "VARIO") {
					if (t.lens_type === "TORIC") {
						$scope.requestBody.vario.toric.price = t.lens_price
					} else {
						$scope.requestBody.vario.non_toric.price = t.lens_price
					}
				} else if (t.lens_name === "VARIO_MAX") {
					if (t.lens_type === "TORIC") {
						$scope.requestBody.varioMax.toric.price = t.lens_price
					} else {
						$scope.requestBody.varioMax.non_toric.price = t.lens_price
					}
				} else if (t.lens_name === "RAYONE") {
					if (t.lens_type === "TORIC") {
						$scope.requestBody.rayone.toric.price = t.lens_price
					} else {
						$scope.requestBody.rayone.non_toric.price = t.lens_price
					}
				} else if (t.lens_name === "LENTIS") {
					if (t.lens_type === "TORIC") {
						$scope.requestBody.lentis.toric.price = t.lens_price
					} else {
						$scope.requestBody.lentis.non_toric.price = t.lens_price
					}
				} else if (t.lens_name === "ALCON_TORIC") {
					if (t.lens_type === "TORIC") {
						$scope.requestBody.alcon_toric.toric.price = t.lens_price
					} else {
						$scope.requestBody.alcon_toric.non_toric.price = t.lens_price
					}
				} else if (t.lens_name === "PURE_SEE") {
					if (t.lens_type === "TORIC") {
						$scope.requestBody.pure_see.toric.price = t.lens_price
					} else {
						$scope.requestBody.pure_see.non_toric.price = t.lens_price
					}
				} else if (t.lens_name === "VIVITY") {
					if (t.lens_type === "TORIC") {
						$scope.requestBody.vivity.toric.price = t.lens_price
					} else {
						$scope.requestBody.vivity.non_toric.price = t.lens_price
					}
				} else if (t.lens_name === "PAN_OPTIX") {
					if (t.lens_type === "TORIC") {
						$scope.requestBody.pan_optix.toric.price = t.lens_price
					} else {
						$scope.requestBody.pan_optix.non_toric.price = t.lens_price
					}
				} else if (t.lens_name === "GALAXY") {
					if (t.lens_type === "TORIC") {
						$scope.requestBody.galaxy.toric.price = t.lens_price
					} else {
						$scope.requestBody.galaxy.non_toric.price = t.lens_price
					}
				} else if (t.lens_name === "CLEARVIEW") {
					if (t.lens_type === "TORIC") {
						$scope.requestBody.clearview.toric.price = t.lens_price
					} else {
						$scope.requestBody.clearview.non_toric.price = t.lens_price
					}
				}
			})
		}
		
		function fetchEmailHistory() {
			$scope.PL.isProcessing = true;
			$scope.PL.getEmailsForPatient($scope.CP.patient.id, $scope.Session.practice.id).then(function (result) {
				$scope.history = result.data
			},
				function (error) {
					showErrorModal(error);
				}).then(function () {
					$scope.PL.isProcessing = false;
				})
		}

		this.$onInit = function () {
			$scope.PL.isProcessing = true;
			$scope.PL.getSavedPriceList($scope.Session.user.id, $scope.Session.practice.id).then(
				function (response) {
					if (response.data.premium_lenses_saved_prices) {
						presetPrice(response.data.premium_lenses_saved_prices)
					}
					if(response.data.premium_lenses_response_email){
						$scope.requestBody.responseEmail = response.data.premium_lenses_response_email;
					}
				},
				function (error) {
					//do nothing because there is no saved setting
				}
			).then(function () {
				$scope.PL.isProcessing = false;
			})
			fetchEmailHistory();
		};

		function partTransform(body, result, isChecked, propName) {
			if (isChecked) {
				if ($scope.right.toric && $scope.left.toric && body.op_eye === 'OU') {
					result[propName] = {
						toric: {
							eye: "OU",
							price: body[propName].toric.price
						}
					}
				} else if (!$scope.right.toric && !$scope.left.toric && body.op_eye === 'OU') {
					result[propName] = {
						non_toric: {
							eye: "OU",
							price: body[propName].non_toric.price
						}
					}
				} else if ($scope.right.toric && !$scope.left.toric && body.op_eye === 'OU') {
					result[propName] = {
						toric: {
							eye: "OD",
							price: body[propName].toric.price,
						},
						non_toric: {
							eye: "OS",
							price: body[propName].non_toric.price
						}
					}
				} else if (!$scope.right.toric && $scope.left.toric && body.op_eye === 'OU') {
					result[propName] = {
						toric: {
							eye: "OS",
							price: body[propName].toric.price,
						},
						non_toric: {
							eye: "OD",
							price: body[propName].non_toric.price
						}
					}
				} else if (body.op_eye === 'OD' && $scope.right.toric) {
					result[propName] = {
						toric: {
							eye: "OD",
							price: body[propName].toric.price
						}
					}
				} else if (body.op_eye === 'OD' && $scope.right.toric === false) {
					result[propName] = {
						non_toric: {
							eye: "OD",
							price: body[propName].non_toric.price
						}
					}
				} else if (body.op_eye === 'OS' && $scope.left.toric) {
					result[propName] = {
						toric: {
							eye: "OS",
							price: body[propName].toric.price
						}
					}
				} else if (body.op_eye === 'OS' && $scope.left.toric === false) {
					result[propName] = {
						non_toric: {
							eye: "OS",
							price: body[propName].non_toric.price
						}
					}
				}

			}
		}

		function transformBody(body) {
			const result = {
				practice_id: body.practice_id,
				patient_id: body.patient_id,
				op_eye: body.op_eye,
				recommendation: body.lens_additional_notes,
				to_email: body.patientEmail,
				cc_email_list: body.anotherEmail ? [body.anotherEmail] : [],
				response_email: body.responseEmail

			}
  
			partTransform(body, result, $scope.lens_checked.monofocal_toric_checked, 'monofocal')
			partTransform(body, result, $scope.lens_checked.eyhance_checked, 'eyhance')
			partTransform(body, result, $scope.lens_checked.symfony_checked, 'symfony')
			partTransform(body, result, $scope.lens_checked.synergy_checked, 'synergy')
			partTransform(body, result, $scope.lens_checked.rayone_emv_checked, 'rayone_emv')
			partTransform(body, result, $scope.lens_checked.mf30_checked, 'mf30')
			partTransform(body, result, $scope.lens_checked.comfort_checked, 'comfort')
			partTransform(body, result, $scope.lens_checked.vario_checked, 'vario')
			partTransform(body, result, $scope.lens_checked.varioMax_checked, 'varioMax')
			partTransform(body, result, $scope.lens_checked.rayone_checked, 'rayone')
			partTransform(body, result, $scope.lens_checked.lentis_checked, 'lentis')


			partTransform(body, result, $scope.lens_checked.alcon_toric_checked, 'alcon_toric');
			partTransform(body, result, $scope.lens_checked.pure_see_checked, 'pure_see');
			partTransform(body, result, $scope.lens_checked.vivity_checked, 'vivity');
			partTransform(body, result, $scope.lens_checked.pan_optix_checked, 'pan_optix');
			partTransform(body, result, $scope.lens_checked.galaxy_checked, 'galaxy');
			partTransform(body, result, $scope.lens_checked.clearview_checked, 'clearview');








			return result;
		}

		$scope.formatRegular = function (regularity) {
			if (regularity === true) return "(Irregular)";
			if (regularity === false) return "(Regular)";
			return "";
		}

		$scope.formatBiometrySummary = function (text, obj) {
			if (obj && obj.corneal_astigmatism_magnitude && obj.corneal_astigmatism_axis) {
				return `${text} ${obj.corneal_astigmatism_magnitude}D x ${obj.corneal_astigmatism_axis}deg ${$scope.formatRegular(obj.corneal_astigmatism_regularity)}`
			}
			return '';
		}

		$scope.popoverRightToric = function () {
			const text = $scope.right_toric_recommend === true ? "Recommended: " : "Use with caution: ";
			return {
				content: $scope.formatBiometrySummary(text, $scope.$ctrl.biometry ? $scope.$ctrl.biometry.od : null)
			}
		}

		$scope.popoverSymfonySynergy = function () {
			return {
				content: ($scope.hasClinicalConsideration || $scope.hasClinicalConsideration2) ? `Use with caution: ${$scope.formatRiskFactor()}` : "No contraindications for diffractive IOLs"
			}
		}

		$scope.popoverRightNonToric = function () {
			const text = $scope.right_toric_recommend === false ? "Recommended: " : "Use with caution: ";
			return {
				content: $scope.formatBiometrySummary(text, $scope.$ctrl.biometry ? $scope.$ctrl.biometry.od : null)
			}
		}

		$scope.popoverLeftToric = function () {
			const text = $scope.left_toric_recommend === true ? "Recommended: " : "Use with caution: ";
			return {
				content: $scope.formatBiometrySummary(text, $scope.$ctrl.biometry ? $scope.$ctrl.biometry.os : null)
			}
		}

		$scope.popoverLeftNonToric = function () {
			const text = $scope.left_toric_recommend === false ? "Recommended: " : "Use with caution: ";
			return {
				content: $scope.formatBiometrySummary(text, $scope.$ctrl.biometry ? $scope.$ctrl.biometry.os : null)
			}
		}

		$scope.popoverResponseEmail = {
			content:
				"This is the email address that the patient will reply to with their lens selection",
		}

		$scope.popoverRed = {
			content:
				"Use with caution:",
		};

		$scope.popoverGreen = {
			content:
				"Recommended:",
		};

		$scope.goSecondStep = function () {
			$scope.showSecondStep = true;
		}

		$scope.goThirdStep = function () {
			$scope.showThirdStep = true;
		}

		$scope.showAnotherEmailFuc = function () {
			$scope.showAnotherEmail = true;
		}
		$scope.removeAnotherEmailFuc = function () {
			$scope.showAnotherEmail = false;
			$scope.requestBody.anotherEmail = "";
		}


		$scope.popoverMonofocal = {
			content: 'Monofocal range: +5 to +34D in 0.5D steps'
		}

		$scope.popoverToricMonofocal = {
			content: 'Monofocal toric IOL. range: +5 to +34D in 0.5D steps, Cyl: 1, 1.5, 2.25, 3, 3.75, 4.5, 5.25, 6, 7, 8D'
		}

		$scope.popoverEyhance = {
			content: 'Eyhance (enhanced monofocal IOL for distance and intermediate vision) range: +5 to +34D in 0.5D steps. Toric range: +15.0 to 26.5D in 0.5D steps, Cyl: 1, 1.5, 2.25, 3D'
		}

		$scope.popoverRayOneEMV = {
			content: 'RayOne EMV (refractive EDoF IOL for distance and intermediate or full range monovision) range: +10 to +30D in 0.5D steps. Toric range: +10 to +25D in 0.5D steps, Cyl: 0.75, 1.5, 2.25, 3, 3.75, 4.5D'
		}

		$scope.popoverRayOneToric = {
			content: 'RayOne EMV (refractive EDoF IOL for distance and intermediate or full range monovision) range: +10 to +30D in 0.5D steps. Toric range: +10 to +25D in 0.5D steps, Cyl: 0.75, 1.5, 2.25, 3, 3.75, 4.5D'
		}

		$scope.popoverSynergy = {
			content: 'Synergy (diffractive hybrid IOL for continuous distance, intermediate and near vision) range: +5 to +34D in 0.5D steps, Cyl: 1, 1.5, 2.25, 3, 3.75D'
		}

		$scope.popoverSymfony = {
			content: 'Symfony (diffractive hybrid IOL for distance, intermediate and some near) range: +5 to +34D in 0.5D steps, Cyl: 1, 1.5, 2.25, 3, 3.75, 4.5, 5.25, 6D'
		}

		$scope.popoverSent = function (item) {
			return {
				content: item.to + ` ` + item.cc.join(),
			}
		}

		function setClinicalConsideration() {
			$scope.hasClinicalConsideration = true;
		}

		function checkHasClinicalConsideration(newValue) {
			$scope.hasClinicalConsideration = false;
			if (newValue.diabetes.diabetes_status === "YES_T1DM" || newValue.diabetes.diabetes_status === "YES_T2DM") {
				setClinicalConsideration();
				return;
			}
			if ($scope.requestBody.op_eye === "OU") {
				if (newValue.ophthalmic_risk_factors.od.fuchs_guttata.indexOf("YES") !== -1 || newValue.ophthalmic_risk_factors.os.fuchs_guttata.indexOf("YES") !== -1) {
					setClinicalConsideration();
					return;
				}

				if (newValue.ophthalmic_risk_factors.od.amblyopia === "YES" || newValue.ophthalmic_risk_factors.os.amblyopia === "YES") {
					setClinicalConsideration();
					return;
				}

				if (newValue.ophthalmic_risk_factors.od.corneal_scarring === "YES_REDUCED_VIEW" || newValue.ophthalmic_risk_factors.os.corneal_scarring === "YES_REDUCED_VIEW") {
					setClinicalConsideration();
					return;
				}
				if (newValue.glaucoma.od.glaucoma === "YES" || newValue.glaucoma.os.glaucoma === "YES") {
					setClinicalConsideration();
					return;
				}

				if ((newValue.amd.od.amd && newValue.amd.od.amd !== "NO") || (newValue.amd.os.amd && newValue.amd.os.amd !== "NO")) {
					setClinicalConsideration();
					return;
				}

				if (newValue.amd.od.epiretinal_membrane === "YES" || newValue.amd.os.epiretinal_membrane === "YES") {
					setClinicalConsideration();
					return;
				}

				if ((newValue.prev_ocular.od.prev_ocular_trauma === "YES" &&
					newValue.prev_ocular.od.corneal_transplant &&
					newValue.prev_ocular.od.corneal_transplant !== "NO") ||
					(newValue.prev_ocular.os.prev_ocular_trauma === "YES" &&
						newValue.prev_ocular.os.corneal_transplant &&
						newValue.prev_ocular.os.corneal_transplant !== "NO")) {
					setClinicalConsideration();
					return;
				}
			}
			if ($scope.requestBody.op_eye === "OD") {
				if (newValue.ophthalmic_risk_factors.od.fuchs_guttata.indexOf("YES") !== -1) {
					setClinicalConsideration();
					return;
				}

				if (newValue.ophthalmic_risk_factors.od.amblyopia === "YES") {
					setClinicalConsideration();
					return;
				}

				if (newValue.ophthalmic_risk_factors.od.corneal_scarring === "YES_REDUCED_VIEW") {
					setClinicalConsideration();
					return;
				}
				if (newValue.glaucoma.od.glaucoma === "YES") {
					setClinicalConsideration();
					return;
				}
				if (newValue.amd.od.amd && newValue.amd.od.amd !== "NO") {
					setClinicalConsideration();
					return;
				}

				if (newValue.amd.od.epiretinal_membrane === "YES") {
					setClinicalConsideration();
					return;
				}

				if (newValue.prev_ocular.od.prev_ocular_trauma === "YES" &&
					newValue.prev_ocular.od.corneal_transplant &&
					newValue.prev_ocular.od.corneal_transplant !== "NO") {
					setClinicalConsideration();
					return;
				}
			}

			if ($scope.requestBody.op_eye === "OS") {
				if (newValue.ophthalmic_risk_factors.os.fuchs_guttata.indexOf("YES") !== -1) {
					setClinicalConsideration();
					return;
				}
				if (newValue.ophthalmic_risk_factors.os.amblyopia === "YES") {
					setClinicalConsideration();
					return;
				}

				if (newValue.ophthalmic_risk_factors.os.corneal_scarring === "YES_REDUCED_VIEW") {
					setClinicalConsideration();
					return;
				}
				if (newValue.glaucoma.os.glaucoma === "YES") {
					setClinicalConsideration();
					return;
				}
				if (newValue.amd.os.amd && newValue.amd.os.amd !== "NO") {
					setClinicalConsideration();
					return;
				}

				if (newValue.amd.os.epiretinal_membrane === "YES") {
					setClinicalConsideration();
					return;
				}

				if (newValue.prev_ocular.os.prev_ocular_trauma === "YES" &&
					newValue.prev_ocular.os.corneal_transplant &&
					newValue.prev_ocular.os.corneal_transplant !== "NO") {
					setClinicalConsideration();
					return;
				}
			}

		}

		function setClinicalConsideration2() {
			$scope.hasClinicalConsideration2 = true;
		}

		function checkHasClinicalConsideration2(newValue) {
			if(!newValue) return;
			$scope.hasClinicalConsideration2 = false;

			if ($scope.requestBody.op_eye === 'OU' && ((newValue.od && newValue.od.corneal_astigmatism_regularity === true) || (newValue.os && newValue.os.corneal_astigmatism_regularity === true))) {
				setClinicalConsideration2();
				return;
			}

			if (newValue.od && $scope.requestBody.op_eye === 'OD' && newValue.od.corneal_astigmatism_regularity === true) {
				setClinicalConsideration2();
				return;
			}

			if (newValue.os && $scope.requestBody.op_eye === 'OS' && newValue.os.corneal_astigmatism_regularity === true) {
				setClinicalConsideration2();
				return;
			}
		}

		function checkBiometryNullability(biometry){
			if(!biometry){
				return true;
			}
			let copy = {...biometry};
			if(copy.od && !copy.od.acd){
				copy.od = null;
			}
			if(copy.os && !copy.os.acd){
				copy.os = null;
			}
			return !copy.od && !copy.os
		} 

		function checkToricRecommend(newValue) {
			if (checkBiometryNullability(newValue)) {
				$scope.isToricMonofocalSuggested = true;
				return;
			}
			$scope.right_toric_recommend = null;
			// $scope.right.toric = null;

			$scope.left_toric_recommend = null;
			// $scope.left.toric = null;
			$scope.isToricMonofocalSuggested = false;

			if (($scope.requestBody.op_eye === 'OU' || $scope.requestBody.op_eye === 'OD') &&
				newValue.od &&
				newValue.od.corneal_astigmatism_magnitude >= 0.5 &&
				newValue.od.corneal_astigmatism_axis &&
				newValue.od.corneal_astigmatism_regularity === false) {
				$scope.right.toric = true;
				$scope.right_toric_recommend = true;
				$scope.isToricMonofocalSuggested = true;
			} else if (($scope.requestBody.op_eye === 'OU' || $scope.requestBody.op_eye === 'OD') &&
				newValue.od &&
				newValue.od.corneal_astigmatism_magnitude >= 0.5 &&
				newValue.od.corneal_astigmatism_axis &&
				newValue.od.corneal_astigmatism_regularity == null) {
				$scope.right.toric = null;
			} else if (($scope.requestBody.op_eye === 'OU' || $scope.requestBody.op_eye === 'OD') &&
				newValue.od &&
				newValue.od.corneal_astigmatism_magnitude >= 0.5 &&
				newValue.od.corneal_astigmatism_axis &&
				newValue.od.corneal_astigmatism_regularity === true) {
				$scope.right.toric = false;
				$scope.right_toric_recommend = false;
				$scope.isToricMonofocalSuggested = false;
			} else if (($scope.requestBody.op_eye === 'OU' || $scope.requestBody.op_eye === 'OD') &&
				newValue.od &&
				newValue.od.corneal_astigmatism_magnitude < 0.5 &&
				newValue.od.corneal_astigmatism_axis) {
				$scope.right.toric = false;
				$scope.right_toric_recommend = false;
				$scope.isToricMonofocalSuggested = false;
			} else {
				$scope.right.toric = null;
			}

			if (($scope.requestBody.op_eye === 'OU' || $scope.requestBody.op_eye === 'OS') &&
				newValue.os &&
				newValue.os.corneal_astigmatism_magnitude >= 0.5 &&
				newValue.os.corneal_astigmatism_axis &&
				newValue.os.corneal_astigmatism_regularity === false) {
				$scope.left.toric = true;
				$scope.left_toric_recommend = true;
				$scope.isToricMonofocalSuggested = true;
			} else if (($scope.requestBody.op_eye === 'OU' || $scope.requestBody.op_eye === 'OS') &&
				newValue.os &&
				newValue.os.corneal_astigmatism_magnitude >= 0.5 &&
				newValue.os.corneal_astigmatism_axis &&
				newValue.os.corneal_astigmatism_regularity == null) {
				$scope.left.toric = null;
			} else if (($scope.requestBody.op_eye === 'OU' || $scope.requestBody.op_eye === 'OS') &&
				newValue.os &&
				newValue.os.corneal_astigmatism_magnitude >= 0.5 &&
				newValue.os.corneal_astigmatism_axis &&
				newValue.os.corneal_astigmatism_regularity === true) {
				$scope.left.toric = false;
				$scope.left_toric_recommend = false;
				$scope.isToricMonofocalSuggested = false;
			} else if (($scope.requestBody.op_eye === 'OU' || $scope.requestBody.op_eye === 'OS') &&
				newValue.os &&
				newValue.os.corneal_astigmatism_magnitude < 0.5 &&
				newValue.os.corneal_astigmatism_axis) {
				$scope.left.toric = false;
				$scope.left_toric_recommend = false;
				$scope.isToricMonofocalSuggested = false;
			} else {
				$scope.left.toric = null;
			}
		}

		$scope.isFirstNextDisabled = function () {
			if ($scope.$ctrl.eye === 'BOTH') {
				return $scope.right.toric == null || $scope.left.toric == null
			} else if ($scope.$ctrl.eye === 'RIGHT') {
				return $scope.right.toric == null
			} else if ($scope.$ctrl.eye === 'LEFT') {
				return $scope.left.toric == null
			}
			return true
		}

		$scope.isSecondNextDisabled = function () {
			let result = false;
			if (!$scope.lens_checked.monofocal_toric_checked &&
				!$scope.lens_checked.eyhance_checked &&
				!$scope.lens_checked.synergy_checked &&
				!$scope.lens_checked.symfony_checked &&
				!$scope.lens_checked.rayone_checked &&
				!$scope.lens_checked.lentis_checked &&
				!$scope.lens_checked.rayone_emv_checked &&
				!$scope.lens_checked.mf30_checked &&
				!$scope.lens_checked.comfort_checked &&
				!$scope.lens_checked.vario_checked &&
				!$scope.lens_checked.varioMax_checked &&
				!$scope.lens_checked.alcon_toric_checked &&
				!$scope.lens_checked.pure_see_checked &&
				!$scope.lens_checked.vivity_checked &&
				!$scope.lens_checked.pan_optix_checked &&
				!$scope.lens_checked.galaxy_checked &&
				!$scope.lens_checked.clearview_checked
				) {
				return true;
			}
			if ($scope.requestBody.op_eye === "OU" && ($scope.right.toric == null || $scope.left.toric == null)) {
				return true;
			}
			if ($scope.requestBody.op_eye === "OD" && $scope.right.toric == null) {
				return true;
			}
			if ($scope.requestBody.op_eye === "OS" && $scope.left.toric == null) {
				return true;
			}
			if ($scope.lens_checked.monofocal_toric_checked) {
				if ($scope.right.toric === true && $scope.requestBody.op_eye !== "OS") {
					result = result || !$scope.requestBody.monofocal.toric.price
				}
				if ($scope.left.toric === true && $scope.requestBody.op_eye !== "OD") {
					result = result || !$scope.requestBody.monofocal.toric.price
				}
			}

			if ($scope.lens_checked.eyhance_checked) {
				if ($scope.right.toric === true && $scope.requestBody.op_eye !== "OS") {
					result = result || !$scope.requestBody.eyhance.toric.price
				}
				if ($scope.right.toric === false && $scope.requestBody.op_eye !== "OS") {
					result = result || !$scope.requestBody.eyhance.non_toric.price
				}
				if ($scope.left.toric === true && $scope.requestBody.op_eye !== "OD") {
					result = result || !$scope.requestBody.eyhance.toric.price
				}
				if ($scope.left.toric === false && $scope.requestBody.op_eye !== "OD") {
					result = result || !$scope.requestBody.eyhance.non_toric.price
				}
			}

			if ($scope.lens_checked.synergy_checked) {
				if ($scope.right.toric === true && $scope.requestBody.op_eye !== "OS") {
					result = result || !$scope.requestBody.synergy.toric.price
				}
				if ($scope.right.toric === false && $scope.requestBody.op_eye !== "OS") {
					result = result || !$scope.requestBody.synergy.non_toric.price
				}
				if ($scope.left.toric === true && $scope.requestBody.op_eye !== "OD") {
					result = result || !$scope.requestBody.synergy.toric.price
				}
				if ($scope.left.toric === false && $scope.requestBody.op_eye !== "OD") {
					result = result || !$scope.requestBody.synergy.non_toric.price
				}
			}

			if ($scope.lens_checked.symfony_checked) {
				if ($scope.right.toric === true && $scope.requestBody.op_eye !== "OS") {
					result = result || !$scope.requestBody.symfony.toric.price
				}
				if ($scope.right.toric === false && $scope.requestBody.op_eye !== "OS") {
					result = result || !$scope.requestBody.symfony.non_toric.price
				}
				if ($scope.left.toric === true && $scope.requestBody.op_eye !== "OD") {
					result = result || !$scope.requestBody.symfony.toric.price
				}
				if ($scope.left.toric === false && $scope.requestBody.op_eye !== "OD") {
					result = result || !$scope.requestBody.symfony.non_toric.price
				}
			}

			if ($scope.lens_checked.varioMax_checked) {
				if ($scope.right.toric === true && $scope.requestBody.op_eye !== "OS") {
					result = result || !$scope.requestBody.varioMax.toric.price
				}
				if ($scope.right.toric === false && $scope.requestBody.op_eye !== "OS") {
					result = result || !$scope.requestBody.varioMax.non_toric.price
				}
				if ($scope.left.toric === true && $scope.requestBody.op_eye !== "OD") {
					result = result || !$scope.requestBody.varioMax.toric.price
				}
				if ($scope.left.toric === false && $scope.requestBody.op_eye !== "OD") {
					result = result || !$scope.requestBody.varioMax.non_toric.price
				}
			}

			if ($scope.lens_checked.vario_checked) {
				if ($scope.right.toric === true && $scope.requestBody.op_eye !== "OS") {
					result = result || !$scope.requestBody.vario.toric.price
				}
				if ($scope.right.toric === false && $scope.requestBody.op_eye !== "OS") {
					result = result || !$scope.requestBody.vario.non_toric.price
				}
				if ($scope.left.toric === true && $scope.requestBody.op_eye !== "OD") {
					result = result || !$scope.requestBody.vario.toric.price
				}
				if ($scope.left.toric === false && $scope.requestBody.op_eye !== "OD") {
					result = result || !$scope.requestBody.vario.non_toric.price
				}
			}

			if ($scope.lens_checked.comfort_checked) {
				if ($scope.right.toric === true && $scope.requestBody.op_eye !== "OS") {
					result = result || !$scope.requestBody.comfort.toric.price
				}
				if ($scope.right.toric === false && $scope.requestBody.op_eye !== "OS") {
					result = result || !$scope.requestBody.comfort.non_toric.price
				}
				if ($scope.left.toric === true && $scope.requestBody.op_eye !== "OD") {
					result = result || !$scope.requestBody.comfort.toric.price
				}
				if ($scope.left.toric === false && $scope.requestBody.op_eye !== "OD") {
					result = result || !$scope.requestBody.comfort.non_toric.price
				}
			}

			if ($scope.lens_checked.mf30_checked) {
				if ($scope.right.toric === true && $scope.requestBody.op_eye !== "OS") {
					result = result || !$scope.requestBody.mf30.toric.price
				}
				if ($scope.right.toric === false && $scope.requestBody.op_eye !== "OS") {
					result = result || !$scope.requestBody.mf30.non_toric.price
				}
				if ($scope.left.toric === true && $scope.requestBody.op_eye !== "OD") {
					result = result || !$scope.requestBody.mf30.toric.price
				}
				if ($scope.left.toric === false && $scope.requestBody.op_eye !== "OD") {
					result = result || !$scope.requestBody.mf30.non_toric.price
				}
			}

			if ($scope.lens_checked.rayone_checked) {
				if ($scope.right.toric === true && $scope.requestBody.op_eye !== "OS") {
					result = result || !$scope.requestBody.rayone.toric.price
				}
				if ($scope.left.toric === true && $scope.requestBody.op_eye !== "OD") {
					result = result || !$scope.requestBody.rayone.toric.price
				}
			}

			if ($scope.lens_checked.lentis_checked) {
				if ($scope.right.toric === true && $scope.requestBody.op_eye !== "OS") {
					result = result || !$scope.requestBody.lentis.toric.price
				}
				if ($scope.left.toric === true && $scope.requestBody.op_eye !== "OD") {
					result = result || !$scope.requestBody.lentis.toric.price
				}
			}

			if ($scope.lens_checked.alcon_toric_checked) {
				if ($scope.right.toric === true && $scope.requestBody.op_eye !== "OS") {
					result = result || !$scope.requestBody.alcon_toric.toric.price
				}
				if ($scope.left.toric === true && $scope.requestBody.op_eye !== "OD") {
					result = result || !$scope.requestBody.alcon_toric.toric.price
				}
			}

			if ($scope.lens_checked.pure_see_checked) {
				if ($scope.right.toric === true && $scope.requestBody.op_eye !== "OS") {
					result = result || !$scope.requestBody.pure_see.toric.price
				}
				if ($scope.left.toric === true && $scope.requestBody.op_eye !== "OD") {
					result = result || !$scope.requestBody.pure_see.toric.price
				}
			}

			if ($scope.lens_checked.vivity_checked) {
				if ($scope.right.toric === true && $scope.requestBody.op_eye !== "OS") {
					result = result || !$scope.requestBody.vivity.toric.price
				}
				if ($scope.left.toric === true && $scope.requestBody.op_eye !== "OD") {
					result = result || !$scope.requestBody.vivity.toric.price
				}
			}

			if ($scope.lens_checked.pan_optix_checked) {
				if ($scope.right.toric === true && $scope.requestBody.op_eye !== "OS") {
					result = result || !$scope.requestBody.pan_optix.toric.price
				}
				if ($scope.left.toric === true && $scope.requestBody.op_eye !== "OD") {
					result = result || !$scope.requestBody.pan_optix.toric.price
				}
			}

			if ($scope.lens_checked.galaxy_checked) {
				if ($scope.right.toric === true && $scope.requestBody.op_eye !== "OS") {
					result = result || !$scope.requestBody.galaxy.toric.price
				}
				if ($scope.left.toric === true && $scope.requestBody.op_eye !== "OD") {
					result = result || !$scope.requestBody.galaxy.toric.price
				}
			}

			if ($scope.lens_checked.clearview_checked) {
				if ($scope.right.toric === true && $scope.requestBody.op_eye !== "OS") {
					result = result || !$scope.requestBody.clearview.toric.price
				}
				if ($scope.left.toric === true && $scope.requestBody.op_eye !== "OD") {
					result = result || !$scope.requestBody.clearview.toric.price
				}
			}

			return result
		}

		$scope.submit = function () {
			const body = transformBody($scope.requestBody)
			$scope.PL.isProcessing = true;
			$scope.PL.submitPrice(body).then(function (response) {
				fetchEmailHistory();
				$scope.dialogMessage = "Email has been sent to the patient";
				$scope.successModal = $modal({
					scope: $scope,
					templateUrl: "app/src/views/templates/modal.generic-success.tpl.html",
					show: true,
					title: "Success",
				});
			},
				function (error) {
					showErrorModal(error);
				}).then(function () {
					$scope.PL.isProcessing = false;
				})
		}

		function merge(left, right) {
			return { ...left, ...right };
		}

		function mergeGlaucomaFactor(left, right) {
			return left.glaucoma === "YES" ? left : right;
		}

		function mergeRiskFactor(left, right) {
			const result = { ...left };
			if (right.fuchs_guttata !== "NO") {
				result.fuchs_guttata = right.fuchs_guttata;
			}
			if (right.corneal_scarring !== "NO") {
				result.corneal_scarring = right.corneal_scarring;
			}
			return result;
		}

		function mergePrevOcularFactor(left, right) {
			return left.corneal_transplant ? left : right
		}

		function mergeAMDFactor(left, right) {
			const result = { ...left };
			if (right.amd !== "NO") {
				result.amd = right.amd
			}
			if (right.epiretinal_membrane !== "NO") {
				result.epiretinal_membrane = right.left.epiretinal_membrane;
			}
			return result;
		}

		$scope.formatRiskFactor = function () {

			let risk_factor = null;
			let glaucoma_factor = null;
			let amd_factor = null;
			let uveitis_factor = null;
			let prev_ocular_factor = null;
			let result = "";
			if (PreOp.data.ophthalmic_section.ophthalmic_risk_factors.od && $scope.requestBody.op_eye === 'OD') {
				risk_factor = PreOp.data.ophthalmic_section.ophthalmic_risk_factors.od;
				glaucoma_factor = PreOp.data.ophthalmic_section.glaucoma.od;
				amd_factor = PreOp.data.ophthalmic_section.amd.od;
				uveitis_factor = PreOp.data.ophthalmic_section.uveitis.od;
				prev_ocular_factor = PreOp.data.ophthalmic_section.prev_ocular.od;
			} else if (PreOp.data.ophthalmic_section.ophthalmic_risk_factors.os && $scope.requestBody.op_eye === 'OS') {
				risk_factor = PreOp.data.ophthalmic_section.ophthalmic_risk_factors.os;
				glaucoma_factor = PreOp.data.ophthalmic_section.glaucoma.os;
				amd_factor = PreOp.data.ophthalmic_section.amd.os;
				uveitis_factor = PreOp.data.ophthalmic_section.uveitis.os;
				prev_ocular_factor = PreOp.data.ophthalmic_section.prev_ocular.os;
			} else if ($scope.requestBody.op_eye === 'OU') {
				risk_factor = mergeRiskFactor(PreOp.data.ophthalmic_section.ophthalmic_risk_factors.os, PreOp.data.ophthalmic_section.ophthalmic_risk_factors.od);
				glaucoma_factor = mergeGlaucomaFactor(PreOp.data.ophthalmic_section.glaucoma.os, PreOp.data.ophthalmic_section.glaucoma.od);
				amd_factor = mergeAMDFactor(PreOp.data.ophthalmic_section.amd.os, PreOp.data.ophthalmic_section.amd.od);
				uveitis_factor = merge(PreOp.data.ophthalmic_section.uveitis.os, PreOp.data.ophthalmic_section.uveitis.od);
				prev_ocular_factor = mergePrevOcularFactor(PreOp.data.ophthalmic_section.prev_ocular.os, PreOp.data.ophthalmic_section.prev_ocular.od);
			}

			if ($scope.hasClinicalConsideration2) {
				result = result + "irregular astigmatism, "
			}

			if (risk_factor.fuchs_guttata && risk_factor.fuchs_guttata !== "NO") {
				result = result + "guttata, "
			}
			if (risk_factor.corneal_scarring && risk_factor.corneal_scarring !== "NO") {
				result = result + "corneal opacity, "
			}
			if (risk_factor.amblyopia && risk_factor.amblyopia !== "NO") {
				result = result + "amblyopia, "
			}

			if (glaucoma_factor.glaucoma && glaucoma_factor.glaucoma !== "NO") {
				result = result + "glaucoma, "
			}

			if (amd_factor.amd && amd_factor.amd !== "NO") {
				result = result + "AMD, "
			}
			if (amd_factor.epiretinal_membrane && amd_factor.epiretinal_membrane !== "NO") {
				result = result + "ERM, "
			}

			if (uveitis_factor.prev_uveitis && uveitis_factor.prev_uveitis !== "NO") {
				result = result + "uveitis, "
			}

			if (prev_ocular_factor.corneal_transplant && prev_ocular_factor.corneal_transplant !== "NO") {
				result = result + "prev corneal transplant, "
			}

			if (PreOp.data.ophthalmic_section.ophthalmic_risk_factors.pseudoexfoliation &&
				PreOp.data.ophthalmic_section.ophthalmic_risk_factors.pseudoexfoliation !== "NO") {
				result = result + "PXF, "
			}

			if (PreOp.data.ophthalmic_section.diabetes.diabetes_status &&
				PreOp.data.ophthalmic_section.diabetes.diabetes_status === "YES_T1DM") {
				result = result + "T1DM."
			}

			if (PreOp.data.ophthalmic_section.diabetes.diabetes_status &&
				PreOp.data.ophthalmic_section.diabetes.diabetes_status === "YES_T2DM") {
				result = result + "T2DM."
			}

			if (result.trim().slice(-1) === ",") {
				result = result.trim().substr(0, result.trim().length - 1) + "."
			}



			return result;
		}
		$scope.isToricMonofocalDisabled = function () {
			let value = false
			if ($scope.requestBody.op_eye === 'OU') {
				value = $scope.right.toric === false && $scope.left.toric === false;
			} else if ($scope.requestBody.op_eye === 'OD') {
				value = $scope.right.toric === false;
			} else {
				value = $scope.left.toric === false;
			}
			if (value) {
				$scope.lens_checked.monofocal_toric_checked = false;
				$scope.lens_checked.rayone_checked = false;
				$scope.lens_checked.lentis_checked = false;
				// $scope.lens_checked.eyhance_checked = false;
			}
			return value;
		}

		$scope.$watch("$ctrl.eye", function (newValue, oldValue) {
			if (newValue) {
				if (newValue === "RIGHT") {
					$scope.requestBody.op_eye = "OD";
				} else if (newValue === "BOTH") {
					$scope.requestBody.op_eye = "OU";
				} else if (newValue === "LEFT") {
					$scope.requestBody.op_eye = "OS";
				}
				checkHasClinicalConsideration(PreOp.data.ophthalmic_section);
				checkHasClinicalConsideration2($scope.$ctrl.biometry);
				checkToricRecommend($scope.$ctrl.biometry);
				if (!$scope.isToricMonofocalDisabled()) {
					$scope.lens_checked.monofocal_toric_checked = true;
					$scope.lens_checked.rayone_checked = true;
					$scope.lens_checked.lentis_checked = true;
				}
			}
		}, true);

		$scope.$watch("PreOp.data.ophthalmic_section", function (newValue, oldValue) {
			if (newValue) {
				checkHasClinicalConsideration(newValue);
			}
		}, true)

		$scope.$watch("$ctrl.biometry", function (newValue, oldValue) {
			if (newValue) {
				checkHasClinicalConsideration2(newValue);
				checkToricRecommend(newValue);
			}
		}, true);

		$scope.$watch("right.toric", function (newValue, oldValue) {
			if (newValue === true && $scope.isToricMonofocalSuggested) {
				$scope.lens_checked.monofocal_toric_checked = true;
				$scope.lens_checked.rayone_checked = true;
				$scope.lens_checked.lentis_checked = true;
			}
		}, true);

		$scope.$watch("left.toric", function (newValue, oldValue) {
			if (newValue === true && $scope.isToricMonofocalSuggested) {
				$scope.lens_checked.monofocal_toric_checked = true;
				$scope.lens_checked.rayone_checked = true;
				$scope.lens_checked.lentis_checked = true;
			}
		}, true);

		$scope.$watch("hasClinicalConsideration2", function (newValue, oldValue) {
			if (newValue) {
				$scope.lens_checked.synergy_checked = false;
				$scope.lens_checked.symfony_checked = false;
			} else {
				$scope.lens_checked.synergy_checked = true;
				$scope.lens_checked.symfony_checked = true;
			}
		}, true);

		$scope.$watch("hasClinicalConsideration", function (newValue, oldValue) {
			if (newValue) {
				$scope.lens_checked.synergy_checked = false;
				$scope.lens_checked.symfony_checked = false;
			} else {
				$scope.lens_checked.synergy_checked = true;
				$scope.lens_checked.symfony_checked = true;
			}
		}, true);

		$scope.$watch("isToricMonofocalSuggested", function (newValue, oldValue) {
			if (newValue && $scope.requestBody.op_eye === "OS" && $scope.left.toric === true) {
				$scope.lens_checked.monofocal_toric_checked = true;
				$scope.lens_checked.rayone_checked = true;
				$scope.lens_checked.lentis_checked = true;
			} else if (newValue && $scope.requestBody.op_eye === "OD" && $scope.right.toric === true) {
				$scope.lens_checked.monofocal_toric_checked = true;
				$scope.lens_checked.rayone_checked = true;
				$scope.lens_checked.lentis_checked = true;
			} else if (newValue && $scope.requestBody.op_eye === "OU" && ($scope.right.toric === true && $scope.left.toric === true)) {
				$scope.lens_checked.monofocal_toric_checked = true;
				$scope.lens_checked.rayone_checked = true;
				$scope.lens_checked.lentis_checked = true;
			}
		}, true);
	},
]);
