app.component("consultTimer", {
	templateUrl: "app/src/js/components/patientDashboard/consultTimer.html",
	controller: "consultTimerController",
});

app.controller("consultTimerController", [
	"$scope",
	"CurrentPatient",
	"Session",
	"AppointmentAPI",
	function ($scope,CurrentPatient,Session, AppointmentAPI) {
		const STATE_NOT_START = 1;
		const STATE_IN_PROGRESS = 2;
		const STATE_FINISH = 3;


		const OPTOMETRIST = 1;
		const TECHNICIAN = 2;
		const NURSE = 3;
		const DOCTOR = 4;

		let lastTimestamp = 0;
		$scope.CP = CurrentPatient;
		$scope.API = AppointmentAPI;
		let timerId = null;
		let savedPatientAppointment = null;

		function finishAppointmentInSilent() {
			const body = {...savedPatientAppointment};

			if(!body.patient_seen){
				body.patient_seen = {};
			}

			if ($scope.userRole === OPTOMETRIST) {
				body.patient_seen.optometrist_finished = true;
				body.patient_seen.optometrist_finished_timestamp = new Date().toISOString();
			} else if ($scope.userRole === TECHNICIAN) {
				body.patient_seen.technician_finished = true;
				body.patient_seen.technician_finished_timestamp = new Date().toISOString();
			} else if ($scope.userRole === NURSE) {
				body.patient_seen.nurse_finished = true;
				body.patient_seen.nurse_finished_timestamp = new Date().toISOString();
			} else if ($scope.userRole === DOCTOR) {
				body.patient_seen.doctor_finished = true;
				body.patient_seen.doctor_finished_timestamp = new Date().toISOString();
			}

			$scope.API.updateAppointment(
				savedPatientAppointment.patient_id,
				savedPatientAppointment.patient_appointment_id,
				body
			);
		}

		function updateAppointment(isStart) {
			if(!$scope.CP.patient.appointment){
				return;
			}

			const body = {...$scope.CP.patient.appointment};

			if(!body.patient_seen){
				body.patient_seen = {};
			}

			if ($scope.userRole === OPTOMETRIST) {
				if(isStart){
					body.patient_seen.optometrist_started = true;
					body.patient_seen.optometrist_started_timestamp = new Date().toISOString();
					body.patient_seen.optometrist_finished = false;
					body.patient_seen.optometrist_finished_timestamp = null;
				}else {
					body.patient_seen.optometrist_finished = true;
					body.patient_seen.optometrist_finished_timestamp = new Date().toISOString();
				}
			} else if ($scope.userRole === TECHNICIAN) {
				if(isStart){
					body.patient_seen.technician_started = true;
					body.patient_seen.technician_started_timestamp = new Date().toISOString();
					body.patient_seen.technician_finished = false;
					body.patient_seen.technician_finished_timestamp = null;
				}else{
					body.patient_seen.technician_finished = true;
					body.patient_seen.technician_finished_timestamp = new Date().toISOString();
				}
			} else if ($scope.userRole === NURSE) {
				if(isStart){
					body.patient_seen.nurse_started = true;
					body.patient_seen.nurse_started_timestamp = new Date().toISOString();
					body.patient_seen.nurse_finished = false;
					body.patient_seen.nurse_finished_timestamp = null;
				}else {
					body.patient_seen.nurse_finished = true;
					body.patient_seen.nurse_finished_timestamp = new Date().toISOString();
				}
			} else if ($scope.userRole === DOCTOR) {
				if(isStart){
					body.patient_seen.doctor_started = true;
					body.patient_seen.doctor_started_timestamp = new Date().toISOString();
					body.patient_seen.doctor_finished = false;
					body.patient_seen.doctor_finished_timestamp = null;
				}else{
					body.patient_seen.doctor_finished = true;
					body.patient_seen.doctor_finished_timestamp = new Date().toISOString();
				}

			}

			$scope.API.updateAppointment(
				$scope.CP.patient.appointment.patient_id,
				$scope.CP.patient.appointment.patient_appointment_id,
				body
			).then(
				function (resp) {
					$scope.CP.patient.appointment = angular.copy(resp.data);
					savedPatientAppointment = angular.copy($scope.CP.patient.appointment);
					checkAppointmentInProgress($scope.CP.patient.appointment);
				},
				function (error) {
					console.error(error);
				}
			);
		}


		function checkUserRole() {
			if (Session.user.roles.some((t) => t === "OPTOMETRIST")) {
				$scope.userRole = OPTOMETRIST;
			} else if (Session.user.roles.some((t) => t === "TECHNICIAN")) {
				$scope.userRole = TECHNICIAN;
			} else if (Session.user.roles.some((t) => t === "REGISTERED_NURSE" || t === "CLINICAL_NURSE_SPECIALIST")) {
				$scope.userRole = NURSE;
			} else if (Session.user.roles.some((t) => t === "REGISTRAR" || t === "OPHTHALMOLOGIST")) {
				$scope.userRole = DOCTOR;
			}
		}

		function setAppointmentState(start,finish){
			if(!start && !finish){
				$scope.state = STATE_NOT_START;
			}else if(start && !finish){
				lastTimestamp = new Date(start);
				$scope.state = STATE_IN_PROGRESS;
			}else if(finish){
				$scope.state = STATE_FINISH;
			}
			
		}
	
		const timeoutTask = function () {
			const SECONDS = Math.floor((Date.now() - lastTimestamp) / 1000);
			$scope.$apply(() => ($scope.durationText = new Date(SECONDS * 1000).toISOString().slice(11, 19)));
		};

		const checkAppointmentInProgress = function (appointment){
			const seen = appointment.patient_seen;
			if(!seen){
				$scope.state = STATE_NOT_START;
				return;
			}
			if ($scope.userRole === OPTOMETRIST) {
				setAppointmentState(seen["optometrist_started_timestamp"], seen["optometrist_finished_timestamp"]);
			} else if ($scope.userRole === TECHNICIAN) {
				setAppointmentState(seen["technician_started_timestamp"],seen["technician_finished_timestamp"]);
			} else if ($scope.userRole === NURSE) {
				setAppointmentState(seen["nurse_started_timestamp"], seen["nurse_finished_timestamp"]);
			} else if ($scope.userRole === DOCTOR) {
				setAppointmentState(seen["doctor_started_timestamp"],seen["doctor_finished_timestamp"]);
			}
		}

	
		function onInit() {
			$scope.state = STATE_NOT_START;
			$scope.userRole = null;
			checkUserRole();

			if ($scope.CP.patient.appointment) {
				checkAppointmentInProgress($scope.CP.patient.appointment);
				savedPatientAppointment = angular.copy($scope.CP.patient.appointment);
			}

			$scope.durationText = "";

			if ($scope.state === STATE_IN_PROGRESS) {
				timerId = setInterval(timeoutTask, 1000);
			}
		}

		this.$onInit = function () {
			onInit();
		};

		this.$onDestroy = function () {
			if(timerId) {
				clearInterval(timerId);
				timerId = null;
			}	
			setTimeout(()=>{
				if(savedPatientAppointment && $scope.state === STATE_IN_PROGRESS){
					finishAppointmentInSilent();
				}
			},100);
			
		}

		$scope.$watch("CP.patient.appointment", function (newValue, oldValue) {
			if (newValue) {
				if(!oldValue || newValue.patient_appointment_id !== oldValue.patient_appointment_id){
					onInit();
				}
			} 
		}, true);

		

		$scope.getButtonText = function() {
           switch($scope.state){
			case STATE_NOT_START:
			case STATE_FINISH:
				return 'Start consult';
			case STATE_IN_PROGRESS:
				return 'Finish consult';
			default:
				return ''
		   }
		}

		$scope.clickButton = function() {
			if($scope.state === STATE_NOT_START || $scope.state === STATE_FINISH){
				$scope.state = STATE_IN_PROGRESS;
				lastTimestamp = Date.now();
				timerId = setInterval(timeoutTask, 1000);
				updateAppointment(true);
			}else if($scope.state === STATE_IN_PROGRESS){
				$scope.state = STATE_FINISH;
				lastTimestamp = 0;
				clearInterval(timerId);
				timerId = null;
				updateAppointment(false);
			}
		}
	},])