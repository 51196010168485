app.controller("adminController", [
	"$scope",
    "$state",
    "$stateParams",
    "Session",
	function ($scope, $state, $stateParams, Session) {
        $scope.isAdminRole = Session.user.roles.includes("ADMIN");
        if(!$stateParams.type || !$scope.isAdminRole){
            $state.go("welcome");
            return;
        }
        $scope.consoleType = $stateParams.type;
        // $scope.$ctrl.consoleType = $stateParams.type;
	},
]);
