app.component("searchDashboard", {
	templateUrl: "app/src/js/components/searchDashboard/searchDashboard.html",
	controller: "searchDashboardController",
	bindings: {
		reload: "=",
	},
});

app.controller("searchDashboardController", [
	"$scope",
	"CurrentReferral",
	"ReferralsAPI",
	"NHI",
	"filterFilter",
	"$timeout",
	"Session",
	"$rootScope",
	"PatientAPI",
	"$modal",
	"CurrentPatient",
	"$state",
	"MEDICAL_INSURANCE",
	"NHISearchPatientsAPI",
	"PlaceAPI",
	"NgTableParams",
	"NHI_SERVICE_ERROR",
	"CONFIG",
	"$mdDialog",
	"$stateParams",
	"NHI_VERSION_V2",
	function (
		$scope,
		CurrentReferral,
		ReferralsAPI,
		NHI,
		filterFilter,
		$timeout,
		Session,
		$rootScope,
		PatientAPI,
		$modal,
		CurrentPatient,
		$state,
		MEDICAL_INSURANCE,
		NHISearchPatientsAPI,
		PlaceAPI,
		NgTableParams,
		NHI_SERVICE_ERROR,
		CONFIG,
		$mdDialog,
		$stateParams,
		NHI_VERSION_V2
	) {
		$scope.PA = PatientAPI;
		$scope.CP = CurrentPatient;
		$scope.CR = CurrentReferral;
		$scope.RA = ReferralsAPI;
		$scope.NHIAPI = NHISearchPatientsAPI;
		$scope.insuranceProvider = MEDICAL_INSURANCE;
		$scope.PlaceAPI = PlaceAPI;
		$scope.Session = Session;
		$scope.formData = {};
		$scope.CPIdx;
		$rootScope.selectedPatient = {};
		$scope.showingDate = {};
		$scope.display = {};
		$scope.display.address = {};
		$scope.addressError = false;
		$scope.regionWarningHasBeenShownOnce = false;
		$scope.currentYear = new Date().getFullYear();
		$scope.showingWarning = false;
		$scope.hideSaveButton = false;
		$scope.userIsPracticeAdmin = false;
		$scope.optionalPatients = [];
		$scope.isNHIFeatureEnabled = false;
		$scope.favoritePatientList = [];
		$scope.showWorkupButton = false;
		$scope.CALENDAR_ENABLE = false;
		$scope.isTaskFeatureEnabled = Session.practice.practiceFeatures.sendTasksWithinPracticeFeature;

		this.$onInit = function () {
			angular.element(document.querySelector("#lastName")).focus();
			$scope.NHI_VERSION_V2 = NHI_VERSION_V2;
			$scope.advancedPatientSearchFeature = Session.user.features.advancedPatientSearchFeature;
			$scope.fhirNhiFeature = Session.practice.practiceFeatures.fhirNhiFeature;
			$scope.legacyTable = false;
			$scope.isFullSearch = true; 
		};

		$scope.toggleFullSearch = function (){
			$scope.isFullSearch = true; 
		}

		$scope.toggleNHISearch = function (){
			$scope.isFullSearch = false; 
			$scope.formData = {
				lastName: '',
				middleName: '',
				firstName: '',
				DD: '',
				MM: '',
				YYYY: '',
				terms: '',
				nhi: ''
			};
		}

		if(Session.practice.practiceFeatures && Session.practice.practiceFeatures.calendarFeature === true){
			$scope.CALENDAR_ENABLE = true;
		}

		$scope.createTask = function (event,patient) {
			event.preventDefault();
			event.stopPropagation();
			
			$mdDialog.show({
				controller: "createTaskController",
				templateUrl: "app/src/views/templates/create-task.tmpl.html",
				parent: angular.element(document.body),
				clickOutsideToClose: false,
				scope: $scope.$new(true),
				preserveScope: false,
				onComplete: (scope, element) => {
					element.prepend('<span id="modal-close-btn" class="glyphicon glyphicon-remove dismiss-close-icon pointer"></span>');
					$("#modal-close-btn").click(function (event) {
						$mdDialog.hide();
					});
				},
				resolve: {
					// accounts: () => $scope.accounts,
					patientName: () => patient.firstName + ' ' + patient.lastName,
					dob: () => patient.dateOfBirth,
					patientId: () => patient.id,
					taskList: () => false,
					createNewTask: () => null,
					updateBadge: () => null,
					fetchTask: () => null,
					defaultAssigner: () => null,
				},
			});
		};

		$scope.clickCalendar = function(event,patient) {
			event.preventDefault();
			event.stopPropagation();
			// $scope.loaded = false;
			$mdDialog
			.show({
				controller: 'calendarController',
				templateUrl: "app/src/views/templates/calendar.tmpl.html",
				// Appending dialog to document.body to cover sidenav in docs app
				// Modal dialogs should fully cover application to prevent interaction outside of dialog
				parent: angular.element(document.body),
				targetEvent: event,
				clickOutsideToClose: true,
				openFrom: "#anchor",
				closeTo: "#anchor",
				scope: $scope.$new(true),
				preserveScope: false,
				// onComplete: () => ($scope.loaded = true),
				// onRemoving: () => ($scope.loaded = false),
				resolve: {
					patientId: () => patient.id,
					patientName: () => patient.firstName + ' ' + patient.lastName,
					selectedAppointment: () => null
				}
			})
			.then(
				function (answer) {
					//   $scope.status = 'You said the information was "' + answer + '".';
				},
				function () {
					//   $scope.status = 'You cancelled the dialog.';
				}
			);
		}

		if($scope.Session.user.features && $scope.Session.user.features.favoritePatientsFeature){
			$scope.favoritePatientList = $scope.Session.user.features.favoritePatientsFeature;
		}

		if (
			Session.practice.practiceFeatures &&
			(Session.practice.practiceFeatures.nhiFeature === true || Session.practice.practiceFeatures.fhirNhiFeature === true)
		) {
			$scope.isNHIFeatureEnabled = true;
		}

		$scope.popoverDormantNHI = function(obj) {
			return {
				title: 'Dormant NHI',
				content:
				obj.dormantIdentifiers.join(),
			};
		} 

		// Clear Session values for CurrentPatient and Current Referral


		if ($scope.Session.user.roles.length === 1 && $scope.Session.user.roles[0] === "PRACTICE_ADMIN") {
			$scope.userIsPracticeAdmin = true;
		}

		if (Session.user.roles[0] === "OPTOMETRIST" && Session.practice.type === "OPTOMETRY") {
			$scope.showWorkupButton = false;
		} else {
			$scope.showWorkupButton = true;
		}

		if ($scope.CP.patients.length > 0 && $scope.CP.query) {
			$timeout(function () {
				$scope.formData = {
					lastName: $scope.CP.query.lastName,
					DD: $scope.CP.query.DD,
					MM: $scope.CP.query.MM,
					YYYY: $scope.CP.query.YYYY,
					terms: $scope.CP.query.terms,
				};
				$scope.searchResults = $scope.CP.patients.map(t=>({...t,practiceId:t.practice.id}));
				if($scope.formData.terms){
					$scope.advanced_search && $scope.advanced_search.$setSubmitted();
				}else{
					$scope.basic_search && $scope.basic_search.$setSubmitted();
				}
				
				$scope.practiceFilter = $scope.searchResults.reduce(function (accumulator, currentValue) {
					if (!accumulator.some(t => t.title === currentValue.practice.name)) {
						accumulator.push({ id: currentValue.practice.id, title: currentValue.practice.name })
					}
					return accumulator
				}, [{ id: '', title: 'All' }])
				$scope.tableParams = new NgTableParams({
					count: 20
				}, {
					counts: [],
					// filterOptions: { filterComparator: applyComparer },
					dataset: $scope.searchResults,
				});
				// const index = $scope.searchResults.findIndex(t => t.id === $scope.CP.patient.id)
				// $scope.selectPatient($scope.searchResults[0], 0);
			}, 0);
		} else {
			// if(!$stateParams.isFromCalendar){
				$scope.CP.clearPatient();
				$scope.CR.clearReferral();
			// }
		}

		function fetchCattraxPatients() {
			let dob = '';
			if($scope.formData.YYYY && $scope.formData.MM && $scope.formData.DD){
				dob = $scope.formData.YYYY + "/" + $scope.formData.MM + "/" + $scope.formData.DD;
			}
			let endpoint;
			if(dob && $scope.formData.lastName){
				endpoint = $scope.PA.search($scope.formData.lastName, dob, '','')
			}else if($scope.formData.nhi){
				endpoint = $scope.PA.search('','','',$scope.formData.nhi)
			}
			if(endpoint){
				$scope.PA.isProcessing = true;
				endpoint.then(function (resp){
					if(resp.data.length === 0){
						$scope.isAdvancedSearch = true;
						$scope.fetchNHIPatients();
					}else{
						$scope.searchResults = resp.data.map(t=>({...t,practiceId:t.practice.id}));
                        $scope.practiceFilter = $scope.searchResults.reduce(function (accumulator, currentValue) {
							if (!accumulator.some(t => t.title === currentValue.practice.name)) {
								accumulator.push({ id: currentValue.practice.id, title: currentValue.practice.name })
							}
							return accumulator
						}, [{ id: '', title: 'All' }])
						
						$scope.tableParams = new NgTableParams({
							count: 20
						}, {
							counts: [],
							// filterOptions: { filterComparator: applyComparer },
							dataset: $scope.searchResults,
						});
						$scope.CP.setPatientResult(resp.data, $scope.formData);
					}
				},function (error){
					console.error(error);
				}).then(function(){
					$scope.PA.isProcessing = false;
				})
			}
		}

		$scope.fetchNHIPatients = function () {
			if(!$scope.isNHIFeatureEnabled){
				return;
			}
			$scope.NHIAPI.isProcessing = true;
			let dob = '';
			if($scope.formData.YYYY && $scope.formData.MM && $scope.formData.DD){
				dob = $scope.formData.YYYY + "/" + $scope.formData.MM + "/" + $scope.formData.DD;
			}
			var lastName = $scope.formData.lastName;
			$scope.advanced_search && $scope.advanced_search.$setPristine();
			$scope.formData.terms = '';

			let endpoint;

			if($scope.formData.nhi){
				endpoint = $scope.NHIAPI.searchAllNHIPatientsV2($scope.formData.nhi)
			}else {
				endpoint = $scope.NHIAPI.queryPatientsV2(lastName,dob,$scope.formData.firstName, $scope.formData.middleName)
			}


			endpoint.then(
				function (resp) {
					let data = resp.data;
					if(!Array.isArray(data)){
                       data = [data];
					}
					$scope.optionalPatients = data.map(t => ({ ...t, 
						firstName: t.names[0].firstName,
						middleName: t.names[0].middleName,
						lastName: t.names[0].lastName,
						expanded: false,
						codedEthnicities:  angular.copy(t.ethnicities)
						// practiceId: t.practice.id
					 }))
					$scope.tableOptionalPatientsParams = new NgTableParams({
						count: 20
					}, {
						counts: [],
						dataset: $scope.optionalPatients
					});
					modalForNHI();

				},
				function (err) {
					console.log(`${JSON.stringify(err)}`);
					$scope.alertMessage = NHI_SERVICE_ERROR.body;
					$scope.serverErrorModal = $modal({
						scope: $scope,
						templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
						show: true,
						title: NHI_SERVICE_ERROR.title,
					});
				}
			).then(function () {
				$scope.NHIAPI.isProcessing = false;
			})
		}

		$scope.isAdvancedSearch = false;

		$scope.onBasicSubmit = function () {
			$scope.searchResults = [];
			$scope.isAdvancedSearch = false;
			$scope.formData.terms = '';
			$scope.advanced_search.$setPristine();
			fetchCattraxPatients();
		}

		$scope.onAdvancedSubmit = function () {
			$scope.isAdvancedSearch = true;
			$scope.PA.isProcessing = true;
			$scope.basic_search && $scope.basic_search.$setPristine();
			let searchTerms = $scope.formData.terms;
			$scope.formData.firstName = '';
			$scope.formData.lastName = '';
			$scope.formData.middleName = '';
			$scope.formData.DD = '';
			$scope.formData.MM = '';
			$scope.formData.YYYY = '';
			$scope.searchResults = [];
			
			$scope.PA.search('', '',searchTerms, '')
				.then(
					function (resp) {
						$scope.searchResults = resp.data.map(t=>({...t,practiceId:t.practice.id}));
                        $scope.practiceFilter = $scope.searchResults.reduce(function (accumulator, currentValue) {
							if (!accumulator.some(t => t.title === currentValue.practice.name)) {
								accumulator.push({ id: currentValue.practice.id, title: currentValue.practice.name })
							}
							return accumulator
						}, [{ id: '', title: 'All' }])
						
						$scope.tableParams = new NgTableParams({
							count: 20
						}, {
							counts: [],
							// filterOptions: { filterComparator: applyComparer },
							dataset: $scope.searchResults,
						});
						$scope.CP.setPatientResult(resp.data, $scope.formData);
						// if($scope.searchResults.length === 0 && $scope.isNHIFeatureEnabled){
						// 	$scope.showCreateModalV2();
						// }
					},
					function (err) {
						console.log(err);
					}
				)
				.then(function () {
					$scope.PA.isProcessing = false;
				});
		}

		$scope.resetSearch = function () {
			$scope.advanced_search && $scope.advanced_search.$setPristine();
			$scope.basic_search && $scope.basic_search.$setPristine();
			$scope.formData = {};
			$scope.searchResults = [];
			$scope.CP.clearPatientResult();
		};

		$scope.isFormEmpty = function(){
			if($scope.formData.lastName || $scope.formData.terms || $scope.formData.terms){
				return false;
			}else if($scope.formData.DD && $scope.formData.MM && $scope.formData.YYYY){
				return false;
			}
			return true;
		}

		$scope.selectPatient = function (patient, idx) {
			$scope.regionWarningHasBeenShownOnce = false;
			$scope.CPIdx = idx;
			$scope.CP.setPatient(patient);
			if (!$scope.CP.patientIsMine) {
				//need to transfer
				$scope.patientDetailsModal = $modal({
					scope: $scope,
					templateUrl: "app/src/views/templates/modal.patient-details.tpl.html",
					show: true,
					title: "PATIENT DETAILS",
				});
			}
		};

		// angular-strap modal close for unknown reason mutates $scope.CP.patient.smokerAnswer to null value when modal is closed
		$scope.showEditModal = function () {
			$scope.patientDetailsModal.hide();
			var smokingValue = $scope.CP.patient.smokerAnswer;
			$scope.editModal = $modal({
				scope: $scope,
				templateUrl: "app/src/views/templates/modal.edit.tpl.html",
				show: true,
				title: "EDIT PATIENT DETAILS",
				onHide: function () {
					$scope.CP.patient.smokerAnswer = smokingValue;
				},
			});
		};

		// When close the 'edit patient modal' if the data has been changed but not sent to the
		// server, this will refresh the patient object with clean data from the server
		$scope.closeEditModal = function () {
			// $scope.onSubmit();
			// $scope.editModal.hide();
		};

		$scope.showCreateModal = function () {
			var newSmokingValue;
			// resetFormExtras();
			$scope.CP.clearPatient();
			$scope.showingWarning = false;
			$scope.hideSaveButton = false;
			if (typeof $scope.formData.lastName === "string") {
				$scope.showingDate.DD = $scope.formData.DD;
				$scope.showingDate.MM = $scope.formData.MM;
				$scope.showingDate.YYYY = $scope.formData.YYYY;
				$scope.CP.patient.lastName = titleCase($scope.formData.lastName);
				$scope.CP.patient.dateOfBirth = makeDateString();
			}
			$scope.CP.createNewPatient();
		};

		$scope.selectOptionalPatient = function (patient, index) {
			$scope.optionalPatients.forEach(function (val) {
				val.expanded = false;
			})
			patient.expanded = true;
		}

		$scope.createNewPatientUI = function () {
			if ($scope.patientsOptionalModal) {
				$scope.patientsOptionalModal.hide();
			}
			$scope.CP.createNewPatient();
		}

		function formatAddress(address) {
			let formattedAddress = '';
			if (address.streetAddress) {
				formattedAddress += address.streetAddress + ',';
			}
			if (address.suburb) {
				formattedAddress += address.suburb + ',';
			}
			if (address.city) {
				formattedAddress += address.city;
			}
			return formattedAddress
		}

		function formatAddressPostCode(address){
			let formattedAddress = '';
			if(address.streetAddress){
				formattedAddress += address.streetAddress + ',';
			}
			if(address.suburb) {
				formattedAddress += address.suburb + ',';
			}
			if(address.city) {
				formattedAddress += address.city + ',';
			}
			if(address.postCode) {
				formattedAddress += address.postCode;
			}
			return formattedAddress
		}

		function extractApartmentNumber(addressObj) {
			const streetAddress = addressObj.streetAddress
			if (streetAddress.includes('/')) {
				addressObj.streetAddress = streetAddress.split('/')[1];
				addressObj.apartmentNumber = streetAddress.split('/')[0];
			}
		}

		$scope.presetPatient = function (patient) {
			$scope.showingDate.DD = patient.dateOfBirth.substr(8, 2);
			$scope.showingDate.MM = patient.dateOfBirth.substr(5, 2);
			$scope.showingDate.YYYY = patient.dateOfBirth.substr(0, 4);
			$scope.CP.patient.lastName = patient.names[0].lastName;
			$scope.CP.patient.dateOfBirth = makeDateString();
			$scope.CP.patient.firstName = patient.names[0].firstName;
			$scope.CP.patient.nhi = patient.nhi;
			$scope.CP.patient.gender = $scope.NHIAPI.transformGenderProp(patient.gender);
			$scope.CP.patient.isResident = patient.isResident;
			if (patient.contacts && patient.contacts.length > 0) {
				if (!patient.contacts[0].confidentialInformation) {
					$scope.CP.patient.phoneNumber = patient.contacts[0].phoneNumber
				}
			}
			if (CONFIG.NHI_ADDRESS && patient.addresses && patient.addresses.length > 0) {
				$scope.CP.patient.addresses = [{}];
				const address = patient.addresses[0];
				extractApartmentNumber(address);
				const add = formatAddressPostCode(address);

				$scope.PlaceAPI.getPlaceDetail(document.getElementById('hidden_address'), add, function (place) {
					if (place === null) {
						if ($scope.patientsOptionalModal) {
							$scope.patientsOptionalModal.hide();
						}
						$scope.CP.createNewPatientWithPreset();
						return;
					}
					$scope.CP.patient.addresses[0] = { type: "PHYSICAL", json: JSON.stringify(place) };
					$scope.CP.patient.addresses[0].line1 = place.formatted_address;
					$scope.CP.patient.addresses[0].apartmentNumber = address.apartmentNumber ? address.apartmentNumber : '';
					setTimeout(() => {
						if ($scope.patientsOptionalModal) {
							$scope.patientsOptionalModal.hide();
						}
						$scope.CP.createNewPatientWithPreset();
						$scope.$apply();
					}, 0);
				});
			}else{
				if ($scope.patientsOptionalModal) {
					$scope.patientsOptionalModal.hide();
				}
				$scope.CP.createNewPatientWithPreset();
			}
			
		}

		function composeDOB(dateOfBirth) {
			if($scope.fhirNhiFeature){
				$scope.showingDate.DD = parseInt(dateOfBirth[0]);
				$scope.showingDate.MM = parseInt(dateOfBirth[1]);
				$scope.showingDate.YYYY = parseInt(dateOfBirth[2]);
			}else{
				$scope.showingDate.DD = parseInt(dateOfBirth[2]);
				$scope.showingDate.MM = parseInt(dateOfBirth[1]);
				$scope.showingDate.YYYY = parseInt(dateOfBirth[0]);
			}
		}

		function getNZCitizenState(nzCitizen) {
			if(nzCitizen === 'YES'){
			   return true;
			}else if(nzCitizen === 'NO'){
			   return false;
			}
			return null;
		 }

		$scope.presetPatientV2 = function (patient) {
			const dateOfBirth = patient.dob.split('-');
			composeDOB(dateOfBirth);
			$scope.CP.patient.lastName = patient.familyNameCol;
			$scope.CP.patient.dateOfBirth = makeDateStringV2();
			$scope.CP.patient.firstName = patient.firstNameCol;
			$scope.CP.patient.nhi = patient.nhiCol;
			$scope.CP.patient.gender = $scope.NHIAPI.transformGenderProp(patient.gender);
			$scope.CP.patient.isResident = getNZCitizenState(patient.nzCitizen);
			$scope.CP.patient.codedEthnicities = patient.codedEthnicities;
			// if (patient.contacts && patient.contacts.length > 0) {
			// 	if (!patient.contacts[0].confidentialInformation && patient.contacts[0].phoneNumber) {
			// 		$scope.CP.patient.phoneNumber = patient.contacts[0].phoneNumber;
			// 	}
			// }
			if (CONFIG.NHI_ADDRESS && patient.addresses && patient.addresses.length > 0) {
				$scope.CP.patient.addresses = [{}];
				let address = patient.addresses.find(t=>t.addressType === 'PHYSICAL');

				if(!address){
					address = patient.addresses[0];
				}

				if(address && !address.addressCountry && !address.overseas){
					address.addressCountry = 'New Zealand';
				}
				// extractApartmentNumber(address);
				// const add = formatAddressPostCode(address);
				const add = address.addressLine.join(',') + ',' + address.addressCity + ',' + address.addressCountry + ',' + address.addressPostalCode;
            
				$scope.PlaceAPI.getPlaceDetail(document.getElementById('hidden_address'), add, function (place) {
					$scope.CP.patient.addresses[0] = {type: "PHYSICAL"};
					$scope.CP.patient.addresses[0].apartmentNumber = address.apartmentNumber ? address.apartmentNumber : '';
					$scope.display.address = angular.copy(place);
					$scope.display.apartmentNumber = $scope.CP.patient.addresses[0].apartmentNumber ? $scope.CP.patient.addresses[0].apartmentNumber : '';

					$scope.CP.patient.addresses[0].line1 = $scope.display.address.formatted_address;
					$scope.CP.patient.addresses[0].type = "PHYSICAL";
					($scope.CP.patient.addresses[0].line3 = null),
						($scope.CP.patient.addresses[0].line4 = null),
						($scope.CP.patient.addresses[0].apartmentNumber = $scope.display.apartmentNumber),
						($scope.CP.patient.addresses[0].json = JSON.stringify($scope.display.address));
		
					$scope.display.address.address_components.map(function (obj) {
						if (obj.types.indexOf("postal_code") !== -1) {
							$scope.CP.patient.addresses[0].postCode = obj.long_name;
						}
					});
		
					$scope.display.address.address_components.map(function (obj) {
						if (obj.types.indexOf("country") !== -1) {
							$scope.CP.patient.addresses[0].country = obj.long_name;
						}
					});
		
					$scope.display.address.address_components.map(function (obj) {
						if (obj.types.indexOf("locality") !== -1) {
							$scope.CP.patient.addresses[0].city = obj.long_name;
						}
					});
		
					$scope.display.address.address_components.map(function (obj) {
						if (obj.types.indexOf("sublocality") !== -1) {
							$scope.CP.patient.addresses[0].line2 = obj.long_name;
						}
					});
                
					setTimeout(()=>{
						$scope.$apply();
					},0);
					
				});
			}
			if ($scope.patientsOptionalModal) {
				$scope.patientsOptionalModal.hide();
			}
			$scope.CP.createNewPatientWithPreset();
		}

		function queryPatients(lastName, dateOfBirth) {
			return $scope.NHI_VERSION_V2 ? $scope.NHIAPI.queryPatientsV2(lastName, dateOfBirth) : $scope.NHIAPI.queryPatients(lastName, dateOfBirth);
		}

		function modalForNHI() {
			if($scope.NHI_VERSION_V2){
				$mdDialog.show({
					controller: "nhiRecordController",
					templateUrl: "app/src/views/templates/modal.select-patient-v2.tpl.html",
					parent: angular.element(document.body),
					clickOutsideToClose: false,
					scope: $scope.$new(true),
					preserveScope: false,
					onComplete: (scope, element) => {
						element.prepend('<span id="modal-close-btn" class="glyphicon glyphicon-remove dismiss-close-icon pointer"></span>');
						$("#modal-close-btn").click(function (event) {
							$mdDialog.hide();
						});
					},
					resolve: {
						optionalPatients: () => $scope.optionalPatients,
						callback: () => (nhiObj) => $scope.presetPatientV2(nhiObj),
						skipAction: () => () => {$scope.showCreateModal()}
					},
				});
			}else{
				$scope.patientsOptionalModal = $modal({
					scope: $scope,
					templateUrl: "app/src/views/templates/modal.select-patient.tpl.html",
					show: true,
					title: "SUGGESTED PATIENTS FROM THE NHI SERVICE",
					backdrop: "static",
					keyboard: false,
					onHide: function () {
						//TODO
						// $scope.resetSearch();
					},
				});
			}
		}

		$scope.showCreateModalV2 = function () {
			/** 
			if(!$scope.isNHIFeatureEnabled){
				$scope.showCreateModal();
				return;
			} */
			$scope.CP.clearPatient();
			$scope.showingWarning = false;
			$scope.hideSaveButton = false;
			if (typeof $scope.formData.lastName === "string") {
				$scope.showingDate.DD = $scope.formData.DD;
				$scope.showingDate.MM = $scope.formData.MM;
				$scope.showingDate.YYYY = $scope.formData.YYYY;
				$scope.CP.patient.lastName = titleCase($scope.formData.lastName);
				$scope.CP.patient.dateOfBirth = makeDateStringV2();
				$scope.PA.isProcessing = true;
				$scope.optionalPatients = [];
				$scope.clickedPatient = null;
				$scope.NHIAPI.isProcessing = true;
				queryPatients($scope.CP.patient.lastName, $scope.CP.patient.dateOfBirth)
					.then(
						function (resp) {
							if (resp.data.length === 0) {
								$scope.CP.createNewPatient();
								return;
							}
							$scope.optionalPatients = resp.data.map(t => ({ ...t, 
								firstName: t.names[0].firstName,
								middleName: t.names[0].middleName,
								lastName: t.names[0].lastName,
								expanded: false,
								// practiceId: t.practice.id
							 }))
							 $scope.practiceFilter = $scope.searchResults.reduce(function (accumulator, currentValue) {
								if (!accumulator.some(t => t.title === currentValue.practice.name)) {
									accumulator.push({ id: currentValue.practice.id, title: currentValue.practice.name })
								}
								return accumulator
							}, [{ id: '', title: 'All' }])
							$scope.tableOptionalPatientsParams = new NgTableParams({
							    count: 20
							}, {
								counts: [],
								dataset: $scope.optionalPatients
							});

							modalForNHI();
							// $scope.patientsOptionalModal = $modal({
							// 	scope: $scope,
							// 	templateUrl: modalTemplateUrl(),
							// 	show: true,
							// 	title: "SUGGESTED PATIENTS FROM THE NHI SERVICE",
							// 	backdrop: "static",
							// 	keyboard: false,
							// 	onHide: function () {
							// 		//TODO
							// 		// $scope.resetSearch();
							// 	},
							// });

						},
						function (err) {
							console.log(`${JSON.stringify(err)}`);
							$scope.alertMessage = NHI_SERVICE_ERROR.body;
							$scope.serverErrorModal = $modal({
								scope: $scope,
								templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
								show: true,
								title: NHI_SERVICE_ERROR.title,
							});
						}
					).then(function () {
						$scope.NHIAPI.isProcessing = false;
					})

			}

			// $scope.CP.createNewPatient();
		};

		$scope.trimLongText = function(text){
			if(text && text.length > 30){
				return text.substring(0, 27) + "...";
			}
			return text;
		}


		function titleCase(str) {
			var splitStr = str.toLowerCase().split(" ");
			for (var i = 0; i < splitStr.length; i++) {
				splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
			}
			return splitStr.join(" ");
		}

		$scope.registerPatient = function () {
			$scope.PA.isProcessing = true;
			$scope.CP.patient.practice = { id: Session.practice.id };
			$scope.CP.patient.dateOfBirth = makeDateString();
			$scope.CP.patient.ethnicities = $scope.selection;

			$scope.CP.patient.addresses = [{}];

			$scope.CP.patient.addresses[0].line1 = $scope.display.address.formatted_address;
			$scope.CP.patient.addresses[0].type = "PHYSICAL";
			($scope.CP.patient.addresses[0].line3 = null),
				($scope.CP.patient.addresses[0].line4 = null),
				($scope.CP.patient.addresses[0].apartmentNumber = $scope.display.apartmentNumber),
				($scope.CP.patient.addresses[0].json = JSON.stringify($scope.display.address));

			$scope.display.address.address_components.map(function (obj) {
				if (obj.types.indexOf("postal_code") !== -1) {
					$scope.CP.patient.addresses[0].postCode = obj.long_name;
				}
			});

			$scope.display.address.address_components.map(function (obj) {
				if (obj.types.indexOf("country") !== -1) {
					$scope.CP.patient.addresses[0].country = obj.long_name;
				}
			});

			$scope.display.address.address_components.map(function (obj) {
				if (obj.types.indexOf("locality") !== -1) {
					$scope.CP.patient.addresses[0].city = obj.long_name;
				}
			});

			$scope.display.address.address_components.map(function (obj) {
				if (obj.types.indexOf("sublocality") !== -1) {
					$scope.CP.patient.addresses[0].line2 = obj.long_name;
				}
			});

			$scope.removeEmptyStringValues();

			$scope.PA.create($scope.CP.patient)
				.then(
					function (resp) {
						$scope.CP.setPatient(resp.data);
					},
					function (err) {
						console.log(err);
						$scope.PA.isProcessing = false;
						if (err.data && err.data.status === 409) {
							// close modal
							$scope.createModal.hide();
							// show error modal
							$scope.duplicateModal = $modal({
								scope: $scope,
								templateUrl: "app/src/views/templates/modal.duplicate-patient.tpl.html",
								show: true,
								title: "DUPLICATE PATIENT",
								backdrop: "static",
								keyboard: false,
								onHide: function () {
									$scope.resetSearch();
								},
							});
						}
						throw new Error("failed to create patient");
					}
				)
				.then(function (obj) {
					$scope.PA.isProcessing = false;
					$scope.createModal.hide();
					$scope.patientDetailsModal = $modal({
						scope: $scope,
						templateUrl: "app/src/views/templates/modal.patient-details.tpl.html",
						show: true,
						title: "PATIENT DETAILS",
					});
				});
		};

		$scope.showTransferModal = function () {
			$scope.patientDetailsModal.hide();
			$scope.previousPracticeName = $scope.CP.patient.practice.name;
			$scope.transferModal = $modal({
				scope: $scope,
				templateUrl: "app/src/views/templates/modal.transfer.tpl.html",
				show: true,
				title: "TRANSFER PATIENT",
			});
		};

		$scope.transferPatient = function () {
			$scope.PA.isProcessing = true;
			$scope.PA.transfer($scope.CP.patient.id, Session.userId, Session.practice.id)
				.then(function (resp) {
					$scope.CP.setPatient(resp.data);
					$scope.searchResults[$scope.CPIdx] = resp.data;
				})
				.then(function () {
					$scope.PA.isProcessing = false;
					$scope.transferModal.hide();
					$scope.successModal = $modal({
						scope: $scope,
						templateUrl: "app/src/views/templates/modal.success.tpl.html",
						show: true,
						title: "SUCCESS",
						onHide: function () {
							//$scope.patientDetailsModal = $modal({
							// 	scope: $scope,
							// 	templateUrl: "app/src/views/templates/modal.patient-details.tpl.html",
							// 	show: true,
							// 	title: "PATIENT DETAILS",
							// });
						},
					});
				});
		};

		function makeDateString() {
			return $scope.showingDate.YYYY + "-" + $scope.showingDate.MM + "-" + $scope.showingDate.DD;
		}

		function makeDateStringV2() {
			return $scope.showingDate.YYYY + "/" + $scope.showingDate.MM + "/" + $scope.showingDate.DD;
		}

		$scope.$watch(
			"display",
			function (newValue, oldValue) {
				if ($scope.display instanceof Object) {
					$scope.addressError = false;
				} else {
					$scope.addressError = true;
				}
			},
			true
		);

		//reload search result
		$scope.$watch(
			"$ctrl.reload",
			function (newValue) {
				if (newValue && $scope.formData.YYYY && $scope.formData.MM && 
					$scope.formData.DD && $scope.formData.lastName) {
						$timeout(function () {
							angular.element(document.querySelector("#submit2")).trigger("click");
						}, 0);
						
				}
			},
			true
		);



		// $scope.autocompleteOptions = {
		// 	componentRestrictions: { country: "nz" },
		// 	types: ["geocode"],
		// };

		$scope.removeEmptyStringValues = function () {
			// Once text is entered and deleted into the phoneNumber, mobileNumber and email fields the value is set to an empty string.
			// Backend requires these values to pass the regex or be null
			if ($scope.CP.patient.phoneNumber === "") {
				$scope.CP.patient.phoneNumber = null;
			}
			if ($scope.CP.patient.mobileNumber === "") {
				$scope.CP.patient.mobileNumber = null;
			}
			if ($scope.CP.patient.email === "") {
				$scope.CP.patient.email = null;
			}
		};

		// This function name - startAgain(), can not be changed as it is called from the modal
		// template in two controllers(this one and examinationController).
		$scope.startAgain = function () {
			$scope.traceSuccessModal.hide();
			$scope.CP.clearPatient();
			$scope.CR.clearReferral();
			$scope.resetSearch();

			$timeout(function () {
				document.querySelector("#lastName").focus();
			}, 200);
		};

		$scope.formatDate = function (_date) {
			if(_date){
				const date = new Date(_date);
				return date.getDate()  + "-" + (date.getMonth()+1) + "-" + date.getFullYear();
			}
			return "";
		}

		$scope.isFavouritePatient = function (patient) {
			return $scope.favoritePatientList.some(t => t.patientId === patient.id);
		}

		$scope.addOrRemovePatient = function (patient, isAdd){
			if(isAdd){
				$scope.PA.addToFavourite(patient.id)
				.then(
					function (resp) {
						if (resp.status === 202) {
							$scope.Session.user.features.favoritePatientsFeature.push({
								patientId: patient.id,
							});
							$scope.favoritePatientList.push({ patientId: patient.id });
						}
					},
					function (err) {
						console.log(err);
					}
				)
				.then(function () {
					$scope.PA.isProcessing = false;
				});
			}else{
				$scope.PA.removeFavourite(patient.id)
				.then(
					function (resp) {
						if (resp.status === 202) {
							$scope.Session.user.features.favoritePatientsFeature =
								$scope.Session.user.features.favoritePatientsFeature.filter(t => t.patientId !== patient.id);
							$scope.favoritePatientList = $scope.favoritePatientList.filter(t => t.patientId !== patient.id);
						}
					},
					function (err) {
						console.log(err);
					}
				)
				.then(function () {
					$scope.PA.isProcessing = false;
				});
			}
		}

		// legacy
		$scope.onSubmit = function () {
			$scope.PA.isProcessing = true;
			var dob = $scope.formData.YYYY + "/" + $scope.formData.MM + "/" + $scope.formData.DD;
			var lastName = $scope.formData.lastName;

			$scope.PA.search(lastName, dob, '', '')
				.then(
					function (resp) {
						$scope.searchResults = resp.data;
						$scope.CP.setPatientResult(resp.data, $scope.formData);
						if($scope.searchResults.length === 0 && $scope.isNHIFeatureEnabled){
							$scope.showCreateModalV2();
						}
					},
					function (err) {
						console.log(err);
					}
				)
				.then(function () {
					$scope.PA.isProcessing = false;
				});
		};
	},
]);

