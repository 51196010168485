app.service('BillingAPI', ['$http', 'ENV', 'Session', function($http, ENV, Session) {

	var service = {
		isProcessing: false,
		getXeroLoginUrl: function () {
			return $http({
				method: "GET",
				url: ENV.API + "/api/billing/auth/xero",
				headers: {
					Authorization: "Bearer " + Session.token,
					"Content-Type": "text/plain;charset=UTF-8",
					"X-PRACTICE-ID": `${Session.practice.id}`,
				},
				transformResponse: [
					function (data) {
						return data;
					},
				],
			});
		},
		getXeroOrg: function () {
			return $http({
				method: "GET",
				url: ENV.API + "/api/billing/auth/xero/organisation",
				headers: {
					Authorization: "Bearer " + Session.token,
					"X-PRACTICE-ID": `${Session.practice.id}`,
				},
			});
		},
		setXeroOrg: function (data) {
			return $http({
				method: "PUT",
				url: ENV.API + "/api/billing/auth/xero/organisation",
				headers: {
					Authorization: "Bearer " + Session.token,
					"X-PRACTICE-ID": `${Session.practice.id}`,
				},
				data,
				transformResponse: [
					function (data) {
						return data;
					},
				],
			});
		},
		getLoggedIn: function () {
			return $http({
				method: "GET",
				url: ENV.API + "/api/billing/auth",
				headers: {
					Authorization: "Bearer " + Session.token,
					"X-PRACTICE-ID": `${Session.practice.id}`,
				},
			});
		},
		logoutXero: function () {
			return $http({
				method: "DELETE",
				url: ENV.API + "/api/billing/auth",
				headers: {
					Authorization: "Bearer " + Session.token,
					"Content-Type": "text/plain;charset=UTF-8",
					"X-PRACTICE-ID": `${Session.practice.id}`,
				},
				transformResponse: [
					function (data) {
						return data;
					},
				],
			});
		},
		getContact: function (patientId, term) {
			return $http({
				method: "GET",
				url: ENV.API + `/api/billing/contacts?patientId=${patientId}&term=${term}`,
				headers: {
					Authorization: "Bearer " + Session.token,
					"X-PRACTICE-ID": `${Session.practice.id}`,
				},
			});
		},
		registerContact: function (patientId) {
			return $http({
				method: "POST",
				url: ENV.API + `/api/billing/contacts?patientId=${patientId}`,
				headers: {
					Authorization: "Bearer " + Session.token,
					"X-PRACTICE-ID": `${Session.practice.id}`,
				},
			});
		},
		linkContract: function (patientId, contractId) {
			return $http({
				method: "PUT",
				url: ENV.API + `/api/billing/contacts?patientId=${patientId}&contactId=${contractId}`,
				headers: {
					Authorization: "Bearer " + Session.token,
					"X-PRACTICE-ID": `${Session.practice.id}`,
				},
			});
		},
		fetchBillingItems: function () {
			return $http({
				method: "GET",
				url: ENV.API + "/api/billing/items",
				headers: {
					Authorization: "Bearer " + Session.token,
					"X-PRACTICE-ID": `${Session.practice.id}`,
				},
			});
		},
		createInvoice: function (patientId, data) {
			return $http({
				method: "POST",
				url: ENV.API + `/api/billing/patient/${patientId}/invoices`,
				headers: {
					Authorization: "Bearer " + Session.token,
					"X-PRACTICE-ID": `${Session.practice.id}`,
				},
				data,
			});
		},
		getInvoices: function (patientId) {
			return $http({
				method: "GET",
				url: ENV.API + `/api/billing/patient/${patientId}/invoices`,
				headers: {
					Authorization: "Bearer " + Session.token,
					"X-PRACTICE-ID": `${Session.practice.id}`,
				},
			});
		},
		updateInvoice: function (patientId, invoice) {
			return $http({
				method: "PUT",
				url: ENV.API + `/api/billing/patient/${patientId}/invoices/${invoice.invoice_id}`,
				headers: {
					Authorization: "Bearer " + Session.token,
					"X-PRACTICE-ID": `${Session.practice.id}`,
				},
				data: invoice,
			});
		},
		sendInvoice: function (patientId, invoiceId) {
			return $http({
				method: "POST",
				url: ENV.API + `/api/billing/patient/${patientId}/invoices/${invoiceId}`,
				headers: {
					Authorization: "Bearer " + Session.token,
					"Content-Type": "text/plain;charset=UTF-8",
					"X-PRACTICE-ID": `${Session.practice.id}`,
				},
				transformResponse: [
					function (data) {
						return data;
					},
				],
			});
		},
		cancelInvoice: function (patientId, invoiceId) {
			return $http({
				method: "DELETE",
				url: ENV.API + `/api/billing/patient/${patientId}/invoices/${invoiceId}`,
				headers: {
					Authorization: "Bearer " + Session.token,
					"X-PRACTICE-ID": `${Session.practice.id}`,
				},
			});
		},
		updatePayment: function (patientId, invoiceId, payment) {
			return $http({
				method: "PUT",
				url: ENV.API + `/api/billing/patient/${patientId}/invoices/${invoiceId}/payment`,
				headers: {
					Authorization: "Bearer " + Session.token,
					"X-PRACTICE-ID": `${Session.practice.id}`,
				},
				data: payment
			});
		},
		downloadInvoice: function (patientId, invoiceId) {
			return $http({
				method: "GET",
				url: ENV.API + `/api/billing/patient/${patientId}/invoices/${invoiceId}/download`,
				headers: {
					Authorization: "Bearer " + Session.token,
					"X-PRACTICE-ID": `${Session.practice.id}`,
				},
				responseType: "arraybuffer",
			});
		},
		unlinkContact: function (patientId, contactId) {
			return $http({
				method: "DELETE",
				url: ENV.API + `/api/billing/contacts?patientId=${patientId}&contactId=${contactId}`,
				headers: {
					Authorization: "Bearer " + Session.token,
					"X-PRACTICE-ID": `${Session.practice.id}`,
				},
				transformResponse: [
					function (data) {
						return data;
					},
				],
			});
		},
	};

	return service;
}])
