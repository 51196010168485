app.component("procedureSearchBox", {
	templateUrl: "app/src/js/components/operationNote/eyeSection/procedureSearchBox.html",
	controller: "procedureSearchBoxController",
	bindings: {
		eyeTitle: "@",
        setProcedure: "&",
		label: "@",
		isRequired: "@",
		isMultiple: "@",
		isNonCataract: "=",
		procedureList: "<",
		procedure: "=",
	},
});

app.controller("procedureSearchBoxController", [
	"$scope",
    "DiagnosisAPI",
	function ($scope, DiagnosisAPI) {
		this.$onInit = function () {
            $scope.noResults = false;
            $scope.DiagnosisAPI = DiagnosisAPI;
			$scope.formData = {
				diagnosis: "",
                search_term: "",
				diagnosis_object: null,
				diagnosis_type: "ocular"
			};
			$scope.procedureList = [];
			$scope.warn = false;
			if($scope.$ctrl.procedure && !$scope.$ctrl.isMultiple){
				$scope.formData.diagnosis_object = $scope.$ctrl.procedure;
				$scope.formData.diagnosis = $scope.formData.diagnosis_object.fsn.term;
			}
			
		};
        $scope.loading = false;

		$scope.$watch("$ctrl.procedureList",function (newValue, oldValue) {
			if(newValue && newValue.length > 0){
				$scope.procedureList = newValue;
			}
		},
		true);

        $scope.$watch(
			"formData.diagnosis",
			function (newValue, oldValue) {
				if (newValue === '') {
					$scope.noResults = false;
				}

				if ($scope.formData.diagnosis_object && $scope.formData.diagnosis_object.fsn.term !== newValue) {
					$scope.formData.diagnosis_object = null;
					$scope.$ctrl.setProcedure({
						form: {
							diagnosis: $scope.formData.search_term,
							operative_eye: $scope.formData.operative_eye,
							diagnosis_object: null
						}
					});
				}
			},
			true
		);

        $scope.fetch = function (value) {
            $scope.loading = true;
            return new Promise((resolve, reject) => {
                searchDiagnosis(value, resolve, reject)
            })
        };


		function searchDiagnosis(terms, resolve, reject) {
			$scope.result = [];
            $scope.noResults = false;
			const isOcular = $scope.formData.diagnosis_type === 'ocular';
			$scope.DiagnosisAPI.searchDiagnosis(terms, isOcular,$scope.$ctrl.isNonCataract)
				.then(
					function (resp) {
						if (resp.data) {
							$scope.result=resp.data;
                            $scope.formData.search_term = terms;
							if(resp.data.length === 0){
								$scope.formData.diagnosis = '';
							}
							resolve($scope.result)
						}
					},
					function (err) {
						console.log(`${JSON.stringify(err)}`)
                        $scope.noResults = true;
                        $scope.result = [];
						$scope.formData.diagnosis = '';
						reject(err)
					}
				).then(function () {
					$scope.loading = false;
				})
		}

		$scope.addProcedure = function () {
			if ($scope.$ctrl.isMultiple && $scope.pendingItem) {
				$scope.procedureList.push($scope.pendingItem);
				$scope.$ctrl.setProcedure({form:$scope.procedureList});
				$scope.pendingItem = null;
				$scope.formData.diagnosis = null;
				$scope.warn = false;
			}
		}

		$scope.removeProcedure = function (index) {
			$scope.procedureList.splice(index, 1);
			$scope.$ctrl.setProcedure({form:$scope.procedureList});
		}

        $scope.diagnosisOnSelect = function(item) {
			if($scope.$ctrl.isMultiple){
				$scope.pendingItem = item;
				$scope.warn = true;
				return;
			}
			$scope.formData.diagnosis_object = item;
            $scope.$ctrl.setProcedure({form:{
                diagnosis: $scope.formData.search_term,
                // operative_eye: $scope.formData.operative_eye,
                diagnosis_object: item
            }});
        }
	},
]);