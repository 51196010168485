app.component("preOpPhone", {
	templateUrl: "app/src/js/components/preOp/preOpPhone/preOpPhone.html",
	controller: "preOpPhoneController",
	bindings: {
		hide: "&",
	},
});

app.controller("preOpPhoneController", [
	"$scope",
	"PreOp",
	"NHI",
	"CurrentPatient",
	"PatientAPI",
	"$timeout",
	"Session",
	function ($scope, PreOp, NHI, CurrentPatient, PatientAPI, $timeout, Session) {
		this.$onInit = function () {
			$scope.preop = PreOp;
			$scope.PA = PatientAPI;
			this.PA = PatientAPI;
			$scope.CP = CurrentPatient;
			$scope.formData = {
				phone: "",
			};
		};
	
		$scope.savePhone = function (phoneNumber) {
			$scope.CP.patient.phoneNumber = phoneNumber;
			if(!$scope.CP.patient.id) {
				$scope.$ctrl.hide();
				return;
			} 
			$scope.PA.isProcessing = true;
			$scope.PA.update($scope.CP.patient)
				.then(function (resp) {
					$scope.CP.setPatient(resp.data);
				})
				.then(function () {
					$scope.PA.isProcessing = false;
					$scope.$ctrl.hide();
					$scope.$ctrl.hideSecondaryNhiComponent();
				})
				.catch(function (err) {
					$scope.PA.isProcessing = false;
					console.log(err);
				});
		};
	},
]);
