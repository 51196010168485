app.component("calendarAvailability", {
	templateUrl: "app/src/js/components/calendarAvailability/calendarAvailability.html",
	controller: "calendarAvailabilityController",
	bindings: {
		
	},
});
app.controller("calendarAvailabilityController", [
	"$scope",
	"Session",
    "AppointmentAPI",
    "$modal",
	function ($scope, Session,AppointmentAPI,$modal) {
        $scope.items = [];
        $scope.form = {};
		this.$onInit = function () {
            $scope.Session = Session;
            $scope.API = AppointmentAPI;
            $scope.selectedPractice = Session.practice.id;
            $scope.practices = $scope.Session.practiceOptions;
            fetchAvailability();
		};

        $scope.practiceOnChange = function(){
			fetchAvailability();
        }



        function trimTime (time) {
            let hours = time.getHours() < 10 ? `0${time.getHours()}` : time.getHours();
            let minutes = time.getMinutes() < 10 ? `0${time.getMinutes()}` : time.getMinutes();
            return `${hours}:${minutes}:00`;
        }

        $scope.delete = function (uuid) {
			$scope.API.isProcessing = true;
			$scope.API.deletePracticeAvailability($scope.selectedPractice, uuid)
				.then(
					function () {
                        fetchAvailability();
                    },
					function (e) {
                        $scope.serverErrorModal = $modal({
							scope: $scope,
							templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
							show: true,
							title: "CatTrax has encountered an error",
						});
                    }
				)
				.then(() => ($scope.API.isProcessing = false));
		};

        $scope.addNew = function () {
			$scope.API.isProcessing = true;
			const data = {
				...$scope.form,
				recurrence_type: "ONE_OFF",
				availability_type: "UNAVAILABLE",
				start_time_local: trimTime($scope.form.start_time_local),
				end_time_local: trimTime($scope.form.end_time_local),
				date_occurrence_utc: `${$scope.form.date_occurrence_utc}T00:00:00`,
			};
            // console.log(data);
            // if(1 === 1){
            //     return;
            // }
			$scope.API.addPracticeAvailability($scope.selectedPractice, data)
				.then(
					function () {
						fetchAvailability();
					},
					function () {
						$scope.serverErrorModal = $modal({
							scope: $scope,
							templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
							show: true,
							title: "CatTrax has encountered an error",
						});
					}
				)
				.then(() => {
					$scope.API.isProcessing = false;
					// $scope.form = {};
				});
		};

        function fetchAvailability() {
			$scope.API.isProcessing = true;
			$scope.API.getPracticeAvailability($scope.selectedPractice)
				.then(
					function (resp) {
                        console.log(resp.data)
                        $scope.items = resp.data;// filter(t=>t.recurrence_type === 'ONE_OFF');
                    },
					function (error) {}
				)
				.then(() => ($scope.API.isProcessing = false));
		}
	},
]);
