app.controller("surgeryAgreementController", [
	"$scope",
	"SURGERY_AGREEMENT_TYPE",
	"ANAESTHETIC_TYPE",
	"SurgeryAgreementAPI",
	"$modal",
	"CurrentPatient",
	"ENV",
	"Session",
	"$filter",
	"$window",
	"PatientAPI",
	function ($scope,SURGERY_AGREEMENT_TYPE,ANAESTHETIC_TYPE,SurgeryAgreementAPI,$modal,CurrentPatient,ENV,Session,$filter,$window,PatientAPI) {
		this.$onInit = function () {
			$scope.form = { procedure_settings_od: {}, procedure_settings_os: {} };
			$scope.SURGERY_AGREEMENT_TYPE = SURGERY_AGREEMENT_TYPE;
			$scope.ANAESTHETIC_TYPE = ANAESTHETIC_TYPE;
			$scope.SAAPI = SurgeryAgreementAPI;
			$scope.PA = PatientAPI;
			$scope.CP = CurrentPatient;
			$scope.surgeryAgreementSettings = [];
			$scope.applyToAllUserPractices = {checked:false};
			fetchSetting();
		};

		function fetchSetting() {
			$scope.SAAPI.isProcessing = true;

			$scope.SAAPI.getSettings()
				.then(
					function (resp) {
						$scope.surgeryAgreementSettings = angular.copy(resp.data.surgery_agreement_settings.procedure_settings);
						if (resp.data.surgery_agreement_settings.saved_user_signature_image) {
							const saved_user_signature_image =
								"data:image/png;base64," + resp.data.surgery_agreement_settings.saved_user_signature_image;
							$scope.signatureClinician = { dataUrl: saved_user_signature_image };
						}
					},
					function (error) {
						showErrorDialog();
					}
				)
				.then(function () {
					$scope.SAAPI.isProcessing = false;
				});
		}

		$scope.positiveClick = ()=>{
			if ($scope.serverErrorModal) {
                $scope.serverErrorModal.hide();
                $window.history.back();
            }
		}

		function showErrorDialog() {
			$scope.serverErrorModal = $modal({
				scope: $scope,
				templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
				show: true,
				title: "CatTrax has encountered an error",
			});
		}
        
		$scope.isDownloadOnly = true;
		$scope.setIsDownloadOnly = function (value){
			$scope.isDownloadOnly = value;
		}

		$scope.submit = function (){
			const form = angular.copy($scope.form);
			if ($scope.operative_eye === "OD") {
				form.procedure_settings_od.unique_procedure_key = generateUniqueProcedureKey(
					form.procedure_settings_od.primary_procedure,
					form.procedure_settings_od.secondary_procedure_list
				);
				form.procedure_settings_od.risks = form.procedure_settings_od.risks.split('\n\n');
				form.procedure_settings_od.after_surgery_advice = form.procedure_settings_od.after_surgery_advice.split('\n\n');
				delete form.procedure_settings_os;
			} else if ($scope.operative_eye === "OS") {
				form.procedure_settings_os.unique_procedure_key = generateUniqueProcedureKey(
					form.procedure_settings_os.primary_procedure,
					form.procedure_settings_os.secondary_procedure_list
				);
				form.procedure_settings_os.risks = form.procedure_settings_os.risks.split('\n\n');
				form.procedure_settings_os.after_surgery_advice = form.procedure_settings_os.after_surgery_advice.split('\n\n');
				delete form.procedure_settings_od;
			} else if ($scope.operative_eye === "BOTH") {
				form.procedure_settings_od.unique_procedure_key = generateUniqueProcedureKey(
					form.procedure_settings_od.primary_procedure,
					form.procedure_settings_od.secondary_procedure_list
				);
				form.procedure_settings_os.unique_procedure_key = generateUniqueProcedureKey(
					form.procedure_settings_os.primary_procedure,
					form.procedure_settings_os.secondary_procedure_list
				);
				form.procedure_settings_od.risks = form.procedure_settings_od.risks.split('\n\n');
				form.procedure_settings_os.risks = form.procedure_settings_os.risks.split('\n\n');

				form.procedure_settings_od.after_surgery_advice = form.procedure_settings_od.after_surgery_advice.split('\n\n');
				form.procedure_settings_os.after_surgery_advice = form.procedure_settings_os.after_surgery_advice.split('\n\n');
			}
			// form.after_surgery_advice = form.after_surgery_advice.trim().split('\n\n');

			$scope.SAAPI.isProcessing = true;
			
			$scope.SAAPI.create($scope.CP.patient.id,form).then(
				function (resp) {
					if($scope.isDownloadOnly){
						downloadPatientFile(resp.data);
					}else{
						previewSurgeryAgreement(resp.data);
					}
					
				},
				function (err) {
					console.log(err);
				}
			)
			.then(function () {
				$scope.SAAPI.isProcessing = false;
			});


		}

		$scope.isUpdateRiskDisabled = function () {
			if ($scope.operative_eye === "OD") {
				return (
					!$scope.form.procedure_settings_od.risks ||
					!$scope.form.procedure_settings_od.primary_procedure 
				);
			} else if ($scope.operative_eye === "OS") {
				return (
					!$scope.form.procedure_settings_os.risks ||
					!$scope.form.procedure_settings_os.primary_procedure
				);
			} else if ($scope.operative_eye === "BOTH") {
				return (
					!$scope.form.procedure_settings_os.risks ||
					!$scope.form.procedure_settings_os.primary_procedure ||
					!$scope.form.procedure_settings_od.risks ||
					!$scope.form.procedure_settings_od.primary_procedure
				);
			}

			return true;
		}

		$scope.isUpdatedDone = false;

		function generateUniqueProcedureKey (primary_procedure,secondary_procedure_list) {
			let key = primary_procedure.concept_id;
			if(secondary_procedure_list && secondary_procedure_list.length > 0){
				secondary_procedure_list.forEach(t=>{
					key = key+"-"+t.concept_id
				})
			}
			return key;
		}

		$scope.pdfSign={translatorName:'',tissueReturn:false,isEPOA:false, sendPatientEmail: false};

		function previewSurgeryAgreement(document_id){
			$scope.pdfSign.translatorName= ''; //  ={translatorName:'',tissueReturn:false};
			$scope.pdfSign.guardian= '';
			$scope.pdfSign.tissueReturn = false;
			$scope.pdfSign.patientEmail = $scope.CP.patient.email;
			$scope.documentId_sign = document_id;
			$scope.pdfUrl = ENV.API + `/api/documents/patient/${$scope.CP.patient.id}/document/SURGERY_AGREEMENT/${document_id}`;
			$scope.httpHeaders = { Authorization: `Bearer ${Session.token}`, "X-PRACTICE-ID": `${Session.practice.id}` };
			$scope.scroll = 0;
			$scope.loading = 'loading';
			$scope.onLoad = function () {
				$scope.loading = '';
				setTimeout(()=>{
					angular.element('#pdf_zoom_in').trigger("click");
					angular.element('#pdf_zoom_out').trigger("click");
				},500);
			}
			$scope.pdfViewerModal = $modal({
				scope: $scope,
				templateUrl: "app/src/views/templates/modal.pdf-viewer-sign.tpl.html",
				show: true,
				title: "DOCUMENT PREVIEW",
				backdrop: "static",
				keyboard: false,
			});
		}

		$scope.boundingBox = {
			width: 600,
			height: 600,
		};

		// One could simply use Canvas#toBlob method instead, but it's just to show
		// that it can be done using result of SignaturePad#toDataURL.
		function dataURLToBlob(dataURL) {
			// Code taken from https://github.com/ebidel/filer.js
			const parts = dataURL.split(";base64,");
			const contentType = parts[0].split(":")[1];
			const raw = window.atob(parts[1]);
			const rawLength = raw.length;
			const uInt8Array = new Uint8Array(rawLength);

			for (let i = 0; i < rawLength; ++i) {
				uInt8Array[i] = raw.charCodeAt(i);
			}

			return new Blob([uInt8Array], { type: contentType });
		}


		$scope.useClinicianSignature = function(accept){
			$scope.signatureClinician = accept();
			if($scope.signatureClinician.isEmpty){
				$scope.signatureClinician.dataUrl = null;
			}
		}

		$scope.usePatientSignature = function(accept){
			$scope.signaturePatient = accept();
			if($scope.signaturePatient.isEmpty){
				$scope.signaturePatient.dataUrl = null;
			}
		}

		$scope.useTranslatorSignature = function(accept){
			$scope.signatureTranslator = accept();
			if($scope.signatureTranslator.isEmpty){
				$scope.signatureTranslator.dataUrl = null;
			}
		}

		


		$scope.sign = function(userSign,patientSign,translatorSign){
			let userBlob = null;
			let patientBlob = null;
			let translatorBlob = null;
			if (userSign && !userSign.isEmpty && userSign.dataUrl) {
				userBlob = dataURLToBlob(userSign.dataUrl);
			}

			if (patientSign && !patientSign.isEmpty && patientSign.dataUrl) {
				patientBlob = dataURLToBlob(patientSign.dataUrl);
			}

			if (translatorSign && !translatorSign.isEmpty && translatorSign.dataUrl) {
				translatorBlob = dataURLToBlob(translatorSign.dataUrl);
			}

			if(!$scope.pdfSign.isEPOA){
				$scope.pdfSign.guardian = null;
			}
	
			$scope.SAAPI.isProcessing = true;
			$scope.SAAPI.sign(
				$scope.documentId_sign,
				$scope.CP.patient.id,
				$scope.pdfSign.translatorName,
				$scope.pdfSign.guardian,
				$scope.pdfSign.tissueReturn,
				$scope.pdfSign.sendPatientEmail,
				$scope.pdfSign.patientEmail,
				$scope.pdfSign.patientEmailCC,
				userBlob,
				patientBlob,
				translatorBlob
			)
				.then(
					function (resp) {
						downloadFile(resp.data, getFileName());
						$scope.closePdfViewer();
						$window.history.back();
					},
					function (error) {
						showErrorDialog();
					}
				)
				.then(() => ($scope.SAAPI.isProcessing = false));
		}

		$scope.closePdfViewer = function () {
			if($scope.pdfViewerModal){
				$scope.pdfViewerModal.hide();
			}
		}

		function getFileName() {
			var theTime = $filter("date")(new Date(), "yyyy-MM-dd@hmma");
			return (
				"CatTrax_surgery_consent_" +
				theTime +
				"_" +
				$scope.CP.patient.firstName +
				"_" +
				$scope.CP.patient.lastName +
				"_" +
				$scope.CP.patient.dateOfBirth +
				".pdf"
			);
		}

		function downloadFile(data, filename, mime, bom) {
			// https://github.com/kennethjiang/js-file-download/blob/master/file-download.js
			var blobData = typeof bom !== "undefined" ? [bom, data] : [data];
			var blob = new Blob(blobData, { type: "application/pdf" });
			if (typeof window.navigator.msSaveBlob !== "undefined") {
				// IE workaround for "HTML7007: One or more blob URLs were
				// revoked by closing the blob for which they were created.
				// These URLs will no longer resolve as the data backing
				// the URL has been freed."
				window.navigator.msSaveBlob(blob, filename);
			} else {
				var blobURL = window.URL.createObjectURL(blob);
				var tempLink = document.createElement("a");
				tempLink.style.display = "none";
				tempLink.href = blobURL;
				tempLink.setAttribute("download", filename);

				// Safari thinks _blank anchor are pop ups. We only want to set _blank
				// target if the browser does not support the HTML5 download attribute.
				// This allows you to download files in desktop safari if pop up blocking
				// is enabled.
				if (typeof tempLink.download === "undefined") {
					tempLink.setAttribute("target", "_blank");
				}

				document.body.appendChild(tempLink);
				tempLink.click();
				document.body.removeChild(tempLink);
				window.URL.revokeObjectURL(blobURL);
				$scope.displaySpinner = false;
			}
		}

		$scope.updateRisk = function () {
			const formArray = [];
			const form = {}; 
			if ($scope.operative_eye === "OD") {
				form.unique_procedure_key = generateUniqueProcedureKey(
					$scope.form.procedure_settings_od.primary_procedure,
					$scope.form.procedure_settings_od.secondary_procedure_list
				);
				form.risks = $scope.form.procedure_settings_od.risks.trim().split('\n\n');
				form.after_surgery_advice = $scope.form.procedure_settings_od.after_surgery_advice.trim().split('\n\n');
				form.anaesthetic = $scope.form.procedure_settings_od.anaesthetic;
				form.laymans_procedure_description = $scope.form.procedure_settings_od.laymans_procedure_description;
				formArray.push(form);
			} else if ($scope.operative_eye === "OS") {
				form.unique_procedure_key = generateUniqueProcedureKey(
					$scope.form.procedure_settings_os.primary_procedure,
					$scope.form.procedure_settings_os.secondary_procedure_list
				);
				form.risks = $scope.form.procedure_settings_os.risks.trim().split('\n\n');
				form.after_surgery_advice = $scope.form.procedure_settings_os.after_surgery_advice.trim().split('\n\n');
				form.anaesthetic = $scope.form.procedure_settings_os.anaesthetic;
				form.laymans_procedure_description = $scope.form.procedure_settings_os.laymans_procedure_description;
				formArray.push(form);
			} else if ($scope.operative_eye === "BOTH") {
				form.unique_procedure_key = generateUniqueProcedureKey(
					$scope.form.procedure_settings_od.primary_procedure,
					$scope.form.procedure_settings_od.secondary_procedure_list
				);
				form.risks = $scope.form.procedure_settings_od.risks.trim().split('\n\n');
				form.after_surgery_advice = $scope.form.procedure_settings_od.after_surgery_advice.trim().split('\n\n');
				form.anaesthetic = $scope.form.procedure_settings_od.anaesthetic;
				form.laymans_procedure_description = $scope.form.procedure_settings_od.laymans_procedure_description;
				formArray.push(form);
				const form2 = {};
				form2.unique_procedure_key = generateUniqueProcedureKey(
					$scope.form.procedure_settings_os.primary_procedure,
					$scope.form.procedure_settings_os.secondary_procedure_list
				);
				form2.risks = $scope.form.procedure_settings_os.risks.trim().split('\n\n');
				form2.after_surgery_advice = $scope.form.procedure_settings_os.after_surgery_advice.trim().split('\n\n');
				form2.anaesthetic = $scope.form.procedure_settings_os.anaesthetic;
				form2.laymans_procedure_description = $scope.form.procedure_settings_os.laymans_procedure_description;
				formArray.push(form2);
			}
				$scope.SAAPI.isProcessing = true;

				$scope.SAAPI.saveSettings(formArray[0],$scope.applyToAllUserPractices.checked).then(
					function (resp) {
						
					},
					function (err) {
						console.log(err);
					}
				)
				.then(function () {
					$scope.SAAPI.isProcessing = false;
					$scope.isUpdatedDone = true;
					if(formArray[1]){
						$scope.SAAPI.isProcessing = true;

						$scope.SAAPI.saveSettings(formArray[1],$scope.applyToAllUserPractices.checked).then(
							function (resp) {
								
							},
							function (err) {
								console.log(err);
							}
						)
						.then(function () {
							$scope.SAAPI.isProcessing = false;
							$scope.isUpdatedDone = true;
						});

					}
				});
			
		}

		// $scope.setAfterSurgeryAdvice = (value) => {
		//    const after_surgery_advice = $scope.form.after_surgery_advice ? $scope.form.after_surgery_advice : '';
        //    $scope.form.after_surgery_advice = value + '\n\n' + after_surgery_advice;
		// }

		function downloadPatientFile (document_id) {
			$scope.PA.downloadDocument($scope.CP.patient.id, document_id, 'SURGERY_AGREEMENT').then(function (resp) {
				downloadFile(resp.data, getFileName());
				$window.history.back();
			}, function (err) {
				console.log(err);
				showErrorDialog();
			});
		}
	},
]);
