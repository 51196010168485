app.component("complicationsSection", {
	templateUrl: "app/src/js/components/operationNote/complicationsSection/complicationsSection.html",
	controller: "complicationsSectionController",
	bindings: {
		data: "=",
		eyeTitle: "@",
		submitted: "="
	},
});

app.controller("complicationsSectionController", [
	"$scope",
	"$timeout",
	function ($scope,$timeout) {
		$scope.warn = false;
		$scope.removeOther = function (index) {
			$scope.$ctrl.data.complications.other.splice(index, 1);
		};

		$scope.addOther = function (text) {
			if (text == null) {
				return;
			}

			if (!$scope.$ctrl.data.complications) {
				$scope.$ctrl.data.complications = { other: [] };
			}

			if (!$scope.$ctrl.data.complications.other) {
				$scope.$ctrl.data.complications.other = [];
			}
			$scope.$ctrl.data.complications.other.push(text);
			// reset fields to empty
			this.$ctrl.form.other = null;
			$scope.warn = false;
		};

		$scope.disableComplications = function () {
			if (!$scope.$ctrl.data) {
				$scope.$ctrl.data = { complications: {} };
			}
			if (!$scope.$ctrl.data.complications) {
				$scope.$ctrl.data.complications = {};
			}

			$scope.$ctrl.data.complications.iris_prolapse = "NO";
			$scope.$ctrl.data.complications.anterior_capsular_runout = "NO";
			$scope.$ctrl.data.complications.zonular_dialysis = "NO";
			$scope.$ctrl.data.complications.posterior_capsular_rupture = "NO";
			$scope.$ctrl.data.complications.vitreous_loss = "NO";
			$scope.$ctrl.data.complications.dropped_nucleus = "NO";
			$scope.$ctrl.data.complications.wound_leak = "NO";
			$scope.$ctrl.data.complications.wound_burn = "NO";
			$scope.$ctrl.data.complications.suprachoridal_haemorrhage = "NO";
		};

		$scope.complications_disable = { value: "" };

		$scope.enableComplications = function () {
			$scope.complications_disable.value = "";
		};

		$scope.showWarn = function () {
			$scope.warn = true;
		}

		$scope.reset = function () {
			if (!$scope.$ctrl.data.complications) {
				$scope.$ctrl.data.complications = {};
			}
			if($scope.$ctrl.data.complications.iris_prolapse &&
			$scope.$ctrl.data.complications.anterior_capsular_runout &&
			$scope.$ctrl.data.complications.zonular_dialysis &&
			$scope.$ctrl.data.complications.posterior_capsular_rupture &&
			$scope.$ctrl.data.complications.vitreous_loss &&
			$scope.$ctrl.data.complications.dropped_nucleus &&
			$scope.$ctrl.data.complications.wound_leak &&
			$scope.$ctrl.data.complications.wound_burn &&
			$scope.$ctrl.data.complications.suprachoridal_haemorrhage){
				let form = angular.element(document.querySelector("#operation-form"));
				$timeout(
					function () {
						if ($scope.$ctrl.submitted) {
							// element[0].dispatchEvent(new Event("submit"));
							form.submit();
						}
					},
					0,
					false
				);
			}
		};
	},
]);
