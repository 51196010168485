app.component("visionTable", {
	templateUrl: "app/src/js/components/visionRefraction/visionTable.html",
	controller: "visionTableController",
	bindings: {
		patientId: "=",
		onVisionUpdate: "<",
		registration: "&",
		valueCallback: "&",
	},
});

app.controller("visionTableController", [
	"$scope",
	"$modal",
	"LINE",
	"DEVICE",
	"PatientVisionAPI",
	"$filter",
	"CurrentPatient",
	function ($scope, $modal, LINE, DEVICE, PatientVisionAPI, $filter,CurrentPatient) {
		this.$onInit = function() {
			$scope.CP = CurrentPatient;
			this.registration({
			  handler: this.triggerUpdate
			});
		  };

		this.triggerUpdate = function(){
			fetchPatientVision();
		}

		$scope.PV = PatientVisionAPI;

		$scope.same_day_od_uncorrected_va_line = false;
		$scope.same_day_os_uncorrected_va_line = false;
		$scope.same_day_od_uncorrected_va_letter = false;
		$scope.same_day_os_uncorrected_va_letter = false;

		$scope.same_day_od_best_va_line = false;
		$scope.same_day_os_best_va_line = false;
		$scope.same_day_od_best_va_letter = false;
		$scope.same_day_os_best_va_letter = false;

		$scope.same_day_od_pinhole_va_line = false;
		$scope.same_day_os_pinhole_va_line = false;
		$scope.same_day_od_pinhole_va_letter = false;
		$scope.same_day_os_pinhole_va_letter = false;

		$scope.same_day_od_refraction = false;
		$scope.same_day_os_refraction = false;

		$scope.same_day_od_iop = false;
		$scope.same_day_os_iop = false;

		$scope.od_iop_risk = false;
		$scope.os_iop_risk = false;

		function getUTCDate(date) {
			return $filter("date")(date.split('T')[0], "dd/MM/yyyy");
		}

		function sameDay(d1, d2) {
			return d1.getFullYear() === d2.getFullYear() &&
				d1.getMonth() === d2.getMonth() &&
				d1.getDate() === d2.getDate();
		}

		function checkTimeStamp(eye, prop) {
			try {
				const now = $scope.data.server_timestamp;
				const time = $scope.data.vision[`${eye}_vision_audit`][prop];
				if (sameDay(new Date(now.split('T')[0]), new Date(time.split('T')[0]))) {
					return true;
				} else {
					return false;
				}

			} catch (e) {
				return false;
			}
		}

		$scope.$watch(
			"$ctrl.patientId",
			function (newValue, oldValue) {
				if (newValue) {
					fetchPatientVision();
				}
			},
			true
		);

		$scope.onVisionUpdateSuccess = function () {
			if ($scope.visionModal) {
				$scope.visionModal.hide();
			}
			fetchPatientVision();
			$scope.$ctrl.onVisionUpdate();
		}

		$scope.showVisionModal = function () {
			/**
			$scope.visionForm = transformData($scope.data);
			$scope.copyData = angular.copy($scope.visionForm); */
			$scope.visionForm = {
				vision: {
					od: {},
					os: {}
				}
			};
			$scope.copyData = angular.copy(transformData($scope.data));
			$scope.visionModal = $modal({
				scope: $scope,
				templateUrl: "app/src/views/templates/modal.vision-refraction.tpl.html",
				show: true,
				title: "Vision, refraction and pressure",
				backdrop: "static",
				keyboard: false,
			});
		}

		$scope.covertLineLetter = function (lineVal, letterVal) {
			const element = LINE.find(t => t.value === lineVal);
			let result = '';
			if (element) {
				if (element.name === 'CF' || element.name === 'HM' || element.name === 'POL' || element.name === 'NPL') {
					return element.name;
				} else {
					result = `6/${element.name}`;
				}
			}
			if (letterVal > 0) {
				result = result + `+${letterVal}`;
			} else if (letterVal < 0) {
				result = result + letterVal;
			}
			return result;
		}

		$scope.transformDeviceName = function (value) {
			const element = DEVICE.find(t => t.value === value);
			if (element) {
				return `(${element.name})`;
			}
			return '';
		}

		function transformData(source) {
			const target = {
				vision: {
					od: {},
					os: {}
				}
			};
			if (!source.vision) {
				return target;
			}
            
			let isNullObj = true;

			for (const property in source.vision) {
				if (source.vision[property]) {
					isNullObj = false;
					break;
				}
			}

			if(isNullObj){
				return target;
			}

			if (!source.vision.od) {
				source.vision.od = {};
			}

			if (!source.vision.os) {
				source.vision.os = {};
			}

			target.vision.od.ucvaLine = source.vision.od.uncorrected_va_line;
			target.vision.od.ucvaLetter = source.vision.od.uncorrected_va_letter;
			target.vision.os.ucvaLine = source.vision.os.uncorrected_va_line;
			target.vision.os.ucvaLetter = source.vision.os.uncorrected_va_letter;

			target.vision.od.bcvaLine = source.vision.od.best_va_line;
			target.vision.os.bcvaLine = source.vision.os.best_va_line;

			target.vision.od.bcvaLetter = source.vision.od.best_va_letter;
			target.vision.os.bcvaLetter = source.vision.os.best_va_letter;


			target.vision.od.bcvaLineNear = source.vision.od.bestcorrected_near_va_line;
			target.vision.os.bcvaLineNear = source.vision.os.bestcorrected_near_va_line;

			target.vision.od.bcvaLetterNear = source.vision.od.bestcorrected_near_va_letter;
			target.vision.os.bcvaLetterNear = source.vision.os.bestcorrected_near_va_letter;

			target.vision.od.bcvaLineMedium = source.vision.od.bestcorrected_medium_va_line;
			target.vision.os.bcvaLineMedium = source.vision.os.bestcorrected_medium_va_line;

			target.vision.od.bcvaLetterMedium = source.vision.od.bestcorrected_medium_va_letter;
			target.vision.os.bcvaLetterMedium = source.vision.os.bestcorrected_medium_va_letter;

			target.vision.od.sphere = source.vision.od.refraction_sphere;
			target.vision.os.sphere = source.vision.os.refraction_sphere;

			target.vision.od.cyl = source.vision.od.refraction_cylinder;
			target.vision.os.cyl = source.vision.os.refraction_cylinder;

			target.vision.od.axis = source.vision.od.refraction_axis;
			target.vision.os.axis = source.vision.os.refraction_axis;

			target.vision.od.auto_refraction = true;
			target.vision.os.auto_refraction = true;

			target.vision.od.pinhole_va_line = source.vision.od.pinhole_va_line;
			target.vision.os.pinhole_va_line = source.vision.os.pinhole_va_line;

			target.vision.od.pinhole_va_letter = source.vision.od.pinhole_va_letter;
			target.vision.os.pinhole_va_letter = source.vision.os.pinhole_va_letter;

			return target;
		}

		function fetchPatientVision() {
			$scope.PV.isProcessing = true;
			$scope.PV.getPatientVision($scope.$ctrl.patientId).then(function (resp) {
				$scope.data = resp.data;
				if($scope.$ctrl.valueCallback){
					$scope.$ctrl.valueCallback({value:angular.copy(resp.data)});
				}
				$scope.same_day_od_uncorrected_va_line = checkTimeStamp('od', 'uncorrected_va_line');
				$scope.same_day_os_uncorrected_va_line = checkTimeStamp('os', 'uncorrected_va_line');
				$scope.same_day_od_uncorrected_va_letter = checkTimeStamp('od', 'uncorrected_va_letter');
				$scope.same_day_os_uncorrected_va_letter = checkTimeStamp('os', 'uncorrected_va_letter');

				$scope.same_day_od_best_va_line = checkTimeStamp('od', 'best_va_line');
				$scope.same_day_os_best_va_line = checkTimeStamp('os', 'best_va_line');
				$scope.same_day_od_best_va_letter = checkTimeStamp('od', 'best_va_letter');
				$scope.same_day_os_best_va_letter = checkTimeStamp('os', 'best_va_letter');

				$scope.same_day_od_pinhole_va_line = checkTimeStamp('od', 'pinhole_va_line');
				$scope.same_day_os_pinhole_va_line = checkTimeStamp('os', 'pinhole_va_line');
				$scope.same_day_od_pinhole_va_letter = checkTimeStamp('od', 'pinhole_va_letter');
				$scope.same_day_os_pinhole_va_letter = checkTimeStamp('os', 'pinhole_va_letter');

				if(!$scope.data.vision['od_vision_audit']){
					$scope.data.vision.od_vision_audit = {};
				}

				if(!$scope.data.vision['os_vision_audit']){
					$scope.data.vision.os_vision_audit = {};
				}


				if (!$scope.same_day_od_uncorrected_va_line && $scope.data.vision['od_vision_audit']['uncorrected_va_line']) {
					$scope.uncorrected_va_od_timestamp = getUTCDate($scope.data.vision['od_vision_audit']['uncorrected_va_line']);
				} else if (!$scope.same_day_od_uncorrected_va_letter && $scope.data.vision['od_vision_audit']['uncorrected_va_letter']) {
					$scope.uncorrected_va_od_timestamp = getUTCDate($scope.data.vision['od_vision_audit']['uncorrected_va_letter']);
				} else {
					$scope.uncorrected_va_od_timestamp = "";
				}

				if (!$scope.same_day_os_uncorrected_va_line && $scope.data.vision['os_vision_audit']['uncorrected_va_line']) {
					$scope.uncorrected_va_os_timestamp = getUTCDate($scope.data.vision['os_vision_audit']['uncorrected_va_line']);
				} else if (!$scope.same_day_os_uncorrected_va_letter && $scope.data.vision['os_vision_audit']['uncorrected_va_letter']) {
					$scope.uncorrected_va_os_timestamp = getUTCDate($scope.data.vision['os_vision_audit']['uncorrected_va_letter']);
				} else {
					$scope.uncorrected_va_os_timestamp = "";
				}

				if (!$scope.same_day_od_best_va_line && $scope.data.vision['od_vision_audit']['best_va_line']) {
					$scope.best_va_od_timestamp = getUTCDate($scope.data.vision['od_vision_audit']['best_va_line']);
				} else if (!$scope.same_day_od_best_va_letter && $scope.data.vision['od_vision_audit']['best_va_letter']) {
					$scope.best_va_od_timestamp = getUTCDate($scope.data.vision['od_vision_audit']['best_va_letter']);
				} else {
					$scope.best_va_od_timestamp = "";
				}

				if (!$scope.same_day_os_best_va_line && $scope.data.vision['os_vision_audit']['best_va_line']) {
					$scope.best_va_os_timestamp = getUTCDate($scope.data.vision['os_vision_audit']['best_va_line']);
				} else if (!$scope.same_day_os_best_va_letter && $scope.data.vision['os_vision_audit']['best_va_letter']) {
					$scope.best_va_os_timestamp = getUTCDate($scope.data.vision['os_vision_audit']['best_va_letter']);
				} else {
					$scope.best_va_os_timestamp = "";
				}

				if (!$scope.same_day_od_pinhole_va_line && $scope.data.vision['od_vision_audit']['pinhole_va_line']) {
					$scope.pinhole_va_od_timestamp = getUTCDate($scope.data.vision['od_vision_audit']['pinhole_va_line']);
				} else if (!$scope.same_day_od_pinhole_va_letter && $scope.data.vision['od_vision_audit']['pinhole_va_letter']) {
					$scope.pinhole_va_od_timestamp = getUTCDate($scope.data.vision['od_vision_audit']['pinhole_va_letter']);
				} else {
					$scope.pinhole_va_od_timestamp = "";
				}

				if (!$scope.same_day_os_pinhole_va_line && $scope.data.vision['os_vision_audit']['pinhole_va_line']) {
					$scope.pinhole_va_os_timestamp = getUTCDate($scope.data.vision['os_vision_audit']['pinhole_va_line']);
				} else if (!$scope.same_day_os_pinhole_va_letter && $scope.data.vision['os_vision_audit']['pinhole_va_letter']) {
					$scope.pinhole_va_os_timestamp = getUTCDate($scope.data.vision['os_vision_audit']['pinhole_va_letter']);
				} else {
					$scope.pinhole_va_os_timestamp = "";
				}

				const same_day_od_refraction_1 = checkTimeStamp('od', 'refraction_sphere');
				const same_day_od_refraction_2 = checkTimeStamp('od', 'refraction_cylinder');
				const same_day_od_refraction_3 = checkTimeStamp('od', 'refraction_axis');
				const same_day_od_refraction_4 = checkTimeStamp('od', 'auto_refraction');

				$scope.same_day_od_refraction = same_day_od_refraction_1 || same_day_od_refraction_2 || same_day_od_refraction_3 || same_day_od_refraction_4;

				if ($scope.same_day_od_refraction) {
					$scope.refraction_va_od_timestamp = "";
				} else if (!same_day_od_refraction_1 && $scope.data.vision['od_vision_audit']['refraction_sphere']) {
					$scope.refraction_va_od_timestamp = getUTCDate($scope.data.vision['od_vision_audit']['refraction_sphere']);
				} else if (!same_day_od_refraction_2 && $scope.data.vision['od_vision_audit']['refraction_cylinder']) {
					$scope.refraction_va_od_timestamp = getUTCDate($scope.data.vision['od_vision_audit']['refraction_cylinder']);
				} else if (!same_day_od_refraction_3 && $scope.data.vision['od_vision_audit']['refraction_axis']) {
					$scope.refraction_va_od_timestamp = getUTCDate($scope.data.vision['od_vision_audit']['refraction_axis']);
				} else if (!same_day_od_refraction_4 && $scope.data.vision['od_vision_audit']['auto_refraction']) {
					$scope.refraction_va_od_timestamp = getUTCDate($scope.data.vision['od_vision_audit']['auto_refraction']);
				} else {
					$scope.refraction_va_od_timestamp = "";
				}

				const same_day_os_refraction_1 = checkTimeStamp('os', 'refraction_sphere');
				const same_day_os_refraction_2 = checkTimeStamp('os', 'refraction_cylinder');
				const same_day_os_refraction_3 = checkTimeStamp('os', 'refraction_axis');
				const same_day_os_refraction_4 = checkTimeStamp('os', 'auto_refraction');

				$scope.same_day_os_refraction = same_day_os_refraction_1 || same_day_os_refraction_2 || same_day_os_refraction_3 || same_day_os_refraction_4;

				if ($scope.same_day_os_refraction) {
					$scope.refraction_va_os_timestamp = "";
				} else if (!same_day_os_refraction_1 && $scope.data.vision['os_vision_audit']['refraction_sphere']) {
					$scope.refraction_va_os_timestamp = getUTCDate($scope.data.vision['os_vision_audit']['refraction_sphere']);
				} else if (!same_day_os_refraction_2 && $scope.data.vision['os_vision_audit']['refraction_cylinder']) {
					$scope.refraction_va_os_timestamp = getUTCDate($scope.data.vision['os_vision_audit']['refraction_cylinder']);
				} else if (!same_day_os_refraction_3 && $scope.data.vision['os_vision_audit']['refraction_axis']) {
					$scope.refraction_va_os_timestamp = getUTCDate($scope.data.vision['os_vision_audit']['refraction_axis']);
				} else if (!same_day_od_refraction_4 && $scope.data.vision['os_vision_audit']['auto_refraction']) {
					$scope.refraction_va_os_timestamp = getUTCDate($scope.data.vision['os_vision_audit']['auto_refraction']);
				} else {
					$scope.refraction_va_os_timestamp = "";
				}

				$scope.same_day_od_iop_1 = checkTimeStamp('od', 'intraocular_pressure_iop');
				$scope.same_day_od_iop_2 = checkTimeStamp('od', 'intraocular_pressure_device');
				$scope.same_day_od_iop = $scope.same_day_od_iop_1 || $scope.same_day_od_iop_2;

				try{
					$scope.od_iop_risk = $scope.data.vision['od']['intraocular_pressure_iop'] > 21;
					$scope.os_iop_risk = $scope.data.vision['os']['intraocular_pressure_iop'] > 21;
				}catch(e){
					$scope.od_iop_risk = false;
					$scope.os_iop_risk = false;
				}


				

				if ($scope.same_day_od_iop) {
					$scope.iop_od_timestamp = "";
				} else if (!$scope.same_day_od_iop_1 && $scope.data.vision['od_vision_audit']['intraocular_pressure_iop']) {
					$scope.iop_od_timestamp = getUTCDate($scope.data.vision['od_vision_audit']['intraocular_pressure_iop']);
				} else if (!$scope.same_day_od_iop_2 && $scope.data.vision['od_vision_audit']['intraocular_pressure_device']) {
					$scope.iop_od_timestamp = getUTCDate($scope.data.vision['od_vision_audit']['intraocular_pressure_device']);
				} else {
					$scope.iop_od_timestamp = "";
				}

				$scope.same_day_os_iop_1 = checkTimeStamp('os', 'intraocular_pressure_iop');
				$scope.same_day_os_iop_2 = checkTimeStamp('os', 'intraocular_pressure_device');

				$scope.same_day_os_iop = $scope.same_day_os_iop_1 || $scope.same_day_os_iop_2;

				if ($scope.same_day_os_iop) {
					$scope.iop_os_timestamp = "";
				} else if (!$scope.same_day_os_iop_1 && $scope.data.vision['os_vision_audit']['intraocular_pressure_iop']) {
					$scope.iop_os_timestamp = getUTCDate($scope.data.vision['os_vision_audit']['intraocular_pressure_iop']);
				} else if (!$scope.same_day_os_iop_2 && $scope.data.vision['os_vision_audit']['intraocular_pressure_device']) {
					$scope.iop_os_timestamp = getUTCDate($scope.data.vision['os_vision_audit']['intraocular_pressure_device']);
				} else {
					$scope.iop_os_timestamp = "";
				}
			}, function (error) {
				console.error(error);
				// $scope.serverErrorModal = $modal({
				// 	scope: $scope,
				// 	templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
				// 	show: true,
				// 	title: "CatTrax has encountered an error",
				// });
			}).then(function () {
				$scope.PV.isProcessing = false;
			})
		}
	},
]);
