app.component("practiceAdminConsole", {
	templateUrl: "app/src/js/components/admin/practiceConsole.html",
	controller: "practiceAdminController",
	bindings: {
		// type: '@',
	},
});

app.controller("practiceAdminController", [
	"$scope",
	"$modal",
	"PRACTICE_TYPE",
	"AdminService",
    "NgTableParams",
	"DHB_LIST",
    "$window",
	function ($scope, $modal, PRACTICE_TYPE,AdminService, NgTableParams, DHB_LIST, $window) {
		$scope.practiceTypeOptions = PRACTICE_TYPE;
		$scope.dhbOptions = DHB_LIST;
		$scope.formDisplay = false;
        $scope.AS = AdminService;
        $scope.AS.isProcessing = false;
		$scope.display = {};
		$scope.display.address = '';
		$scope.autocompleteOptions = {
            componentRestrictions: { country: "nz" },
            types: ["geocode"],
        };

		$scope.fetchPractices = function () {
            $scope.AS.getAvailablePractices().then(function (resp) {
				$scope.tableParams = new NgTableParams({
                    count: 10,
					sorting: { id: 'asc' },
                }, {
                    counts: [],
                    dataset: resp.data
                });
            }, function (error) {

            }).then(function () {
                $scope.AS.isProcessing = false;
            })
        };

		$scope.fetchPractices();

		$scope.selectPractice = function (practice, index) {
			$scope.createPractice();
			$scope.$ctrl.data.id = practice.id;
            $scope.fetchSelectedPractice(practice.id);
		};

		$scope.createPractice = function () {
			$scope.$ctrl.data = {};
            // $scope.$ctrl.data.registration = {};
            // $scope.$ctrl.data.practices = [];
            // $scope.$ctrl.data.roles = [];
            $scope.display = {};
		    $scope.display.address = '';
            // $scope.roleModel = [];
            // $scope.fetchPractices();
            $scope.formDisplay = true;
		};

		$scope.fetchSelectedPractice = function (practiceId) {
            $scope.AS.getPracticeDetail(practiceId).then(function (resp) {
                $scope.$ctrl.data = resp.data;
                // $scope.roleModel = $scope.$ctrl.data.roles.map(t => ({ id: t }));
                $scope.display.address = $scope.$ctrl.data.addresses[0].line1;
                // $scope.display.address = $scope.CP.patient.addresses[0].line1;
            }, function (error) {

            }).then(function () {
                $scope.AS.isProcessing = false;
            })
        };

		$scope.setAddress = function () {
			if(!$scope.display || !$scope.display.address){
				return;
			}
            $scope.$ctrl.data.addresses = [{
                type: "POSTAL",
                line1: $scope.display.address.formatted_address,
                line2: "",
                city: "",
                postCode: "",
                country: "",
            }];
            $scope.display.address.address_components.map(function (obj) {
                if (obj.types.indexOf("postal_code") !== -1) {
                    $scope.$ctrl.data.addresses[0].postCode = obj.long_name;
                }
            });

            $scope.display.address.address_components.map(function (obj) {
                if (obj.types.indexOf("country") !== -1) {
                    $scope.$ctrl.data.addresses[0].country = obj.long_name;
                }
            });

            $scope.display.address.address_components.map(function (obj) {
                if (obj.types.indexOf("locality") !== -1) {
                    $scope.$ctrl.data.addresses[0].city = obj.long_name;
                }
            });

            $scope.display.address.address_components.map(function (obj) {
                if (obj.types.indexOf("sublocality") !== -1) {
                    $scope.$ctrl.data.addresses[0].line2 = obj.long_name;
                }
            });

        };

		$scope.registerOrUpdatePractice = function() {
            $scope.$ctrl.data.addresses.forEach(t => delete t.id);
            /** Fix bug 988: Can't update FHIR NHI toggle (https://dev.azure.com/quantric/Quantric/_workitems/edit/988)
            if(!$scope.$ctrl.data.practiceFeatures || !$scope.$ctrl.data.practiceFeatures.nhiFeature){
                delete $scope.$ctrl.data.practiceFeatures;
            } */

            if (!$scope.$ctrl.data.faxNumber || $scope.$ctrl.data.faxNumber.length === 0) {
                delete $scope.$ctrl.data.faxNumber;
            }
            const isEditPractice = $scope.$ctrl.data.id !== undefined
            let handler = $scope.AS.createPractice;
			
            if(isEditPractice){
                handler = $scope.AS.updatePracticeDetail;
            }
			handler($scope.$ctrl.data).then(function (resp) {
                $scope.dialogMessage = isEditPractice ? "Practice Updated" : "Practice Created";
                $scope.successModal = $modal({
					scope: $scope,
					templateUrl: "app/src/views/templates/modal.generic-success.tpl.html",
					show: true,
					title: "Success",
                    onHide: function () {
                        $scope.formDisplay = false;
                        $scope.fetchPractices();
                    },
				});
            }, function (error) {
                if (error.data && error.data.status === 409) {
                    $scope.dialogMessage = "Practice already exists";
                    $scope.successModal = $modal({
                        scope: $scope,
                        templateUrl: "app/src/views/templates/modal.generic-success.tpl.html",
                        show: true,
                        title: "Failure",
                    });
                } else {
                    $scope.serverErrorModal = $modal({
                        scope: $scope,
                        templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
                        show: true,
                        title: "CatTrax has encountered an error",
                    });
                }
            }).then(function () {
                $scope.AS.isProcessing = false;
            })
			
		}

		$scope.cancel = function () {
            $scope.createPractice();
            $scope.formDisplay = false;
        };

        $scope.goBack = function () {
            $window.history.back();
        }

        // $scope.$watch(
		// 	"$ctrl.data.practiceFeatures.fhirNhiFeature",
		// 	function (newValue, oldValue) {
		// 		if(newValue){
		// 			$scope.$ctrl.data.practiceFeatures.nhiFeature = true;
		// 		}
		// 	},
		// 	true
		// );
	},
]);
