app.component("taskAutomationSetting", {
	templateUrl: "app/src/views/templates/tasks-automation.tmpl.html",
	controller: "taskAutomationSettingController",
});



app.controller("taskAutomationSettingController", [
	"$scope",
	"$mdDialog",
	"TasksAPI",
	"Session",
	"$modal",
	function ($scope, $mdDialog, TasksAPI, Session, $modal) {
		this.$onInit = function () {
			$scope.API = TasksAPI;
			$scope.$mdDialog = $mdDialog;
			$scope.Session = Session;
			$scope.setting = {};
			$scope.users = [];
			$scope.options = [
				{ name: "Referral", id: "referral_automation_settings" },
				{ name: "Surgery Waitlist", id: "surgery_waitlist_automation_settings" },
				{ name: "Progress Note", id: "progress_note_automation_settings" },
				{ name: "Injection Note", id: "injection_note_automation_settings" },
				{ name: "Operation Note", id: "operation_note_automation_settings" },
				{ name: "Histology", id: "histology_automation_settings" },
			];
			$scope.selectedCatalog = $scope.options[0].id;
			$scope.partialSetting = null;
			fetchSetting();
		};

		$scope.onCatalogChange = function () {
			$scope.partialSetting = $scope.setting[$scope.selectedCatalog];
			$scope.onLabelChange();
		};

		$scope.save = function () {
			$scope.API.isProcessing = true;
			$scope.API.updateTaskSetting($scope.setting)
				.then(
					function (success) {},
					function (error) {
						showErrorDialog();
					}
				)
				.then(() => ($scope.API.isProcessing = false));
		};

		$scope.onLabelChange = function(){
            const id = $scope.partialSetting.label_id;
            if(id){
                const target = $scope.labels.find(t=>t.task_label_id === id);
                $scope.spanStyle={'background-color':`#${target.colour}`,'width': '36px', 'height': '36px'};
            }else{
                $scope.spanStyle={'background-color':'white','width': '36px', 'height': '36px'};
            }
        }

		function fetchSetting() {
			$scope.API.isProcessing = true;
			const p1 = $scope.API.getUsers($scope.Session.practice.id);
			const p2 = $scope.API.getTaskSetting();
			const p3 = $scope.API.queryTaskLabels();

			Promise.all([p1, p2, p3])
				.then(
					(values) => {
						values[0].data.forEach(function (doc) {
							if (Session.user.id === doc.id) {
								$scope.users.push({
									name: "Me (" + doc.firstName + " " + doc.lastName + ")",
									value: doc.id,
									practiceId: doc.practiceId,
								});
							} else {
								$scope.users.push({ name: doc.firstName + " " + doc.lastName, value: doc.id, practiceId: doc.practiceId });
							}
						});

						$scope.users.sort(function (a, b) {
							if (a.name < b.name) {
								return -1;
							}
							if (a.name > b.name) {
								return 1;
							}
							return 0;
						});
						
						$scope.labels = values[2].data.filter(t=>$scope.Session.practice.id === t.practice_id);

						if (values[1].data.task_settings) {
							$scope.setting = values[1].data.task_settings;
						} else {
							$scope.setting = {
								referral_automation_settings: {
									create_task_checked: true,
									days_until_due_date: 8,
									timezone: "Pacific/Auckland",
									description: "",
									assign_to_user_id: $scope.users[0].value,
									assign_to_practice_id: $scope.Session.practice.id,
									label_id: $scope.labels[0].task_label_id
								},
								surgery_waitlist_automation_settings: {
									create_task_checked: true,
									days_until_due_date: 8,
									timezone: "Pacific/Auckland",
									description: "",
									assign_to_user_id: $scope.users[0].value,
									assign_to_practice_id: $scope.Session.practice.id,
									label_id: $scope.labels[0].task_label_id
								},
								progress_note_automation_settings: {
									create_task_checked: true,
									days_until_due_date: 8,
									timezone: "Pacific/Auckland",
									description: "",
									assign_to_user_id: $scope.users[0].value,
									assign_to_practice_id: $scope.Session.practice.id,
									label_id: $scope.labels[0].task_label_id
								},
								injection_note_automation_settings: {
									create_task_checked: true,
									days_until_due_date: 8,
									timezone: "Pacific/Auckland",
									description: "",
									assign_to_user_id: $scope.users[0].value,
									assign_to_practice_id: $scope.Session.practice.id,
									label_id: $scope.labels[0].task_label_id
								},
								operation_note_automation_settings: {
									create_task_checked: true,
									days_until_due_date: 8,
									timezone: "Pacific/Auckland",
									description: "",
									assign_to_user_id: $scope.users[0].value,
									assign_to_practice_id: $scope.Session.practice.id,
									label_id: $scope.labels[0].task_label_id
								},
								histology_automation_settings: {
									create_task_checked: true,
									days_until_due_date: 8,
									timezone: "Pacific/Auckland",
									description: "",
									assign_to_user_id: $scope.users[0].value,
									assign_to_practice_id: $scope.Session.practice.id,
									label_id: $scope.labels[0].task_label_id
								},
							};
						}
						$scope.onCatalogChange();
					},
					(error) => showErrorDialog()
				)
				.then(() => {
					$scope.API.isProcessing = false;
					$scope.$apply();
				});
		}

		$scope.positiveClick = () => {
			if ($scope.serverErrorModal) {
				$scope.serverErrorModal.hide();
				$scope.$mdDialog.hide();
			}
		};

		function showErrorDialog() {
			$scope.serverErrorModal = $modal({
				scope: $scope,
				templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
				show: true,
				backdrop: "static",
				keyboard: false,
				title: "CatTrax has encountered an error",
			});
		}
	},
]);
