app.component("operationNoteHeader", {
	templateUrl: "app/src/js/components/operationNote/operationNoteHeader/operationNoteHeader.html",
	controller: "operationNoteHeaderController",
	bindings: {
		data: "=",
		header: "="
	},
});

app.controller("operationNoteHeaderController", [
	"$scope",
	"CurrentPatient",
	"MEDICAL_INSURANCE",
	"$window",
	function ($scope, CurrentPatient, MEDICAL_INSURANCE, $window) {
		this.$onInit = function () {
			this.CP = CurrentPatient;
		};

		$scope.getInsuranceName = function (provider) {
			const item = MEDICAL_INSURANCE.find(t => t.value === provider);
			return item ? item.name : 'Add insurance';
		}

		$scope.backDashboard = function(){
			$window.history.back();
		}
	},
]);
